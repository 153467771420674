import React from 'react'
import { Progress } from 'antd'
import { purpleDark } from 'assets/styles/mixins.scss';
import styles from './index.module.scss'

/**
 *
 * @param {number} percent
 * @param {string} title
 * @param {string} subtitle
 * @param {Object|string} color
 * @param {'small' | 'default'} size
 * @return {JSX.Element}
 */
const ProgressInfo = ({ percent, title, subtitle, color, size = 'default', className = '' }) => {


  return (
    <div className={`${styles.progress_container} ${size === 'small' ? styles.small_bar : styles.default_bar} ${className}`}>
      <div className={styles.progress_title_container}>
        <span>{title}</span>
        <span>{percent} %</span>
      </div>
      <Progress percent={percent} showInfo={false} strokeColor={color || purpleDark} />
      <span className={styles.progress_subtitle}>{subtitle}</span>
    </div>
  )
}

export default  ProgressInfo
