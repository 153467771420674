import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

// hooks
import useGlotio from 'hooks/useGlotio'
import { useCreateModal } from 'hooks/useCreateModal'

// selectors
import { selectProjectId } from 'redux/project/selectors'
import { selectConfirmedSubscriptionPlan, selectIsLoadingSubscription } from 'redux/subscription/selectors'
import { selectIsFirstSession, selectZendeskToken } from 'redux/user/selectors'

// components
import GlotioChatButton from 'components/GlotioComponents/GlotioChatButton'

// constants
import { COOKIES_CONFIRM_MODAL } from 'constants/modals.constants'

// utils
import { userAcceptedAllCookies } from 'utils/cookies'

const ZendeskChat = ({ projectId, isFirstSession, hasSubscriptionPlan, zendeskToken, isLoadingSubscription }) => {
  const { selectedLocale } = useGlotio()
  const { onOpenModal } = useCreateModal()
  const [isOpenChat, setIsOpenChat] = useState(false)
  const [hasLoadedChat, setHasLoadedChat] = useState(false)
  const [unreadMessages, setUnreadMessage] = useState(0)
  const [allCookiesAccepted, setAllCookiesAccepted] = useState(() => userAcceptedAllCookies())
  const isFirstAssistant = Boolean(projectId) && (isFirstSession || (!isLoadingSubscription && !hasSubscriptionPlan))
  const isLoadingChat = allCookiesAccepted && !hasLoadedChat

  useEffect(() => {
    const onLoadZendesk = (e) => {
      setHasLoadedChat(e.detail.loaded)
      window.zE("messenger:on", "unreadMessages", (count) => {
        setUnreadMessage(count)
      })
    }
    const cookiesChanged = (e) => {
      setAllCookiesAccepted(e.detail.allCookiesAccepted)
    }

    document.addEventListener('cookies:on', cookiesChanged)
    document.addEventListener('zendesk:load', onLoadZendesk)

    return () => {
      document.removeEventListener('cookies:on', cookiesChanged)
      document.removeEventListener('zendesk:load', onLoadZendesk)
    }
  }, [])

  useEffect(() => {
    if (hasLoadedChat && selectedLocale) {
      window.zE('messenger:set', 'locale', selectedLocale)
    }
  }, [selectedLocale])

  useEffect(() => {
    if (hasLoadedChat && projectId) {
      window.zE("messenger:set", "conversationTags", [`glotio-project-${projectId}`])
      window.zE("messenger:on", "open", () => {
        setIsOpenChat(true)
      })
      window.zE("messenger:on", "close", () => {
        setIsOpenChat(false)
      })
    }
  }, [projectId, hasLoadedChat])

  useEffect(() => {
    if (hasLoadedChat && zendeskToken) {
      window.zE('messenger', 'loginUser', (callback) => {
        callback(zendeskToken)
      })
    }
  }, [hasLoadedChat, zendeskToken])

  useEffect(() => {
    if (hasLoadedChat) {
      if (!isFirstAssistant) {
        window.zE('messenger', 'hide')
      } else {
        window.zE('messenger', 'show')
      }
    }
  }, [isFirstAssistant, hasLoadedChat])

  const openZendeskChat = () => {
    if (!allCookiesAccepted) {
      onOpenModal({
        type: COOKIES_CONFIRM_MODAL,
        maskClosable: false,
        closable: false
      })
      return
    }

    if (hasLoadedChat) {
      toggleZendeskChat()
    }
  }

  const toggleZendeskChat = () => {
    if (isOpenChat) {
      window.zE('messenger', 'close')
      return
    }
    window.zE('messenger', 'open')
  }




  if (isFirstAssistant) {
    return <GlotioChatButton onClick={openZendeskChat} unreadMessages={unreadMessages} isLoading={isLoadingChat} isOpenChat={isOpenChat} />
  }

  return null
}

const mapStateToProps = (state) => ({
  projectId: selectProjectId(state),
  isFirstSession: selectIsFirstSession(state),
  hasSubscriptionPlan: selectConfirmedSubscriptionPlan(state),
  zendeskToken: selectZendeskToken(state),
  isLoadingSubscription: selectIsLoadingSubscription(state)
})

export default connect(mapStateToProps, null)(ZendeskChat)
