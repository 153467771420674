/**
 * Converts number to string
 * e.g 2000000 tunrs into 2 mill
 *
 * @returns {(number) => string} Converted number "2 million"
 */
const StringNumber = (translateText) => {
  return (value) => {
    const suffixes = {
      1 : {
        singular: translateText("thousand"),
        plural: translateText("thousand")
      },
      2 : {
        singular: translateText("million"),
        plural: translateText("millions")
      }
    }

    let suffixNum = Math.floor(((` ${value}`).length - 2)/3)
    suffixNum = suffixNum > 2 ? 2 : suffixNum
    let shortValue = parseFloat((suffixNum !== 0 ? (value / 1000 ** suffixNum) : value).toPrecision(2))
      if (shortValue % 1 !== 0) {
        shortValue = shortValue.toFixed(1)
      }
      if(suffixNum === 0 ) {
        return `${shortValue}`
      }
      return `${shortValue} ${shortValue > 1 ? suffixes[suffixNum].plural : suffixes[suffixNum].singular}`
    }

  }

  export default StringNumber;
