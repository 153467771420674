import actions from './actions';

const initialState = {
    loading: false,
    error: null,
    currentSubscription: null,
}

export default function userReducer(state = initialState, { type, payload }) {
    switch (type) {
      case actions.START_SUBSCRIPTIONS_REQUEST:
        return {
          ...state,
          loading: true,
          error: null,
        }
    case actions.SUBSCRIPTIONS_REQUEST_SUCCESS:
        return {
          ...state,
          loading: false,
          error: null,
          currentSubscription: payload
        }
    case actions.SUBSCRIPTIONS_REQUEST_ERROR:
        return {
          ...state,
          loading: false,
          error: payload,
        }
      default:
        return state
    }
  }
