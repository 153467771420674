import React from 'react'

// utils
import { isEmpty } from 'lodash'

// components
import InfoPopover from 'components/CleanUIComponents/Popover/InfoPopover'

// hooks
import useGlotio from 'hooks/useGlotio'

// constants
import { statusColor, statusType } from 'constants/status.constants'

// utils
import abbreviateNumber from 'utils/abbreviateNumber'

// styles
import styles from './index.module.scss'

/**
 * @param {DefaultLanguageEstimation | Object} defaultLanguages
 * @param {NewTranslationOffer[]} installedLanguages
 * @return {JSX.Element}
 */
export default function AnalyseResume ({ defaultLanguages, installedLanguages, className = '', info = '', status = statusType.info }) {
  const { translateText, translateIsoCode } = useGlotio()
  const abbreviateNumberFunction = abbreviateNumber(translateText)

  /**
   * Returns number of characters
   *
   * @returns {JSX.Element}
   */
  const getCharacters = () => {
    return (
      <>
        <div>
          <h1>{ abbreviateNumberFunction(defaultLanguages.stats.numChars) }</h1>
          <p>{ translateText('Characters') }</p>
        </div>
      </>
    )
  }

  /**
   * Returns number of words
   *
   * @returns {JSX.Element}
   */
  const getWords = () => {
    const words = Math.round((defaultLanguages.stats.numChars) / 5)
    const result = abbreviateNumberFunction(words)
    return (
      <>
        <div>
          <h1>{ result }</h1>
          <p>{ translateText('Words') }</p>
        </div>
      </>
    )
  }

  /**
   * Returns default language
   *
   * @returns {JSX.Element}
   */
  const getDefaultLanguage = () => {
    return (
      <>
        <div>
          <h1 className={styles.default_language}>
            { !isEmpty(defaultLanguages) ? translateIsoCode(defaultLanguages.stats.languageIso) : translateText('There is no default language') }
          </h1>
          <div className={styles.lang_default_wrapper}>
            <p>{ translateText('Default language') }</p>
            {info && 
              <InfoPopover
                message={info}
                color={statusColor[status]}
                direction='right'
              />
            }
          </div>
        </div>
      </>
    )
  }

  /**
   * Returns count of installed languages
   *
   * @returns {JSX.Element}
   */
  const getInstalledLanguagesCount = () => {
    return (
      <>
        <div>
          <h1>{ installedLanguages.length + 1 }</h1>
          <p>{ translateText('Installed languages') }</p>
        </div>
      </>
    )
  }

  return (
    <>
      <div className={`${styles.resume_container} ${className}`}>
        {getDefaultLanguage()}
        {getCharacters()}
        {getWords()}
        {getInstalledLanguagesCount()}
      </div>
    </>
  )
}

