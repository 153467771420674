import React from 'react'
import { Link } from 'react-router-dom'
import { Icon, Spin } from 'antd'
import styles from './index.module.scss'

const styleForVariant = {
  default: styles.button_default,
  outlined: styles.button_outlined,
  link: styles.button_link
}

const styleForSize = {
  small: styles.button_small,
  medium: styles.button_medium,
  large: styles.button_large
}

const styleForStatus = {
  success: styles.success,
  error: styles.error,
  secondary: styles.secondary,
  warning: styles.warning,
  light: styles.light
}

/**
 * @param {('button', 'Link', 'a')} as
 * @param {string} url
 * @param children
 * @param {('default'|'outlined'|'link')} variant
 * @param {('success'|'error'|'secondary'|'warning'|'light')} status
 * @param {('small'|'medium'|'large')} size
 * @param {('button'|'submit'|'reset')} type
 * @param {boolean} isLoading
 * @param {boolean} fluid
 * @param {boolean} disabled
 * @param {Function} onClick
 * @param {JSX.Element} icon
 * @param {string} className
 * @return {JSX.Element}
 * @constructor
 */
const GlotioButton = ({
  as = 'button',
  url,
  children,
  variant,
  status,
  size,
  type = 'button',
  isLoading,
  fluid = false,
  disabled = false,
  onClick,
  icon,
  className,
  ...rest
}) => {
  const style = []
  style.push(styleForVariant[variant] || styleForVariant.default)
  style.push(styleForSize[size] || styleForSize.large)
  if (status) style.push(styleForStatus[status])
  if (fluid) style.push(styles.button_fluid)
  if (disabled) style.push(styles.disabled)
  if (as === 'Link' || as === 'a') style.push(styles.link)

  const getIconForButton = () => {
    if (isLoading || icon) {
      return isLoading ? <Spin className={styles.spin} indicator={<Icon type="loading" spin />} /> : icon
    }
    return <></>
  }

  if (as === 'a') {
    return (
      <a href={url} className={`${style.join(' ')} ${className}`} {...rest}>
        {getIconForButton()}
        {children}
      </a>
    )
  }

  if (as === 'Link') {
    return (
      <Link to={url} className={`${style.join(' ')} ${className}`} {...rest}>
        {getIconForButton()}
        {children}
      </Link>
    )
  }

  return (
    // eslint-disable-next-line react/button-has-type
    <button type={type} className={`${style.join(' ')} ${className}`} disabled={disabled || isLoading} onClick={onClick} {...rest}>
      {getIconForButton()}
      {children}
    </button>
  )
}

export default GlotioButton
