import firebase from 'firebase/app'

const GoogleAnalyticsInitializer = async (logger = null) => {
  if (!process.env.REACT_APP_FIREBASE_AUTH_DOMAIN) {
    return false;
  }

  if (firebase.apps.length) {
    return true;
  }

  try {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
      appId: process.env.REACT_APP_FIREBASE_APPID,
      measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
    }

    // Initialize Firebase
    firebase.initializeApp(firebaseConfig)

    const analyticsSupported = await firebase.analytics.isSupported()

    if (analyticsSupported) {
      firebase.analytics()
      return true
    }

    return false
  } catch (e) {
    if (logger) {
      logger(e.message)
    }
    return false
  }
}

export default GoogleAnalyticsInitializer
