export const ASSISTANT_ROUTER_HASH = {
  DOWNLOAD_COMPLETED: "download-completed",
  UPGRADE_TYPE: "upgrade-type",
  FIRST_TRANSLATE_QUESTIONS: "first-translate-questions",
  FREE_LANGUAGE_INSTALLATION: "option-1-free-language-selection",
  CONFIRM_LANGUAGES_INTEGRATION: "confirm-languages-integration",
  BILLING_FORM: "billing-form",
  DOWNLOAD_PROGRESS: "download-progress",
  TRANSLATE_PROGRESS: "translate-progress",
  CONFIRM_UPDATE: "confirm-update",
  TRANSLATION_FINISHED: "translation-finished",
  ABORTED: "aborted",
  START_ANALYSIS: "start-analysis",
  DEFAULT_LANGUAGE: "default-language",
  DEFAULT_LANGUAGE_POPUP: "default-language-popup",
  DEFAULT_LANGUAGE_HELP: "default-language-help",
  DEFAULT_LANGUAGE_HELP_POPUP: "default-language-help-popup",
  LANGUAGES_INSTALLATION: "languages-installation",
  LANGUAGES_SELECTION: "languages-selection",
  LANGUAGE_SELECTION_OPTION_2: "languages-selection-option-2",
  LANGUAGE_SELECTION_OPTION_3: "languages-selection-option-3"
}
