import antdData from 'antd/lib/locale-provider/it_IT'
import localeData from 'react-intl/locale-data/it'
import countries from './countries/it.json'
import languages from './languages/it.json'
import platform from './platform/it.json'
import urls from './urls/it.json'
import texts from './texts/it.json'
import faq from './faq/it.json'

export default {
  locale: 'it',
  antdData,
  localeData,
  messages: { ...texts, ...countries, ...languages, ...platform, ...urls, ...faq },
}
