import React from 'react'
import { translateMetaCategoriesSource } from 'utils/translation_sources'

// components
import CardCheckbox from 'components/CleanUIComponents/Checkbox/CardCheckbox'

// hoc
import withGlotio from 'hoc/withGlotio';

// utils
import { getFinalSegment } from 'utils/Utils';
import { fallBackTranslation } from 'utils/fallbackTranslation';

// constants
import { CURRENT_PROJECT_STORAGE_KEY } from 'constants/session_storage_keys.contants';
import { PLATFORMS } from 'constants/platforms.contants';

// styles
import styles from './index.module.scss';

const DefintionBlock = props => {
  const {
    definition,
    disabledFields,
    handleCheckboxChange,
    translateText,
    formatNumber,
  } = props

  const { translatable, fields, num_chars, meta_source, _id: definitionId } = definition
  const wildcardTemplateTranslation = ['theme', 'module', 'theme_module', 'mail', 'theme_email', 'module_email', 'theme_module_email'];
  const { platform = PLATFORMS.PRESTASHOP } = JSON.parse(sessionStorage.getItem(CURRENT_PROJECT_STORAGE_KEY)) || {}

   const getWildcardTranslation = (type) => {
    if (wildcardTemplateTranslation.includes(type)) {
      return translateText('templates')
    }

    return translateText('other_fields')
  }

  const getFieldCharsNumber = (chars, isDisabled = false) => {
    return (
      <>
        ({translateText('{chars} characters', { chars: formatNumber(chars) })})
        { isDisabled &&
          <>
            &nbsp;
            <span className={styles.not_supported}>{translateText('Not supported')}</span>
          </>
        }
      </>
    )
  }

  const isTranslatableField = (field) => {
    return translatable && field.translatable
  }

  const isActiveField = field => {
    return isTranslatableField(field) && !disabledFields.includes(field.field)
  }

  const getBlockName = () => {
    if (!meta_source) return null
    return <>{ translateMetaCategoriesSource(meta_source, ' > ', platform) }</>
  }

  const renderDefinitionHeader = () => {

    return (
      <div className={styles.definition_header}>
        <span className={styles.definition_title}>
          {getBlockName()}
        </span>
        <span className={styles.definition_title_info}>
          {getFieldCharsNumber(num_chars)}
        </span>
      </div>
    )
  }

  const renderFieldsByDefinition = () => {
    const listItems = fields.map(field => {
      const fieldName = `platform.fields.${ field.field === '*' ? getWildcardTranslation(meta_source.type) : field.field }`
      const isTranslatable = isTranslatableField(field)
      const fieldText = fallBackTranslation(translateText(fieldName), fieldName, getFinalSegment)

      return (
        <div key={field.field} className={styles.definition_block}>
          <CardCheckbox
            value={`${definitionId}-${field.field}`}
            title={fieldText}
            subtitle={getFieldCharsNumber(field.num_chars, !isTranslatable)}
            isChecked={isActiveField(field)}
            isDisabled={!isTranslatable}
            onChange={checked => handleCheckboxChange({ value: field.field, checked, key: definitionId })}
            fluid
          />
        </div>
      )
    })

    return listItems

  }

  return (
    <div className={styles.defintion_category_wrapper}>
      <div>
        {renderDefinitionHeader()}
      </div>
      <div className={styles.defintion_wrapper}>
        { renderFieldsByDefinition() }
      </div>
    </div>
  )
}

export default withGlotio(DefintionBlock)
