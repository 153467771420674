 /**
 * This function returns a fallback translation if the translated text is the same as the raw text.
 *
 * @param {string} translatedRawText - The translated version of a text.
 * @param {string} rawText - The original text that was attempted to be translated.
 * @param {Function} predicate - A function that provides a fallback translation when necessary.
 * @return {string}
 */
export const fallBackTranslation = (translatedRawText, rawText, predicate) => {
  if (translatedRawText === rawText) {
    const fallbackTranslation = predicate(rawText)
    return fallbackTranslation
  }
  return translatedRawText
}