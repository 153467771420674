import { createSelector } from 'reselect'

const selectLanguages = (state) => state.languages

/**
 * Returns the fetching status of the languages state
 */
export const selectFetchingLanguages = createSelector(
  [selectLanguages],
  (languages) => languages.fetching,
)

/**
 * Returns the error status of the languages state
 */
export const selectErrorFetchingLanguages = createSelector(
  [selectLanguages],
  (languages) => languages.error,
)

/**
 * Returns the configuration languages of the project
 */
export const selectConfigLanguages = createSelector(
  [selectLanguages],
  (languages) => languages.configLanguages,
)

/**
 * Returns the platform language
 */
 export const selectPlatformLanguage = createSelector(
  [selectLanguages],
  (languages) => languages.configLanguages.find(lang => lang.platform_default),
)

/**
 * Returns the loading message if exists
 */
 export const selectLanguagesLoadingMessage = createSelector(
  [selectLanguages],
  (languages) => languages.loadingMessage,
)

export const selectConfigLangugesIsos = createSelector(
  [selectLanguages],
  (languages) => languages.configLanguages.map(({iso}) => iso),
)