import { createSelector } from 'reselect'

/**
 * Returns the settings slice from the store
 * @param {Object<string, any>} state The redux store object
 */
const selectSettings = state => state.settings

/**
 * Returns if the menu is collapsed
 */
export const selectIsMenuCollapsed = createSelector(
  [selectSettings],
  settings => settings.isMenuCollapsed,
)

/**
 * Returns if the mobile view is active
 */
export const selectIsMobileView = createSelector(
  [selectSettings],
  settings => settings.isMobileView,
)

/**
 * Returns if the settings panel is opened
 */
export const selectIsSettingsOpen = createSelector(
  [selectSettings],
  settings => settings.isSettingsOpen,
)

/**
 * Returns if the light theme is active
 */
export const selectIsLightTheme = createSelector(
  [selectSettings],
  settings => settings.isLightTheme,
)

/**
 * Returns if the mobile menu is opened
 */
export const selectIsMobileMenuOpen = createSelector(
  [selectSettings],
  settings => settings.isMobileMenuOpen,
)

/**
 * Returns if the menu top is active
 */
export const selectIsMenuTop = createSelector([selectSettings], settings => settings.isMenuTop)

/**
 * Returns the currently active locale
 */
export const selectCurrentLocale = createSelector([selectSettings], settings => settings.locale)
