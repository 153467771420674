import actions from './actions';

const initialState = {
  selectedQuestion: null
}

export default function questionReducer(state = initialState, { type, payload }) {
  let question = null;
  switch (type) {
    case actions.SET_QUESTION:
      question = payload || initialState;
      sessionStorage.setItem('selectedQuestion', JSON.stringify(question))
      return {
        ...state,
        ...question
        
      }
    case actions.GET_QUESTION:
      return JSON.parse(sessionStorage.getItem('selectedQuestion')) || initialState
    case actions.DELETE_QUESTION:
      sessionStorage.removeItem('selectedQuestion')
      return initialState
    default:
      return state
  }

}