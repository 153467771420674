import React, { useState, useEffect, useRef } from 'react'

// selectors
import { notification, Skeleton } from 'antd'

// hoc
import withGlotio from 'hoc/withGlotio'
import withPaymentGateway from 'hoc/withPaymentGateway'

// models
import { updateAccountByIdRequest } from 'models/api/account'

// hooks
import useGlotio from 'hooks/useGlotio'
import { connect, useDispatch, useSelector } from 'react-redux'

// redux
import { selectCurrentAccount } from 'redux/account/selectors'
import { saveAccountUpdates, fetchAccountStart } from 'redux/account/actions'
import { clearError } from 'redux/payment/actions'
import { selectHasBackendError } from 'redux/payment/selectors'

// components
import Container from 'components/CleanUIComponents/Container'
import Summary from 'components/GlotioComponents/Summary'
import PaymentMethods from 'pages/payments/methods'
import Step from 'components/GlotioComponents/Step'
import Coupon from 'components/GlotioComponents/Summary/Coupon'
import BillingForm from 'components/GlotioComponents/BillingForm'

// utils
import Account from 'utils/Account'

// services
import mediator from "services/Mediator";

// style
import './index.module.scss'


/**
 *
 *
 * @param {{
 * extraInfo: Object[],
 * discount: number,
 * discountAmount: number,
 * total: Price,
 * subtotal: number,
 * tax: number,
 * summaryFields: Object[],
 * handlePay: Function,
 * pushBack: Function,
 * refetch: Function,
 * isLoading: boolean,
 * title: string,
 * discountCoupon: Price }} props
 * @returns {JSX.Element}
 */
const SummaryBilling = (props) => {
  const { extraInfo = [], total, tax, summaryFields, supplements = [], handlePay, pushBack, refetch, isLoading, title, discountCoupon ,coupons } = props
  const { currentAccount } = props

  const { translateText } = useGlotio()
  const dispatch = useDispatch()
  const hasPaymentBackendError = useSelector(selectHasBackendError)
  const [isSavingBillingInfo, setIsSavingBillingInfo] = useState(false);
  const [hasBillingData, setHasBillingData] = useState(Account.hasBillingData(currentAccount))
  const [hasPaymentMethod, setHasPaymentMethod] = useState(Account.hasPaymentMethod(currentAccount))
  const [formErrors, setFormErrors] = useState([]);
  const [showEditMode, setShowEditMode] = useState(undefined);
  const [isTyping, setIsTyping] = useState(false)
  const firstSave = useRef(false);

  useEffect(() => {
    const hasAllBillingData = Account.hasBillingData(currentAccount)
    const hasAllPaymentMethod = Account.hasPaymentMethod(currentAccount)
    setHasBillingData(hasAllBillingData)
    setHasPaymentMethod(hasAllPaymentMethod)

    if (typeof hasAllBillingData !== 'undefined' && !firstSave.current) {
      firstSave.current = true;
      setShowEditMode(hasAllBillingData)
    }

    if (!currentAccount) {
      dispatch(fetchAccountStart());
    }

  }, [currentAccount])

  useEffect(() => {
    mediator.subscribe('billing_form_error', setFormErrors)
  }, [])

  useEffect(() => {
    return () => {
      if (hasPaymentBackendError) {
        dispatch(clearError())
      }
    }
  }, [hasPaymentBackendError])

  const getSubtitle = () => ({
    billing: (
      <div>
        <p>
          { showEditMode ? translateText("Check the tax details of your business that you have entered so that invoices are issued with this information.") : translateText('Enter the tax details of your business so that invoices are issued with these details.')}
        </p>
      </div>
    ),
    payment_method: (
      <div>
        <p>
          { translateText("A confirmation with your bank may be required during the payment process.") }
        </p>
      </div>
    ),

  })

  const saveUpdatedBillingData = async (updatedInfo, onSuccess, onError) => {
    try {
      setIsSavingBillingInfo(true);
      const { error, result } = await updateAccountByIdRequest({
        accountId: currentAccount.id,
        params: updatedInfo,
      })

      if (!error && result.success) {
        dispatch(saveAccountUpdates(updatedInfo))
        showSuccessNotification()
        refetch()
        onSuccess();

      } else {
        showErrorNotification()
        onError();
      }
    } catch (err) {
      showErrorNotification()
      onError();
    } finally {
      setIsSavingBillingInfo(false);
    }
  }

  /**
   * Renders a success notification on screen
   *
   * @returns {JSX.Element}
   */
  const showSuccessNotification = () => {
    notification.success({
      message: translateText('Saved'),
      duration: 3,
      key: 'saveSuccess',
    })
  }

  /**
   * Renders an error notification on screen
   *
   * @returns {JSX.Element}
   */
  const showErrorNotification = () => {
    notification.error({
      message: translateText('An error has occurred'),
      duration: 3,
      key: 'saveError',
    })
  }

  if (!currentAccount) return <Container><Skeleton active /></Container>

  return (
    <Summary
      total={total}
      tax={tax}
      discountCoupon={discountCoupon}
      extraInfo={extraInfo}
      summaryFields={summaryFields}
      supplements={supplements}
      handleSubmitButton={{
        title: title || translateText('Pay and continue'),
        isLoading: isLoading || isSavingBillingInfo,
        onClick: handlePay,
        isDisabled: !hasBillingData || !hasPaymentMethod || formErrors.length > 0 || isTyping
      }}
      pushBack={pushBack}
      alwaysShowTotal
    >
      <Step
        title={translateText('Billing Information')}
        subtitle={getSubtitle().billing}
      >
        {currentAccount &&
          <BillingForm
            currentAccount={currentAccount}
            saveDataSource={saveUpdatedBillingData}
            initialEditMode={showEditMode}
            onTyping={setIsTyping}
          />
        }
      </Step>
      <Step
        title={translateText('Payment info')}
        subtitle={getSubtitle().payment_method}
      >
        <PaymentMethods />
      </Step>
      {coupons &&
        <Coupon coupons={coupons} refetch={refetch} />
      }
    </Summary>
  )
}

const mapStateToProps = (state) => ({
  currentAccount: selectCurrentAccount(state)
})

export default connect(mapStateToProps)(withGlotio(withPaymentGateway(SummaryBilling)));
