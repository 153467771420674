import React from 'react'

// hooks
import useGlotio from 'hooks/useGlotio'

// redux
import { useSelector } from 'react-redux'
import { selectPaymentData } from 'redux/account/selectors'

// components
import CreditCardLogo from 'components/GlotioComponents/CreditCardLogo'

// styles
import styles from './index.module.scss'

const SavedCreditCard = () => {
  const { translateText } = useGlotio()
  const { brand, exp_month, exp_year, last4 } = useSelector(selectPaymentData)

  const monthString = exp_month > 9 ? exp_month : `0${exp_month}`
  const yearString = String(exp_year).substring(2)

  return (
    <div className={styles.card_container}>
      <CreditCardLogo brand={brand} />
      <span className={styles.card_info}>{translateText('Ended in')} *{last4}</span>
      <span className={styles.card_date}>{`${monthString}/${yearString}`}</span>
    </div>
  )
}

export default SavedCreditCard
