import React, { useState } from 'react'
import { notification } from 'antd'

// components
import Summary from 'components/GlotioComponents/Summary'
import Step from 'components/GlotioComponents/Step'
import Coupon from 'components/GlotioComponents/Summary/Coupon'

// constants
import { PRODUCT_TYPE } from 'constants/product.constants'

// models
import { getSubscriptionsPaymentLink, getTranslationOffersPaymentLink } from 'models/api/payment'

// hooks
import useGlotio from 'hooks/useGlotio'


const SummaryBillingShopify = props => {

  const [isLoading, setIsLoading] = useState(false)
  const { translateText } = useGlotio()
  const {extraInfo = [], total, tax, summaryFields, supplements = [], enviromentProduct, payParams, title, pushBack, refetch, discountCoupon, coupons} = props

  const endpointsPaymentsRedirectsByProductType = {
    [PRODUCT_TYPE.SUBSCRIPTION]: getSubscriptionsPaymentLink,
    [PRODUCT_TYPE.TRANSLATION]: getTranslationOffersPaymentLink
  }

  function showErrorNotification() {
    notification.error({
      message: translateText('Unexpected error'),
      duration: 3,
      key: 'saveSuccess',
    })
  }

  const getPaymentUrl = async () => {
    setIsLoading(true);
    try {

      const { error, result } = await endpointsPaymentsRedirectsByProductType[enviromentProduct]({
        ...payParams
      })
      if (result.success && result.data) {
        window.location.href = result.data.redirect_url
      } else {
        showErrorNotification(error)
      }

    } catch (error) {
      showErrorNotification(error)
      
    } finally {
      setIsLoading(false);
    }

  }

  return (
    <Summary
      total={total}
      tax={tax}
      discountCoupon={discountCoupon}
      extraInfo={extraInfo}
      summaryFields={summaryFields}
      supplements={supplements}
      handleSubmitButton={{
        title: title || translateText('Pay and continue'),
        isLoading,
        onClick: getPaymentUrl,
        isDisabled: false
      }}
      pushBack={pushBack}
      alwaysShowTotal
    >
      <Step
        title={translateText('Confirm payment of your purchase')}
        subtitle={translateText('When you continue, you will proceed to checkout via Shopify.')}
      />

      {coupons &&
        <Coupon coupons={coupons} refetch={refetch} />
      }
    </Summary>
  )
}

export default SummaryBillingShopify