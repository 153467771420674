import Request from 'models/Request'

export const createAccountRequest = (accountData) => 
  Request.fetch({
    key: 'createAccount',
    schema: 'ApiResponse',
    url: '/api/v1/account',
    method: 'POST',
    params: accountData,
  })

export const checkIfAccountExistsRequest = ({ apikey, callback }) =>
  Request.fetch({
    key: 'checkIfAccountExists',
    schema: 'ApiResponse',
    url: `/api/v1/account/${apikey}/check`,
    method: 'GET',
    callback
  })

export const sendVerificationAccountToEmailRequest = ({ email, callback }) =>
  Request.fetch({
    key: 'sendVerificationEmail',
    url: `/api/v1/account/${email}/newValidationEmail`,
    method: 'POST',
    callback,
  })

  export const getAccountByIdRequest = ({ accountId, callback }) =>
  Request.fetch({
    key: 'getAccountById',
    url: `/api/v1/account/${accountId}`,
    method: 'GET',
    callback,
  })

export const updateAccountByIdRequest = ({ accountId, params, callback }) =>
  Request.fetch({
    key: 'updateAccountById',
    url: `/api/v1/account/${accountId}`,
    method: 'PUT',
    params,
    callback,
  })