import React, { useContext } from 'react'
import IntlContext from '../context/intl'

/**
 * Injects into the received component the translateTex function that allows it to translate
 * texts with or without variables inside to multiple languages
 *
 * @param {JSX.Element} Component The original component to alter
 * @returns {JSX.Element} The updated version of the component with the new props
 */
const withGlotio = (Component) => {
  return (props) => {
    const tools = useContext(IntlContext)

    return <Component {...props} {...tools} withGlotio />
  }
}

export default withGlotio
