import { get } from 'lodash';

// utils
import { getDateForUpdates, getUpdatesCounter } from "utils/CurrentSubscription"

// constants
import { AUTOMATIC_FREQUENCY_TEXT, MANUAL_FREQUENCY_TEXT } from 'constants/subscription.constants';

export const useUpdatesInfo = ({currentSubscription, translateText}) => {

  const { autoUpdateFrequency, manualUpdateFrequency } = get(currentSubscription, 'subscriptionPlan', {})
  const { manualCounter, automaticCounter } = getUpdatesCounter(translateText)(currentSubscription)
  const { manualUpdateDate, autoUpdateDate } = getDateForUpdates(translateText)(currentSubscription)

  const manualFrequencyText = translateText(MANUAL_FREQUENCY_TEXT[manualUpdateFrequency]);
  const automaticFrequencyText = translateText(AUTOMATIC_FREQUENCY_TEXT[autoUpdateFrequency]);

  return {
    manualCounter,
    automaticCounter,
    manualUpdateDate,
    autoUpdateDate,
    manualFrequencyText,
    automaticFrequencyText
  }

}
