import React, { useState } from 'react'
// hooks
import {connect, useDispatch, useSelector} from 'react-redux'
import useGlotio from 'hooks/useGlotio'

// components
import { Form, notification, Icon, Spin } from 'antd'
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js'
import InfoDanger from 'components/CleanUIComponents/Messages/InfoDanger'
import GlotioButton from 'components/GlotioComponents/GlotioButton'
import GlotioAlert from 'components/GlotioComponents/GlotioAlert'

// redux
import { bindActionCreators } from 'redux'

// actions
import { fetchAccountStart } from 'redux/account/actions'
import { addNewPaymentMethod, updatePaymentMethod } from 'redux/trackevents/actions'
import { clearError } from 'redux/payment/actions'

// selectors
import { selectHasBackendError, selectErrorMessage } from 'redux/payment/selectors'

// models
import { setPaymentMethodRequest } from 'models/api/payment'

// utils
import StripeUtils from 'utils/Stripe'
import withGlotio from 'hoc/withGlotio'

// redux
import {
  selectCurrentAccount,
  selectPaymentData
} from 'redux/account/selectors'

// styles
import styles from './index.module.scss'

/**
 * Credit card form to save up a new pay method into the current account
 *
 * @param {{ onCancel: Function, currentAccount: object }} props
 * @returns {JSX.Element}
 */
const CreditCardForm = (props) => {
  const { currentAccount, hasBackendError, backendErrorMessage, onCancel = null, clearBackendError } = props
  const { translateText } = useGlotio()
  const dispatch = useDispatch()

  const stripe = useStripe()
  const elements = useElements()

  const [isLoading, setIsLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  // stores the client secret used to confirm a setup intent
  const [clientSecret, setClientSecret] = useState({ error: null, client_secret: null })

  // stores the confirmed setup intent info that will be sent to the server
  const [setupIntentObject, setSetupIntentObject] = useState(null)

  const selectedPaymentData = useSelector(selectPaymentData);

  const brand = 'stripe'
  const canCancelEdit = onCancel
  const hasError = hasBackendError || errorMessage

  /**
   * ### Handles the onChange events on the CardElement component.
   * If the input is "complete" a client_secret is generated on the backend.
   * When the client_secret is correctly generated and stored on the component state
   * the submit button is enabled.
   *
   * @param {React.FormEvent<IbanElement>} event
   */
  const handleCardChange = async ({ complete, error }) => {
    if (error) {
      setErrorMessage(error.message)
      return
    }

    setErrorMessage(null)

    if (complete) {
      const response = await StripeUtils.getStripeClientSecretSetupIntent(currentAccount.id)
      setClientSecret(response)
      if (!response.error) {
        await trySaveCard(response)
      }
      return
    }

    setClientSecret({ error: null, client_secret: null })
  }

  /**
   * Handles the submit event on the credit card form.
   * It validates credit card and store its token on the server
   */
  const trySaveCard = async (response) => {
    setIsLoading(true)
    setErrorMessage(null)

    const { error, client_secret } = response

    if (!error && client_secret) {
      await confirmCreditCardSetup(client_secret)
    } else {
      setErrorMessage('Could not connect with the service to validate your card')
      setIsLoading(false)
    }
  }

  /**
   * Confirms card setup with stripes setupIntent created previously
   *
   * @param {string} client_secret
   */
  const confirmCreditCardSetup = async (client_secret) => {
    // get a reference to the Card Element mounted somewhere
    // in your <Elements> tree
    const cardElement = elements.getElement(CardElement)

    // if the card has already been confirmed it avoids confirm it again
    if (setupIntentObject && setupIntentObject.client_secret === clientSecret.client_secret) {
      await saveCreditCard(setupIntentObject)
      return
    }

    const setupOptions = {
      payment_method: {
        card: cardElement,
      },
    }

    try {
      const { error, setupIntent } = await stripe.confirmCardSetup(client_secret, setupOptions)

      if (!error && setupIntent) {
        // The setup has succeeded. Display a success message and send
        // result.setupIntent.payment_method to your server to save the
        // card to a Customer
        setErrorMessage(null)
        await saveCreditCard(setupIntent)
        setSetupIntentObject(setupIntent)
      } else {
        setErrorMessage(
          error ? error.message : 'Your card could not be saved. Please try again later.',
        )
        setIsLoading(false)
      }
    } catch (err) {
      setErrorMessage('Could not connect to the service. Please try again later.')
      setIsLoading(false)
    }
  }

  /**
   * Saves the new credit card reference on the server
   *
   * @param {Object<string, any>} setupIntent
   */
  const saveCreditCard = async (setupIntent) => {
    try {
      const { error, result } = await setPaymentMethodRequest({
        paymentObject: setupIntent,
        accountId: currentAccount.id,
      })

      if (error || result.success !== true) {
        notification.error({
          key: 'save-card-error',
          message: translateText('The credit card could no be saved. Please try again.'),
        })
      } else {
        clearBackendError()
        notification.success({
          key: 'save-card-success',
          message: translateText('Credit card successfully saved.'),
        })

        dispatch(fetchAccountStart())

        if (!selectedPaymentData) {
          dispatch(addNewPaymentMethod({brand}))
        } else {
          dispatch(updatePaymentMethod({brand}))
        }

        if (onCancel) {
          onCancel()
        }
      }
    } catch (err) {
      notification.error({
        key: 'save-card-error',
        message: translateText('The credit card could no be saved. Please try again.'),
      })
    } finally {
      setIsLoading(false)
      setSetupIntentObject(null)
      setErrorMessage(null)
      setClientSecret({ error: null, client_secret: null })
    }
  }

  return (
    <Form
      style={{ pointerEvents: isLoading && 'none' }}
      className={isLoading ? styles.form_loading : styles.form}
    >
      {clientSecret.error && (
        <Form.Item className={styles.alert_stripe_card}>
          <GlotioAlert type="error" message={translateText('Your credit card could not be verified')} />
        </Form.Item>
      )}
      <Form.Item
        className={hasError ? styles.form_item_with_error : styles.form_item}
      >
        <div className={styles.card_input_container}>
          {isLoading &&
            <Spin className={styles.spinner} indicator={<Icon type="loading" spin />} />
          }
          <CardElement
            options={{ hidePostalCode: true, disabled: isLoading }}
            onChange={handleCardChange}
            className={hasError ? styles.form_input_error : styles.form_input}
          />
          {errorMessage &&
            <div className={styles.error_message_container}>
              <InfoDanger text={translateText(errorMessage)} />
            </div>
          }
        </div>
      </Form.Item>
      {hasBackendError &&
        <div className={styles.error_message_container}>
          <InfoDanger text={translateText(backendErrorMessage)} />
        </div>
      }
      <Form.Item style={{ margin: 0 }} className={styles.form_item}>
        <div>
          {canCancelEdit && (
            <GlotioButton
              className={styles.cancel_button}
              type='button'
              variant='link'
              size='medium'
              onClick={onCancel}
              disabled={isLoading}
            >
              {translateText('Cancel')}
            </GlotioButton>
          )}
        </div>
      </Form.Item>
    </Form>
  )
}

const mapStateToProps = (state) => ({
  currentAccount: selectCurrentAccount(state),
  hasBackendError: selectHasBackendError(state),
  backendErrorMessage: selectErrorMessage(state)
})

const mapDispatchToProps = (dispatch) => ({
  clearBackendError: bindActionCreators(clearError, dispatch),
})


export default connect(mapStateToProps, mapDispatchToProps)(withGlotio(CreditCardForm));
