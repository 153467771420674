export const subscriptionType = {
  FREE: 'FREE',
  CLASSIC: 'CLASSIC',
  BUSINESS: 'BUSINESS',
  BASIC: 'BASIC',
  CORPORATE: 'CORPORATE'
}

export const MOST_POPULAR_PLAN = subscriptionType.BUSINESS;

export const subscriptionFrequencyType = {
  MONTHLY: 'MONTHLY',
  DAILY: 'DAILY',
  NONE: 'NONE',
  UNLIMITED: "UNLIMITED"
}

// TODO: (@frontend) ir añadiendo valores de constantes como vienen de la api en propiedad period
export const SUBSCRIPTIONS_PERIOD = {
  MONTHLY: 'MONTHLY',
  DAILY: 'DAILY',
}

export const SUBSCRIPTIONS_PERIOD_TEXT_WITH_CONTEXT = {
  MONTHLY: 'Per month',
  YEARLY: 'Per year'
}

export const CLASSIC_START_DATE = '2023-02-01 00:00:00'

export const AUTOMATIC_FREQUENCY_TEXT = {
  [subscriptionFrequencyType.NONE]: 'Automatics',
  [subscriptionFrequencyType.MONTHLY]: 'Automatic monthly',
  [subscriptionFrequencyType.DAILY]: 'Automatic daily',
  [subscriptionFrequencyType.UNLIMITED]: 'Automatic daily',
}

export const MANUAL_FREQUENCY_TEXT = {
  [subscriptionFrequencyType.NONE]: 'Manuals',
  [subscriptionFrequencyType.MONTHLY]: 'Manual monthly',
  [subscriptionFrequencyType.DAILY]: 'Manual daily',
  [subscriptionFrequencyType.UNLIMITED]: 'Manual daily',
}

export const CORPORATE_AUTOMATIC_UPDATES_MIN_AMOUNT = 2