import { useContext } from 'react'
import { WelcomeContext } from 'context/WelcomeContext'
import { ROUTES } from 'constants/routes.constants'
import { selectCurrentUser, selectUserToken } from 'redux/user/selectors'
import { useSelector } from 'react-redux'
import { get } from 'lodash'

export const useWelcome = () => {
  const welcomeContext = useContext(WelcomeContext)
  const userToken = useSelector(selectUserToken)
  const currentUser = useSelector(selectCurrentUser)
  const { currentProject, isOpenLoginModal, setIsOpenLoginModal, accountStatus } = welcomeContext
  const accountExists = get(accountStatus, 'accountExists', false)
  const canShowRegisterButton = currentProject && !accountExists
  const isUserAuthorized = Boolean(currentUser) && Boolean(userToken)
  const canShowLoginButton = !isUserAuthorized && accountExists

  const onOpenLoginModal = () => setIsOpenLoginModal(true)
  const onCloseLoginModal = () => setIsOpenLoginModal(false)

  const getUrlSignUp = () => {
    if (!currentProject) return ''
    const encodeParams = Object.keys(currentProject)
      .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(currentProject[key])}`)
      .join('&');

    const baseUrl = `${ROUTES.SIGN_UP}`
    const signUpURL = `${baseUrl}?${encodeParams}`;

    return signUpURL
  }

  return {
    getUrlSignUp,
    currentProject,
    isOpenLoginModal,
    onOpenLoginModal,
    onCloseLoginModal,
    canShowRegisterButton,
    canShowLoginButton
  }
}
