import React from 'react';

// icon 
import {ReactComponent as InfoIcon} from 'assets/images/icons/info.svg'

// styles
import styles from './index.module.scss';

const FieldMessage = ({message = "", className = "", icon = <InfoIcon /> }) => {

  return (
    <div className={`${styles.field_message_wrapper} ${className}`}>
      <div className={styles.field_message}>
        <span className={styles.icon}>{icon}</span>
        <span className={styles.message}>{message}</span>
      </div>
    </div>
  )
}

export default FieldMessage;
