import React from 'react';

// components
import RadioButton from 'components/CleanUIComponents/RadioButton';

// stytles
import styles from './index.module.scss';

const QuestionBlock = ({id, text, onChange}) => {
  return (
    <label key={id} htmlFor={id} className={styles.label_question}>
      <div className={`${styles.question_block}`}>
        <div className={styles.question_input}>
          <RadioButton value={id} name="radioOption" onChange={() => onChange(id)} size="small" />
        </div>
        <div className={`${styles.question_title}`}>
          {text}
        </div>
      </div>
    </label>
  )
}

export default QuestionBlock
