import { isEqual } from 'lodash'
import countryList from 'utils/country-list'

/**
 * @typedef {Object<string, any>} AccountData
 * @property {number} id
 * @property {number} user_owner_id
 * @property {number} active
 * @property {string} created_at
 * @property {string} updated_at
 * Billing information
 * @property {string} company_name
 * @property {string} document
 * @property {string} tax_type
 * @property {string} address1
 * @property {string} address2
 * @property {string} city
 * @property {string} postal_code
 * @property {string} iso_country
 * Monthly limit
 * @property {number} payment_limit
 * @property {number} monthly_spent
 * Payment information
 * @property {{
 *  type: string,
 *  object: Object<string, string | number>
 * }} paymentMethod
 * @property {string} price_rule
 * @property {string} payment_gateway_card_id
 */

export default class Account {
  /**
   * Checks if the account object passed has all necessary billing info stored
   *
   * @param {AccountData} account The object to check
   * @returns {boolean} If the account has all fields completed
   */
  static hasBillingData(account) {
    if (!account) return false

    if (
      account.company_name &&
      account.document &&
      account.address1 &&
      account.city &&
      account.postal_code &&
      account.iso_country
    ) {
      return true
    }

    return false
  }

  /**
   * Checks if the account has an active payment method
   *
   * @param {AccountData} account The current account object
   * @returns {boolean}
   */
  static hasPaymentMethod(account) {
    if (
      !account ||
      !account.paymentMethod ||
      !account.paymentMethod.type ||
      !account.paymentMethod.object
    )
      return false

    return true
  }

  /**
   * Returns the type of payment method setup on the account
   *
   * @param {AccountData} account The current account object
   * @returns {string}
   */
  static getActivePaymentMethodType(account) {
    if (!account || !Account.hasPaymentMethod(account)) {
      return null
    }

    return account.paymentMethod.type
  }

  /**
   * Returns the data for the current payment method on the account
   *
   * @param {AccountData} account The current account object
   * @returns {Object<string, string>}
   */
  static getActivePaymentMethodData(account) {
    if (!account || !Account.hasPaymentMethod(account)) {
      return null
    }

    return account.paymentMethod.object
  }

  /**
   * Checks if the monthly spent is greater than the 80% of the monthly limit
   * @param {AccountData} account The current account object
   * @returns {boolean}
   */
  static isCloseToMonthlyLimit(account) {
    if (!account || account.payment_limit == null || account.monthly_spent == null) {
      return false
    }

    const limitPercent = Math.round(account.payment_limit * 0.8)

    if (account.monthly_spent >= limitPercent && account.monthly_spent < account.payment_limit) {
      return true
    }

    return false
  }

  /**
   * Checks if the monthly spent is equal or greater than the payment limit
   * @param {AccountData} account
   * @returns {boolean}
   */
  static hasReachedMonthlyLimit(account) {
    if (!account || account.payment_limit == null || account.monthly_spent == null) return false

    if (account.monthly_spent >= account.payment_limit) {
      return true
    }

    return false
  }

  /**
   * Returns an object structure that will be used to generate a form to customize the model's data
   *
   * @param {AccountData} account The current store object from redux
   * @returns {Object<string, any>}
   */
  static getEditableFields = account => {
    if (!account) return null

    return {
      company_name: {
        type: 'text',
        name: 'company_name',
        label: 'Name / Company',
        editable: true,
        value: account.company_name || '',
        rules: {
          required: true,
        },
      },
      document: {
        type: 'text',
        name: 'document',
        label: 'NIF',
        editable: true,
        value: account.document || '',
        rules: {
          required: true,
        },
      },
      address1: {
        type: 'text',
        name: 'address1',
        label: 'Address',
        editable: true,
        value: account.address1 || '',
        rules: {
          required: true,
        },
      },
      postal_code: {
        type: 'text',
        name: 'postal_code',
        label: 'Zip code',
        editable: true,
        value: account.postal_code || '',
        rules: {
          required: true,
        },
      },
      city: {
        type: 'text',
        name: 'city',
        label: 'City',
        editable: true,
        value: account.city || '',
        rules: {
          required: true,
        },
      },
      iso_country: {
        type: 'select',
        name: 'iso_country',
        label: 'Country',
        editable: true,
        value: account.iso_country || '',
        placeholder: 'Select your Country',
        options: countryList,
        rules: {
          required: true,
        },
      },
    }
  }

  /**
   * Returns an object with the current values from the store
   *
   * @param {AccountData} account The current store object from redux
   * @returns {Object<string, any>}
   */
  static getEditableValues = account => {
    if (!account) return null

    return {
      company_name: account.company_name || '',
      document: account.document || '',
      address1: account.address1 || '',
      postal_code: account.postal_code || '',
      city: account.city || '',
      iso_country: account.iso_country || '',
    }
  }

  /**
   * Gets the label name of the country found by the iso recieved
   *
   * @param {string} iso
   * @returns {string}
   */
  static getUserLangNameFromIsoCode = iso => {
    if (!iso) return ''

    const foundLang = countryList.filter(country => {
      if (country.value.toLowerCase() === iso.toLowerCase()) {
        return true
      }

      return false
    })

    if (foundLang.length > 0) return foundLang[0].label

    return iso
  }

  /**
   * Checks if the user data has been loaded
   *
   * @param {{ currentAccount: AccountData }}
   */
  static hasLoaded = account => {
    if (account === null) return false

    return true
  }

  /**
   * Checks if the values passed are different from the original model values
   *
   * @param { AccountData } account
   * @param {Object<string, any>} editedValues
   * @returns {boolean}
   */
  static doesCurrentValuesDifferFromDataSource = (account, editedValues) => {
    const initialValues = Account.getEditableValues(account)

    return !isEqual(initialValues, editedValues)
  }
}
