import React from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import { Helmet } from 'react-helmet'
import Loadable from 'react-loadable'
import useGlotio from 'hooks/useGlotio'

import Loader from 'components/LayoutComponents/Loader'
import NotFoundPage from 'pages/404'
import IndexLayout from 'layouts'
import { ROUTES } from 'constants/routes.constants'

const loadable = (loader) =>
  Loadable({
    loader,
    delay: false,
    loading: () => <Loader />,
  })

const routes = [
  // Open new session (Login)
  {
    path: ROUTES.LOGIN,
    component: loadable(() => import('pages/login')),
    exact: true,
  },

  // Open new admin session
  {
    path: ROUTES.ADMIN,
    component: loadable(() => import('pages/loginAdmin')),
    exact: true,
  },

  // Handles direct auth tokens
  {
    path: ROUTES.DIRECT_AUTH,
    component: loadable(() => import('pages/direct-auth')),
    exact: true,
  },

  // Removes the current session (Logout)
  {
    path: ROUTES.LOG_OUT_FORCE,
    component: loadable(() => import('pages/logout')),
    exact: true,
  },

  // Forgot my password
  {
    path: ROUTES.PASSWORD_FORGOT,
    component: loadable(() => import('pages/password-forgot')),
    exact: true,
  },

  // Forgot my password
  {
    path: `${ROUTES.PASSWORD_RESET}/:tokenID`,
    component: loadable(() => import('pages/password-reset')),
    exact: true,
  },

  // Alternative to login and sigunp when cookies are disabled on the iframe
  {
    path: ROUTES.OPEN,
    component: loadable(() => import('pages/open')),
    exact: true,
  },

  // Warning when cookies are blocked in the browser
  {
    path: ROUTES.SIGN_IN_DISABLED,
    component: loadable(() => import('pages/signin-disabled')),
    exact: true,
  },

  // Create a new account (Only from Prestashop)
  {
    path: ROUTES.SIGN_UP,
    component: loadable(() => import('pages/signup')),
    exact: true,
  },

  // Email verification
  {
    path: ROUTES.EMAIL_VERIFICATION,
    component: loadable(() => import('pages/email-verification')),
    exact: true,
  },

  // Dashoard (homepage)
  {
    path: ROUTES.DASHBOARD,
    component: loadable(() => import('pages/dashboard')),
    exact: true,
  },

  // Translation Asistant
  {
    path: `${ROUTES.ASSISTANT}/:variant?`,
    component: loadable(() => import('pages/assistant')),
    exact: true,
  },

  // Advanced translation options
  {
    path: ROUTES.ADVANCED_OPTIONS,
    component: () => <Redirect to="/advanced-options/catalog" />,
    exact: true,
  },
  {
    path: `${ROUTES.ADVANCED_OPTIONS}/:section`,
    component: loadable(() => import('pages/options')),
    exact: true,
  },
  {
    path: ROUTES.ADVANCED_OPTIONS_MAILS,
    component: loadable(() => import('pages/options')),
    exact: true,
  },

  // User Profile
  {
    path: ROUTES.MY_PROFILE,
    component: () => <Redirect to={ROUTES.MY_PROFILE_USER} />,
    exact: true,
  },
  {
    path: ROUTES.MY_PROFILE_USER,
    component: loadable(() => import('pages/profile/user')),
    exact: true,
  },
  {
    path: ROUTES.MY_PROFILE_PROJECT,
    component: loadable(() => import('pages/profile/project')),
    exact: true,
  },
  {
    path: ROUTES.MY_PROFILE_PASSWORD,
    component: loadable(() => import('pages/profile/password')),
    exact: true,
  },

  // Payment info
  {
    path: ROUTES.PAYMENTS,
    component: () => <Redirect to={ROUTES.PAYMENTS_BILLING} />,
    exact: true,
  },
  {
    path: ROUTES.PAYMENTS_BILLING,
    component: loadable(() => import('pages/payments/billing')),
    exact: true,
  },
  {
    path: ROUTES.PAYMENTS_HISTORY,
    component: loadable(() => import('pages/payments/history')),
    exact: true,
  },
  {
    path: ROUTES.CONTACT,
    component: () => <Redirect to={ROUTES.CONTACT_CORPORATE} />,
    exact: true,
  },
  {
    path: ROUTES.CONTACT_CORPORATE,
    component: loadable(() => import('pages/contact/corporate')),
    exact: true,
  },
  {
    path: ROUTES.CONTACT_PARTIAL_UPDATE,
    component: loadable(() => import('pages/contact/partial-update')),
    exact: true,
  },
  {
    path: ROUTES.CONTACT_US,
    component: loadable(() => import('pages/contact/contact-us')),
    exact: true,
  },
  {
    path: ROUTES.CONTACT_PARTIAL_UPDATE_SUCCESS,
    component: loadable(() => import('pages/contact/partial-update/success')),
    exact: true,
  },
  {
    path: `${ROUTES.CONTACT_CORPORATE_SUCCESS}/:first?`,
    component: loadable(() => import('pages/contact/corporate/success')),
    exact: true,
  },
  {
    path: ROUTES.CONTACT_US_SUCCESS,
    component: loadable(() => import('pages/contact/contact-us/success')),
    exact: true,
  },
  // {
  //   path: '/payments/methods',
  //   component: loadable(() => import('pages/payments/methods')),
  //   exact: true,
  // },
  {
    path: `${ROUTES.SUBSCRIPTION_UPGRADE}/:type?`,
    component: loadable(() => import('pages/subscriptions')),
    exact: true,
  },
  {
    path: ROUTES.SUBSCRIPTION_DASHBOARD,
    component: loadable(() => import('pages/subscriptions-dashboard')),
    exact: true,
  },

  // Proposals
  {
    path: ROUTES.PROPOSALS,
    component: loadable(() => import('pages/proposals')),
    exact: true,
  },

  // Notifications
  {
    path: ROUTES.NOTIFICATIONS,
    component: loadable(() => import('pages/notifications')),
    exact: true,
  },

  // Historical (translation processes)
  {
    path: ROUTES.HISTORY,
    component: loadable(() => import('pages/history')),
    exact: true,
  },

  // My languages
  {
    path: ROUTES.MY_LANGUAGES,
    component: loadable(() => import('pages/my-languages')),
    exact: true,
  },

  // Excluded Words
  {
    path: ROUTES.EXCLUDED_WORDS,
    component: loadable(() => import('pages/excluded-words')),
    exact: true,
  },

  // Find and replace
  {
    path: ROUTES.FIND_AND_REPLACE,
    component: loadable(() => import('pages/find-and-replace')),
    exact: true,
  },

  // Dictionary
  {
    path: ROUTES.DICTIONARY,
    component: loadable(() => import('pages/dictionary')),
    exact: true,
  },

  // Contact
  {
    path: ROUTES.TECHNICAL_SUPPORT,
    component: loadable(() => import('pages/technical-support')),
    exact: true,
  },

  // Automatic updates
  {
    path: ROUTES.UPDATES,
    component: () => <Redirect to={ROUTES.MANUAL_UPDATES} />,
    exact: true,
  },
  {
    path: ROUTES.MANUAL_UPDATES,
    component: loadable(() => import('pages/updates')),
    exact: true,
  },
  {
    path: ROUTES.AUTOMATICALLY_UPDATES,
    component: loadable(() => import('pages/updates/automatically')),
    exact: true,
  },
  {
    path: `${ROUTES.UPDATE_SUBSCRIPTION_PAYMENT_CONFIRMED}/:uuid`,
    component: loadable(() => import('pages/update-subscription-payment-confirmed')),
    exact: true,
  },

  {
    path: `${ROUTES.MANUAL_PAYMENT_CONFIRMED}/:uuid`,
    component: loadable(() => import('pages/manual-payment-confirmed')),
    exact: true,
  },

  // Installation guide
  {
    path: `${ROUTES.GUIDE}/:id`,
    component: loadable(() => import('pages/guide')),
    exact: true,
  },

  {
    path: `${ROUTES.WELCOME_DASHBOARD_NO_AUTH}`,
    component: loadable(() => import('pages/welcome-dashboard-no-auth')),
    exact: true,
  },

  {
    path: `${ROUTES.WELCOME_DASHBOARD_AUTH}`,
    component: loadable(() => import('pages/welcome-dashboard-auth')),
    exact: true,
  },


  // Dashboard Redirection (Temporal fix)
  {
    path: '/',
    component: () => <Redirect to={ROUTES.DASHBOARD} />,
    exact: true,
  },
]

const Router = ({ history }) => {
  const { translateText } = useGlotio()

  return (
    <ConnectedRouter history={history}>
      <Route
        path="/"
        render={() => (
          <IndexLayout>
            <Helmet
              titleTemplate="Glotio | %s"
              title={translateText('Advanced translation system')}
            />
            <Switch>
              {routes.map((route) => (
                <Route key={route.path} {...route} />
              ))}
              <Route component={NotFoundPage} />
            </Switch>
          </IndexLayout>
        )}
      />
    </ConnectedRouter>
  )
}

export default Router
