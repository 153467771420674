import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import settings from './settings/sagas'
import process from './process/sagas'
import languages from './languages/sagas'
import project from './project/sagas'
import account from './account/sagas'
import eventTracker from './trackevents/sagas'

export default function* rootSaga() {
  yield all([
    user(),
    menu(),
    settings(),
    process(),
    languages(),
    project(),
    account(),
    eventTracker(),
  ])
}
