// Key is translation term

const externalUrls = {
  FAQ: 'FAQ_URL',
  CONTACT: 'CONTACT_URL',
  COOKIES: 'COOKIES_URL',
  PRIVACY: 'PRIVACY_URL',
  TERMS: 'TERMS_URL',
  FAQ_TOOLS: 'FAQ_TOOLS_URL',
  ADVANTAGES_GLOTIO_GUIDE: 'ADVANTAGES_GLOTIO_GUIDE',
  LANGUAGE_LIST: "LANGUAGE_LIST",
  SWITCH_TO_SUBSCRIPTION_PLANS: "SWITCH_TO_SUBSCRIPTION_PLANS",
  DOWNLOAD_GLOTIO: "DOWNLOAD_GLOTIO",
  TECHNICAL_SUPPORT: "TECHNICAL_SUPPORT",
  FIRST_STEPS_WITH_GLOTIO: "FIRST_STEPS_WITH_GLOTIO",
  GLOTIO_NEWS: "GLOTIO_NEWS",
  QUICK_GUIDE: "QUICK_GUIDE",
  ADVANTAGES_GLOTIO: "ADVANTAGES_GLOTIO_URL",
  HOW_IT_WORKS_GLOTIO: "HOW_IT_WORKS_GLOTIO",
  TECHNOLOGY_GLOTIO: "TECHNOLOGY_GLOTIO",
  FEATURES_GLOTIO: "FEATURES_GLOTIO",
  TOOLS_GLOTIO: "TOOLS_GLOTIO",
  INTEGRATIONS_GLOTIO: "INTEGRATIONS_GLOTIO",
  TRANSLATE_LANGUAGE: "TRANSLATE_LANGUAGE",
  UPDATING_LANGUAGE: "UPDATING_LANGUAGE",
  EDITING_AND_CORRECTION_TOOLS: "EDITING_AND_CORRECTION_TOOLS",
  FIRST_TRANSLATION_INFO: "FIRST_TRANSLATION_INFO",
  LEARN_USE_GLOTIO: "LEARN_USE_GLOTIO",
  FIRST_TRANSLATION_PROMO_50: "FIRST_TRANSLATION_PROMO_50"
}

export default externalUrls
