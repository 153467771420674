import React, {useState} from 'react'
import {ReactComponent as InfoIcon} from 'assets/images/icons/info.svg'
import {ReactComponent as CloseIcon} from 'assets/images/icons/x-circle.svg'
import { Popover } from 'antd'
import styles from './index.module.scss'

const InfoPopover = ({ message, color, direction }) => {
  const [open, setOpen] = useState(false)
  const visiblePopover = (visible) => {
    setOpen(visible)
  }

  const hidePopover = () => {
    setOpen(false)
  }

  return (
    <Popover
      trigger='click'
      placement={direction}
      visible={open}
      onVisibleChange={visiblePopover}
      overlayClassName={styles.popover_container}
      content={
        <div className={styles.popover_content}>
          <div>
            {message}
          </div>
          <div className={styles.circle_icon_container}>
            <CloseIcon className={styles.circle_icon} onClick={() => hidePopover()} />
          </div>
        </div>
    }
    >
      <InfoIcon className={open ? styles.info_icon_active : styles.info_icon} color={color} />
    </Popover>
  )
}

export default InfoPopover
