import React from 'react'

// hooks
import useGlotio from 'hooks/useGlotio'

// constants
import { ASSISTANT_ROUTER_HASH } from 'constants/assistant.router.hash';
import useAssistantHash from 'hooks/useAssistantHash';

export default function ProcessingMessage({ isTranslating }) {
  const { translateText } = useGlotio()
  useAssistantHash(isTranslating ? ASSISTANT_ROUTER_HASH.TRANSLATE_PROGRESS : ASSISTANT_ROUTER_HASH.DOWNLOAD_PROGRESS, [isTranslating])

  if (isTranslating) {
    return (
      <div>
        <p>
          {translateText(
            'Glotio is translating your online store. It is important that during this process you do not add content to your website or make any modifications.',
          )}
        </p>
      </div>
    )
  }

  return (
    <div>
      <p>
        {translateText(
          'Glotio is analyzing your online store to detect all translatable content and find the total number of characters.',
        )}
      </p>
    </div>
  )
}
