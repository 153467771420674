import React from 'react'

// components
import HelperFooter from 'components/GlotioComponents/HelperFooter'
import { FaqLinkWithIcon } from 'components/GlotioComponents/FaqLink'

// hooks
import useGlotio from 'hooks/useGlotio'

// icon
import { ReactComponent as AlertCircleIcon } from 'assets/images/icons/alert-circle.svg'
import { ReactComponent as ExternalLink } from 'assets/images/icons/external-link.svg'

// styles
import styles from './index.module.scss'

/**
 * @typedef {Object} Action
 * @property {string} title
 * @property {function} onClick
 */

/**
 *
 * @param {string} title
 * @param {string|ReactNode} description
 * @param {Object[]} faqs
 * @param {ReactNode} children
 * @return {JSX.Element}
 */
const ShowError = ({ title, description, faqs = [], children }) => {
  const { translateText } = useGlotio()
  return (
    <div className={styles.default_error_wrapper}>
      <div className={styles.default_error}>
        <AlertCircleIcon className={styles.icon} />
        <h2>{ title }</h2>
        <p>{ description }</p>
        {children &&
          <div className={styles.children_wrapper}>
            {children}
          </div>
        }
        <div className={styles.footer_wrapper}>
          {faqs.length > 0 &&
          <>
            <p className={styles.title_faq}>{translateText('We recommend you to read these articles in case the problem continues.')}</p>
            <div className={styles.faqs_content}>
              {faqs.map(faq => {
                return (
                  <FaqLinkWithIcon key={faq.id} className={styles.faq_container} id={faq.id}>
                    {translateText(faq.text)}
                    <ExternalLink className={styles.link_icon} />
                  </FaqLinkWithIcon>
                  )
                })
              }
            </div>
          </>
        }
          <HelperFooter className={styles.help_error} />
        </div>
      </div>
    </div>
  )
}

export default ShowError
