import React from 'react'
import { Input, Select } from 'antd'
import { get } from 'lodash'

import countryList from 'utils/country-list'
import { generateComponentUuid } from 'utils/Utils'

// styles
import styles from './index.module.scss'

class InputPhone extends React.Component {
  render() {
    const { 
      value: inputValue = {},
      onChange,
      formatPhoneCodeLabel,
      codePhoneInputPlaceholder = '',
      inputPlaceHolder = '',
      phoneCodeInputWidth = '30%',
      numberPhoneInputWidth =  '70%'
    } = this.props;
    const { pcode , pnumber } = inputValue

    const defaultStyles = {
      phoneCodeStyle: {width: phoneCodeInputWidth},
      numberPhoneStyle: {width: numberPhoneInputWidth}
    }
    const triggerChange = (changedValue) => {
      if (typeof onChange === 'function') {
        onChange({ pcode, pnumber, ...changedValue })
      }
    }

    return (
      <Input.Group compact className={styles.group_phone}>
        <Select
          className={styles.select_phone_code}
          value={pcode || undefined}
          style={defaultStyles.phoneCodeStyle}
          showSearch
          placeholder={codePhoneInputPlaceholder}
          onChange={(selectValue) => triggerChange({ pcode: selectValue })}
          filterOption={(iValue, option) => get(option, 'props.children','').toLowerCase().includes(iValue.toLowerCase())}
        >
          { countryList.map(({label: country, value: isoCode, phoneCode}, index) => (
            <Select.Option key={generateComponentUuid(index)} value={`${isoCode}#${phoneCode}`}>{formatPhoneCodeLabel(country, phoneCode)}</Select.Option>
            ))
          }
        </Select>
        <Input
          style={defaultStyles.numberPhoneStyle}
          className={styles.input_phone}
          value={pnumber}
          placeholder={inputPlaceHolder}
          onChange={(e) => triggerChange({ pnumber: e.target.value })}
        />
      </Input.Group>
        
    );
  }
}


export default InputPhone