import React from 'react'

// components
import ProjectSelector from 'components/GlotioComponents/ProjectSelector'
import ProfileMenu from 'components/LayoutComponents/TopBar/ProfileMenu'
import HelpMenu from 'components/LayoutComponents/TopBar/HelpMenu'

// assets
import logoImg from 'assets/images/logos/glotio_logo_purple.svg'

// styles
import styles from './style.module.scss'

class TopBar extends React.Component {
  render() {
    const styleClass = `${styles.topbar} row`
    return (
      <div className={styleClass}>
        <div className={styles.logo_wrapper}>
          <picture>
            <img src={logoImg} alt="Glotio logo" />
          </picture>
        </div>
        <ProjectSelector />
        <HelpMenu />
        <ProfileMenu />
      </div>
    )
  }
}

export default TopBar