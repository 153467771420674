import React, { Component } from 'react'
import { connect } from 'react-redux'
import { isEmpty } from 'lodash'
import { withRouter } from 'react-router'
import withGlotio from 'hoc/withGlotio'

// components
import { Form, Input, Alert, Divider } from 'antd'
import Helmet from 'react-helmet'
import { Link } from 'react-router-dom'
import AuthHeader from 'components/GlotioComponents/AuthHeader'
import GlotioButton from 'components/GlotioComponents/GlotioButton'
import FieldMessage from 'components/GlotioComponents/FieldMessage'
import GlotioAlert from 'components/GlotioComponents/GlotioAlert'
import AuthPromotionBlock from 'components/GlotioComponents/AuthPromotionBlock'
import ShopifyLoginButton from 'components/GlotioComponents/ShopifyLoginButton'

// actions
import { loginUserStart } from 'redux/user/actions'
import { changeCurrentLocale } from 'redux/settings/actions'

// selectors
import { selectProjectEmail } from 'redux/project/selectors'
import {
  selectCurrentUser,
  selectIsUserLoading,
  selectIsUserError,
  selectUserToken,
} from 'redux/user/selectors'

// constants
import { ROUTES } from 'constants/routes.constants'
import { AUTO_LOGIN_ERRORS } from 'constants/backend_errors'

// services
import externalUrls from 'services/externalUrls'

// utils
import langsList from 'services/languages-list'
import GlotioURL from 'utils/GlotioURL'
import { isIframeAndNotSbIframe } from 'utils/Utils'

// styles
import authStyles from 'assets/styles/auth.module.scss';

const PROMO_DISCOUNT_EXTRA_NUM_LANGS = 'PROMO_DISCOUNT_EXTRA_NUM_LANGS';
const FIRST_TRANSLATE = 'FIRST_TRANSLATE';
const URL_PROMO_PARAM_NAME = 'promo';
const AVAILABLE_PROMO_CODES = [PROMO_DISCOUNT_EXTRA_NUM_LANGS, FIRST_TRANSLATE];

class LoginPage extends Component {
  constructor(props) {
    super(props)
    const { projectEmail } = this.props

    this.state = {
      email: projectEmail,
      password: '',
      remember: false,
      loadedFromPrestashop: isIframeAndNotSbIframe(),
      isValidated: false,
      autoLoginError: false,
    }
  }

  componentDidMount() {
    this.checkAccountValidation()
  }

  /**
   * Checks if a validation parameter has been received inside the url
   * If so, a message of validation success is shown to the user.
   *
   * Checks if a lang parameter has been received inside the url
   * If it is valid the interface changes its current locale
   */
  checkAccountValidation = () => {
    const { changeLocale } = this.props
    const { validated, errorCode, email, lang } = new GlotioURL().getAllSearchParams()

    if (validated === 'true') {
      this.setState({ isValidated: true })
    }

    if (errorCode && AUTO_LOGIN_ERRORS.includes(errorCode)) {
      this.setState({ autoLoginError: true })
    }

    if (email) {
      this.setState({email})
    }

    if (lang) {
      const lowerLocale = lang.toLowerCase()
      if (this.isValidLocale(lowerLocale)) {
        // launch a locale change
        changeLocale(lang)
      }
    }
  }

  /**
   * Checks is the received value is one of the interface safe langs
   */
  isValidLocale = localeParam => {
    return langsList.some(lang => lang.value === localeParam)
  }

  /**
   * Handles the change event on an input element
   *
   * @param {{ target: { name: string, value: string }}} event
   */
  handleInputChange = ({ target }) => {
    const { name, value } = target

    this.setState({
      [name]: value,
    })
  }

  /**
   * Handles the click events on the "remember" checkbox
   */
  handleRememberChange = () => {
    this.setState(prevState => ({ remember: !prevState.remember }))
  }

  /**
   * Handles submit events
   *
   * @param {import('react').FormEvent} event
   */
  handleSubmit = event => {
    event.preventDefault()
    const { email, password, remember } = this.state
    const { form, loginUser, location: { search }} = this.props

    const shouldShowLoginLoading = window.location === window.parent.location

    form.validateFields(error => {
      if (!error) {
        loginUser({ email, password, remember, redirectTo: search, loading: shouldShowLoginLoading });
      }
    })
  }

  getActivationPromoMessage = () => {

    const {translateText, location: {search}} = this.props;
    const urlParams = new URLSearchParams(search);

    if (!AVAILABLE_PROMO_CODES.includes(urlParams.get(URL_PROMO_PARAM_NAME))) {
      return null;
    }

    return (
      <>
        <Form.Item className={authStyles.form_item}>
          <Alert
            message={translateText('We have activated the promotion in your account.')}
            description={translateText('Login and select the languages to translate to apply the discount.')}
            type="success"
            showIcon
            className={authStyles.promoActivated}
          />
        </Form.Item>
        <div className={authStyles.form_separator_small} />
      </>
    );
  }

  handleClick = () => {

    const { translateText } = this.props
    window.open(translateText(externalUrls.DOWNLOAD_GLOTIO), '_blank')

  }

  render() {
    const { form, isUserLoading, loginError, translateText, hasLoginWith = true } = this.props
    const {
      email,
      password,
      loadedFromPrestashop,
      isValidated,
      autoLoginError,
    } = this.state

    const isValid = () => !isEmpty(email) && !isEmpty(password)

    const registerForgotPasswordLink = () => {
      const isFrameProps = {
        target:"_blank",
        rel:"noopener noreferrer"
      }
      return {
        to:ROUTES.PASSWORD_FORGOT,
        ...(loadedFromPrestashop ? isFrameProps : {})
      }
    }

    return (
      <>
        <Helmet title={translateText('Sign in')} />
        <div className={authStyles.wrapper_form}>
          <AuthHeader title="Sign in" />
          { hasLoginWith &&
            <>
              <ShopifyLoginButton />
              <Divider>o</Divider>
            </>
          }
          <Form hideRequiredMark onSubmit={this.handleSubmit}>
            {this.getActivationPromoMessage()}
            <Form.Item className={authStyles.form_item}>
              {form.getFieldDecorator('email', {
                initialValue: email,
                validate: [
                  {
                    trigger: ['onSubmit', 'onBlur'],
                    rules: [
                      {
                        type: 'email',
                        required: true,
                        message: <FieldMessage message={translateText('The email address you have entered is invalid.')} />,
                      },
                    ],
                  },
                  {
                    trigger: ['onFocus', 'onChange'],
                    rules: [{ required: false }],
                  },
                ],
              })(
                <div>
                  <div className={authStyles.form_label}>{translateText('Email')}</div>
                  <Input
                    size="default"
                    name="email"
                    type="email"
                    required
                    placeholder={translateText('Email')}
                    onChange={this.handleInputChange}
                  />
                </div>
              )}
            </Form.Item>
            <div className={authStyles.form_separator_small} />
            <Form.Item className={authStyles.form_item}>
              {form.getFieldDecorator('password', {
                initialValue: password,
                validate: [
                  {
                    trigger: ['onSubmit', 'onBlur'],
                    rules: [
                      {
                        required: true,
                        message: <FieldMessage message={translateText('You forgot to enter your password.')} />,
                      },
                    ],
                  },
                  {
                    trigger: ['onFocus', 'onChange'],
                    rules: [{ required: false }],
                  },
                ]
              })(
                <div>
                  <div className={authStyles.form_label}>{translateText('Password')}</div>
                  <Input.Password
                    name="password"
                    placeholder={translateText('Password')}
                    required
                    onChange={this.handleInputChange}
                  />
                </div>
              )}
            </Form.Item>
            <div className={authStyles.form_separator_medium} />
            <Form.Item className={authStyles.form_item}>
              <div className={authStyles.button_form}>
                <GlotioButton
                  type='submit'
                  size='large'
                  variant='default'
                  isLoading={isUserLoading}
                  disabled={!isValid()}
                >
                  {translateText('Log in')}
                </GlotioButton>
              </div>
            </Form.Item>
            <div className={authStyles.form_separator_small} />
            <Form.Item className={`${authStyles.form_item} ${authStyles.forgot_password}`}>
              <Link {...registerForgotPasswordLink()}>{translateText('Forgot your password?')}</Link>
            </Form.Item>
            {isValidated && (
              <>
                <div className={authStyles.form_separator_small} />
                <Form.Item className={authStyles.form_item}>
                  <GlotioAlert
                    type="success"
                    message={translateText('Your account has been successfully validated')}
                  />
                </Form.Item>
              </>
            )}
            {autoLoginError && (
              <>
                <div className={authStyles.form_separator_small} />
                <Form.Item className={authStyles.form_item}>
                  <GlotioAlert
                    type="info_danger"
                    message={translateText('Please log in to continue. If you are unable to log in, please contact us.')}
                  />
                </Form.Item>
              </>
            )}
            {loginError && (
              <>
                <div className={authStyles.form_separator_small} />
                <Form.Item className={authStyles.form_item}>
                  <GlotioAlert type="error" message={translateText(loginError)} />
                </Form.Item>
              </>
            )}
          </Form>
        </div>
        <AuthPromotionBlock
          title={translateText('Do you want to translate your online shop? The first language with a 50% discount')}
          rightComponent={(
            <GlotioButton
              type='submit'
              size='medium'
              variant='outlined'
              status='light'
              onClick={this.handleClick}
            >
              {translateText('Download Glotio')}
            </GlotioButton>
          )}
        />
      </>
    )
  }
}

LoginPage.defaultProps = {
  projectEmail: '',
  currentUser: null,
  token: null,
  isUserLoading: false,
}

const mapStateToProps = state => ({
  currentUser: selectCurrentUser(state),
  token: selectUserToken(state),
  isUserLoading: selectIsUserLoading(state),
  loginError: selectIsUserError(state),
  projectEmail: selectProjectEmail(state),
})

const mapDispatchToProps = dispatch => ({
  loginUser: loginData => dispatch(loginUserStart(loginData)),
  changeLocale: locale => dispatch(changeCurrentLocale(locale)),
})

export default Form.create()(
  connect(mapStateToProps, mapDispatchToProps)(withGlotio(withRouter(LoginPage))),
)
