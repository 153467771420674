import React from 'react'
import { SHOPIFY_LOGIN } from 'constants/urls.constants'
import shopifyLogo from 'assets/images/logos/shopify.png'

// hooks
import useGlotio from 'hooks/useGlotio'

// styles
import styles from './index.module.scss'

const ShopifyLoginButton = () => {
  const { translateText } = useGlotio()
  return (
    <a className={styles.shopify_link} href={SHOPIFY_LOGIN} rel='noopener noreferrer'>
      <img alt='Shopify logo' src={shopifyLogo} />
      <span>{translateText('Sign in with Shopify')}</span>
    </a>
  )
}

export default ShopifyLoginButton
