import React, { useState } from 'react';
import GlotioButton from 'components/GlotioComponents/GlotioButton'

// styles
import styles from './index.module.scss';

const ConfirmModal = ({buttons = [], title = "", description = "", image = {}}) => {
  
  const [isLoading, setIsloading] = useState({});

  const onCallback = (callback, loadingBeforeCallback, buttonIndex) => {
    if (loadingBeforeCallback) {
      setIsloading((prev) => ({...prev, [buttonIndex]: true }));
      callback();
      return;
    }
    callback()
  }

  return (
    <>
      {Object.keys(image).length > 0 &&
        <div className={styles.wrapper_image}>
          <img src={image.src} alt={image.alt || ''} className={image.className} />
        </div>
      }
      <div className={styles.wrapper_title}>
        { title && <p className={styles.title}>{title}</p>}
        { description && <div className={styles.description}>{description}</div>}
      </div>
      <div className={styles.wrapper_actions}>
        {
          buttons.map(({ text, callback, options, loadingBeforeCallback = false }, index) => (
            <GlotioButton isLoading={isLoading[index]} key={text} {...options} onClick={()=> onCallback(callback, loadingBeforeCallback, index)}>{text}</GlotioButton>
          ))
        }
      </div>
    </>
  )

}

export default ConfirmModal
