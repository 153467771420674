import React from 'react'

// hoc
import { connect } from 'react-redux'

// selectors
import { selectProjectUrl, selectProjectName } from 'redux/project/selectors'

// styles
import css from './index.module.scss'

const ProjectSelector = ({ projectName, projectUrl }) => {
  if (!projectName || !projectUrl) return null

  return (
    <div className={css.selector__container}>
      <div>
        <p className={css.project__name}>{projectName}</p>
      </div>
      <div>
        <a href={projectUrl} target="_blank" rel="noopener noreferrer" className={css.project__url}>
          {projectUrl}
        </a>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  projectName: selectProjectName(state),
  projectUrl: selectProjectUrl(state),
})

export default connect(mapStateToProps)(ProjectSelector)
