export default class Faq {
  static articles = {
    HOW_IT_WORKS: {
      id: 'HOW_IT_WORKS',
      text: 'How does Glotio work?',
    },
    LANGUAGE_DELETE: {
      id: 'LANGUAGE_DELETE_OR_MODIFIED',
      text: 'Translated language deleted',
    },
    LANGUAGE_MODIFIED: {
      id: 'LANGUAGE_DELETE_OR_MODIFIED',
      text: 'Source language modified',
    },
    HOW_DELETE_LANGUAGES: {
      id: 'HOW_DELETE_LANGUAGES',
      text: 'How to delete languages in your Prestashop',
    },
    SOURCE_LANGUAGE: {
      id: 'SOURCE_LANGUAGE',
      text: 'About PrestaShop default language',
    },
    CONNECTION_ERRORS: {
      id: 'CONNECTION_ERRORS',
      text: 'Why is Glotio not connecting to my online store?',
    },
    HOW_UPDATES_WORK: {
      id: 'HOW_UPDATES_WORK',
      text: 'How does Glotio keep my translations up to date?',
      text_alt: 'How Glotio updates my translations',
    },
    TRANSLATED_FIELDS: {
      id: 'TRANSLATED_FIELDS',
      text: 'What are the fields that Glotio can translate?',
    },
    TRANSLATION_MODES: {
      id: 'TRANSLATION_MODES',
      text: 'Which translation mode should I choose?',
    },
    SMART_DICTIONARY: {
      id: 'SMART_DICTIONARY',
      text: 'Learn how to use the smart dictionary',
    },
    EXCLUDED_WORDS: {
      id: 'EXCLUDED_WORDS',
      text: 'Learn more about excluded words',
    },
    FIND_AND_REPLACE: {
      id: 'FIND_AND_REPLACE',
      text: 'Learn more about search and replace',
    },
    UPDATE_MODULE: {
      id: 'UPDATE_MODULE',
      text: 'How to update your glotio module',
    },
    MULTISHOP_NOT_ALLOWED: {
      id: 'MULTISHOP_NOT_ALLOWED',
      text: 'Multishop is disabled in Glotio',
    },
    CHANGE_DEFAULT_LANGUAGE_PS : {
      id: 'CHANGE_DEFAULT_LANGUAGE_PS',
      text: 'Change the default language'
    },
    CHANGE_LANGUAGE_ISO : {
      id: 'CHANGE_LANGUAGE_ISO',
      text: 'ISO change in a translated language'
    },
    NOT_RECEIVED_VALIDATION_LINK : {
      id: 'NOT_RECEIVED_VALIDATION_LINK',
      text: ''
    },
    BALANCE_CHAR_EXCEEDED : {
      id: 'BALANCE_CHAR_EXCEEDED',
      text: 'Plan balance exceeded'
    },
    INSTALL_LANGUAGES_SELECTOR : {
      id: 'INSTALL_LANGUAGES_SELECTOR',
      text: 'How to install a language selector'
    },
    TRANSLATE_TEXT_NOT_APPEAR : {
      id: 'TRANSLATE_TEXT_NOT_APPEAR',
      text: 'I cannot see the translated text'
    },
    ADD_LANGUAGE_CMS : {
     id: 'ADD_LANGUAGE_CMS',
     text: 'How to add a language'
    },
    INSTALLED_LANGUAGES : {
      id: 'INSTALLED_LANGUAGES',
      text: 'Learn more about the languages installed.'
    }
  }
}
