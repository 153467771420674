// import React from 'react'
import { push } from 'react-router-redux'
import { call, put, all, takeLatest, delay, select } from 'redux-saga/effects'

// components
import { notification } from 'antd'

// models
import {
  cancelAndRestartProcessRequest,
  launchManualNewCOFRequest
} from 'models/api/process'

// constants
import { ORIGIN_INTEGRATION, ORIGIN_TRANSLATION, ORIGIN_UPDATES } from 'constants/process_origin.constants'
import { ROUTES } from 'constants/routes.constants'

// utils
import Process from 'utils/Process'
import Translator from 'utils/Translator'
import getLeftMenuData from 'services/menu'
import { isEqual } from 'lodash'

// actions
import { fetchProjectStart } from 'redux/project/actions'
import actionTypes, {
  processStatusSuccess,
  setCurrentProcessId,
  processStatusFail,
  launchNewTranslationProcessFinish,
  setCurrentProcess,
} from 'redux/process/actions'

// selectors
import {selectCurrentProcessId, selectProcessLaunchedFromOrigin} from 'redux/process/selectors'
import { selectMenuLeftData } from 'redux/menu/selectors'
import { selectProjectId } from 'redux/project/selectors'

/**
 * Blocks the left menu if an update process is running
 * @param {{process: object, status: object}} currentProcess
 */
function* updateMenu (currentProcess) {
  const { hasProcessFinished, hasProcessAborted, isProcessUpdating, isProcessReplacing, isProcessTranslating, isAskLangsToTranslate, coordinatorWithSpecialDisableMenu } = Process
  const { status, process } = currentProcess

  const isUpdating =
    isProcessUpdating(status) &&
    !hasProcessFinished(status) &&
    !hasProcessAborted(status)

  const isReplacing =
    isProcessReplacing(status) &&
    !hasProcessFinished(status) &&
    !hasProcessAborted(status)


  const isTranslating =
    isProcessTranslating(status) &&
    !isAskLangsToTranslate(status) &&
    !hasProcessFinished(status) &&
    !hasProcessAborted(status)

  const hasMenuDisabled = isUpdating || isReplacing || isTranslating
  const hasSpecialDisabled = Object.keys(coordinatorWithSpecialDisableMenu).includes(process.type) && hasMenuDisabled
  const allShouldBeDisabled = !hasSpecialDisabled && hasMenuDisabled
  const optionsDisable = hasSpecialDisabled ? coordinatorWithSpecialDisableMenu[process.type] : []

  const menuLeftData = yield getLeftMenuData(allShouldBeDisabled, optionsDisable)
  const currentLeftMenuData = yield select(selectMenuLeftData)

  if (!isEqual(menuLeftData, currentLeftMenuData)) {
    yield put({
      type: 'menu/SET_STATE',
      payload: {
        menuLeftData,
      },
    })
  }

  if (isUpdating) {
    yield put(push(ROUTES.ASSISTANT))
  } else if (isReplacing) {
    yield put(push(ROUTES.FIND_AND_REPLACE))
  }
}

/**
 * Handles the request for the latest status of the current process
 */
function* processStart({ newProcessStatus }) {
  const { status } = newProcessStatus
  if (isErrorStatus(status)) {
     yield put(
       processStatusFail({
         error: true,
         message: 'Process has error or is retrying',
       }),
     )
   } else {
     // manage the valid status object received
     yield call(handleNewStatus, newProcessStatus)
   }

  return true
}

/**
 * ### Handles the save process for a new status
 * If a process has info is stored directly on redux.
 * If a process has no info, it looks on the session for the last stored.
 * If has no older status stored then it saves to redux the status without info
 * but if founds one stored in the session it grabs it and store it on redux as the last response
 *
 * @param {Process} newProcess The status received from the server
 */
function* handleNewStatus(newProcess) {
  const { status: newStatus } = newProcess
  yield call(updateMenu, newProcess)
  yield put(launchNewTranslationProcessFinish())

  if (Process.isProcessReplacing(newStatus) && Process.hasProcessFinished(newStatus)) {
    yield put(processStatusSuccess(null))
    return
  }

  // When a FINISHED or ABORTED status comes from the server
  if (Process.hasProcessFinished(newStatus) || Process.hasProcessAborted(newStatus)) {
    yield put(processStatusSuccess(newStatus))
    yield call(saveStatusToSession, newStatus)
    return
  }

  // when a PROCESSING status with no currentSubProcess comes from the server
  if (Process.isProcessProcessing(newStatus) && !Process.hasCurrentSubProcess(newStatus)) {
    // get last valid status
    const lastStoredStatus = yield call(getLastStatusFromSession, newStatus.processID)

    // store to redux the last valid status (if it has data) or the new status
    yield put(processStatusSuccess(lastStoredStatus || newStatus))
  }

  // when a PROCESSING with a valid currentSubProcess comes from the server it is stored (redux & sessionStorage)
  if (Process.isProcessProcessing(newStatus) && Process.hasCurrentSubProcess(newStatus)) {
    yield put(processStatusSuccess(newStatus))
    yield call(saveStatusToSession, newStatus)
  }
}

/**
 * Stores the status object into the session storage with the process id as the key
 * @param {Process} status The latest process status object
 */
function* saveStatusToSession(status) {
  yield window.sessionStorage.setItem(status.processID, JSON.stringify(status))
}

/**
 * Get the last process status saved into the session storage
 * @returns {Process | null}
 */
function* getLastStatusFromSession(id) {
  const lastStatus = yield JSON.parse(window.sessionStorage.getItem(id))
  return lastStatus
}

/**
 * Handles a request to launch a new translation process
 */
function* launchNewTranslationProcessStart() {

  const processLaunchedFromOrigin = yield select(selectProcessLaunchedFromOrigin)

  yield put(setCurrentProcessId(null))
  yield put(processStatusSuccess(null))

  const projectId = yield select(selectProjectId)
  const { error, result } = yield launchManualNewCOFRequest({ projectId })

  if (error) {
    notification.error({
      key: 'onClickButton2',
      message: Translator.getTranslatedMessage(
        'An error occurred while starting the translation.',
      ),
      duration: 3,
      className: 'notification-no-closable',
    })
    yield put(launchNewTranslationProcessFinish())
  } else if (result && result.success) {

    notification.success({
      key: 'onClickButton2',
      message: Translator.getTranslatedMessage('Succesfully created new translation process'),
      duration: 2,
      className: 'notification-no-closable',
    })
    // after 3 seconds redirect to the the translation assistant
    yield delay(3000)
    yield put(fetchProjectStart())

    // WARN: this call will redirect to the assistant always.
    // In some testings this is not mandatory. But we should be watching
    // if this behavior will not cause errors or malfunction of this flow.

    if (processLaunchedFromOrigin === ORIGIN_TRANSLATION) {
      yield put(push(ROUTES.ASSISTANT));
    }
    if (processLaunchedFromOrigin === ORIGIN_UPDATES) {
      yield put(push(ROUTES.MANUAL_UPDATES));
    }

    if (processLaunchedFromOrigin === ORIGIN_INTEGRATION) {
      yield put(push(`${ROUTES.ASSISTANT}/${ORIGIN_INTEGRATION}`));
    }

  } else {
    notification.error({
      key: 'onClickButton2',
      message: Translator.getTranslatedMessage(
        'An error occurred while starting the translation.',
      ),
      duration: 3,
      className: 'notification-no-closable',
    })
    yield put(launchNewTranslationProcessFinish())
  }
}

/**
 * Handles the request to cancel the current process and launch a new one
 */
function* cancelAndRestartProcess() {
  const processId = yield select(selectCurrentProcessId)
  const { error, result } = yield cancelAndRestartProcessRequest({ processId })

  if (!error && result && result.success && result.new_process_id) {
    yield put(setCurrentProcess(null))
    yield put(setCurrentProcessId(result.new_process_id))
    yield put(fetchProjectStart())

    // resets the scroll position on the screen
    window.scrollTo(0, 0)

    notification.success({
      key: 'cancel-and-restart-process-success',
      message: Translator.getTranslatedMessage('Succesfully created new translation process'),
      duration: 3,
      className: 'notification-no-closable',
    })
  } else {
    notification.error({
      key: 'cancel-and-restart-process-error',
      message: Translator.getTranslatedMessage(
        'An error occurred while starting the translation.',
      ),
      duration: 3,
      className: 'notification-no-closable',
    })
    yield put(launchNewTranslationProcessFinish())
  }
}

// Action listeners
function* onFetchProcessStart() {
  yield takeLatest(actionTypes.PROCESS_STATUS_START, processStart)
}

function* onLaunchNewTranslationProcess() {
  yield takeLatest(actionTypes.LAUNCH_NEW_TRANSLATION_PROCESS, launchNewTranslationProcessStart)
}

function* onCancelAndRestartProcess() {
  yield takeLatest(actionTypes.CANCEL_AND_RESTART_PROCESS, cancelAndRestartProcess)
}

/** ******************
 * utility functions *
 ****************** */

/**
 * Checks if the process passed has an error
 * @param {object} processStatus The current process object
 */
function isErrorStatus(processStatus) {
  if (processStatus === undefined) {
    return false
  }

  const { status } = processStatus

  if (status === 'ERROR' && status === 'RETRY') {
    return true
  }

  return false
}

export default function* processSaga() {
  yield all([
    call(onFetchProcessStart),
    call(onLaunchNewTranslationProcess),
    call(onCancelAndRestartProcess),
  ])
}
