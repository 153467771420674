import React, { useRef } from 'react'
import { Form, Input } from 'antd'

// hooks
import useGlotio from 'hooks/useGlotio'

// components
import InfoDanger from 'components/CleanUIComponents/Messages/InfoDanger'
import GlotioButton from 'components/GlotioComponents/GlotioButton'

import styles from './index.module.scss'

/** @typedef {object} Coupon
 * @property {number} voucherId
 * @property {string} voucherCode
 * @property {object} voucherDiscount
 * @property {string} voucherDiscount.type
 * @property {number} voucherDiscount.value
 * @property {boolean} isAvailable
 * @property {object} monthlyDiscount
 * @property {number} monthlyDiscount.amount
 * @property {string} monthlyDiscount.currency
 * @property {object} yearlyDiscount
 * @property {number} yearlyDiscount.amount
 * @property {string} yearlyDiscount.currency
 */

/**
 *
 * @param {Coupon[]} coupons
 * @param {Function} refetch
 * @param {Object} form
 * @return {JSX.Element}
 */
const Coupon = ({ coupons, refetch, form }) => {
  const { translateText } = useGlotio()
  const couponSelected = useRef()
  const { validateFields, getFieldDecorator, resetFields, isFieldsTouched } = form
  const isError = coupons.some(coupon => coupon.isAvailable === false)
  const hasValidVoucher = coupons.some(coupon => coupon.voucherId && coupon.voucherCode === couponSelected.current)
  const isDirty = isFieldsTouched(['coupon'])
  const hasCoupon = !isError && coupons.length > 0

  const onSubmit = (e) => {
    e.preventDefault()
    validateFields(async (errors, { coupon }) => {
      if (!errors) {
        couponSelected.current = coupon
        refetch(coupon)
      }
    })
  }

  const handleDeleteCoupon = () => {
    couponSelected.current = undefined
    resetFields()
    refetch(null)
  }

  return (
    <div className={styles.coupon_container}>
      <h2 className={styles.title}>{translateText('Add promotional code')}</h2>
      <p className={styles.description}>{translateText('Get discounts or other offers by entering a promotional code.')}</p>
      <Form onSubmit={onSubmit}>
        <Form.Item className={`${isError ? styles.input_error : styles.input}`}>
          { getFieldDecorator('coupon')(
            <Input required id="coupon" type="text" name="coupon" disabled={hasCoupon} placeholder={translateText('Promotional code')} />
          )}
        </Form.Item>
        { isError &&
          <InfoDanger
            className={styles.info_danger_message}
            text={translateText(hasValidVoucher ? 'Coupon cannot be applied' : 'Coupon does not exist')}
          />
        }
        {
          hasCoupon
            ?
            (
              <div className={styles.buttons_container}>
                <GlotioButton type='button' variant='link' size='medium' status='error' onClick={handleDeleteCoupon}>
                  {translateText('Remove')}
                </GlotioButton>
              </div>
            )
            :
            (
              <GlotioButton type='submit' variant='link' size='medium' disabled={!isDirty}>
                {translateText('Save')}
              </GlotioButton>
            )
        }
      </Form>
    </div>
  )
}

export default Form.create()(Coupon)
