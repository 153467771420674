import { isEmpty, debounce, get } from 'lodash';

const debounceDelay = 1200;

export const DEFAULT_ACCOUNT = (account) =>  ({
  initialValues: {
    company_name: get(account,'company_name',''),
    document: get(account,'document',''),
    address1: get(account,'address1',''),
    postal_code: get(account,'postal_code',''),
    city: get(account,'city', ''),
    iso_country: get(account,'iso_country', undefined),
  },
  status: {
    document: { validateStatus: '', errorMessages: [], isTouched: false, errorField: (value, rules) => validateFields(value, rules)},
    address1: { validateStatus: '', errorMessages: [], isTouched: false, errorField: (value, rules) => validateFields(value, rules)},
    city: { validateStatus: '', errorMessages: [], isTouched: false, errorField: (value, rules) => validateFields(value, rules)},
    postal_code: { validateStatus: '', errorMessages: [], isTouched: false, errorField: (value, rules) => validateFields(value, rules)},
    company_name: { validateStatus: '', errorMessages: [], isTouched: false, errorField: (value, rules) => validateFields(value, rules)},
    iso_country: { validateStatus: '', errorMessages: [], isTouched: false, errorField: (value, rules) => validateFields(value, rules)}
  },
  
})

export const formHandlers = {
  
  company_name: debounce((fieldName, fieldValue, hasErrors, callback) => callback(fieldName, fieldValue, hasErrors), debounceDelay),
  document: debounce((fieldName, fieldValue, hasErrors, callback) => callback(fieldName, fieldValue, hasErrors), debounceDelay),
  address1: debounce((fieldName, fieldValue, hasErrors, callback) => callback(fieldName, fieldValue, hasErrors), debounceDelay),
  postal_code: debounce((fieldName, fieldValue, hasErrors, callback) => callback(fieldName, fieldValue, hasErrors), debounceDelay),
  city: debounce((fieldName, fieldValue, hasErrors, callback) => callback(fieldName, fieldValue, hasErrors), debounceDelay),
  iso_country: debounce((fieldName, fieldValue, hasErrors, callback) => callback(fieldName, fieldValue, hasErrors), debounceDelay),
  
}

const validators = {
  REQUIRED: value => isEmpty(value),
  MAX_LENGTH: (value, { max }) => value && value.length > max
}

const validateFields = (fieldValue, rules) => {
  const errors = [];
  rules.forEach(rule => {
    const { id, message = "", ...restProps} = rule;
    if (validators[id](fieldValue, restProps)) {
      errors.push(message)
    }
  });

  return errors;
}


export const ACCOUNT_RULES = {
  company_name: [
    { 
      id: 'REQUIRED',
      message: {
        asset_id: "The field '{field}' is a required field",
        params: [
          {
            id: 'field',
            value: "Name or company name",
            isTranslation: true
          }
        ]
      }
    },
    {
      id: 'MAX_LENGTH',
      max: 100,
      message: {
        asset_id: "The field '{field}' cannot be longer than {max} characters.",
        params: [
          {
            id: "field",
            value: "Name or company name",
            isTranslation: true
          },
          {
            id: 'max',
            value: 100
          }
        ]
      }
    }
  ],
  document: [
    { 
      id: 'REQUIRED', 
      message: {
        asset_id: "The field '{field}' is a required field",
        params: [
          {
            id: 'field',
            value: "NIF",
            isTranslation: true
          }
        ]
      }
    },
    {
      id: 'MAX_LENGTH',
      max: 20,
      message: {
        asset_id: "The field '{field}' cannot be longer than {max} characters.",
        params: [
          {
            id: "field",
            value: "NIF",
            isTranslation: true
          },
          {
            id: 'max',
            value: 20
          }
        ]
      }
    },
  ],
  address1: [
    { 
      id: 'REQUIRED', 
      message: {
        asset_id: "The field '{field}' is a required field",
        params: [
          {
            id: 'field',
            value: "Address",
            isTranslation: true
          }
        ]
      }
    },
    {
      id: 'MAX_LENGTH',
      max: 100,
      message: {
        asset_id: "The field '{field}' cannot be longer than {max} characters.",
        params: [
          {
            id: "field",
            value: "Address",
            isTranslation: true
          },
          {
            id: 'max',
            value: 100
          }
        ]
      }
    }
  ],
  postal_code: [
    { 
      id: 'REQUIRED', 
      message: {
        asset_id: "The field '{field}' is a required field",
        params: [
          {
            id: 'field',
            value: "Zip code",
            isTranslation: true
          }
        ]
      }
    },
    {
      id: 'MAX_LENGTH',
      max: 100,
      message: {
        asset_id: "The field '{field}' cannot be longer than {max} characters.",
        params: [
          {
            id: "field",
            value: "Zip code",
            isTranslation: true
          },
          {
            id: 'max',
            value: 100
          }
        ]
      }
    },
  ],
  city: [
    { 
      id: 'REQUIRED', 
      message: {
        asset_id: "The field '{field}' is a required field",
        params: [
          {
            id: 'field',
            value: "City",
            isTranslation: true
          }
        ]
      }
    },
    {
      id: 'MAX_LENGTH',
      max: 50,
      message: {
        asset_id: "The field '{field}' cannot be longer than {max} characters.",
        params: [
          {
            id: "field",
            value: "City",
            isTranslation: true
          },
          {
            id: 'max',
            value: 50
          }
        ]
      }
    },
  ],
  iso_country: [
    { 
      id: 'REQUIRED', 
      message: {
        asset_id: "The field '{field}' is a required field",
        params: [
          {
            id: 'field',
            value: "Country",
            isTranslation: true
          }
        ]
      }
    },
    {
      id: 'MAX_LENGTH',
      max: 50,
      message: {
        asset_id: "The field '{field}' cannot be longer than {max} characters.",
        params: [
          {
            id: "field",
            value: "Country",
            isTranslation: true
          },
          {
            id: 'max',
            value: 50
          }
        ]
      }
    },
  ]
}