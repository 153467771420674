import { ROUTES } from "constants/routes.constants";
import Storage from "utils/storage";
import SentryInitializer from "third_parties/sentry-initializer";
import ActivecampaignInitializer from "third_parties/activecampaign-initializer";
import HotjarInitializer from "third_parties/hotjar-initializer";
import GoogleAnalyticsInitializer from "third_parties/google-analytics-initializer";
import zendeskInitializer from 'third_parties/zendesk-initializer'

const couldAccessToBrowserStorage = Storage.isLocalStorageAvailable() && Storage.isSessionStorageAvailable();
const browserHasCookiesEnabled    = navigator.cookieEnabled;
const isMasterEnvironment         = process.env.REACT_APP_NODE_ENVIROMENT === 'master';
const isDevelopEnvironment        = process.env.REACT_APP_NODE_ENVIROMENT === 'develop';
const environmentIsAllowed        = isMasterEnvironment || isDevelopEnvironment;
const isAdminInterface            = window.location.pathname.includes(ROUTES.ADMIN);
const shouldInitializeTracker     = environmentIsAllowed && couldAccessToBrowserStorage && browserHasCookiesEnabled && !isAdminInterface;

export const initializeSentry = () => {
  logToConsole('Initializing Sentry')

  const sentryInitialized = SentryInitializer(logToConsole)

  if (!sentryInitialized) {
    logToConsole('Sentry is not available or it has been already initialized.')
    return;
  }

  logToConsole('Sentry Initialized')
}

export const initializeGoogleAnalytics = async () => {
  logToConsole('Initializing Google Analytics')

  const googleAnalyticsInitialized = GoogleAnalyticsInitializer(logToConsole)

  if (!googleAnalyticsInitialized) {
    logToConsole('Google Analytics not initialized')
    return;
  }

  logToConsole('Google Analytics Initialized')
}

export const initializeActiveCampaign = () => {
  logToConsole('Initializing Active Campaign')

  const activecampaignInitialized = ActivecampaignInitializer(logToConsole)

  if (!activecampaignInitialized) {
    logToConsole('Active Campaign not initialized')
    return;
  }

  logToConsole('Active Campaign Initialized')
}

export const initializeHotjar = () => {
  logToConsole('Initializing Hotjar')
  const hotjarInitialized = HotjarInitializer(logToConsole)


  if (!hotjarInitialized) {
    logToConsole('Hotjar not initialized')
    return;
  }

  logToConsole('Hotjar Initialized')
}

export const initializeTrackers = (allCookiesAccepted = true) => {
  if (Storage.getIsAdminUser() || !shouldInitializeTracker) {
    logToConsole(`Trackers will not load due to filters: isAdminUser: ${Storage.getIsAdminUser()}, !shouldInitializeTracker: ${!shouldInitializeTracker}`)
    return;
  }
  if (allCookiesAccepted) {
    loadAllThirdPartyApplications()
  }
}

export const initializeZendesk = () => {
  logToConsole('Initializing Zendesk')
  const zendeskInitialized = zendeskInitializer(logToConsole)

  if (!zendeskInitialized) {
    logToConsole('Zendesk not initialized')
    return
  }

  logToConsole('Zendesk Initialized')
}

const loadAllThirdPartyApplications = () => {
  initializeSentry()
  initializeGoogleAnalytics().then();
  initializeActiveCampaign()
  initializeHotjar()
  initializeZendesk()
}

const logToConsole = (logText) => {
  if (isMasterEnvironment) {
    return;
  }

  console.log(logText)
}
