import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import 'firebase/analytics'
import IntlPolyfill from 'intl'

// redux setup
import { store, history } from 'redux/store'

// components
import Router from 'router'
import Localization from 'components/LayoutComponents/Localization'
import GenericModal from 'components/CleanUIComponents/GenericModal'
import CookieLayout from 'components/GlotioComponents/CookieLayout'
import ZendeskChat from 'components/GlotioComponents/ZendeskChat'

// external services
import * as serviceWorker from './serviceWorker'

// app styles
import './global.scss'

if (process.env.REACT_APP_NODE_ENVIROMENT === 'local') {
  // eslint-disable-next-line global-require
  const { worker } = require('mocks/browser')
  worker.start({ onUnhandledRequest: 'bypass' }).catch((error) => console.log('error', error))
}

// Fixes intl runtime availability on safari and older browsers
if (!window.Intl) {
  window.Intl = IntlPolyfill
}

ReactDOM.render(
  <Provider store={store}>
    <Localization>
      <Router history={history} />
      <GenericModal />
      <CookieLayout />
      <ZendeskChat />
    </Localization>
  </Provider>,
  document.getElementById('root') || document.createElement('div'),
)
serviceWorker.unregister()
