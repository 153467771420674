import actionTypes from './actions'

const initialState = {
  configLanguages: null,
  fetching: false,
  error: null,
  loadingMessage: ""
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.FETCH_LANGUAGES_START:
      return {
        ...state,
        fetching: true,
        error: null,
        loadingMessage: payload
      }
    case actionTypes.FETCH_LANGUAGES_SUCCESS:
      return {
        ...state,
        configLanguages: payload,
        fetching: false,
        loadingMessage: ""
      }
    case actionTypes.FETCH_LANGUAGES_FAIL:
      return {
        ...state,
        error: payload,
        fetching: false,
        loadingMessage: ""
      }
    default:
      return {
        ...state,
      }
  }
}

export default reducer
