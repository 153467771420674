import { get, update } from 'lodash'
import { DEFAULT_PRIORITY_MODALS, PRIORITY_LOW, PRIORITY_MEDIUM } from 'constants/modals.constants'
import { CLOSE_MODAL, NEXT_MODAL, OPEN_MODAL } from './actions'

const initialState = {
  open: false,
  queue: []
}

export default function modalReducer(state = initialState, action ) {
  switch (action.type) {
    case OPEN_MODAL: {
      if (state.queue.some(({id}) => id === action.payload.message.id)) {
        return state
      }
      return { open: true, queue: addModalToQueue(action.payload.message, [...state.queue]) }
    }
    case CLOSE_MODAL: {
      const newQueue = deleteModalBeingViewedToQueue(action.payload.id, state.queue)
      return { open: newQueue.length !== 0, queue: newQueue }
    }
    case NEXT_MODAL : {
      return { ...state, queue: update(state.queue, '[0]', () => action.payload.message)  }
    }
    default:
      return state
  }

}

const deleteModalBeingViewedToQueue = (id, queue) => {
  if (id) {
    return  queue.filter(message => message.id !== id)
  }

  return queue
}

const addModalToQueue = (message, queue) => {
  const priority = getPriorityOfMessage(message)
  const newMessage = { ...message, priority }

  if (queue.length === 0) return [newMessage]

  if (priority === PRIORITY_LOW) return [...queue, newMessage]

  const indexToInsert = queue.findIndex(value => value.priority > priority)

  if (indexToInsert === -1) return [...queue, newMessage]

  queue.splice(indexToInsert, 0, newMessage)
  return queue
}

const getPriorityOfMessage = (message) => {
  const { priority } = message
  return priority || get(DEFAULT_PRIORITY_MODALS, message.type, PRIORITY_MEDIUM)
}
