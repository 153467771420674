import React from 'react'
import {cloneDeep, get } from 'lodash'

// components
import DoubleProgressInfo from 'components/CleanUIComponents/DoubleProgressInfo'

// utils
import { isFreeUserWhileProcessIsNotFinished } from 'utils/isFreeUser'
import abbreviateNumber from 'utils/abbreviateNumber'

import StringNumber from 'utils/StringNumber'
import { getPercentageForQuantity } from 'utils/Utils'

// constants
import {
  ADD_EXTRA_CHARACTERS_SECTION,
  ADD_LANGUAGES_SECTION,
  ADD_PLAN_SECTION,
  ADD_UPDATE_LANGUAGES_SECTION
} from 'constants/summary_sections.constants'
import { BIG_COMMERCE_LIMIT_CHARS } from 'constants/big_commerce.constants'
import { STRIPE_SUPPLEMENT } from 'constants/summary_extra_info.constants'
import { ROUTES } from 'constants/routes.constants'
import { subscriptionType } from 'constants/subscription.constants'
import { DISCOUNT_LANGUAGE_OFFER } from 'constants/language_offer.constants'

/**
 * Check offer structure and initialize the state with the languageIso property
 * @param {Offer|Object} offers
 * @return {null|string}
 */
export const initializeOfferLanguages = (offers) => {
  const selectedLanguage = offers.selectedLanguagesOffers || []
  if (selectedLanguage.length > 0 && selectedLanguage[0].languageIso) {
    return [selectedLanguage[0].languageIso]
  }
  return []
}

/**
 * Check offer structure and initialize the state with the languagesIso properties
 * @param {Offer|Object} offers
 * @return {[]|Offer[]}
 */

export const initializeSelectedLanguages = (offers) => offers.selectedLanguagesOffers.length ? offers.selectedLanguagesOffers.map((lang) => lang.languageIso) : []

/**
 * @typedef {Object} Summary
 * @property {Price} total
 * @property {Price} tax
 * @property {SummaryField[]} summaryFields
 * @property {Button} handleSubmitButton
 */
/**
 * parseSummaryValues Structure
 * @param {Summary | Object} initialSummaryValues
 * @param {NewTranslationOffer[] | []} selectedLanguagesOffers
 * @param {NewTranslationOffer[] | []} updateTranslationOffers
 * @param {TranslationUpdatesExtra[] | {}} translationUpdatesExtra
 * @param {TranslationUpdatesExtra[] | {}} selectedLanguagesExtra
 * @param {Totals | {}} languagesTotals
 * @param {string} currentPlan
 * @param {[]} sections
 * @param {Function} translateText
 * @param {[]} supplements
 * @param {string} currentPlanText
 * @param {Object} extraInfo
 * @param {function} translateIsoCode
 * @return {{Summary | Object}
 */

export const parseSummaryValues = ({

  initialSummaryValues,
  selectedLanguagesOffers = [],
  updateTranslationOffers = [],
  translationUpdatesExtra = {},
  subscriptionAddLanguagesExtra = {},
  selectedLanguagesExtra = {},
  languagesTotals,
  currentPlan = "",
  sections = [],
  translateText,
  supplements = [],
  currentPlanText = "",
  extraInfo = {},
  translateIsoCode
}) => {

  let summaryValues = cloneDeep(initialSummaryValues)

  const addSummaryTotal = (summary) => {
    const total = {
      amount: get(languagesTotals, 'total.amount', 0),
      currency: get(languagesTotals, 'total.currency', 'EUR')
    }
    summary.total = total
    return summary
  }

  const addSummaryTaxes = (summary) => {
    const tax = {
      amount: get(languagesTotals, 'taxTotal.amount', 0), currency: get(languagesTotals, 'taxTotal.currency', 'EUR')
    }
    summary.tax = tax
    return summary
  }

  const getEmptyLanguagesMessage = () => selectedLanguagesOffers.length > 0 ? '' : translateText('You have to select at least one language')

  const addSupplements = (summary) => {
    const supplementsSection = []
    switch(true) {
      case supplements.includes(STRIPE_SUPPLEMENT):
        supplementsSection.push({
          price :{
            amount:  get(languagesTotals, 'supplement.amount', 0),
            currency: get(languagesTotals, 'supplement.currency', 'EUR')
          },
          text: translateText('Supplement')
        })
        break
        default:
        break

    }
    summary.supplements = supplementsSection
    return summary
  }

  const getDiscountByType = (languageDiscount, total, amount, currency) => {
    const discountType = get(languageDiscount, 'type', null);
    if (discountType === DISCOUNT_LANGUAGE_OFFER.FIRST_LANGUAGE_FREE) {
      return translateText('Free')
    }
    if (discountType === DISCOUNT_LANGUAGE_OFFER.FIRST_LANGUAGE_DISCOUNT) {
      return {
        amount: total.amount,
        currency: total.currency
      }
    }
    return {
       amount,
       currency
    }

  }

  const setAddLanguageSection = () => {

    const section = {}
    const fields = []

    const title = {
      text: translateText('New languages'), info: translateText("This block collects the price of the new and first translations of your online store into new languages.")
    }

    const subtotal = {
      amount: get(selectedLanguagesExtra, 'total.amount', 0),
      currency: get(selectedLanguagesExtra, 'total.currency', 'EUR')
    }

    const discount = {
      discountAmount: {
        amount: get(selectedLanguagesExtra, 'discountTotalExcludingFreeLanguage.amount', 0),
        currency: get(selectedLanguagesExtra, 'discountTotalExcludingFreeLanguage.currency', 'EUR'),
      },
      percentage: '',
    }

    section.title = title
    section.infoMessage = getEmptyLanguagesMessage()

    section.subtotal = subtotal
    section.discount = discount

    selectedLanguagesOffers.forEach(({ languageIso, basePrice : { amount, currency }, discount:languageDiscount, total }) => {
      fields.push({
        title: translateIsoCode(languageIso),
        subtitle: get(languageDiscount, 'type', null) === DISCOUNT_LANGUAGE_OFFER.FIRST_LANGUAGE_DISCOUNT ? `${StringNumber(translateText)(BIG_COMMERCE_LIMIT_CHARS)} ${translateText('characters')}` :  '',
        info: getDiscountByType(languageDiscount, total, amount, currency),
        subInfo: get(languageDiscount, 'type', null) === DISCOUNT_LANGUAGE_OFFER.FIRST_LANGUAGE_DISCOUNT ? {amount, currency} : '',
        isStrokeLine: get(languageDiscount, 'type', null) === DISCOUNT_LANGUAGE_OFFER.FIRST_LANGUAGE_DISCOUNT,
        isBlue: get(languageDiscount, 'type', null) === DISCOUNT_LANGUAGE_OFFER.FIRST_LANGUAGE_FREE || get(languageDiscount, 'type', null) === DISCOUNT_LANGUAGE_OFFER.FIRST_LANGUAGE_DISCOUNT
      })
    })

    section.fields = fields

    return section

  }

  const setPlanSection = () => {
    const section = {}
    // TODO Done for Classic Plan
    const isClassicUser = subscriptionType.CLASSIC === currentPlan

    const title = {
      text: translateText('Current plan'),
      info: translateText('This is the plan to which you are currently subscribed.')
    }
    const fields = [{
      title: isClassicUser ? translateText('No plan') : currentPlanText,
      info: isClassicUser ? translateText('Choose plan') : translateText('Improve Plan'),
      url: ROUTES.SUBSCRIPTION_UPGRADE
    }]

    // volumen discount per language

    let subtotal = null
    let discount = null;
 
    if (languagesTotals.subscriptionTotal > 0 && !isClassicUser) {
      
      fields.push({
        title: translateText('New languages'),
        info: {
          amount: get(subscriptionAddLanguagesExtra, 'subtotal.amount', 0),
          currency: get(subscriptionAddLanguagesExtra, 'subtotal.currency', 'EUR')
        }
      })

      discount = {
        discountAmount: {
          amount: get(subscriptionAddLanguagesExtra, 'discount.value.amount', 0),
          currency: get(subscriptionAddLanguagesExtra, 'discount.value.currency', 'EUR'),
        }
      }

      if (discount.discountAmount.amount > 0) {
        subtotal = {
          amount: languagesTotals.subscriptionTotal || 0,
          currency: get(selectedLanguagesExtra, 'total.currency', 'EUR')
        }
      }

    }

    if (get(extraInfo, `${ADD_PLAN_SECTION}`, false)) {
      fields.push({
        type: 'extraInfo',
        ...extraInfo.ADD_PLAN_SECTION
      })
    }

    section.discount = discount
    section.subtotal = subtotal
    section.title = title
    section.fields = fields

    return section
  }

  const setUpdateLanguagesSection = () => {

    let section = {}
    const fields = []

    const tooltipMessage = currentPlan === subscriptionType.FREE ?
    translateText('If you have reached the limit of your character bag or if you are subscribed to the Free Plan, updating your languages will have an extra cost, which will depend on the conditions of the plan to which you are subscribed.') :
    translateText("This is the balance of characters you have available per month to update your languages.")

    const title = {
      text: translateText('Update languages'),
      info: tooltipMessage
    }
    if (isFreeUserWhileProcessIsNotFinished(translationUpdatesExtra)) {
      const discount = {
        discountAmount: {
          amount: get(translationUpdatesExtra, 'discount.value.amount', 0),
          currency: get(translationUpdatesExtra, 'discount.value.currency', 'EUR'),
        }
      }

      const subtotal = {
        amount: get(translationUpdatesExtra, 'subTotal.amount', 0),
        currency: get(translationUpdatesExtra, 'subTotal.currency', 'EUR'),
      }

      updateTranslationOffers.forEach(({languageIso, toTranslateCharacters, basePrice : { amount, currency }}) => {
        fields.push({
          title: translateIsoCode(languageIso),
          subtitle: `${StringNumber(translateText)(toTranslateCharacters)} ${translateText('characters')}`,
          info: {
            amount,
            currency
          },
        })
      })

      section = { ...section, title, fields, discount, subtotal}

      return section

    }

    if (updateTranslationOffers.length > 0) {
      const { currentUsedChars, currentAvailableChars, nextAvailableChars, nextUsedChars } = translationUpdatesExtra
      const abbreviateNumberHost = abbreviateNumber(translateText)
      const availableCharsNow = {
        label: `${abbreviateNumberHost(currentUsedChars)} / ${abbreviateNumberHost(currentAvailableChars)}`,
        value: getPercentageForQuantity(currentAvailableChars, currentUsedChars)
      }
      const availableCharsAfter = {
        label: `${abbreviateNumberHost(Math.min(nextUsedChars, nextAvailableChars))} / ${abbreviateNumberHost(nextAvailableChars)}`,
        value: getPercentageForQuantity(nextAvailableChars, Math.min(nextUsedChars, nextAvailableChars))
      }


      fields.push(
        <DoubleProgressInfo
          title={availableCharsAfter.label}
          subtitle={translateText('Characters used after update')}
          beforeUpdatePercent={availableCharsNow.value}
          afterUpdatePercent={availableCharsAfter.value}
          remainingCharsMessage={translateText('The remaining character balance will be used for manual update.')}
          exceedCharBalanceMessage={translateText('You have used up your character balance. This manual update will cost extra.')}
        />
      )

    }

    section = { ...section, title, fields }

    return section

  }

  const setExtraCharactersSection = () => {

    let section = {}
    const fields = []

    const title = {
      text: translateText('Extra characters'),
      info: translateText('When you exceed the bag of characters that you have linked to your subscription plan, the cost of the characters will be displayed in this block.')
    }

    const discount = {
      discountAmount: {
        amount: get(translationUpdatesExtra, 'discount.value.amount', 0),
        currency: get(translationUpdatesExtra, 'discount.value.currency', 'EUR'),
      }
    }

    const subtotal = {
      amount: get(translationUpdatesExtra, 'total.amount', 0),
      currency: get(translationUpdatesExtra, 'total.currency', 'EUR'),
    }

    const total = {
      amount: get(translationUpdatesExtra, 'subTotal.amount', 0),
      currency: get(translationUpdatesExtra, 'subTotal.currency', 'EUR'),
    }

    fields.push({
      title: translateText("Total characters"),
      subtitle: StringNumber(translateText)(get(translationUpdatesExtra, 'charsToUpdate', 0)),
      info: { ...total}

    })

    section = { ...section, title, fields, discount, subtotal}

    return section

  }

  sections.forEach((sectionType, indexSection) => {

    switch(sectionType) {
      case ADD_LANGUAGES_SECTION:
        summaryValues.summaryFields[indexSection] = setAddLanguageSection()
        break
      case ADD_PLAN_SECTION:
        summaryValues.summaryFields[indexSection] = setPlanSection()
        break
      case ADD_UPDATE_LANGUAGES_SECTION:
        summaryValues.summaryFields[indexSection] = setUpdateLanguagesSection()
        break
      case ADD_EXTRA_CHARACTERS_SECTION:
        summaryValues.summaryFields[indexSection] = setExtraCharactersSection()
        break
      default:
        break
    }

  })

  summaryValues = [addSummaryTotal, addSummaryTaxes, addSupplements].reduceRight((s, fn) => fn(s), summaryValues)

  return summaryValues

}