import React from 'react';

// components
import CardCheckbox from 'components/CleanUIComponents/Checkbox/CardCheckbox';

// styles
import styles from './index.module.scss';

const ActiveProducts = ({id, title, subtitle, isChecked = false, onChange, isDisabled = false}) => {
  return (
    <div className={styles.active_product_wrapper}>
      <CardCheckbox
        value={id} 
        title={title}
        subtitle={subtitle}
        isChecked={isChecked}
        isDisabled={isDisabled}
        onChange={checked => onChange(checked)}
        fluid
        column
      />
    </div>
  )
}

export default ActiveProducts;