import { getStripeSetupIntentRequest } from 'models/api/payment'

export default class StripeUtils {
  /**
   * Gets a client secret from the server to generate a new setupIntent
   */
  static getStripeClientSecretSetupIntent = async (accountId) => {
    try {
      const { error, result } = await getStripeSetupIntentRequest({ accountId })
      if (error) {
        return { error, client_secret: null }
      }

      return { error: null, client_secret: result.data.clientSecret }
    } catch (err) {
      return { error: err, client_secret: null }
    }
  }
}
