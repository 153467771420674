import { SchemaValidator } from '@smartrecruiters/openapi-schemas-validator'
import { notification } from 'antd'
// utils
import openApiSpec from 'services/openapi'
import Translator from 'utils/Translator'

// constants
import { ROUTES } from 'constants/routes.constants'

// redux
import { logout } from 'redux/user/actions'
import { store } from 'redux/store'

class Request {
  // static urlBase = 'http://0603d32b.eu.ngrok.io';
  static urlBase = process.env.REACT_APP_GLOTIO_API_URL

  static apiUrlString = '/api/v1'

  static errors = {}

  static schemaValidator = new SchemaValidator(openApiSpec)

  static fetchBlob({
    url,
    method,
    params = {},
    key = null,
    callback = null,
    download = false,
    downloadNameFile = 'GlotioFile',
  }) {
    const {
      user: { currentUser },
    } = store.getState()

    const headers = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    }

    if (currentUser && currentUser.token) {
      headers.Authorization = `Bearer ${currentUser.token}`
    }

    const urlFetch = `${Request.urlBase}${url}`

    return fetch(urlFetch, {
      method,
      body: method === 'GET' ? null : JSON.stringify(params),
      headers,
    })
      .then(async (response) => {
        const resultStatus = Request.checkStatus(response)
        if (resultStatus === true) {
          return {
            result: await response.blob(),
            error: null,
          }
        }
        return resultStatus
      })
      .then((response) => {
        const { result, error } = response
        if (download && !error) Request.downloadBlob(result, downloadNameFile)
        return response
      })

      .then((response) => {
        const { error } = response
        if (key != null) Request.setErrorFetch({ key, error })
        return response
      })
      .then((response) => (callback ? callback(response) : response))

      .catch((error) => {
        const response = { result: null, error: { fetch: error, message: 'Conection error' } }
        if (key != null) {
          Request.setErrorFetch({ key, error })
        }
        return callback ? callback(response) : response
      })
  }

  static fetch({
    url,
    method,
    params = {},
    schema = null,
    key = null,
    callback = null,
  }) {
    const {
      user: { currentUser },
    } = store.getState()

    const headers = new Headers({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
      })

    if (currentUser && currentUser.token) {
      headers.set('Authorization', `Bearer ${currentUser.token}`)
    }

    const xDebugSessionStart = document.cookie.split('; ').find(row => row.startsWith('XDEBUG_SESSION'))
    const isEnviromentAllowed = process.env.REACT_APP_NODE_ENVIROMENT === 'local'

    const urlFetch = new URL(`${Request.urlBase}${url}`);
    if (xDebugSessionStart && isEnviromentAllowed) {
      urlFetch.searchParams.append('XDEBUG_SESSION_START', 'true');
    }

    if (method === 'GET' && typeof params === 'object') {
      for (const [keyParam, value] of Object.entries(params)) {
        if (value !== null && value !== undefined) {
          urlFetch.searchParams.append(keyParam, value);
        }
      }
    }

    return fetch(urlFetch, {
      method,
      body: method === 'GET' ? null : JSON.stringify(params),
      headers,
    })
      .then(async (response) => {
        const resultStatus = Request.checkStatus(response)
        if (resultStatus === true) {
          return {
            result: await response.json(),
            error: null,
            status: response.status,
          }
        }

        resultStatus.result = await response.json()
        return resultStatus
      })

      .then(async (response) => {
        const { result, error } = response

        if (!error) {
          const schemaResult = await Request.checkSchema({ schema, result })

          if (schemaResult !== true) {
            return {
              ...response,
              error: { schema: schemaResult, message: 'Schema error' },
            }
          }
        }
        return response
      })

      .then((response) => {
        const { error } = response
        if (key != null) Request.setErrorFetch({ key, error })
        return response
      })
      .then((response) => (callback ? callback(response) : response))

      .catch((error) => {
        const response = { result: null, error: { fetch: error, message: 'Conection error' } }
        if (key != null) {
          Request.setErrorFetch({ key, error })
        }
        return callback ? callback(response) : response
      })
  }

  static checkSchema({ schema, result }) {
    if (!schema) {
      return true
    }

    const configSchema = openApiSpec.components.schemas[schema]
    if (!configSchema) {
      return `no existe el schema ${schema}`
    }

    const resultClone = JSON.parse(JSON.stringify(result))
    const validateResult = Request.schemaValidator.validate(resultClone, configSchema)

    if (validateResult !== undefined) {
      return validateResult
    }

    return true
  }

  static setErrorFetch({ key, error }) {
    if (error !== null) {
      Request.errors[key] = error
    } else {
      delete Request.errors[key]
    }
  }

  static getErrorFetch(key) {
    return Request.errors[key]
  }

  static hasErrorFetch(key) {
    const error = Request.getErrorFetch(key)
    return error !== null && error !== undefined
  }

  static checkStatus(response) {
    const { status } = response

    if (status === 401 && window.location.pathname !== ROUTES.LOGIN) {
      Request.errors = {}
      notification.error({
        message: Translator.getTranslatedMessage('Session expired.'),
        key: 'session-expired',
      })

      const {
        project: { currentProject },
      } = store.getState()

      store.dispatch(logout({ project: currentProject ? currentProject.url : null }))

      return {
        result: null,
        error: 'Your session has expired.',
      }
    }

    if (status !== 200) {
      return {
        result: null,
        error: `status ${status}`,
      }
    }

    return true
  }

  static downloadBlob(blob, nameFile) {
    const urlDownload = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.style.display = 'none'
    a.href = urlDownload
    a.download = nameFile
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(urlDownload)
  }

  static external(url) {
    return fetch(url)
      .then(result => {
        if (!result.ok) {
          throw new Error(`${result.status}`);
        }
        return result.json()
      })
      .then(result => {
        return { result, error: null }
      })
      .catch(error => ({ result: null, error: { fetch: error, message: 'Conection error' }}))
  }
}

export default Request
