import React from 'react';
import { withRouter } from 'react-router';

// components
import FirstTranslation from 'layouts/FirstTranslation'
import Step from 'components/GlotioComponents/Step'
import QuestionBlock from 'components/GlotioComponents/QuestionBlock'
import RootPortal from 'components/CleanUIComponents/RootPortal'
import BackButton from 'components/CleanUIComponents/Buttons/BackButton'

// hooks
import useGlotio from 'hooks/useGlotio'
import useAssistantHash from 'hooks/useAssistantHash';

// constants
import TRANSLATION_WIZARD from 'constants/translation_wizard.constants';
import { UPDATE_COMPLETE, UPDATE_PARTIAL } from 'constants/update_type.constants';
import { ROUTES } from 'constants/routes.constants';
import { ASSISTANT_ROUTER_HASH } from 'constants/assistant.router.hash';

// styles
import styles from './index.module.scss';

const UpdateType = ({setAssistantPage, history}) => {

  const { translateText } = useGlotio();

  useAssistantHash(ASSISTANT_ROUTER_HASH.UPGRADE_TYPE)

  const goBackPage = () => {
    setAssistantPage({ page: TRANSLATION_WIZARD.pages.QUESTION_DEFAULT_LANGUAGE, lang: null })
  }

  const handleType = (type) => {

    const nextPage = {
      [UPDATE_COMPLETE]: () => setAssistantPage({ page: TRANSLATION_WIZARD.pages.LANGUAGE_SELECTOR_MULTIPLE, lang: null }),
      [UPDATE_PARTIAL]: () => {
        const params = new URLSearchParams({from: window.location.href });
        history.push({ pathname: ROUTES.CONTACT_PARTIAL_UPDATE, search: params.toString() });
      }
    }

    nextPage[type]();

  }

  const getOptions = () => {

    const renderQuestionText = ({slug1, slug2, description}) => {
      return (
        <div>
          <p><strong>{slug1}</strong>,&nbsp;{slug2}</p>
          <p><i className={styles.description}>{description}</i></p>
        </div>
      )
    }

    const options = [
      {
        text: renderQuestionText({
          slug1: translateText('Full translation'),
          slug2: translateText('update all languages.'),
          description: translateText('All languages will be fully updated, so previous translations will be replaced by Glotio translation')
        }),
        id: UPDATE_COMPLETE
      },
      {
        text: renderQuestionText({
          slug1: translateText('Partial translation'),
          slug2: translateText('I just want to translate the pending characters.'),
          description: translateText('Only the characters that are not translated will be updated taking into account the language that is defined by default.')
        }),
        id: UPDATE_PARTIAL
      }
    ]

    return options;

  }

  const renderButtonBar = () => {
    return (
      <RootPortal>
        <RootPortal.Grid>
          <BackButton pushBack={goBackPage} data="icon" />
        </RootPortal.Grid>
      </RootPortal>
    )
  }

  return (
    <FirstTranslation>
      <Step
        title={translateText('What kind of update would you like to do?')}
        subtitle={translateText('You can update the languages of your online store by performing a full or partial translation.')}
      >
        <div className={styles.question_container}>
          {
            getOptions().map((option) => (<QuestionBlock key={option.id} {...option} onChange={handleType} />))
          }
        </div>
      </Step>
      { renderButtonBar() }
    </FirstTranslation>
  )

}

export default withRouter(UpdateType);
