const actionTypes = {
  FETCH_ACCOUNT_START: 'account/FETCH_ACCOUNT_START',
  FETCH_ACCOUNT_SUCCESS: 'account/FETCH_ACCOUNT_SUCCESS',
  FETCH_ACCOUNT_FAIL: 'account/FETCH_ACCOUNT_FAIL',
  SAVE_ACCOUNT_UPDATES: 'account/SAVE_ACCOUNT_UPDATES',
}

export default actionTypes

export const fetchAccountStart = () => ({
  type: actionTypes.FETCH_ACCOUNT_START,
})

export const fetchAccountSuccess = accountInfo => ({
  type: actionTypes.FETCH_ACCOUNT_SUCCESS,
  payload: accountInfo,
})

export const fetchAccountFail = error => ({
  type: actionTypes.FETCH_ACCOUNT_FAIL,
  payload: error,
})

export const saveAccountUpdates = updatedValues => ({
  type: actionTypes.SAVE_ACCOUNT_UPDATES,
  payload: updatedValues,
})
