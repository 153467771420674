export const ROUTES = {
  SUBSCRIPTION_UPGRADE : "/subscriptions-upgrade",
  SUBSCRIPTION_UPGRADE_REACTIVATE : "/subscriptions-upgrade/reactivate",
  SUBSCRIPTION_DASHBOARD: "/subscriptions-dashboard",
  LOGIN: "/login",
  ADMIN: "/admin-direct-auth",
  DIRECT_AUTH: "/direct-auth",
  LOG_OUT_FORCE: "/logout/f",
  PASSWORD_FORGOT: "/password-forgot",
  PASSWORD_RESET: "/password-reset",
  OPEN: "/open",
  SIGN_IN_DISABLED: "/signin-disabled",
  SIGN_UP: "/signup",
  EMAIL_VERIFICATION: "/email-verification",
  DASHBOARD: "/dashboard",
  ASSISTANT: "/assistant",
  ADVANCED_OPTIONS: "/advanced-options",
  ADVANCED_OPTIONS_CATALOG: "/advanced-options/catalog",
  ADVANCED_OPTIONS_MODULES: "/advanced-options/modules",
  ADVANCED_OPTIONS_OTHERS: "/advanced-options/others",
  ADVANCED_OPTIONS_THEME: "/advanced-options/themes",
  ADVANCED_OPTIONS_MAILS: "/advanced-options/mails",
  MY_PROFILE: "/my-profile",
  MY_PROFILE_USER: "/my-profile/user",
  MY_PROFILE_PROJECT: "/my-profile/project",
  MY_PROFILE_PASSWORD: "/my-profile/password",
  PAYMENTS: "/payments",
  PAYMENTS_BILLING: "/payments/billing",
  PAYMENTS_HISTORY: "/payments/history",
  CONTACT: "/contact",
  CONTACT_US: "/contact/us",
  CONTACT_CORPORATE: "/contact/corporate",
  CONTACT_PARTIAL_UPDATE: "/contact/partial-update",
  CONTACT_PARTIAL_UPDATE_SUCCESS: "/contact/partial-update/success",
  CONTACT_CORPORATE_SUCCESS: "/contact/corporate/success",
  CONTACT_US_SUCCESS: "/contact/us/success",
  TECHNICAL_SUPPORT: "/technical-support",
  HISTORY: "/history",
  MY_LANGUAGES: "/my-languages",
  EXCLUDED_WORDS: "/excluded-words",
  FIND_AND_REPLACE: "/find-and-replace",
  DICTIONARY: "/dictionary",
  UPDATES: "/updates",
  MANUAL_UPDATES: "/updates/manually-update",
  AUTOMATICALLY_UPDATES: "/updates/automatically-update",
  GUIDE: "/guide",
  NOTIFICATIONS: "/notifications",
  PROPOSALS: '/proposals',
  UPDATE_SUBSCRIPTION_PAYMENT_CONFIRMED: "/update-subscription-payment-confirmed",
  MANUAL_PAYMENT_CONFIRMED: "/manual-payment-confirmed",
  WELCOME_DASHBOARD_NO_AUTH: '/welcome-dashboard-no-auth',
  WELCOME_DASHBOARD_AUTH: '/welcome-dashboard-auth'
}
// TODO (a.rodriguez): Routes migrated to new submenu layout in tabs
export const PAGE_WITH_SUBMENU_TABS = [ROUTES.PAYMENTS_BILLING, ROUTES.PAYMENTS_HISTORY, ROUTES.MY_PROFILE_USER, ROUTES.MY_PROFILE_PROJECT, ROUTES.MY_PROFILE_PASSWORD]

export const OPEN_ROUTES_ON_PAYMENT_ERROR = [
  ROUTES.PAYMENTS,
  ROUTES.PAYMENTS_BILLING,
  ROUTES.PAYMENTS_HISTORY,
  ROUTES.CONTACT,
  ROUTES.CONTACT_US,
  ROUTES.CONTACT_PARTIAL_UPDATE,
  ROUTES.CONTACT_CORPORATE_SUCCESS,
  ROUTES.CONTACT_US_SUCCESS,
  ROUTES.TECHNICAL_SUPPORT,
  ROUTES.CONTACT_PARTIAL_UPDATE_SUCCESS,
  ROUTES.SUBSCRIPTION_DASHBOARD,
  ROUTES.NOTIFICATIONS,
  ROUTES.MY_PROFILE,
  ROUTES.MY_PROFILE_PROJECT,
  ROUTES.MY_PROFILE_PASSWORD,
  ROUTES.MY_PROFILE_USER,
  ROUTES.UPDATE_SUBSCRIPTION_PAYMENT_CONFIRMED,
  ROUTES.MANUAL_PAYMENT_CONFIRMED
]
