import { Form, Input, Select } from 'antd';
import { get, isEmpty } from 'lodash';
import React, { useState, useCallback, useEffect } from 'react';

// components
import FieldMessage from 'components/GlotioComponents/FieldMessage'
import BillingEditView from 'components/GlotioComponents/BillingForm/BillingEditView';

// hooks
import useGlotio from 'hooks/useGlotio'

// constants
import { ACCOUNT_RULES, DEFAULT_ACCOUNT, formHandlers } from 'constants/account.constants';

// utils
import countryList from 'utils/country-list';
import { generateComponentUuid } from 'utils/Utils';
import { getAllErrorMessages, getFieldErrorMessage } from 'utils/getFieldErrorMessage';

// services
import mediator from "services/Mediator";

// styles
import styles from './index.module.scss';

/**
 *
 * @param {IAccount} currentAccount
 * @param {Function} saveDataSource
 * @param {Function} onTyping
 * @param {Boolean} initialEditMode
 * @return {JSX.Element}
 * @constructor
 */

const BillingForm = ({currentAccount = {}, saveDataSource, initialEditMode, onTyping }) => {

  const { translateText } = useGlotio()
  const [stateForm, setFormState] = useState(() => initializeFormValues());
  const [statusState, setStatusState] = useState(() =>  initializeFormStatus())
  const [editMode, setEditMode] = useState(initialEditMode);

  useEffect(() => {
    setEditMode(initialEditMode)
  }, [initialEditMode])

  function initializeFormValues () {
    return DEFAULT_ACCOUNT(currentAccount).initialValues;

  }

  function initializeFormStatus () {

    return DEFAULT_ACCOUNT(currentAccount).status;

  }

  const handleTyping = isWriting => {
    if (typeof onTyping === 'function') {
      onTyping(isWriting)
    }
  }
 
  const onSendData = useCallback((name, value, hasErrors) => {

    if (hasErrors) return

    setStatusState((prevState) => ({
      ...prevState,
      [name]: {...prevState[name], validateStatus: 'validating'}
    }))

    const onSuccess = () => {
      
      setStatusState((prevState) => ({
        ...prevState,
        [name]: {...prevState[name], validateStatus: "success"}
      }))

      handleTyping(false)
    }

    const onError = () => {

      setStatusState((prevState) => ({
        ...prevState,
        [name]: {...prevState[name], validateStatus: "error"}
      }))

      handleTyping(false)
    }

    if (typeof saveDataSource === 'function') {
      saveDataSource({[name]: value}, onSuccess, onError);
    }
    
  },[saveDataSource])

  const validateField = (name, value) => {

    const errorsField = statusState[name].errorField(value, ACCOUNT_RULES[name]);
    const hasErrors = !isEmpty(errorsField);

    const newStatusState = {
      ...statusState,
      [name]: {...statusState[name], errorMessages: [...errorsField], validateStatus: hasErrors ? 'error': ''}
    }

    setStatusState(() => ({
      ...newStatusState,
    }))

    const errorMessageCollector = getAllErrorMessages(newStatusState);
    mediator.publish('billing_form_error', errorMessageCollector)
    formHandlers[name](name, value, hasErrors, onSendData)

  }

  const onChange = (event) => {

    handleTyping(true);
    
    const {
      target: { name, value },
    } = event

    setFormState((prevState) => ({
      ...prevState,
      [name]: value
    }))

    validateField(name, value)

  }

  const onSetBlur = name => {

    setStatusState((prevState) => ({
      ...prevState,
      [name]: {...prevState[name], isTouched: true}
    }))

  } 

  const renderErrorMessages = nameField => {

    const fieldErrorMessage = statusState[nameField].errorMessages;

    return fieldErrorMessage.map((error, index) => ((
      <div className={styles.fluid_col} key={generateComponentUuid(index)}>
        <FieldMessage
          message={getFieldErrorMessage(error, translateText)} 
          className={styles.fieldMessage}
        />
      </div>
    )))

  }

  const getEditView = () => <BillingEditView currentAccount={currentAccount} onChangeView={() => setEditMode(false)} />

  const getFormView = () => {

    return (
      <Form>
        <div className={styles.grid_layout}>
          <div className={styles.col_1_9}>
            <Form.Item
              className={`${styles.form_item} ${statusState.company_name.validateStatus === 'error' ? styles.hasError : ''}`}
              label={translateText('Name or company name')}
              hasFeedback
              validateStatus={statusState.company_name.validateStatus}
            >
              <Input
                type="text"
                placeholder={translateText('Name or company name')}
                value={stateForm.company_name}
                onBlur={() => onSetBlur('company_name')}
                onChange={onChange}
                name="company_name"
              />
            </Form.Item>
          </div>
          <div className={styles.col_3_12}>
            <Form.Item
              className={`${styles.form_item} ${statusState.document.validateStatus === 'error' ? styles.hasError : ''}`}
              label={translateText("NIF")}
              hasFeedback
              validateStatus={statusState.document.validateStatus}
            >
              <Input
                type="text"
                placeholder={translateText("NIF")}
                value={stateForm.document}
                onBlur={() => onSetBlur('document')}
                onChange={onChange}
                name="document"
              />
            </Form.Item>
          </div>
          { statusState.company_name.isTouched && renderErrorMessages('company_name') }
          { statusState.document.isTouched && renderErrorMessages('document') }
          <div className={styles.fluid_col}>
            <Form.Item
              className={`${styles.form_item} ${statusState.address1.validateStatus === 'error' ? styles.hasError : ''}`}
              label={translateText('Address')}
              hasFeedback
              validateStatus={statusState.address1.validateStatus}
            >
              <Input
                type="text"
                placeholder={translateText('Address')}
                value={stateForm.address1}
                onBlur={() => onSetBlur('address1')}
                onChange={onChange}
                name="address1"
              />
            </Form.Item>
          </div>
          { statusState.address1.isTouched && renderErrorMessages('address1') }
          <div className={styles.col_1_9}>
            <Form.Item
              className={`${styles.form_item} ${statusState.city.validateStatus === 'error' ? styles.hasError : ''}`}
              label={translateText("City")}
              hasFeedback
              validateStatus={statusState.city.validateStatus}
            >
              <Input
                type="text"
                placeholder={translateText("City")}
                value={stateForm.city}
                onBlur={() => onSetBlur('city')}
                onChange={onChange}
                name="city"
              />
            </Form.Item>
            
          </div>
          <div className={styles.col_3_12}>
            <Form.Item
              className={`${styles.form_item} ${statusState.postal_code.validateStatus === 'error' ? styles.hasError : ''}`}
              label={translateText("Zip code")}
              hasFeedback
              validateStatus={statusState.postal_code.validateStatus}
            >
              <Input
                type="text"
                placeholder={translateText("Zip code")}
                value={stateForm.postal_code}
                onBlur={() => onSetBlur('postal_code')}
                onChange={onChange}
                name="postal_code"
              />
            </Form.Item>
          </div>
          { statusState.city.isTouched && renderErrorMessages('city') }
          { statusState.postal_code.isTouched && renderErrorMessages('postal_code') }
          <div className={styles.fluid_col}>
            <Form.Item
              className={`${styles.form_item} ${statusState.iso_country.validateStatus === 'error' ? styles.hasError : ''}`}
              label={translateText("Country")}
              hasFeedback
              validateStatus={statusState.iso_country.validateStatus}
            >
              <Select
                showSearch
                placeholder={translateText("Country")}
                defaultValue={stateForm.iso_country}
                filterOption={(inputValue, option) => get(option, 'props.children','').toLowerCase().includes(inputValue.toLowerCase())}
                onChange={(newValue) => onChange({target: { value: newValue, name: 'iso_country'}})}
              >
                { countryList.map(({label, value}) => (
                  <Select.Option key={value} value={value}>{translateText(label)}</Select.Option>
                  ))
                }
              </Select>
            </Form.Item>
          </div>
          { statusState.city.isTouched && renderErrorMessages('iso_country') }
        </div>
      </Form>
    )
  
  }

  const handleView = () => {

    if (typeof editMode === 'undefined') return null

    if (editMode) {
      return getEditView()
    }

    return getFormView();

  }

  return handleView()
  
}

export default BillingForm;