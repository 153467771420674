import React from 'react'
import InfoAdvertisement from 'components/GlotioComponents/InfoAdvertisement'
import styles from './index.module.scss'

/**
 * @param {string|React.ReactNode} title
 * @param {string|React.ReactNode} description
 * @param {Function} onClick
 * @param {Function} onKeyDown
 * @param {string} className
 * @param {boolean} pointer
 * @return {JSX.Element}
 */
const InfoAdvertisementVertical = ({
  title = "",
  description = "",
  onClick = () => {},
  onKeyDown = () => {},
  className = "",
  pointer = false
}) => {
  return (
    <InfoAdvertisement onClick={onClick} onKeyDown={onKeyDown} className={className} pointer={pointer}>
      <h2 className={styles.title}>{title}</h2>
      <p className={styles.subtitle}>{description}</p>
    </InfoAdvertisement>
  )
}

export default InfoAdvertisementVertical
