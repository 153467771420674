import React from 'react';
import { withRouter } from 'react-router';
import Tabs from 'components/LayoutComponents/Menu/Tabs'

import { ROUTES } from 'constants/routes.constants';

// styles
import styles from './index.module.scss'; 

const LanguageUpdatesTabs = ({ location: { pathname } }) => {

  const options = [
    {
      key: 'Manuals',
      disabled: false,
      url: ROUTES.MANUAL_UPDATES,
    },
    {
      key: 'Configure updates',
      disabled: false,
      url: ROUTES.AUTOMATICALLY_UPDATES,
    }
  ]

  return (
    <Tabs options={options} pathname={pathname} className={styles.wrapper_tabs} />
  )
}

export default withRouter(LanguageUpdatesTabs)