import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router';
import { get } from 'lodash'

// redux
import { connect, useDispatch, useSelector } from 'react-redux';

// components
import Container from 'components/CleanUIComponents/Container'
import UpdateSummary from 'components/GlotioComponents/Asistent/Steps/Update/UpdateSummary'
import LastAnalysisInfo from 'components/GlotioComponents/Asistent/Steps/Update/LastAnalysisInfo';
import LenguageSelectionUpdateInfo from 'components/GlotioComponents/Asistent/Steps/Update/UpdateLanguagesInfo';
import LanguageUpdatesTabs from 'components/GlotioComponents/LanguageUpdatesTabs';

// selectors
import { selectCurrentProcess } from 'redux/process/selectors'
import { selectCurrentProject, selectProjectId } from 'redux/project/selectors'

// actions
import { fetchAccountStart } from 'redux/account/actions';

// models
import { getCurrentSubscriptionRequest } from 'models/api/subscription'

// constants
import { ORIGIN_UPDATES } from 'constants/process_origin.constants';

// utils
import Process from 'utils/Process';
import { getRequestData } from 'utils/Requests'

// styles
import styles from './index.module.scss';

const LenguageUpdateHandler = () => {

  const dispatch = useDispatch();
  const currentProcess = useSelector(selectCurrentProcess);
  const projectId = useSelector(selectProjectId)
  const [isError, setIsError] = useState(false)
  const [currentSubscription, setCurrentSubscription] = useState(null)
  const [isLoadingRequest, setIsLoadingRequest] = useState({
    isLoadingSubscription: true
  })
  const isLoading = Object.values(isLoadingRequest).some(loading => loading)
  const currentSubscriptionData = get(currentSubscription, 'data', {})

  useEffect(() => {
    dispatch(fetchAccountStart());
  }, []);

  useEffect(() => {
    if (projectId) {
      getRequestData(
        getCurrentSubscriptionRequest,
        (data) => setCurrentSubscription(data),
        (state) => setIsLoadingRequest((prevState) => ({ ...prevState, isLoadingSubscription: state })),
        (error) => setIsError(error),
        projectId
      )
    }
  }, [projectId])

  const shouldShowStartAnalysis = () => {

    const {
      hasProcessFinished,
      hasProcessAborted
    } = Process;

    // if the current process has finished
    if (hasProcessFinished(currentProcess)) {
      return true;
    }

    // if the there is no currentProcess or the currentProcess is Aborted
    if (!currentProcess || hasProcessAborted(currentProcess)) {
      return true;
    }

    return false;

  };

  const isNothingToTranslate = Process.isAbortedNothingToTranslate(currentProcess)

  const handleCurrentView = () => {

    if (shouldShowStartAnalysis()) {
      return (
        <Container isLoading={isLoading} isError={isError}>
          <div className={styles.info_wrapper_tabs}>
            <LanguageUpdatesTabs />
          </div>
          <UpdateSummary currentSubscription={currentSubscriptionData}>
            <LastAnalysisInfo origin={ORIGIN_UPDATES} sectionTitle='Start analysis to update languages' isNothingToTranslate={isNothingToTranslate} />
          </UpdateSummary>
        </Container>
      )
    }

    return (
      <Container isLoading={isLoading} isError={isError} noPadding={!isLoading && !isError}>
        <LenguageSelectionUpdateInfo currentSubscription={currentSubscriptionData} />
      </Container>
    )

  }

  return handleCurrentView();

}

const mapStateToProps = (state) => ({
  currentProject: selectCurrentProject(state),
})


export default connect(mapStateToProps)(withRouter(LenguageUpdateHandler))
