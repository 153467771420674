import React from 'react'

// components
import GlotioButton from 'components/GlotioComponents/GlotioButton'

// hooks
import useGlotio from "hooks/useGlotio";


// styles
import styles from './index.module.scss'

const WelcomeAuthActionsHeader = ({onOpenLoginModal, canShowLoginButton, canShowRegisterButton, urlSignUp}) => {
  const { translateText } = useGlotio()

  return (
    <div className={styles.wrapper_button}>
      { canShowRegisterButton &&
        <GlotioButton
          onClick={() => window.open(urlSignUp, '_blank')}
          type='button'
          size='medium'
          variant='default'
        >
          {translateText("Create an account")}
        </GlotioButton>
      }
      { canShowLoginButton &&
        <GlotioButton
          type='button'
          variant='default'
          size='medium'
          onClick={onOpenLoginModal}
        >
          {translateText('Sign in')}
        </GlotioButton>
      }
    </div>
  )
}

export default WelcomeAuthActionsHeader
