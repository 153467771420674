// utils
import { InjectScriptInDom } from "utils/inject-script-in-dom";

const ActivecampaignInitializer = (logger = null) => {
  if (!process.env.REACT_APP_AC_ACCOUNT_ID) {
    return false;
  }

  if (typeof vgo === 'function') {
    return true;
  }

  try {
    InjectScriptInDom('(function(e,t,o,n,p,r,i){e.visitorGlobalObjectAlias=n;e[e.visitorGlobalObjectAlias]=e[e.visitorGlobalObjectAlias]||function(){(e[e.visitorGlobalObjectAlias].q=e[e.visitorGlobalObjectAlias].q||[]).push(arguments)};e[e.visitorGlobalObjectAlias].l=(new Date).getTime();r=t.createElement("script");r.src=o;r.async=true;i=t.getElementsByTagName("script")[0];i.parentNode.insertBefore(r,i)})(window,document,"https://diffuser-cdn.app-us1.com/diffuser/diffuser.js","vgo")')

    vgo('setAccount', process.env.REACT_APP_AC_ACCOUNT_ID);
    vgo('setTrackByDefault', true);
    vgo('process');

    return true
  } catch (e) {
    if (logger) {
      logger(e.message)
    }
    return false
  }
}

export default ActivecampaignInitializer
