import { createSelector } from 'reselect'

/**
 * @param {Object<string, any>} state
 */
const selectPayment = state => state.payment

/**
 * Returns if the menu is collapsed
 */
export const selectHasBackendError = createSelector(
  [selectPayment],
  payment => payment.hasBackendError,
)

export const selectErrorMessage = createSelector(
  [selectPayment],
  payment => payment.errorMessages,
)
