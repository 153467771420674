import { useContext } from 'react'
import IntlContext from 'context/intl'

/**
 * Injects the contents of intlcontext into a component that executes this hook
 *
 * @returns {Object<string, Function>}
 */
export default function useGlotio() {
  const translationTools = useContext(IntlContext)

  return translationTools
}
