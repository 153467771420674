const actionTypes = {
  FETCH_PROJECT_START: 'project/FETCH_PROJECT_START',
  FETCH_PROJECT_FAIL: 'project/FETCH_PROJECT_FAIL',
  FETCH_PROJECT_SUCCESS: 'project/FETCH_PROJECT_SUCCESS',
  SET_PROJECT_ID: 'project/SET_PROJECT_ID',
  SET_PROJECT_EMAIL: 'project/SET_PROJECT_EMAIL',
  SAVE_PROJECT_CHANGES: 'project/SAVE_PROJECT_CHANGES',
}

export const fetchProjectStart = payload => ({
  type: actionTypes.FETCH_PROJECT_START,
  payload
})

export const fetchProjectFail = error => ({
  type: actionTypes.FETCH_PROJECT_FAIL,
  payload: error,
})

export const fetchProjectSuccess = project => ({
  type: actionTypes.FETCH_PROJECT_SUCCESS,
  payload: project,
})

export const setProjectId = id => ({
  type: actionTypes.SET_PROJECT_ID,
  payload: id,
})

export const setProjectEmail = email => ({
  type: actionTypes.SET_PROJECT_EMAIL,
  payload: email,
})

export const saveProjectChanges = projectChanges => ({
  type: actionTypes.SAVE_PROJECT_CHANGES,
  payload: projectChanges,
})

export default actionTypes
