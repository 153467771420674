import React from 'react'
import { connect, useSelector } from 'react-redux'
import { withRouter } from 'react-router'

// layouts
import FirstTranslation from 'layouts/FirstTranslation'

// components
import Step from 'components/GlotioComponents/Step'
import FaqArticlesList from 'components/GlotioComponents/FaqArticlesList'
import RootPortal from 'components/CleanUIComponents/RootPortal'
import BackButton from 'components/CleanUIComponents/Buttons/BackButton'
import GlotioButton from 'components/GlotioComponents/GlotioButton'

// selectors
import { selectCurrentProcess } from 'redux/process/selectors'

// actions
import { cancelAndRestartProcess, launchNewTranslationProcess } from 'redux/process/actions'

// hooks
import useAssistantHash from 'hooks/useAssistantHash'
import useGlotio from 'hooks/useGlotio'
import { selectIsProjectTranslatable } from 'redux/project/selectors'
import { useCreateModal } from 'hooks/useCreateModal'

// constants
import { ASSISTANT_ROUTER_HASH } from 'constants/assistant.router.hash'
import { CONFIRM_MODAL } from 'constants/modals.constants'
import TRANSLATION_WIZARD from 'constants/translation_wizard.constants'

// utils
import Faq from 'utils/Faq'
import { shouldLaunchNewTranslationProcess } from 'utils/shouldLaunchNewTranslationProcess'

// styles
import styles from './index.module.scss'

const DefaultLanguageHelp = ({ launchTranslation, cancelAndLaunchTranslation, setAssistantPage }) => {
  const { translateText } = useGlotio()
  const { onOpenModal, onCloseModal } = useCreateModal()
  const isProjectTranslatable = useSelector(selectIsProjectTranslatable)
  const currentProcess = useSelector(selectCurrentProcess)
  const { addAssistantHash } = useAssistantHash(ASSISTANT_ROUTER_HASH.DEFAULT_LANGUAGE_HELP)

  const onReanalyse = () => {
    if (isProjectTranslatable) {
      if (shouldLaunchNewTranslationProcess(currentProcess)) {
        launchTranslation()
      } else {
        cancelAndLaunchTranslation()
      }
    }
  }

  const changeLanguageModal = () => {
    const message = {
      type: CONFIRM_MODAL,
      title: translateText('Did you modify the default language?'),
      description: translateText('Remember that, for Glotio to work correctly, the default language defined in your platform must be the same language you use to create the content in your online store.'),
      onCancel: () => {
        addAssistantHash(ASSISTANT_ROUTER_HASH.DEFAULT_LANGUAGE_HELP)
      },
      buttons: [{
        text: translateText('No'),
        options: {
          type: "button",
          variant: "outlined",
          size: "large",
          className: styles.modal_button
        },
        callback: () => {
          addAssistantHash(ASSISTANT_ROUTER_HASH.DEFAULT_LANGUAGE_HELP)
          onCloseModal()
        },
      },
        {
          text: translateText('Yes'),
          options: {
            type: "button",
            variant: "default",
            size: "large",
            className: styles.modal_button
          },
          callback: () => {
            onCloseModal()
            onReanalyse()
          },
        }]
    }
    onOpenModal({...message})
    addAssistantHash(ASSISTANT_ROUTER_HASH.DEFAULT_LANGUAGE_HELP_POPUP);
  }

  const goBackPage = () => {
    setAssistantPage({ page: TRANSLATION_WIZARD.pages.QUESTION_DEFAULT_LANGUAGE, lang: null })
  }

  const renderButtonBar = () => {
    return (
      <RootPortal>
        <RootPortal.Grid>
          <BackButton pushBack={goBackPage} data="icon" />
          <GlotioButton
            data="button"
            type='button'
            size='large'
            variant='default'
            onClick={changeLanguageModal}
            disabled={!isProjectTranslatable}
          >
            {translateText('Reanalyse')}
          </GlotioButton>
        </RootPortal.Grid>
      </RootPortal>
    )
  }

  return (
    <FirstTranslation>
      <Step
        title={translateText('Change the default language')}
        subtitle={translateText('We recommend that you read these articles to learn how to change the default language of your ecommerce platform. Once you have changed it, you can relaunch the analysis.')}
      >
        <FaqArticlesList
          className={styles.help_component}
          faqs={[Faq.articles.CHANGE_DEFAULT_LANGUAGE_PS, Faq.articles.SOURCE_LANGUAGE, Faq.articles.ADD_LANGUAGE_CMS]}
        />
      </Step>
      { renderButtonBar() }
    </FirstTranslation>
  )
}

const mapDispatchToProps = (dispatch) => ({
  launchTranslation: () => dispatch(launchNewTranslationProcess()),
  cancelAndLaunchTranslation: () => dispatch(cancelAndRestartProcess())
})

export default connect(null, mapDispatchToProps)(withRouter(DefaultLanguageHelp));
