import React from 'react'

// styles
import styles from './index.module.scss'

const WelcomeProjectInfoHeader = ({projectName, projectUrl}) => {
  return (
    <div className={styles.project_info_header}>
      <div>
        <p className={styles.project_name}>{projectName}</p>
      </div>
      <div>
        <a href={projectUrl} target="_blank" rel="noopener noreferrer" className={styles.project_url}>
          {projectUrl}
        </a>
      </div>
    </div>
  )
}

export default WelcomeProjectInfoHeader