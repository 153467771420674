const actions = {
  HAS_BACKEND_ERROR: 'payment/HAS_BACKEND_ERROR',
  CLEAR_BACKEND_ERROR: 'payment/CLEAR_BACKEND_ERROR'
}

export default actions

export const paymentError = (message) => ({
  type: actions.HAS_BACKEND_ERROR,
  payload: message
})

export const clearError = () => ({
  type: actions.CLEAR_BACKEND_ERROR,
})
