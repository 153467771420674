import { createSelector } from 'reselect'
import { getDefaultLanguageByBrowser } from 'utils/Utils'

/**
 * Returns the user slice from the redux store
 * @param {Object<string, any>} state The redux store object
 */
const selectUser = (state) => state.user

/**
 * Returns the current user stored on the state
 */
export const selectCurrentUser = createSelector([selectUser], (user) => user.currentUser)

/**
 * Returns the current user token
 */
export const selectUserToken = createSelector([selectCurrentUser], (currentUser) =>
  currentUser ? currentUser.token : null,
)

/**
 * Returns the id of the current user
 */
export const selectUserId = createSelector([selectCurrentUser], (currentUser) =>
  currentUser && currentUser.id ? currentUser.id : null,
)

/**
 * Returns the locale set up for the current user
 */
export const selectUserLocale = createSelector([selectCurrentUser], (currentUser) =>
  currentUser && currentUser.lang ? currentUser.lang : getDefaultLanguageByBrowser(),
)

/**
 * Returns the email of the current user
 */
export const selectUserEmail = createSelector([selectCurrentUser], (currentUser) =>
  currentUser && currentUser.email ? currentUser.email : null
)

/**
 * Returns the name of the current user
 */
 export const selectUserName = createSelector([selectCurrentUser], (currentUser) =>
 currentUser && currentUser.name ? currentUser.name : null
)

/**
 * Returns the status of the first session of the user
 */
export const selectIsFirstSession = createSelector([selectUser], (user) => user.isFirstSession)

/**
 * Returns the loading state on the user state
 */
export const selectIsUserLoading = createSelector([selectUser], (user) => user.loading)

/**
 * Returns the error state of the user object
 */
export const selectIsUserError = createSelector([selectUser], (user) => user.error)

/**
 * Returns the logoutLoading state of the user object
 */
export const selectIsLogoutLoading = createSelector([selectUser], (user) => user.logoutLoading)

/**
 * Returns the expiration date of the current token
 */
export const selectTokenExpirationDate = createSelector([selectCurrentUser], (currentUser) =>
  currentUser && currentUser.token_exp ? currentUser.token_exp : null,
)

/**
 * Returns the timezone info from the current user
 */
export const selectUserTimezone = createSelector([selectCurrentUser], (currentUser) =>
  currentUser && currentUser.timezone ? currentUser.timezone : null,
)

/**
 * Returns the admin state of the user
 */
export const selectIsAdminUser = createSelector([selectUser], (user) => user.isAdminUser)

export const selectZendeskToken = createSelector([selectCurrentUser], (currentUser) =>
  currentUser && currentUser.zendeskToken ? currentUser.zendeskToken : null,
)
