import React from 'react'

// components
import ShowError from 'components/GlotioComponents/ShowError'
import GlotioButton from 'components/GlotioComponents/GlotioButton'

// hooks
import useGlotio from 'hooks/useGlotio'

// style
import styles from './index.module.scss'

const NoConnectionError = () => {
  const { translateText } = useGlotio()

  return (
    <ShowError
      title={translateText('Something went wrong')}
      description={translateText('An error occurred while trying to load this page, please reload again. If the problem persists, we recommend that you contact technical support.')}
    >
      <div className={styles.button_wrapper}>
        <GlotioButton
          className={styles.button_reload}
          type='button'
          variant='default'
          size='large'
          onClick={() => window.location.reload()}
          fluid
        >
          {translateText('Reload')}
        </GlotioButton>
      </div>
    </ShowError>
  )
}

export default NoConnectionError
