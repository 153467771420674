import antdData from 'antd/lib/locale-provider/pt_PT'
import localeData from 'react-intl/locale-data/pt'
import countries from './countries/pt.json'
import languages from './languages/pt.json'
import platform from './platform/pt.json'
import urls from './urls/pt.json'
import texts from './texts/pt.json'
import faq from './faq/pt.json'

export default {
  locale: 'pt',
  antdData,
  localeData,
  messages: { ...texts, ...countries, ...languages, ...platform, ...urls, ...faq },
}
