import React from 'react'
import classNames from 'classnames'

// hook
import useGlotio from 'hooks/useGlotio'

// styles
import styles from './style.module.scss'

const Loader = ({ spinning = true, fullScreen, text = ''}) => {
  const { translateText } = useGlotio()
  return (
    <div
      className={classNames(styles.loader_wrapper, {
        [styles.hidden]: !spinning,
        [styles.fullScreen]: fullScreen,
      })}
    >
      <div className={styles.loader} />
      {text && 
        <div className={styles.loader_message}>
          {translateText(text)}
        </div>
      }
    </div>  
  )
}
 


export default Loader
