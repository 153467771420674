export default class Translator {
  /**
   * @param {string} message
   */
  static getTranslatedMessage = (message) => {
    if (!message) return null

    const locale = JSON.parse(localStorage.getItem('app.settings.locale'))
    if (!locale) return message

    let result
    try {
      const translated = externalTranslations[locale][message]
      if (translated) {
        result = translated
      } else {
        result = message
      }
    } catch (err) {
      result = message
    }

    return result
  }

  /**
   * Returns a printable platform name from the platform code of the project
   *
   * @param {string} platform The platform code from the project
   * @returns {string | null}
   */
  static getPrettyPlatformName(platform) {
    if (!platform) return null

    let translation
    try {
      translation = supportedPlatforms[platform]
    } catch (err) {
      translation = null
    }

    return translation
  }
}

const supportedPlatforms = {
  PRESTASHOP_8: 'Prestashop 8.0',
  PRESTASHOP_16: 'PrestaShop 1.6',
  PRESTASHOP_17: 'PrestaShop 1.7',
  WORDPRESS_WPML: 'WordPress',
  SHOPIFY: 'Shopify',
}

/**
 * List of translations for texts rendered outside react components which don't have the intl context available
 */
const externalTranslations = {
  es: {
    'Session expired.': 'La sesión expiró.',
    'Succesfully created new translation process': 'Nuevo proceso de traducción creado con éxito',
    'An error occurred while starting the translation.': 'Se ha producido un error al iniciar la traducción.',
    "You can't access to this process.": 'No puedes acceder a este proceso.',
    'It may have been deleted or you lost your access.': 'Es posible que haya sido eliminado o que hayas perdido el acceso.',
  },
  en: {
    'Session expired.': 'Session expired.',
    'An error occurred while starting the translation.': 'An error occurred while starting the translation.',
    'Succesfully created new translation process': 'Succesfully created new translation process',
    "You can't access to this process.": "You can't access to this process.",
    'It may have been deleted or you lost your access.': 'It may have been deleted or you lost your access.',
  },
  de: {
    'Session expired.': 'Sitzung abgelaufen.',
    'Succesfully created new translation process': 'Erfolgreich erstellter neuer Übersetzungsprozess',
    'An error occurred while starting the translation.': 'Bei der Übersetzung ist ein Fehler aufgetreten.',
    "You can't access to this process.": 'Sie können nicht auf diesen Prozess zugreifen.',
    'It may have been deleted or you lost your access.': 'Möglicherweise wurde sie gelöscht, oder Sie haben Ihren Zugriff verloren.',
  },
  fr: {
    'Session expired.': 'La session a expiré.',
    'Succesfully created new translation process': 'Nouveau processus de traduction créé avec succès',
    "You can't access to this process.": 'Vous ne pouvez pas accéder à ce processus.',
    'It may have been deleted or you lost your access.': 'Il peut avoir été supprimé ou vous avez perdu votre accès.',
    'An error occurred while starting the translation.': 'Une erreur s\'est produite lors du démarrage de la traduction.'
  },
  pt: {
    'Session expired.': 'A sessão expirou.',
    'Succesfully created new translation process': 'Novo processo de tradução criado com sucesso',
    "You can't access to this process.": 'Você não pode acessar este processo.',
    'It may have been deleted or you lost your access.': 'Pode ter sido apagado ou você perdeu o acesso.',
    'An error occurred while starting the translation.': 'Ocorreu um erro ao iniciar a tradução.'
  },
  it: {
    'Session expired.': 'Sessione scaduta.',
    'Succesfully created new translation process': 'Nuovo processo di traduzione creato con successo',
    "You can't access to this process.": 'Non è possibile accedere a questo processo.',
    'It may have been deleted or you lost your access.': "Potrebbe essere stato eliminato o hai perso l'accesso.",
    'An error occurred while starting the translation.': 'Si è verificato un errore durante l\'avvio della traduzione.'
  },
  nl: {
    'Session expired.': 'Sessie is verlopen.',
    'Succesfully created new translation process': 'Succesvol nieuw vertaalproces gemaakt',
    "You can't access to this process.": 'U hebt geen toegang tot dit proces.',
    'It may have been deleted or you lost your access.': 'Het kan zijn verwijderd of u uw toegang verloren.',
    'An error occurred while starting the translation.': 'Er is een fout opgetreden bij het starten van de vertaling.'
  },
  pl: {
    'Session expired.': 'Sesja wygasła.',
    'Succesfully created new translation process': 'Pomyślnie utworzono nowy proces tłumaczenia',
    "You can't access to this process.": 'Nie można uzyskać dostępu do tego procesu.',
    'It may have been deleted or you lost your access.': 'Być może został usunięty lub utraciłeś dostęp.',
    'An error occurred while starting the translation.': 'Wystąpił błąd podczas rozpoczynania tłumaczenia.'
  },
}
