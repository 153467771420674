import React, { createContext } from 'react'
import { injectIntl } from 'react-intl'
import IntlMessageFormat from 'intl-messageformat'
import moment from 'moment-timezone'
import 'moment/locale/es'
import 'moment/locale/en-gb'
import 'moment/locale/de'
import 'moment/locale/it'
import 'moment/locale/fr'
import 'moment/locale/pt'
import 'moment/locale/nl'
import 'moment/locale/pl'

// constants
import { CURRENT_PROJECT_STORAGE_KEY } from 'constants/session_storage_keys.contants'
import { DEFAULT_LANGUAGE } from 'constants/default_language'
import { PLATFORMS } from 'constants/platforms.contants'

// hooks
import usePlatform from 'hooks/usePlatform'

// utils
import { fallBackTranslation } from 'utils/fallbackTranslation'
import { getPriceWithSymbol } from 'utils/currencySymbol'

const periodPrice = {
  MONTHLY: 'monthly',
  YEARLY: 'yearly'
}

// TODO(a.rodriguez) Check if should be use in the env
const IntlContext = createContext({ translateText: (defaultText) => defaultText })

const SetIntlContext = ({ intl, children }) => {
  const { platform = PLATFORMS.PRESTASHOP } = JSON.parse(sessionStorage.getItem(CURRENT_PROJECT_STORAGE_KEY)) || {}
  const { isShopify } = usePlatform()
  const lastUserLang = localStorage.getItem('app.settings.locale') ? JSON.parse(localStorage.getItem('app.settings.locale')) : ''
  const selectedLocale = lastUserLang || intl.locale || DEFAULT_LANGUAGE
  moment.locale(selectedLocale)

  const numberFormat = (price, options) => {
    const isShopifyPlatform = isShopify()
    const currency = isShopifyPlatform ? 'USD' : 'EUR'
    const amount = new Intl.NumberFormat(selectedLocale, { ...options }).format(price)
    return getPriceWithSymbol(amount, selectedLocale, currency)
  }

  /**
   * Returns the translated text received with the values injected if necessary
   *
   * @param {string} text The original text
   * @param {{}} values An object with the actual values to inject
   * @returns {string} The translated text
   */
  const translateText = (text, values = {}) => {
    if (Object.keys(values).length) {
      return new IntlMessageFormat(intl.formatMessage({ id: text })).format(values)
    }
    return intl.formatMessage({ id: text })
  }

  const translatePrice = (amount) => {
    if (typeof amount !== 'number') return ''
    const priceNumber = amount / 100
    const hasDecimals = priceNumber % 1 !== 0

    const options = {
      minimumFractionDigits: hasDecimals ? 2 : 0
    }

    return numberFormat(priceNumber, options)
  }

  const translateCharPrice = (amount) => {
    if (typeof amount !== 'number') return ''
    const priceForChart = amount/100000000
    const priceForChartString = priceForChart.toString()
    const decimals = priceForChartString.includes('.') ? priceForChartString.split('.')[1].length : priceForChart.toFixed(20).split('.')[1].length

    const options = {
      minimumFractionDigits: decimals
    }

    return numberFormat(priceForChart, options)
  }

  const translatePricePeriod = (amount, period) => {
    if (period) {
      amount = period === periodPrice.MONTHLY ? amount / 12 : amount * 12
    }
    return translatePrice(amount)
  }

  /**
   * Translate iso code
   * @param {string} iso
   * @param {string} defaultText
   * @return {string}
   */
  const translateIsoCode = (iso, defaultText) => {
    const platformKey = platform.includes(PLATFORMS.PRESTASHOP) ? PLATFORMS.PRESTASHOP : platform
    const translationOfIso = translateText(`${platformKey}.${iso}`)
    const fieldText = fallBackTranslation(translationOfIso, `${platformKey}.${iso}`, () => defaultText)
    return fieldText ? `${fieldText} (${iso.toUpperCase()})` : iso.toUpperCase()
  }

  /**
   * Formats numbers with points to improve readability of large numbers
   * @param {number} charNum The raw number of characters
   * @returns {number} The formatted number of characters
   */
  const formatNumber = (charNum) => intl.formatNumber(charNum)

  /**
   * Transforms a price number into a formatted price string
   *
   * @param {number} price
   * @param {string} locale
   * @returns {string}
   */
  const formatPrice = (price, locale) => {

    return new Intl.NumberFormat(locale, { minimumFractionDigits: 2 }).format(price)

    // this version attaches the currency sign depending on the current locale
    // return new Intl.NumberFormat(locale, { style: 'currency', currency: 'EUR' }).format(price)
  }

  /**
   * Formats a date string with a better readable format given by the user language
   *
   * @param {string} dateString
   * @param {string} timezone
   * @param {string} locale
   *
   * @returns {string} A locale formatted date string with hour:minute
   */
  const translateDate = ({ dateString, timezone = 'UTC', locale = 'en' }, onlyDate = false) => {
    if (!dateString) return null

    const UTCDate = moment.utc(dateString)

    let dateWithTimezone = UTCDate
    if (timezone !== 'UTC') {
      dateWithTimezone = UTCDate.tz(timezone)
    }

    const formattedDate = new Date(dateWithTimezone).toLocaleString(locale, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
      ...(!onlyDate && {
        hour: 'numeric',
        minute: 'numeric',
      }),
    })

    return <span>{formattedDate}</span>
  }

  return (
    <IntlContext.Provider value={
      {
        translateText,
        translateDate,
        formatNumber,
        formatPrice,
        translatePrice,
        translateCharPrice,
        translatePricePeriod,
        translateIsoCode,
        selectedLocale
      }
    }
    >
      {children}
    </IntlContext.Provider>
  )
}

export const InjectIntlContext = injectIntl(SetIntlContext)
export default IntlContext
