import { useEffect } from 'react'

// constants
import { ALL_COOKIES_ACCEPTED, PARTIAL_COOKIES_ACCEPTED } from 'constants/cookies.constants'
import { COOKIES_CONFIRM_MODAL } from 'constants/modals.constants'

// hooks
import { useCreateModal } from 'hooks/useCreateModal'

// utils
import Storage from 'utils/storage'
import { getCookieConsentValue } from 'utils/cookies'

export const useCookiesModal = (excludePaths = []) => {
  const { onOpenModal } = useCreateModal()
  const consentType = getCookieConsentValue()
  const hasNoCookies = consentType !== ALL_COOKIES_ACCEPTED && consentType !== PARTIAL_COOKIES_ACCEPTED

  useEffect(() => {
    const { pathname } = window.location
    if (!Storage.getIsAdminUser() && (hasNoCookies) && (!excludePaths.includes(pathname) && Storage.isLocalStorageAvailable())) {
      onOpenModal({
        type: COOKIES_CONFIRM_MODAL,
        maskClosable: false,
        closable: false
      })
    }
  },[])

  return {
    hasNoCookies
  }
}
