import React, { useEffect, useState } from 'react'
import { Input, Form } from 'antd'

// components
import RadioButton from 'components/CleanUIComponents/RadioButton'
import GlotioButton from 'components/GlotioComponents/GlotioButton'

// hooks
import useGlotio from 'hooks/useGlotio'

// constants
import { CANCEL_SUBSCRIPTION_REASONS, OTHER } from 'constants/cancel_subscription_reasons'
import { LARGE_WIDTH_MODAL, SMALL_WIDTH_MODAL } from 'constants/modals.constants'

// styles
import styles from './index.module.scss'

const CancelSubscriptionModal = ({initialStep = 0, onClose, onCancelSubscription, setModalWidth, onCancelInQuestions, isLoading = false }) => {
  const { translateText } = useGlotio();
  const [ currentStepModal, setCurrentStepModal] = useState(initialStep);
  const [ selectedOption, setSelectedOption ] = useState(null);
  const [ otherReasonValue, setOtherReasonValue] = useState("");

  useEffect(() => {
    return () => setModalWidth(SMALL_WIDTH_MODAL)
  },[])

  const nextStepButtonOptions = {
    0 : {
      text:translateText('Cancel subscription'),
      callback: () => {
        setModalWidth(LARGE_WIDTH_MODAL)
        setCurrentStepModal((prevStep) => prevStep + 1)
      }
    },
    1 : {
      text:translateText('Finalize'),
      callback: async () => {
        const payload = {
          reason: selectedOption,
          comment: selectedOption === OTHER.id ? otherReasonValue : null,
        }
        try {
          await onCancelSubscription(payload)
          onClose()
        } catch (error) {
          console.error('Cancel subscription request: ', error)
        }
      }
    }
  }

  const buttons = [
    {
      text: translateText('Cancel'),
      callback: () => {
        onClose()
        if (typeof onCancelInQuestions === 'function') {
          onCancelInQuestions()
        }
      },
      options: {
        type: "button",
        variant: 'outlined',
        size: 'large',
        className: styles.modal_button,
      },
    },
    {
      text: nextStepButtonOptions[currentStepModal].text,
      callback: () => nextStepButtonOptions[currentStepModal].callback(),
      options: {
        type: "button",
        variant: 'default',
        size: 'large',
        status: 'error',
        className: styles.modal_button,
        isLoading,
        disabled: currentStepModal === 1 && !selectedOption
      },
    }
  ]

  const getReasonsToCancel = () => CANCEL_SUBSCRIPTION_REASONS.map((option) => ({title: translateText(option.text), id: option.id}));
  const onSetOtherReasonValue = (e) => setOtherReasonValue(e.target.value);
  const onSelectOption = (id) => setSelectedOption(id);

  const getHeaderInfo = () => {

    const headerInfo = {
      0 : () => (
        <>
          <p className={styles.title}>{translateText('Cancel subscription')}</p>
          <p className={styles.description}>{translateText('If you continue, you will cancel your plan. After completing this process, you will be able to continue using Glotio until the end of the current billing period. You can undo the cancellation at any time while the billing period is in progress.')}</p>
        </>
      ),
      1 : () => (
        <>
          <p className={styles.title}>{translateText('Why are you cancelling your subscription?')}</p>
          <p className={styles.description}>{translateText('We want to be better, so it is important for us to know why you cancel your subscription.')}</p>
        </>
      )
    }

    return headerInfo[currentStepModal]()

  }

  return (
    <>
      <div className={styles.wrapper_title}>
        {getHeaderInfo()}
      </div>
      { currentStepModal === 1 &&
        <div className={styles.wrapper_content}>
            {
              getReasonsToCancel().map((option) => {
                return (
                  <label key={option.id} htmlFor={option.id} className={styles.label_question}>
                    <div className={`${styles.question_block}  ${selectedOption === option.id && styles.active_question}`}>
                      <div className={styles.question_input}>
                        <RadioButton
                          checked={option.id === selectedOption}
                          value={option.id}
                          name="radioQuestion"
                          onChange={() => onSelectOption(option.id)}
                          size="small"
                        />
                      </div>
                      <div className={`${styles.question_title} ${selectedOption === option.id && styles.active_question_title}`}>
                        <p>{ option.title }</p>
                      </div>
                    </div>
                  </label>
                )
              })
            }
            {
              selectedOption === OTHER.id && (

                <div className={styles.textarea_wrapper}>
                  <Form>
                    <Input.TextArea
                      required
                      placeholder={translateText('We briefly explain why you are canceling your subscription.')}
                      rows={3}
                      className={styles.textarea}
                      value={otherReasonValue}
                      onChange={onSetOtherReasonValue}
                      maxLength={500}
                    />
                  </Form>
                </div>
              )
            }
        </div>
      }
      <div className={styles.wrapper_actions}>
        {
          buttons.map(({ text, callback, options }) => (
            <GlotioButton key={text} {...options} onClick={callback}>{text}</GlotioButton>
          ))
        }
      </div>
    </>
  )

}

export default CancelSubscriptionModal
