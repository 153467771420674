import React, { useEffect, useState } from 'react'
import { Icon, Spin } from 'antd'

// hooks
import useGlotio from 'hooks/useGlotio'

// redux
import { selectProjectId } from 'redux/project/selectors'
import { useSelector } from 'react-redux'

// models
import { payPendingInvoiceRequest } from 'models/api/payment'
import { getInvoicesRequest } from 'models/api/project'

// constants
import { STATUS_INVOICE } from 'constants/status_invoice'

import styles from './index.module.scss'

const RetrySubscriptionPaymentModal = ({ onFailRetryPayment }) => {
  const { translateText } = useGlotio();
  const projectId = useSelector(selectProjectId)
  const [isError, setIsError] = useState(false)

  useEffect(() => {
    retryPayment()
  }, [])

  useEffect(() => {
    if (isError) {
      onFailRetryPayment()
    }
  }, [isError])

  const getNoBillPay = async () => {
    const { error, result } = await getInvoicesRequest({ page: 1, status: STATUS_INVOICE.UNPAYED })
    if (error) {
      throw new Error()
    }
    return result.data.at(0)
  }

  const payPendingInvoice = async (invoiceWithError) => {
    const { error } =  await payPendingInvoiceRequest({
      projectId,
      invoiceId: invoiceWithError.id,
      totalToPay: invoiceWithError.total_amount
    })
    if (error) {
      throw new Error()
    }
    window.location.reload()
  }

  const retryPayment = async () => {
    try {
      const invoiceWithError = await getNoBillPay()
      await payPendingInvoice(invoiceWithError)
    } catch (error) {
      setIsError(true)
    }
  }

  return (
    <div className={styles.loading_container}>
      <Spin indicator={<Icon type="loading" style={{ fontSize: 64 }} spin />} />
      <h2 className={styles.loading_title}>{translateText('Making payment')}</h2>
      <p className={styles.loading_description}>{translateText('We are making another payment attempt. This may take a few minutes')}</p>
    </div>
  )

}

export default RetrySubscriptionPaymentModal
