import TRANSLATION_WIZARD from 'constants/translation_wizard.constants'
import actions from './actions'

const initialState = {
  assistantPage: {
    page: TRANSLATION_WIZARD.pages.ANALYSE_FINISH,
    lang: null,
  }
}

export default function wizardReducer(state = initialState, { type, payload }) {
  let assistantPage = ''
  switch (type) {
    case actions.SET_ASSISTANT_PAGE:
      assistantPage = payload || initialState.assistantPage
      sessionStorage.setItem('assistantPage', JSON.stringify(assistantPage))
      return {
        ...state,
        assistantPage
      }
    case actions.GET_ASSISTANT_PAGE:
      return JSON.parse(sessionStorage.getItem('assistantPage')) || initialState.assistantPage
    case actions.DELETE_ASSISTANT_PAGE:
      sessionStorage.removeItem('assistantPage')
      return initialState
    default:
      return state
  }
}
