// default languages
const GERMAN = 'de';
const ENGLISH = 'en';
const SPANISH = 'es';
const FRENCH = 'fr';
const ITALIAN = 'it';
const DUTCH = 'nl';
const PORTUGUESE = 'pt';
const POLISH = 'pl'

// other languages
const SPANISH_ARGENTINA = 'ag';
const CATALAN_SPAIN = 'ca';
const SPANISH_COLUMBIA = 'cb';
const BASQUE_SPAIN = 'eu';
const GALICIAN_SPAIN = 'gl';
const SPANISH_MEXICO = 'mx';
const SPANISH_PERU = 'pe';
const PORTUGUESE_BRAZIL = 'br';
const FRENCH_CANADA = 'qc';
const GERMAN_SWITZERLAND = 'dh';

const DEFAULT = ENGLISH;

const LANGUAGES_MAPPING = {
  [SPANISH]: SPANISH,
  [SPANISH_ARGENTINA]: SPANISH,
  [CATALAN_SPAIN]: SPANISH,
  [SPANISH_COLUMBIA]: SPANISH,
  [BASQUE_SPAIN]: SPANISH,
  [GALICIAN_SPAIN]: SPANISH,
  [SPANISH_MEXICO]: SPANISH,
  [SPANISH_PERU]: SPANISH,
  [PORTUGUESE]: PORTUGUESE,
  [PORTUGUESE_BRAZIL]: PORTUGUESE,
  [GERMAN]: GERMAN,
  [GERMAN_SWITZERLAND]: GERMAN,
  [FRENCH]: FRENCH,
  [FRENCH_CANADA]: FRENCH,
  [ITALIAN]: ITALIAN,
  [DUTCH]: DUTCH,
  [ENGLISH]: ENGLISH,
  [POLISH]: POLISH
};

export const fallbackLanguage = language => language && LANGUAGES_MAPPING[language] ? LANGUAGES_MAPPING[language] : DEFAULT
