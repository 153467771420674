import React from 'react'

// style
import styles from './index.module.scss'

/**
 * @param {Function} onClick
 * @param {Function} onKeyDown
 * @param {string} className
 * @param {ReactNode|JSX.Element} children
 * @param {boolean} pointer
 * @return {JSX.Element}
 */
const InfoAdvertisement = ({onClick, onKeyDown, className, children, pointer}) => {
  const styleForContainer = pointer ? `${styles.info_advertisement_container_pointer}` : `${styles.info_advertisement_container}`
  return (
    <div
      className={`${styleForContainer} ${className}`}
      onClick={onClick}
      onKeyDown={onKeyDown}
      tabIndex="0"
      role="button"
    >
      {children}
    </div>
  )
}

export default InfoAdvertisement
