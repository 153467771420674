import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import Image from 'assets/images/illustrations/finish-checkout.png';
import { withRouter } from 'react-router';

// components
import TicketSupportForm from 'components/GlotioComponents/TicketSupportForm'
import GlotioButton from 'components/GlotioComponents/GlotioButton';

// constants
import { ROUTES } from 'constants/routes.constants';
import { ASSISTANT_ROUTER_HASH } from 'constants/assistant.router.hash'
import { EXTERNAL_TICKET_SUPPORT_SUBJECTS } from 'constants/ticket_support.constants'

// hooks
import useGlotio from 'hooks/useGlotio'

// selector
import { selectCurrentProcess } from 'redux/process/selectors'

// style
import styled from './index.module.scss'

const TicketSupport = ({handleClose, location}) => {

  const { translateText } = useGlotio()
  const [showSuccessView, setSuccessView] = useState(false);
  const currentProcess = useSelector(selectCurrentProcess)

  
  /* Avoid retrieve errors and sent them in the follow routes (anonymous routes + default ticket support form) */
  const excludedRoutes = ![ROUTES.TECHNICAL_SUPPORT, ROUTES.PASSWORD_FORGOT, ROUTES.EMAIL_VERIFICATION].includes(location.pathname)

  /**
   * getExternalSubject: retrieve the hash value to handle when a ticket form is open for inject the subject value relative to a path/hash route
   * @returns string | null
   */
  const getExternalSubject = () => {

    const { hash = '' } = window.location
    const currentHash = hash.replace('#', '')
    const externalSubjectByRoute = {
      [ASSISTANT_ROUTER_HASH.DEFAULT_LANGUAGE]: EXTERNAL_TICKET_SUPPORT_SUBJECTS.DEFAULT_LANGUAGE,
      [ASSISTANT_ROUTER_HASH.DEFAULT_LANGUAGE_HELP]: EXTERNAL_TICKET_SUPPORT_SUBJECTS.DEFAULT_LANGUAGE,
      [ASSISTANT_ROUTER_HASH.TRANSLATION_FINISHED]: EXTERNAL_TICKET_SUPPORT_SUBJECTS.TRANSLATION_FINISHED
    }

    return (excludedRoutes && externalSubjectByRoute[currentHash]) || null

  }

  /**
   * registerErrorInfo: retrieve the process status and processID to attach their values when a ticket form is created to make it more context
   * @returns Object
   */
  const registerErrorInfo = process => {

    let errorInfo = {}
    const shouldTrackError = get(process,'status', false) && get(process,'processID', false) && get(process,'status', '').includes('ABORTED') && excludedRoutes
    if (shouldTrackError) {
      errorInfo = {
        processUniqId: process.processID,
        errorCode: process.status
      }
    }

    return errorInfo

  }

  const onShowSuccessView = () => setSuccessView(true)

  const handleView = () => {

    if (showSuccessView) {
      return (
        <div className={`${styled.root}`}>
          <div className={styled.imageLayout}>
            <img src={Image} alt="" className={styled.successImage} />
          </div>
          <div className={styled.infoLayout}>
            <h2 className={styled.title}>{translateText('Your ticket has been opened')}</h2>
            <p className={styled.description}>{translateText('One of our technicians will contact you as soon as possible via email.')}</p>
          </div>
          <div className={styled.button_wrapper}>
            <GlotioButton className={styled.close_button} variant='default' size="large" onClick={handleClose}>{translateText('Accept')}</GlotioButton>
          </div>
        </div>
      )
    }
    
    return <TicketSupportForm {...registerErrorInfo(currentProcess)} onSuccess={onShowSuccessView} externalSubject={getExternalSubject()} />

  }

  return handleView()

}

export default withRouter(TicketSupport)