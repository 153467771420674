import { useEffect } from 'react';

 /**
 * useAssistantHash: Add hash to the current url
 * @param {String} hash
 * @param {any[]} deps
 * @return {Function} 
 */

function useAssistantHash (hash = "", deps = []) {

  useEffect(() => {
    addAssistantHash(hash)
  },[...deps])

  function addAssistantHash (newHash) {
    if (!newHash) return
    window.location.replace(`#${newHash}`);
  }

  return { addAssistantHash };
}

export default useAssistantHash