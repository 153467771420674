import { createSelector } from 'reselect'

/**
 * Returns the menu slice from the global state
 * @param {Object<string, any>} state The redux store object
 */
const selectMenu = state => state.menu

/**
 * Returns the left menu data to render
 */
export const selectMenuLeftData = createSelector([selectMenu], menu => menu.menuLeftData)
