import { initializeTrackers } from "thirdPartiesCookies";
import Cookies from 'js-cookie'

// constants
import { ALL_COOKIES_ACCEPTED, COOKIE_NAME, PARTIAL_COOKIES_ACCEPTED } from "constants/cookies.constants";

const isMasterEnvironment= process.env.REACT_APP_NODE_ENVIROMENT === 'master';
const isDevelopEnvironment= process.env.REACT_APP_NODE_ENVIROMENT === 'develop';
const environmentIsAllowed= isMasterEnvironment || isDevelopEnvironment;

export const onInitThirdPartyTrackers = (allCookiesAccepted, onDeclineCookies) => {
  document.dispatchEvent(new CustomEvent('cookies:on', {
    detail: {
      allCookiesAccepted,
    },
  }))

  if (typeof onDeclineCookies === 'function') {
    onDeclineCookies();
    return;
  }
  initializeTrackers(allCookiesAccepted)
}

export const onSetCookies = (value) => {

  const cookieOptions = {
    expires: 365,
    sameSite: environmentIsAllowed ? 'none': 'strict',
    secure: window.location.protocol === "https:"
  }

  Cookies.set(COOKIE_NAME, value, cookieOptions);
  Cookies.set(`${COOKIE_NAME}-legacy`, value, cookieOptions);
}


export const getCookieConsentValue = () => {
  return Cookies.get('glotio_gdpr_consent')
}

export const userAcceptedAllCookies = () => {
  const consentType = getCookieConsentValue()
  return consentType === ALL_COOKIES_ACCEPTED;
}

export const userAcceptedPartialCookies = () => {
  const consentType = getCookieConsentValue()
  return consentType === PARTIAL_COOKIES_ACCEPTED;
}
