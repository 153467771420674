import React from 'react';

import styled from './index.module.scss';

const LanguageInfoWrapper = ({children, fluid = false, title = "", description = ""}) => {

  return (
    <div className={`${styled.language_info_wrapper} ${fluid ? 'fluid' : ''}`}>
      { (description || title ) && 
      <div className={styled.info_content}>
        { title && <h1 className={styled.title}>{title}</h1>}
        { description && <p className={styled.description}>{description}</p>}
      </div>}
      {children}
    </div>
  )

}

export default LanguageInfoWrapper