// react
import React, { useEffect, useState } from 'react'

// components
import FirstTranslation from 'layouts/FirstTranslation'
import Step from 'components/GlotioComponents/Step'
import WizardFooter from 'components/GlotioComponents/Wizard/Footer'
import AddLanguagesContainer from 'components/GlotioComponents/Wizard/HandlerNewLanguages/AddLanguagesContainer'
import RootPortal from 'components/CleanUIComponents/RootPortal'
import GlotioButton from 'components/GlotioComponents/GlotioButton'
import BackButton from 'components/CleanUIComponents/Buttons/BackButton'
import AnalyseResume from 'components/GlotioComponents/Wizard/AnalyseResume'

// hooks
import useAssistantHash from 'hooks/useAssistantHash'
import useGlotio from 'hooks/useGlotio'
import { useCreateModal } from 'hooks/useCreateModal'

// constants
import { CONFIRM_MODAL } from 'constants/modals.constants'
import { ASSISTANT_ROUTER_HASH } from 'constants/assistant.router.hash'
import TRANSLATION_WIZARD from 'constants/translation_wizard.constants'

// utils
import { filter, orderBy } from 'lodash'
import { initializeOfferLanguages } from 'utils/LanguageOffers'

// types
import 'components/GlotioComponents/Wizard/types'

// styles
import styles from './index.module.scss'

/**
 * Returns view of select default language of shop
 * @param {Function} setAssistantPage
 * @param {Offer | Object} languagesOffers
 * @param {Totals | Object} languagesTotals
 * @param {Function} getTranslationOffers
 * @param {NewTranslationOffer[]} allLanguages
 * @param {Function} onTranslateFree
 * @param {boolean} isLoadingPay
 * @return {JSX.Element}
 */
const LanguageSelectorFree = ({
  setAssistantPage,
  languagesOffers,
  getTranslationOffers,
  allLanguages,
  onTranslateFree,
  isLoadingPay,
  defaultLanguages =  []
  }) => {
  const { translateText } = useGlotio()
  const { onOpenModal, onCloseModal } = useCreateModal()
  const [selectedLanguage, setSelectedLanguage] = useState(() => initializeOfferLanguages(languagesOffers))
  const canContinue = !!languagesOffers.selectedLanguagesOffers.length;

  useAssistantHash(ASSISTANT_ROUTER_HASH.FREE_LANGUAGE_INSTALLATION)


  useEffect(() => {
    setSelectedLanguage(() => initializeOfferLanguages(languagesOffers));
  }, [languagesOffers])


  // Get installed languages aka: preglotio languages
  const installedLanguages = orderBy(filter(allLanguages, { 'installed': true }), ['translatable'], ['desc']);

  function showInstalledLanguagesModal (onSuccess) {

    const message = {
      type: CONFIRM_MODAL,
      closable: false,
      maskClosable: false,
      title: translateText('Fully translate languages'),
      description: translateText('The languages installed in your online store prior to Glotio will be completely translated again, so you will lose the previous translations. Do you want to integrate the languages to Glotio and translate them again?'),
      buttons: [{
        text: translateText('Cancel'),
        options: {
          type: "button",
          variant: 'outlined',
          size: 'large',
          className: styles.modal_button
        },
        callback: onCloseModal,
      },
      {
        text: translateText('Translate completely'),
        loadingBeforeCallback: true,
        options: {
          type: "button",
          variant: 'default',
          size: 'large',
          className: styles.modal_button,
        },
        callback: onSuccess,
      }]

    }

    onOpenModal({...message});

  }

  const goBackPage = () => {
    setAssistantPage({ page: TRANSLATION_WIZARD.pages.QUESTION_DEFAULT_LANGUAGE, lang: null })
    getTranslationOffers([], false, false);
  }

  const handleToggleLanguage = (iso) => {
    if (selectedLanguage.includes(iso)) {
       setSelectedLanguage([])
       getTranslationOffers([], false)
    } else {
      setSelectedLanguage([iso])
      getTranslationOffers([iso], false)
    }
  }

  const hasInstalledLanguageToTranslate = () => installedLanguages.some(({languageIso}) => selectedLanguage.includes(languageIso))

  const launchFreeTranslation = () => {
    const payload = {
      selectedLanguages: selectedLanguage,
      totalToPay: 0
    }
    onTranslateFree(payload, () => {
      setAssistantPage({ page: TRANSLATION_WIZARD.pages.LANGUAGE_SELECTOR_FREE, lang: selectedLanguage })
    });
  }

  const onPayFree = () => {

    if (hasInstalledLanguageToTranslate()) {
      showInstalledLanguagesModal(() => {
        onCloseModal();
        launchFreeTranslation()
      })
      return
    }
    launchFreeTranslation();
  }

   const renderButtonBar = () => {
    return (
      <RootPortal>
        <RootPortal.Grid>
          <BackButton pushBack={goBackPage} data="icon" />
          <GlotioButton
            data='button'
            type='button'
            className={styles.welcome_button}
            variant='default'
            size='large'
            onClick={onPayFree}
            isLoading={isLoadingPay}
            disabled={!canContinue}
          >
            {translateText ('Translate for free')}
          </GlotioButton>
        </RootPortal.Grid>
      </RootPortal>
    )
  }

  return (
    <FirstTranslation>
      <AnalyseResume
        defaultLanguages={defaultLanguages}
        installedLanguages={installedLanguages}
        className={styles.container_resume}
        info={translateText('All languages will be translated from the source language.')}
      />
      <Step
        title={translateText('What languages do you want to translate into?')}
        subtitle={<>{translateText('This is the last step prior to the translation of your online store. Remember that because you are a new user')},&nbsp;<p className={styles.free_first_language}>{ translateText('the first language is free.') }</p></>}
      >
        <AddLanguagesContainer
          handleToggleLanguages={handleToggleLanguage}
          selectedLanguages={selectedLanguage}
          languages={allLanguages}
        />
      </Step>
      <WizardFooter
        className={styles.footer}
        hasDescription
      />
      {renderButtonBar()}
    </FirstTranslation>
  )
}


export default LanguageSelectorFree
