import React, { useEffect, useState } from 'react'
import { createPortal } from 'react-dom'

// styles
import styles from './index.module.scss'

const RootPortal = ({children}) => {
  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true)
  }, [])

  const domTarget =  document.getElementById('root-portal')
  return (isMounted && domTarget) && createPortal(<div className={styles.innerWrapper}>{children}</div>, domTarget)
}

const Basic = ({children}) => <div className={styles.basicTemplate}>{children}</div>

const Grid = ({children}) => <div className={styles.gridTemplate}>{children}</div>

RootPortal.Basic = Basic;
RootPortal.Grid = Grid;

export default RootPortal;
