import React from 'react'
import { connect } from 'react-redux'
import store from 'store'

// hoc
import { Link, withRouter } from 'react-router-dom'
import withGlotio from 'hoc/withGlotio'

// components
import { Scrollbars } from 'react-custom-scrollbars'

// utils
import { fetchProjectStart } from 'redux/project/actions'

// components
import { Menu, Layout, Divider } from 'antd'

// constants
import { SUBSCRIPTION_STATUS_WITH_RESTRICTIONS } from 'constants/status_subscriptions'

// redux selectors
import { selectMenuLeftData } from 'redux/menu/selectors'
import {selectHasAccessToTools, selectSubscriptionStatus} from 'redux/subscription/selectors'
import {
  selectIsMenuCollapsed,
  selectIsMobileView,
  selectIsLightTheme,
  selectIsSettingsOpen,
  selectIsMobileMenuOpen,
} from 'redux/settings/selectors'

// assets
import logoImg from 'assets/images/logos/glotio_logo_purple.svg'
import glotioIcon from 'assets/images/logos/glotio_icon.svg'

// services
import { optionsWithTools } from 'services/menu'

// styles
import styles from './style.module.scss'

const { Sider } = Layout
const { SubMenu } = Menu

class MenuLeft extends React.PureComponent {
  state = {
    selectedKeys: store.get('app.menu.selectedKeys') || [],
    openedKeys: store.get('app.menu.openedKeys') || [],
  }

  setSelectedKeys = props => {
    const { menuData } = this.props

    // Adds to the selection list the parent item if one submenu item is selected
    const selectedItems = menuData.map(menuItem => {
      const { history } = props
      const {
        location: { pathname },
      } = history
      const { url, key } = menuItem

      return this.checkIfMenuItemIsSelected(pathname, url, key)
    })

    this.setState({
      selectedKeys: selectedItems || [],
    })
  }

  checkIfMenuItemIsSelected = (pathname, url, key) => {
    if (!pathname || !url) return null

    // take the first slug of the current pathname
    const path = pathname.split('/')[1]
    const itemUri = url ? url.split('/')[1] : ''

    if (path === itemUri) {
      return key
    }

    return null
  }

  onCollapse = (value, type) => {
    const { collapseMenu, isMenuCollapsed } = this.props
    if (type === 'responsive' && isMenuCollapsed) {
      return
    }
    collapseMenu(isMenuCollapsed)

    this.setState({
      openedKeys: [],
    })
  }

  onOpenChange = openedKeys => {
    store.set('app.menu.openedKeys', openedKeys)
    this.setState({
      openedKeys,
    })
  }

  handleClick = e => {
    const { fireClick, isSettingsOpen } = this.props
    store.set('app.menu.selectedKeys', [e.key])
    // custom action on settings menu item
    if (e.key === 'settings') {
      fireClick(isSettingsOpen)
      return
    }
    this.setState({
      selectedKeys: [e.key],
      // openKeys: e.keyPath,
    })
  }

  generateMenuItems = () => {
    const { menuData = [], isMenuCollapsed, isMobileView, history, translateText, subscriptionStatus  } = this.props
    const filterMenuData = menuData.map(menuItem => optionsWithTools.includes(menuItem.key) ? { ...menuItem, disabled:  menuItem.disabled ||  SUBSCRIPTION_STATUS_WITH_RESTRICTIONS.includes(subscriptionStatus)   } : { ...menuItem })

    const {
      location: { pathname },
    } = history

    const generateItem = item => {
      const { key, title, url, icon, disabled } = item

      if (item.divider) {
        if (isMenuCollapsed) return null
        return (
          <span key={key}>
            <Divider orientation="left">{translateText(key)}</Divider>
          </span>
        )
      }

      const titleKey = key ? `${key}` : title

      if (item.url) {
        return (
          <Menu.Item
            key={key}
            disabled={disabled}
            id={
              this.checkIfMenuItemIsSelected(pathname, url, key) ? styles.menu_item_selected : null
            }
          >
            {item.target ? (
              <a href={translateText(url)} target={item.target} rel="noopener noreferrer">
                { typeof icon === 'function' && icon({className:`${styles.icon} icon-collapsed-hidden`})}
                <span className={styles.title}>{translateText(titleKey)}</span>
              </a>
            ) : (
              <Link to={url}>
                {typeof icon === 'function' && icon({className:`${styles.icon} icon-collapsed-hidden`})}
                <span className={styles.title}>{translateText(titleKey)}</span>
              </Link>
            )}
          </Menu.Item>
        )
      }
      return (
        <Menu.Item key={key} disabled={disabled}>
          {typeof icon === 'function' && icon({className:`${styles.icon} icon-collapsed-hidden`})}
          <span className={styles.title}>{translateText(titleKey)}</span>
        </Menu.Item>
      )
    }

    const generateSubmenu = items =>
      items.map(menuItem => {
        if (menuItem.children) {
          const subMenuTitle = (
            <span key={menuItem.key}>
              <span className={styles.title}>{menuItem.title}</span>
              { typeof menuItem.icon === 'function' && menuItem.icon({className:`${styles.icon}`})}
            </span>
          )
          return (
            <SubMenu title={subMenuTitle} key={menuItem.key}>
              {generateSubmenu(menuItem.children)}
            </SubMenu>
          )
        }
        return generateItem(menuItem)
      })

    return filterMenuData.map(menuItem => {
      if (menuItem.children && isMobileView) {

        const subMenuTitle = (
          <span key={menuItem.key}>
            <span className={styles.title}>{translateText(menuItem.key)}</span>
            {typeof menuItem.icon === 'function' && menuItem.icon({className:`${styles.icon}`})}
          </span>
        )

        return (
          <SubMenu
            title={subMenuTitle}
            key={menuItem.key}
            onTitleClick={!isMobileView ? () => history.push(menuItem.url) : () => {}}
          >
            {isMobileView && generateSubmenu(menuItem.children)}
          </SubMenu>
        )
      }

      return generateItem(menuItem)
    })
  }

  UNSAFE_componentWillMount() {
    this.setSelectedKeys(this.props)
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.isMenuCollapsed && !newProps.isMobileView) {
      this.setState({
        openedKeys: [],
      })
    }
    this.setSelectedKeys(newProps)
  }

  render() {
    const { selectedKeys, openedKeys } = this.state
    const { isMobileView, isMenuCollapsed, isLightTheme } = this.props
    const menuSettings = isMobileView
      ? {
          width: 256,
          collapsible: false,
          collapsed: false,
          onCollapse: this.onCollapse,
        }
      : {
          width: 256,
          collapsible: true,
          collapsed: isMenuCollapsed,
          onCollapse: this.onCollapse,
          // breakpoint: 'lg',
        }

    const menu = this.generateMenuItems()

    return (
      <Sider
        {...menuSettings}
        className={isLightTheme ? `${styles.menu} ${styles.light}` : styles.menu}
      >
        <div className={styles.logo}>
          <div
            className={`${styles.logoContainer} ${isMenuCollapsed ? styles.logoCollapsed : null}`}
          >
            <Link to="/">
              <picture>
                <img src={isMenuCollapsed ? glotioIcon : logoImg} alt="Glotio logo" />
              </picture>
            </Link>
          </div>
        </div>
        <Scrollbars
          className={isMobileView ? styles.scrollbarMobile : styles.scrollbarDesktop}
          renderThumbVertical={({ style, ...props }) => (
            <div
              {...props}
              style={{
                ...style,
                width: '4px',
                borderRadius: 'inherit',
                backgroundColor: '#c5cdd2',
                left: '1px',
              }}
            />
          )}
          autoHide
        >
          <Menu
            theme={isLightTheme ? 'light' : 'dark'}
            onClick={this.handleClick}
            selectedKeys={selectedKeys}
            openKeys={openedKeys}
            onOpenChange={this.onOpenChange}
            mode="inline"
            className={styles.navigation}
          >
            {menu}
          </Menu>
        </Scrollbars>
      </Sider>
    )
  }
}

const mapStateToProps = state => ({
  isMenuCollapsed: selectIsMenuCollapsed(state),
  isMobileView: selectIsMobileView(state),
  isSettingsOpen: selectIsSettingsOpen(state),
  isLightTheme: selectIsLightTheme(state),
  isMobileMenuOpen: selectIsMobileMenuOpen(state),
  menuData: selectMenuLeftData(state),
  hasAccessToTools: selectHasAccessToTools(state),
  subscriptionStatus: selectSubscriptionStatus(state)
})

const mapDispatchToProps = dispatch => ({
  fetchProject: () => dispatch(fetchProjectStart()),
  collapseMenu: isMenuCollapsed =>
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isMenuCollapsed',
        value: !isMenuCollapsed,
      },
    }),
  fireClick: isSettingsOpen =>
    dispatch({
      type: 'settings/CHANGE_SETTING',
      payload: {
        setting: 'isSettingsOpen',
        value: !isSettingsOpen,
      },
    }),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withGlotio(MenuLeft)))
