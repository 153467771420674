import React from 'react'
import styles from './styles.module.scss'

const FirstTranslation = ({ className = '', children }) => {
  return (
    <div className={`${styles.layout_wrapper} ${className}`}>
      <div className={styles.layout_container}>
        {children}
      </div>
    </div>
  )
}

export default FirstTranslation
