import React, { useEffect, useState } from 'react';
import {Col, Row, Switch} from "antd";

// components
import GlotioButton from 'components/GlotioComponents/GlotioButton'

// hooks
import useGlotio from "hooks/useGlotio";

// utils
import { getCookieConsentValue, onInitThirdPartyTrackers, onSetCookies } from 'utils/cookies';

// constants
import { ALL_COOKIES_ACCEPTED, PARTIAL_COOKIES_ACCEPTED } from 'constants/cookies.constants';

// services
import externalUrls from 'services/externalUrls';

// styles
import styles from './index.module.scss';


const CookiesConfigModal = ({onClose}) => {

  const { translateText } = useGlotio()
  const [acceptedAllCookies, setAcceptedAllCookies] = useState(false)
  const onAcceptThirdPartyCookies = checked => setAcceptedAllCookies(checked)

  useEffect(() => {
    const consentType = getCookieConsentValue()
    setAcceptedAllCookies(consentType === ALL_COOKIES_ACCEPTED)
  },[])

  function onSaveConfig(){

    const previousCookiesValue = getCookieConsentValue()
    onSetCookies(acceptedAllCookies ? ALL_COOKIES_ACCEPTED : PARTIAL_COOKIES_ACCEPTED)

    if (!acceptedAllCookies && previousCookiesValue === ALL_COOKIES_ACCEPTED) {
      onInitThirdPartyTrackers(acceptedAllCookies, () => window.location.reload());
      return;
    }

    onInitThirdPartyTrackers(acceptedAllCookies);
    onClose()

  }

  return (
    <div className={styles.modal_window_content}>
      <Row>
        <Col>
          <h1 className={styles.cookie_title}>{translateText('Cookie Preferences')}</h1>
          <p>{translateText('This website uses cookies to enhance your experience. You can choose not to accept them if you wish.')}</p>
          <p>
            <a href={translateText(externalUrls.COOKIES)} target="_blank" rel="noopener noreferrer">{translateText('Cookie Preferences')}</a>
            {translateText(' and ')}
            <a href={translateText(externalUrls.PRIVACY)} target="_blank" rel="noopener noreferrer">{translateText('Privacy')}</a>
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>{translateText('Mandatory Cookies')}</h3>
          <p>{translateText('These cookies are necessary for the basic functioning of the website and are therefore always active. They include cookies that allow you to remember your preferences when browsing the website. They make the shopping cart and the checkout process smoother, as well as providing security and compliance assistance.')}</p>
        </Col>
      </Row>
      <Row>
        <Row>
          <Col span={12}><h3>{translateText('Third party cookies')}</h3></Col>
          <Col span={12} className={styles.switch}><Switch onChange={onAcceptThirdPartyCookies} checked={acceptedAllCookies} /></Col>
        </Row>
        <Col>
          <p>{translateText('Social network cookies offer you the possibility to connect to your social networks and share the content of our website through them. Advertising (or third-party) cookies collect information to better tailor advertising to your interests. In some cases, these cookies include the processing of your personal data. Deselecting these cookies may cause you to see advertising that is not relevant to you or that you cannot link effectively with Facebook, Twitter or other social networks and that you cannot share content on social networks.')}</p>
        </Col>
      </Row>
      <div className={styles.modal_accept}>
        <div>
          <GlotioButton
            type='button'
            variant='default'
            size='large'
            onClick={onSaveConfig}
            fluid
          >
            {translateText('Save')}
          </GlotioButton>
        </div>
      </div>
    </div>
  )

}

export default CookiesConfigModal
