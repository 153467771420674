import React from 'react'

// components
import { Col, Icon, Row } from 'antd'

// styles
import styles from './style.module.scss'

// Todo: refactor -> old component when
const AlertMessage = ({ text, type, background = false }) => {
  return (
    <Row>
      <Col className={`${styles.alert_container} ${styles[`alert_${type}`]} ${background && styles.alert_background}`} xs={24} sm={24} md={24} lg={24} xl={24}>
        <Icon type='exclamation-circle-o' />
        <div className={styles.alert_text_container}>
          <p className={styles.alert_text}>{ text }</p>
        </div>
      </Col>
    </Row>
  )
}

export default AlertMessage
