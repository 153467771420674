import React from 'react';

// components
import AuthPromotionBlock from 'components/GlotioComponents/AuthPromotionBlock'

// hooks
import useGlotio from 'hooks/useGlotio'

// constants
import { ROUTES } from 'constants/routes.constants'

// utils
import { getBasePath } from 'utils/Utils';

// assets
import illustration from 'assets/images/illustrations/auth_illustration.png'

// styles
import styles from './index.module.scss'


const CommonLayout = ({children, isFade = false}) => {
  return (
    <div className={`${styles.rightColumn} ${(isFade ? styles.isFade : '')}`}>
      <img src={illustration} alt="branding illustration" />
      {children}
    </div>
  )
}

const AuthRightInfo = ({location}) => {

  const { translateText } = useGlotio()
  const defaultScheme = {
    getComponent: () => null,
    isFade: false,
  }
  const rightColumnScheme = {
    [ROUTES.SIGN_UP]: {
      getComponent: () => getAuthPromotionComponent(),
      isFade: true,
    },
    [ROUTES.PASSWORD_FORGOT]: {
      getComponent: () => null,
      isFade: false,
    },
    [ROUTES.PASSWORD_RESET]: {
      getComponent: () => null,
      isFade: false,
    },
    [ROUTES.LOGIN]: {
      getComponent: () => null,
      isFade: false,
    }
  }

  function getAuthPromotionComponent () {
    return (
      <div className={styles.banner_overlay}>
        <AuthPromotionBlock
          title={translateText('Try Glotio by translating the first language for free')}
          rightComponent={translateText("No hidden fees and no payment method to enter. Try Glotio's translation system for free")}
        />
      </div>
    )
  }

  const handleView = () => {

    const {pathname} = location;
    const currentPath = getBasePath(pathname);
    const currentScheme = rightColumnScheme[currentPath] || defaultScheme;
    return (
      <CommonLayout isFade={currentScheme.isFade}>
        {currentScheme.getComponent()}
      </CommonLayout>
    )

  }

  return handleView()

}

export default AuthRightInfo;
