import React, { useState } from 'react'

// components
import FaqLink from 'components/GlotioComponents/FaqLink'
import GlotioIconButton from 'components/GlotioComponents/GlotioIconButton'
import TicketSupportModalFullContent from 'components/GlotioComponents/TicketSupport/TicketSupportModal'

// hooks
import useGlotio from 'hooks/useGlotio'

// assets
import { ReactComponent as ExternalLink } from 'assets/images/icons/external-link.svg'
import { ReactComponent as Headphones } from 'assets/images/icons/headphones.svg'
import {ReactComponent as HelpCircle} from 'assets/images/icons/help-circle.svg'
import {ReactComponent as Grid} from 'assets/images/icons/grid.svg'

// service
import externalUrls from 'services/externalUrls'

// styles
import styles from './index.module.scss'

/**
 *
 * @param {Object[]} faqs
 * @param {string} className
 * @return {JSX.Element}
 * @constructor
 */
const FaqArticlesList = ({ faqs, className }) => {
  const { translateText } = useGlotio()
  const [isOpenModal, setOpenModal] = useState(false)

  return (
    <>
      <div className={`${styles.faq_wrapper} ${className}`}>
        {faqs.map(faq => {
          return (
            <FaqLink
              key={faq.id}
              variant='outlined'
              className={styles.faq_button_container}
              id={faq.id}
            >
              {translateText(faq.text)}
              <ExternalLink className={styles.link_icon} />
            </FaqLink>
          )
        })
        }
      </div>
      <div className={styles.faq_actions}>
        <GlotioIconButton
          className={styles.action}
          size='medium'
          text={translateText('Technical support')}
          icon={<Headphones />}
          onClick={() => setOpenModal(true)}
        />
        <GlotioIconButton
          className={styles.action}
          as='a'
          url={translateText(externalUrls.FAQ)}
          size='medium'
          text={translateText('Faq')}
          icon={<HelpCircle />}
          target='_blank'
        />
        <GlotioIconButton
          className={styles.action}
          as='a'
          url={translateText(externalUrls.FAQ)}
          size='medium'
          text={translateText('Help Center')}
          icon={<Grid />}
          target='_blank'
        />
      </div>
      <TicketSupportModalFullContent isOpen={isOpenModal} handleClose={() => setOpenModal(false)} />
    </>
  )
}

export default FaqArticlesList
