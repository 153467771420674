class GlotioURL extends URL {

  constructor(router = window.location.href) {
    super(router, window.location.origin);
  }

  getAllSearchParams() {
    const searchParams = {}

    for (const [key, value] of this.searchParams) {
      searchParams[key] = value
    }
    return searchParams
  }

  getRoute() {
    return this.toString().replace(this.origin, '')
  }

  /**
   * @param {string[]} params
   */
  deleteSearchParams(params) {
    params.forEach(param => this.searchParams.delete(param))
    return this
  }

}

export default GlotioURL
