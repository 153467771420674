import { Avatar, Badge, Menu, Dropdown } from 'antd'
import React, { useState } from 'react'

// utils
import { getUserInitials } from 'utils/getUserInitials'

// hooks
import useGlotio from 'hooks/useGlotio'

// constants
import { ROUTES } from 'constants/routes.constants'
import { TARGET_TYPE } from 'constants/target'

// icons
import { ReactComponent as LogoutIcon } from 'assets/images/icons/icon_log-out.svg'
import { ReactComponent as UserIcon } from 'assets/images/icons/icon_user.svg'

// styles
import styles from './index.module.scss'

const WelcomeAvatarHeader = ({userName, projectUrl, isRestrictedMenu, logoutUser}) => {
  const [isVisible, setIsVisible] = useState(false);
  const { translateText } = useGlotio()

  const handleUserLogout = () => {
    logoutUser({ project: projectUrl })
  }

  if(!userName) return null

  const getOverlayMenu = () => (
    <Menu selectable={false}>
      {isRestrictedMenu &&
        <Menu.Item className={styles.menu_item}>
          <button
            type="button"
            onClick={() => onClickItem(() => window.open(ROUTES.MY_PROFILE, TARGET_TYPE.BLANK))}
            className={styles.custom__button}
          >
            <UserIcon />
            <span className={styles.icon_text}>{translateText('My Profile')}</span>
          </button>
        </Menu.Item>
      }
      {isRestrictedMenu && <Menu.Divider /> }
      <Menu.Item className={styles.menu_item}>
        <button type="button" onClick={() => onClickItem(handleUserLogout)} className={`${styles.custom__button} ${styles.close_session_btn}`}>
          <LogoutIcon />
          <span className={styles.icon_text}>{translateText('Logout')}</span>
        </button>
      </Menu.Item>
    </Menu>
  )

  const onVisibleChange = visible => {
    setIsVisible(visible)
  }

  const onClickItem = callback => {
    setIsVisible(false)
    callback()
  }

  return (
    <Dropdown overlay={getOverlayMenu} trigger={['click']} onVisibleChange={onVisibleChange}>
      <div className={styles.dropdown}>
        <Badge className={styles.badge_menu}>
          <Avatar
            className={`${styles.avatar} ${isVisible ? styles.isVisible : ''}`}
            shape="circle"
            size="large"
            icon={!userName && 'user'}
          >
            {userName ? getUserInitials(userName) : null}
          </Avatar>
        </Badge>
      </div>
    </Dropdown>
  )
}

export default WelcomeAvatarHeader
