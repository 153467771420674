import React from 'react'
import Helmet from 'react-helmet'

// components
import { Link, withRouter } from 'react-router-dom'
import Footer from 'components/LayoutComponents/Footer'
import AuthRightInfo from 'components/GlotioComponents/AuthRightInfo'

// hooks
import { useCookiesModal } from 'hooks/useCookiesModal'

// constants
import { ROUTES } from 'constants/routes.constants'

// assets
import logoImg from 'assets/images/logos/glotio_logo_purple.svg'

// styles
import css from './styles.module.scss'

const AuthLayout = ({ title, children, location }) => {
 useCookiesModal([ROUTES.ADMIN, ROUTES.DIRECT_AUTH])

  return (
    <main className={css.layout}>
      <Helmet title={title} />
      <div className={css.authRow}>
        <div className={css.leftColumn}>
          <div className={css.img__wrapper}>
            <Link to={ROUTES.LOGIN}>
              <picture>
                <img src={logoImg} alt="glotio logo" />
              </picture>
            </Link>
          </div>
          {children}
          <footer className={css.footer}>
            <Footer />
          </footer>
        </div>
        <AuthRightInfo location={location} />
      </div>
    </main>
  )
}

export default withRouter(AuthLayout)
