import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import { get } from 'lodash'

// components
import InfoPopover from 'components/CleanUIComponents/Popover/InfoPopover'
import InfoDanger from 'components/CleanUIComponents/Messages/InfoDanger'

// hooks
import useGlotio from 'hooks/useGlotio'

// utils
import { generateComponentUuid } from 'utils/Utils'

// constants
import { statusColor, statusType } from 'constants/status.constants'

// styles
import styles from './index.module.scss'

/**
 * @typedef Price
 * @property {number} amount
 * @property {string} currency
 */

/**
 * @typedef Supplements
 * @property {Price} price
 * @property {string} text
 * @property {string} info
 */

/**
 * @typedef Title
 * @property {string} text
 * @property {string} info
 */

/**
 * @typedef Button
 * @property {string} title
 * @property {boolean} isLoading
 * @property {boolean} isDisabled
 * @property {Function} onCLick
 */

/**
 * @typedef {object} Field
 * @property {string} title
 * @property {string} subtitle
 * @property {Price|string|number} info
 * @property {string} subInfo
 * @property {boolean} isBlue
 * @property {string} url
 */

/**
 * @typedef {object} Discount
 * @property {Price} discountAmount
 * @property {number} percentage
 */

/**
 * @typedef {object} SummaryField
 * @property {Title} title
 * @property {string} infoMessage
 * @property {Price} subtotal
 * @property {Discount} discount
 * @property {Array.<Field|ReactNode>} fields
 */

/**
 * @param {{
 * supplements:
 * Supplements[],
 * total: Price,
 * tax: Price,
 * discountCoupon: Price,
 * summaryFields: SummaryField[],
 * hasPaymentBackendError: Boolean,
 * backendErrorMessage: String
 * alwaysShowTotal: boolean
 * }} props
 * @returns {JSX.Element}
 */
const Summary = (props) => {

  const { hasPaymentBackendError = false, backendErrorMessage = '', supplements = [], total, tax, summaryFields, alwaysShowTotal, discountCoupon } = props
  const { translateText, translatePrice } = useGlotio()

  /**
   * Transform the price into a string
   * @param {Price|string} price
   * @return {string}
   */
  const getPrice = (price) => {
    const type = typeof price
    if (type === 'string' || type === 'number' ) {
      return price
    }
    if (price.amount >= 0) {
      return `${translatePrice(price.amount)}`
    }
    return 'Error'
  }

  const renderDefaultField = (field) => {
    return (
      <>
        <div className={styles.field}>
          <span className={styles.title_span}>{field.title}</span>
          <span className={`${styles.title_span} ${field.isBlue ? styles.isBlue : ''}`}>
            {field.url ? <Link className={styles.field_link} to={field.url}>{getPrice(field.info)}</Link> : getPrice(field.info)}
          </span>
        </div>
        <div className={styles.field}>
          { field.subtitle &&
            <span className={styles.low_span}>{field.subtitle}</span>
          }
          <span className={`${styles.low_span} ${field.isStrokeLine ? styles.line_through : ''}`}>{field.isStrokeLine ? getPrice(field.subInfo) : field.subInfo}</span>
        </div>
      </>
    )
  }

  const renderExtraInfoField = (field) => {
    return (
      <>
        <div>
          <p className={styles.summary_extra_info_subtitle}>{field.subtitle}</p>
        </div>
        <div className={styles.field}>
          <span className={styles.title_span}>{field.text}</span>
          <span className={styles.title_span}>{field.value}</span>
        </div>
        <div className={styles.field}>
          <span className={styles.low_span}>{field.subtext}</span>
        </div>
        {field.message &&
          <div className={styles.summary_info_title}>
            {field.message}
          </div>
        }
      </>
    )
  }

  const renderField = field => {

    switch (true) {
      case React.isValidElement(field):
        return <>{field}</>
      case get(field, "type", false) === 'extraInfo':
        return renderExtraInfoField(field)
      default:
        return renderDefaultField(field)
    }

  }

  return (
    <div className={styles.summary_content}>
      <p className={styles.summary_title}>{translateText('Summary')}</p>
      <div className={styles.summary_field_container}>
        {
          summaryFields.map((summaryField, summaryIndex) =>
            <Fragment key={generateComponentUuid(summaryIndex)}>
              {summaryField.fields.length > 0 ?
                <div>
                  <div className={`${styles.summary_info_property} ${styles.summary_info_title}`}>
                    <p className={styles.summary_subtitle}>{summaryField.title.text}</p>
                    {
                      summaryField.title.info &&
                      <InfoPopover message={summaryField.title.info} color={statusColor[statusType.info]} direction='left' />
                    }
                  </div>
                  <div className={styles.fields_container}>
                    {
                    summaryField.fields.map((field, fieldIndex) =>
                      <div key={generateComponentUuid(fieldIndex)}>
                        {renderField(field)}
                      </div>
                    )
                  }
                  </div>
                  {
                    summaryField.subtotal &&
                    <div className={styles.summary_field_subtotal_container}>
                      {
                        summaryField.discount.discountAmount.amount ?
                          <div className={styles.summary_field_subtotal}>
                            <span className={`${styles.isBlue} ${styles.low_span}`}>
                              {summaryField.discount.percentage ? `${summaryField.discount.percentage} % ${translateText('Discount')}` : translateText('Discount')}
                            </span>
                            <span className={`${styles.isBlue} ${styles.low_span}`}>-{getPrice(summaryField.discount.discountAmount)}</span>
                          </div>
                          :
                          <></>
                      }
                      <div className={`${styles.summary_field_subtotal} ${styles.summary_field_subtotal_field}`}>
                        <span className={styles.subtotal_text}>{translateText('Subtotal')}</span>
                        <span>{getPrice(summaryField.subtotal)}</span>
                      </div>
                    </div>
                  }
                </div>
                :
                <>
                  {
                    summaryField.infoMessage &&
                      <p className={styles.info_message}>
                        {summaryField.infoMessage}
                      </p>
                  }
                </>
              }
            </Fragment>
          )
        }
      </div>
      {(total.amount > 0 || alwaysShowTotal) &&
        <>
          <div className={styles.summary_info}>

            {
              supplements.map((supplement, supplementIndex) => (
                <div className={styles.summary_info_property} key={generateComponentUuid(supplementIndex)}>
                  <div className={styles.summary_extra_info}>
                    <span className={styles.low_span}>{supplement.text}</span>
                    {supplement.info && <InfoPopover message={supplement.info} />}
                  </div>
                  <span className={styles.low_span}>{getPrice(supplement.price)}</span>
                </div>
              ))
            }

            {discountCoupon && discountCoupon.amount > 0 &&
              <div className={styles.summary_info_property}>
                <span className={`${styles.low_span} ${styles.isBlue}`}>{translateText('Discount promotional code')}</span>
                <span className={`${styles.low_span} ${styles.isBlue}`}>-{getPrice(discountCoupon)}</span>
              </div>
            }
            <div className={styles.summary_info_property}>
              <span className={styles.low_span}>{translateText('Taxes')}</span>
              <span className={styles.low_span}>{getPrice(tax)}</span>
            </div>
          </div>
          <div className={styles.summary_info_property}>
            <p className={styles.summary_total}>{translateText('Total')}</p>
            <p className={styles.summary_total}>{getPrice(total)}</p>
          </div>
        </>
      }
      {
        hasPaymentBackendError &&
        <InfoDanger className={styles.payment_error} text={translateText(backendErrorMessage)} />
      }
    </div>
  )
}

export default Summary
