import React from 'react'

// utils
import {generateComponentUuid} from 'utils/Utils'

// components
import GlotioButton from 'components/GlotioComponents/GlotioButton'

import styles from './index.module.scss'

/**
 * @typedef Options
 * @property {string} title
 * @property {string} value
 */

/**
 *
 * @param {ReactNode} children
 * @param {Options[]} options
 * @param {boolean | string} defaultValue
 * @param {function} setDefaultValue
 * @return {JSX.Element}
 * @constructor
 */
const Tabs = ({ options, defaultValue, setDefaultValue }) => {
  return (
    <div>
      {
        options.map((option, index) => {
          const isActiveTab = defaultValue.value === option.value
          return (
            <GlotioButton
              type='button'
              variant={isActiveTab ? 'default' : 'link'}
              size='medium'
              key={generateComponentUuid(index)}
              onClick={() => setDefaultValue(option)}
              className={isActiveTab ? styles.tab_active : styles.tab}
            >
              {option.title}
            </GlotioButton>
          )
        })
      }
    </div>
  )
}

export default Tabs
