import React from 'react'

// redux 
import { useSelector } from 'react-redux'

// selectors
import { selectProjectPlatform } from 'redux/project/selectors'

// constants
import { PLATFORMS } from 'constants/platforms.contants';

// components
import SummaryBillingShopify from 'components/GlotioComponents/Summary/SummaryBillingShopify'

function withPaymentGateway(WrappedComponent) {
  return function PaymentGatewayComponent(props) {
    const currentPlatform = useSelector(selectProjectPlatform)
    const isShopify = currentPlatform === PLATFORMS.SHOPIFY
    const Component = isShopify ? SummaryBillingShopify : WrappedComponent
    return <Component {...props} /> 
  }
}

export default withPaymentGateway;