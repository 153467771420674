import React from 'react';

// components
import GlotioButton from 'components/GlotioComponents/GlotioButton'

// hooks
import useGlotio from "hooks/useGlotio";

// utils
import { onInitThirdPartyTrackers, onSetCookies } from 'utils/cookies';
import externalUrls from 'services/externalUrls';

// constants
import { DEFAULT_WIDTH_MODAL, COOKIES_CONFIG_MODAL } from 'constants/modals.constants';
import { ALL_COOKIES_ACCEPTED } from 'constants/cookies.constants';

// styles
import styles from './index.module.scss';


const CookiesModal = ({onClose, onNext}) => {
  const { translateText } = useGlotio()

  function onAcceptAllCookies() {
    onClose()
    onSetCookies(ALL_COOKIES_ACCEPTED)
    onInitThirdPartyTrackers(true)
  }

  function onConfigCookies() {
    onNext({
      type: COOKIES_CONFIG_MODAL,
      modalWidth: DEFAULT_WIDTH_MODAL,
      maskClosable: false,
      closable: false
    })
  }

  return (
    <>
      <div className={styles.wrapper_title}>
        <p className={styles.title}>{translateText('Cookie Preferences')}</p>
        <div className={styles.description}>{translateText('This website uses cookies to improve your experience. You can choose not to accept them if you wish.')}</div>
        <p className={styles.subinfo}>
          <a href={translateText(externalUrls.PRIVACY)} target="_blank" rel="noopener noreferrer">{translateText('Privacy Policy')}</a>
          {translateText(' and ')}
          <a href={translateText(externalUrls.COOKIES)} target="_blank" rel="noopener noreferrer">{translateText('Cookies')}</a>
        </p>
      </div>
      <div className={styles.wrapper_actions}>
        <GlotioButton
          type='button'
          variant='outlined'
          size='large'
          fluid
          onClick={onConfigCookies}
        >
          {translateText('Configure')}
        </GlotioButton>
        <GlotioButton
          type='button'
          variant='default'
          size='large'
          fluid
          onClick={onAcceptAllCookies}
        >
          {translateText('Accept all')}
        </GlotioButton>
      </div>
    </>
  )

}

export default CookiesModal
