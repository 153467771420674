import React from 'react';

// components
import GlotioIconButton from 'components/GlotioComponents/GlotioIconButton';

// utils
import countryList from 'utils/country-list';

// hooks
import useGlotio from 'hooks/useGlotio'

// icons
import { ReactComponent as EditIcon } from 'assets/images/icons/edit.svg'

// styles
import styles from './index.module.scss';

const BillingEditView = ({currentAccount, onChangeView}) => {

  const { translateText } = useGlotio()

  const getCompanyAndDocument = () => {
    return <p><span>{currentAccount.company_name}</span> {currentAccount.document}</p>
  }

  const getAddress = () => {
    return <p>{currentAccount.address1}</p>
  }

  const getPostalAndCityAndCountry = () => {
    const countryName = countryList.find(({value}) => value === currentAccount.iso_country).label
    return <p>{currentAccount.postal_code} {currentAccount.city}, {translateText(countryName)}</p>
  }

  return (
    <div className={styles.edit_view_wrapper}>
      <div className={styles.content}>
        {getCompanyAndDocument()}
        {getAddress()}
        {getPostalAndCityAndCountry()}
      </div>
      <div className={styles.action}>
        <GlotioIconButton
          size='small'
          onClick={onChangeView}
          icon={<EditIcon />}
        />
      </div>
    </div>
  )
}

export default BillingEditView