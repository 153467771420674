import React, { Component } from 'react'

// hoc
import { connect } from 'react-redux'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

// selectors
import { selectCurrentLocale } from 'redux/settings/selectors'

// components
import PayMethodSelection from './PayMethodSelection'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY)

class PaymentMethodConfig extends Component {
  render() {
    const { locale, ...props } = this.props

    return (
      <Elements locale={locale} stripe={stripePromise}>
        <PayMethodSelection {...props} />
      </Elements>
    )
  }
}

const mapStateToProps = state => ({ locale: selectCurrentLocale(state) })

export default connect(mapStateToProps)(PaymentMethodConfig)
