import React from 'react'

// components
import LanguageSelector from 'components/LayoutComponents/TopBar/LanguageSelector'
import GlotioIconButton from 'components/GlotioComponents/GlotioIconButton';

// hooks
import useGlotio from 'hooks/useGlotio'
import { useCreateModal } from 'hooks/useCreateModal'

// services
import externalUrls from 'services/externalUrls'

// constants
import { COOKIES_CONFIRM_MODAL } from 'constants/modals.constants';

// assets
import { ReactComponent as SettingsIcon } from 'assets/images/icons/icon_settings.svg'

// styles
import styles from './style.module.scss'

const Footer = () => {
  const { translateText } = useGlotio()
  const { onOpenModal } = useCreateModal()

  const showCookiesModal = () => {
    onOpenModal({
      type: COOKIES_CONFIRM_MODAL,
      maskClosable: false,
      closable: false
    })
  }

  return (
    <div className={styles.footer} id="footer">
      <div className={styles.footer_container}>
        <div className={styles.links}>
          <LanguageSelector />
          <a className={styles.links_target} href={translateText(externalUrls.FAQ)} target="_blank" rel="noopener noreferrer">{translateText('Help')}</a>
          <a className={styles.links_target} href={translateText(externalUrls.TERMS)} target="_blank" rel="noopener noreferrer">{translateText('Legal')}</a>
          <a className={styles.links_target} href={translateText(externalUrls.PRIVACY)} target="_blank" rel="noopener noreferrer">{translateText('Privacy')}</a>
          <a className={styles.links_target} href={translateText(externalUrls.COOKIES)} target="_blank" rel="noopener noreferrer">{translateText('Cookies')}</a>
          <GlotioIconButton onClick={showCookiesModal} className={styles.links_target} size='small' icon={<SettingsIcon />} />
        </div>
      </div>
    </div>
  )
}

export default Footer
