import antdData from 'antd/lib/locale-provider/en_US'
import localeData from 'react-intl/locale-data/en'
import countries from './countries/en.json'
import languages from './languages/en.json'
import platform from './platform/en.json'
import urls from './urls/en.json'
import texts from './texts/en.json'
import faq from './faq/en.json'

export default {
  locale: 'en',
  antdData,
  localeData,
  messages: { ...texts, ...countries, ...languages, ...platform, ...urls, ...faq },
}
