import {createSelector} from "reselect";
import { get } from 'lodash';

const selectSubscription = state => state.subscription;

export const selectConfirmedSubscriptionPlan = createSelector(
  [selectSubscription],
  subscription => get(subscription, 'currentSubscription.subscription.hasConfirmedSubscriptionPlan', false)
);

export const selectHasAccessToTools = createSelector(
  [selectSubscription],
  subscription => get(subscription, 'currentSubscription.subscription.hasAccessToTools', null)
);

export const selectSubscriptionType = createSelector(
  [selectSubscription],
  subscription => get(subscription, 'currentSubscription.subscription.subscriptionPlanType', '')
);

export const selectSubscriptionStatus = createSelector(
  [selectSubscription],
  subscription => get(subscription, 'currentSubscription.subscription.status', null)
)

export const selectIsLoadingSubscription = createSelector(
  [selectSubscription],
  subscription => subscription.loading
)

export const selectIsErrorSubscription = createSelector(
  [selectSubscription],
  subscription => subscription.error
)

export const selectCurrentSubscription = createSelector(
  [selectSubscription],
  subscription => subscription.currentSubscription
)
