import { useState } from 'react'

// hooks
import useGlotio from 'hooks/useGlotio'

// constants
import { DEFAULT_LANGUAGE, FAQ_LANGUAGES_AVAILABLE } from 'constants/default_language'
import { GLOTIO_BASE_DOMAIN } from 'constants/urls.constants'

// models
import { getContentFAQRequest } from 'models/api/externals'

/**
 * @param {string} faqId
 * @return {{articleURI: string, isLoadingArticle: boolean, articleContent: unknown, getArticleContent: ((function(): void)|*), hasErrorLoadingArticle: boolean}}
 */
export const useGetFaqContent = (faqId) => {
  const { translateText, selectedLocale } = useGlotio()
  const id = translateText(faqId)
  const iso = (selectedLocale && FAQ_LANGUAGES_AVAILABLE.includes(selectedLocale)) ? selectedLocale : DEFAULT_LANGUAGE
  const [isLoadingArticle, setIsLoadingArticle] = useState(false)
  const [hasErrorLoadingArticle, setHasErrorLoadingArticle] = useState(false)
  const [articleContent, setArticleContent] = useState(null)
  const articleURI = `${GLOTIO_BASE_DOMAIN}${iso}/?p=${translateText(id)}`

  /**
   * Fetches article content from the backend service and saves the response in the state
   */
  const getArticleContent = async () => {
    setIsLoadingArticle(true)

    try {
      const { result, error } = await getContentFAQRequest({ id: translateText(id)})

      if (error || result.success === false) {
        setHasErrorLoadingArticle(true)
      } else {
        setArticleContent(result)
      }
    } catch (err) {
      setHasErrorLoadingArticle(true)
    } finally {
      setIsLoadingArticle(false)
    }
  }

  return {
    articleContent,
    isLoadingArticle,
    hasErrorLoadingArticle,
    articleURI,
    getArticleContent
  }

}
