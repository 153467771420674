import { useDispatch } from 'react-redux'
import { useEffect, useRef } from 'react'
import { uniqueId } from 'lodash'

// actions
import { closeModal, nextModal, openModal } from 'redux/modal/actions'

export const useCreateModal = () => {
  const dispatch = useDispatch()
  const generatedId = useRef(uniqueId()).current
  const onCloseModal = () => dispatch(closeModal(generatedId))
  const onOpenModal = params => dispatch(openModal({ id: generatedId, ...params }))
  const onNextModal = params => dispatch(nextModal({ id: generatedId, ...params }))

  useEffect(() => {
    return () => {
      onCloseModal()
    }
  }, [])

  return {
    onOpenModal,
    onCloseModal,
    onNextModal
  }
}
