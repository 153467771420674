import moment from 'moment'
import { dateFormat } from 'constants/dateFormat.constant'

/**
 *
 * @param {string} date
 * @param {string} format
 * @return {string}
 */
export const transformDate = (date, format = dateFormat.default) => {
  return moment(date).format(format)
}

/**
 *
 * @param {string} date
 * @param {number} days
 * @param {string} format
 * @return {string}
 */
export const afterDayFromDate = (date, days = 1, format = dateFormat.default) => {
  return moment(date).add(days, 'day').format(format)
}

/**
 *
 * @param {string} date
 * @param {number} days
 * @param {string} format
 * @return {string}
 */
export const beforeDayFromDate = (date, days = 1, format = dateFormat.default) => {
  return moment(date).subtract(days, 'day').format(format)
}


export const transformDateWithUTC = (data, format = dateFormat.withHourInUTC) => {
  const UTCdate = moment.parseZone(data)
  const formatDate = UTCdate.format(format)
  return formatDate;

}

/**
 * TODO Done for Classic Plan
 * @param {string} startAt
 * @return {number}
 */
export const getDaysForClassic = (startAt) => {
  const daysForClassic = 60
  const daysFromStart = moment().diff(moment(startAt), 'days')
  return daysFromStart < daysForClassic ? daysForClassic - daysFromStart : 0
}

export const getDateDiff = (dateA, dateB) => {
  return moment(dateA).diff(moment(dateB))
}

export const getDateDiffByHours = (date) => {
  return moment().diff(moment(date), 'hour')
}

/**
 *
 * @param {Date} date
 * @return {boolean}
 */
export const hasPassed24Hours = (date) => {
  const now = moment();
  const givenDate = moment(date);
  const duration = moment.duration(now.diff(givenDate));
  const hours = duration.asHours();
  return hours >= 24;
}
