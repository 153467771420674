import React from 'react'
import { ReactComponent as InfoIcon } from 'assets/images/icons/info.svg'
import { ReactComponent as CheckCircleIcon } from 'assets/images/icons/check-circle_generic.svg'
import { get } from 'lodash'
import styles from './index.module.scss'

const styleForAlert = {
  info_danger: {
    icon: <InfoIcon />,
    style: styles.alert_info_danger
  },
  error: {
    icon: <InfoIcon />,
    style: styles.alert_error
  },
  success: {
    icon: <CheckCircleIcon />,
    style: styles.alert_success
  }
}
/**
 *
 * @param {('info_danger'|'error'|'success')} type
 * @param {string} message
 * @param {string} className
 * @return {JSX.Element}
 * @constructor
 */
const GlotioAlert = ({type, message, className}) => {
  const { icon, style } = get(styleForAlert, type, styleForAlert.info_danger)
  return (
    <div className={`${styles.alert_container} ${style} ${className}`}>
      {icon}
      <span>{message}</span>
    </div>
  )
}

export default GlotioAlert
