import React, { useState } from 'react'
import { isNull } from 'lodash'

// redux
import { useSelector } from 'react-redux'
import { selectUserEmail, selectUserName } from 'redux/user/selectors'
import { selectProjectId } from 'redux/project/selectors'

// constants
import { TECHNICAL_SUPPORT_TYPES } from 'constants/technical_support.constants'


// utils
import useGlotio from 'hooks/useGlotio'
import externals from 'services/externalUrls'
import { parsePhoneValue } from 'utils/parsePhoneValue'

// models
import { createAnonymousTicketRequest, createTicketRequest } from 'models/api/externals'

// components
import { Alert, Checkbox, Col, Form, Input, Row } from 'antd'
import InputPhone from 'components/CleanUIComponents/InputPhone'
import GlotioButton from 'components/GlotioComponents/GlotioButton'
import Container from 'components/CleanUIComponents/Container'

// assets
import form_img from 'assets/images/illustrations/corporate_contact.png'

// styles
import styles from './styles.module.scss'

/**
  * @property {Object} form
  * @property {string} processUniqId
  * @property {string} errorCode
  * @property {Function} onSuccess
  * @property {string | null} externalSubject
*/

function TicketSupportForm({ form, processUniqId, errorCode, onSuccess, externalSubject }) {
  const { translateText, selectedLocale } = useGlotio()

  const userEmail = useSelector(selectUserEmail)
  const userName = useSelector(selectUserName)
  const projectId = useSelector(selectProjectId)
  
  const [showPrivacyWarn, setShowPrivacyWarn] = useState(false)
  const [isSendingMessage, setIsSendingMessage] = useState(false)

  /**
   * @typedef {Object} Feedback
   * @property {'success' | 'error'} type
   * @property {string} message
   * /

  /**
   * @type {[
   *  formFeedback: Feedback,
   *  setFormFeedback: (feedback: Feedback) => void
   * ]}
   */
  const [formFeedback, setFormFeedback] = useState(null)

  const privacyRef = React.createRef()

  const validationMessages = {
    contactEmail: translateText('The email address you have entered is invalid.'),
    contactName: translateText('The name you have entered is not valid.'),
    message: translateText('Describe briefly why you are contacting us.'),
    subject: translateText('The subject you have entered is not valid.'),
    privacy: translateText('Please accept the privacy terms and conditions to send the message'),
    success: translateText(
      'Your message has been successfully sent! We will respond to you as soon as possible.',
    ),
    error: translateText('Sorry, the message could not be sent. Please, try again.'),
  }


  /**
   * renderPrivacyValidation: show error if the user no accepted the privacy terms
   * @returns React.node
   */
  const renderPrivacyValidation = () => {
    if (showPrivacyWarn) {
      return (
        <Form.Item>
          <Alert type="error" message={validationMessages.privacy} showIcon />
        </Form.Item>
      )
    }

    return null
  }


  /**
   * renderFormFeedback: show any error after send the request form data
   * @returns React.node
   */
  const renderFormFeedback = () => {
    if (!formFeedback) return null

    const { type, message } = formFeedback

    return (
      <Form.Item>
        <Alert type={type} message={message} showIcon role="alert" />
      </Form.Item>
    )
  }


  /**
   * shoudAddErrorInformation: check if exists error information props and if the form is open from authenticated views or not (isAnonymous)
   * @returns boolean
   */
  const shoudAddErrorInformation = () => {
    const isFromAbortIssue = processUniqId && errorCode;
    const isAnonymous = isNull(projectId)
    return Boolean(isFromAbortIssue) && !isAnonymous
  }

  /**
   * getExternalSubjectByContext: get the context of the subject if exists
   * @returns string
   */
  const getExternalSubjectByContext = userValue => {
    if (shoudAddErrorInformation()) {
      return `Error: ${errorCode}`
    }
    if (externalSubject) {
      return externalSubject
    }
    return userValue
  }

  const hasErrorInformationOrExternalSubject = shoudAddErrorInformation() || Boolean(externalSubject)
  
  const handleFormSubmit = (e) => {
    e.preventDefault()

    const { checked } = privacyRef.current.rcCheckbox.state

    if (!checked) {
      setShowPrivacyWarn(true)
      return
    }

    setIsSendingMessage(true)
    setShowPrivacyWarn(false)
    setFormFeedback(null)

    form.validateFields(async (errors, values) => {
      if (!errors) {
        try {

          const isAnonymous = isNull(projectId)
          const hasErrorInformation = shoudAddErrorInformation()

          const registerHideParams = () => {
            const hideParams = {
              ...(hasErrorInformation ? { processUniqId } : {}),
              ...(hasErrorInformation ? { errorCode } : {}),
              ...(hasErrorInformation ? { type: TECHNICAL_SUPPORT_TYPES.PROBLEM } : { type: TECHNICAL_SUPPORT_TYPES.INCIDENT })
            }
            return hideParams
          }

          const registerParams = formValues => {
            return {
              ...formValues,
              contactLanguage: selectedLocale,
              acceptPrivacy: checked,
              ...parsePhoneValue(formValues, 'contactPhoneNumber'),
              ...registerHideParams(),
              subject: getExternalSubjectByContext(formValues.subject)

            }
          }

          const params = {
            ...registerParams(values)
          }

         const resolveEndpoint = () => {
            if (isAnonymous) {
              return createAnonymousTicketRequest({ params })
            }
            return createTicketRequest({ params, projectId })
          }

          const { error, result } = await resolveEndpoint()

          if (!error && !result.error && result.success) {
            onSuccess()
          } else {
            setFormFeedback({ type: 'error', message: validationMessages.error })
          }
        } catch (err) {
          setFormFeedback({ type: 'error', message: validationMessages.error })
        } finally {
          setIsSendingMessage(false)
        }
      } else {
        setIsSendingMessage(false)
      }
    })
  }

  const title = translateText('What can we help you with?');
  const subtitle = translateText("One of our advisors will contact you to answer any questions you may have regarding the Glotio service.")

  const getPolicyText = () => {

    const privacy = `<a href=${translateText(externals.PRIVACY)} target="_blank" rel="noreferrer noopener">${translateText('Privacy Policy')}</a>`
    const tos = `<a href=${translateText(externals.TERMS)} target="_blank" rel="noreferrer noopener">${translateText('Terms of Use')}</a>`
    return <span dangerouslySetInnerHTML={{__html: translateText("I accept the {privacy} and {tos}.", { privacy, tos})}} />

  }

  return (
    <Container noPadding>
      <div className={styles.inner}>
        <Row className={styles.consultant_form_content} gutter={24}>
          <Col xl={12}>
            <div className={styles.left_content}>
              <h2>{title}</h2>
              <p>{subtitle}</p>
              <img src={form_img} alt='Glotio consultant' className={styles.left_content_image} />
            </div>
          </Col>
          <Col xl={12}>
            <div className={styles.consultant_form}>
              <h3>{translateText('Contact a consultant')}</h3>
              <Form onSubmit={handleFormSubmit}>

                <Form.Item htmlFor="contactName" className={styles.form_input}>
                  {form.getFieldDecorator('contactName', {
                    initialValue: userName,
                    validate: [
                      {
                        trigger: ['onSubmit', 'onBlur'],
                        rules: [
                          {
                            required: true,
                            message: validationMessages.contactName,
                          },
                        ],
                      },
                    ],
                  })(<Input required id="contactName" type="text" name="contactName" placeholder={translateText('Name')} />)}
                </Form.Item>

                <Form.Item htmlFor="contactPhoneNumber" className={styles.form_input_phone}>
                  {form.getFieldDecorator('contactPhoneNumber', {
                    initialValue: {
                      pcode:'',
                      pnumber:''
                    }
                  })(
                    <InputPhone
                      name="contactPhoneNumber"
                      phoneCodeInputWidth="50%"
                      numberPhoneInputWidth="50%"
                      formatPhoneCodeLabel={(countryName, phoneCode) => `${translateText(countryName)} (+${phoneCode})`}
                      inputPlaceHolder={translateText('Phone')}
                      codePhoneInputPlaceholder={translateText('Prefix')}
                    />
                  )}
                </Form.Item>

                <Form.Item htmlFor="contactEmail" className={styles.form_input}>
                  {form.getFieldDecorator('contactEmail', {
                    initialValue: userEmail,
                    validate: [
                      {
                        trigger: ['onSubmit', 'onBlur'],
                        rules: [
                          {
                            type: 'email',
                            required: true,
                            message: validationMessages.contactEmail,
                          },
                        ],
                      },
                    ],
                  })(<Input required id="contactEmail" type="email" name="contactEmail" placeholder={translateText('Email')} />)}
                </Form.Item>

                {!hasErrorInformationOrExternalSubject && 
                  <Form.Item htmlFor="subject" className={styles.form_input}>
                    {form.getFieldDecorator('subject', {
                      validate: [
                        {
                          trigger: ['onSubmit', 'onBlur'],
                          rules: [
                            {
                              required: true,
                              message: validationMessages.subject,
                            },
                          ],
                        },
                      ],
                    })(<Input id="subject" type="text" name="subject" placeholder={translateText('Subject')} />)}
                  </Form.Item>
                }

                <Form.Item htmlFor="message" className={styles.form_input}>
                  {form.getFieldDecorator('message', {
                    validate: [
                      {
                        trigger: ['onSubmit', 'onBlur'],
                        rules: [
                          {
                            required: true,
                            message: validationMessages.message,
                          },
                        ],
                      },
                    ],
                  })(<Input.TextArea required name="message" id="message" autoSize={{ minRows: 5}} placeholder={translateText('Message')} />)}
                </Form.Item>

      
                <Form.Item>
                  <div className={styles.privacy_check}>
                    <Checkbox required id="privacy" name="privacy" ref={privacyRef}>
                      {getPolicyText()}
                    </Checkbox>
                  </div>
                </Form.Item>

                <Form.Item>
                  <GlotioButton fluid type='submit' variant='default' size='large' isLoading={isSendingMessage}>
                    {translateText('Contact')}
                  </GlotioButton>
                </Form.Item>
                {renderPrivacyValidation()}
                {renderFormFeedback()}
              </Form>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  )
  
}

export default Form.create()(TicketSupportForm)
