import React from 'react'
import Checkbox from 'components/CleanUIComponents/Checkbox/index'
import styles from './index.module.scss'

const CardCheckbox = ({ title, subtitle, value, isChecked, onChange, fluid = false, column = false, isDisabled = false }) => {
  return (
    <label htmlFor={value} className={`${(fluid ? styles.fluid : ``)}`}>
      <div className={`${styles.card_checkbox_container}  ${isChecked && styles.active} ${isDisabled && styles.disabled}`}>
        <div className={styles.input}>
          <Checkbox value={value} checked={isChecked} onChange={onChange} type='checkbox' isDisabled={isDisabled} />
        </div>
        <div className={`${styles.title} ${(column ? styles.flexColumn : ``)}`}>
          <p>
            { title }
          </p>
          {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
        </div>
      </div>
    </label>
  )
}

export default CardCheckbox
