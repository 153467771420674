/* eslint-disable */
import React, { useEffect, useState } from 'react'

// components
import Container from 'components/CleanUIComponents/Container'
import NoConnectionError from 'components/GlotioComponents/ShowError/NoConnectionError'
import WelcomeHeader from 'components/LayoutComponents/WelcomeHeader'
import Footer from 'components/LayoutComponents/Footer'
import LoginModal from 'components/GlotioComponents/LoginModal'

// redux
import { useSelector } from 'react-redux'

// hooks
import useIframeObserver from 'hooks/useIframeObserver'

// selectors
import { selectCurrentProject } from 'redux/project/selectors'

// models
import { checkIfAccountExistsRequest } from 'models/api/account'

// context
import { WelcomeContext } from 'context/WelcomeContext'

import styles from './index.module.scss'

const WelcomeLayout = ({children}) => {
  const { isIframeConnected } = useIframeObserver('welcome-layout')
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false)
  const [currentProject, setCurrentProject] = useState(null);
  const projectSelector = useSelector(selectCurrentProject);
  const [accountStatus, setAccountStatus] = useState({})
  const [error, setError] = useState(false)

  useEffect(() => {
    getCurrentProject()
  },[])

  const checkIfAccountIsRegistered = async ({ apikey }) => {
    try {
      const { result } = await checkIfAccountExistsRequest({ apikey });
      const { accountExists } = result;
      setAccountStatus({accountExists})
    } catch (err) {
      setError(true)
    }
  }

  const getCurrentProject = async () => {
    try {
      const storedProject = sessionStorage.getItem('app.project')
      const project = storedProject ? JSON.parse(storedProject) : projectSelector
      if (project && project.apikey) {
        setCurrentProject(project)
        await checkIfAccountIsRegistered({
          apikey: project.apikey,
        })
      } else {
        setError(true)
      }
    } catch (error) {
      setError(true)
    }
  }

  const onCloseLoginModal = () => setIsOpenLoginModal(false)

  if (error) {
    return (
      <Container>
        <NoConnectionError />
      </Container>
    )
  }

  return (
    <WelcomeContext.Provider value={{ accountStatus, currentProject, isOpenLoginModal, setIsOpenLoginModal }}>
      <section className={`${styles.welcome_section} ${!isIframeConnected ? styles.full : ''}`} id="welcome-layout">
        <header className={styles.welcome_header_wrapper}>
          <WelcomeHeader />
        </header>
        <main className={styles.welcome_main}>
          {children}
        </main>
        <footer className={styles.welcome_footer}>
          <Footer />
        </footer>
        <LoginModal isOpen={isOpenLoginModal} handleClose={onCloseLoginModal} />
      </section>
    </WelcomeContext.Provider>
  )
}

export default WelcomeLayout
