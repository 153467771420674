// react
import React, { useEffect, useState } from 'react'
import { filter, omit, get, orderBy } from 'lodash'

// components
import Step from 'components/GlotioComponents/Step'
import WizardFooter from 'components/GlotioComponents/Wizard/Footer'
import Summary from 'components/GlotioComponents/Summary'
import InfoAdvertisementVertical from 'components/GlotioComponents/InfoAdvertisement/InfoAdvertisementVertical'
import { InfoTable } from 'components/CleanUIComponents/LanguageInfoTable'
import SummaryBilling from 'components/GlotioComponents/Summary/SummaryBilling'
import AnalyseResume from 'components/GlotioComponents/Wizard/AnalyseResume'
import AddLanguagesContainer from 'components/GlotioComponents/Wizard/HandlerNewLanguages/AddLanguagesContainer'

// hooks
import { useCreateModal } from 'hooks/useCreateModal'

// redux
import { connect, useSelector } from 'react-redux'
import { selectProjectId } from 'redux/project/selectors'
import { selectCurrentAccount } from 'redux/account/selectors'
import { selectCurrentProcess } from 'redux/process/selectors'

// utils
import { initializeSelectedLanguages, parseSummaryValues } from 'utils/LanguageOffers'

// hooks
import { useDeepEffect } from 'hooks/useDeepEffect'
import useAssistantHash from 'hooks/useAssistantHash'
import useGlotio from 'hooks/useGlotio'

// types
import 'components/GlotioComponents/Wizard/types'

// constants
import { STRIPE_SUPPLEMENT } from 'constants/summary_extra_info.constants'
import { DEFAULT_DISCOUNT } from 'constants/discount.constant'
import { ASSISTANT_ROUTER_HASH } from 'constants/assistant.router.hash'
import { CONFIRM_MODAL } from 'constants/modals.constants'
import { PLANS_NAME } from 'constants/plans.constants'
import { PRODUCT_TYPE } from 'constants/product.constants'

// models
import { getCurrentSubscriptionRequest } from 'models/api/subscription'

// styles
import styles from './index.module.scss'

/**
 * Returns view of select default language of shop
 * @param {Offer | Object} languagesOffers
 * @param {Function} getTranslationOffers
 * @param {Function} onTranslate
 * @param {boolean} isLoadingPay
 * @param {NewTranslationOffer[]} allLanguages
 * @param {Totals | Object} languagesTotals
 * @param {Function | null} summaryBackButton
 * @param {string[]} summarySections
 * @param {Boolean} hasVariantIntegration
 * @return {JSX.Element}
 */

const HandlerNewLanguages = ({
  languagesOffers,
  getTranslationOffers,
  allLanguages,
  languagesTotals,
  onTranslate,
  isLoadingPay,
  summaryBackButton = null,
  summarySections =  [],
  isFirstSession,
  hasVariantIntegration = false,
  hasFromPreviousUpdateType = false,
  defaultLanguages = [],
  }) => {

  const { translateText, formatNumber, translateIsoCode } = useGlotio()
  const { onOpenModal, onCloseModal } = useCreateModal()
  const currentProcess = useSelector(selectCurrentProcess)
  const projectId = useSelector(selectProjectId)
  const {addAssistantHash} = useAssistantHash(getCurrentHash())

  const [subscriptionType, setSubscriptionType] = useState(null)
  const [subscriptionTypeText, setSubscriptionTypeText] = useState(null)
  const [selectedLanguages, setSelectedLanguages] = useState(() => initializeSelectedLanguages(languagesOffers))
  const [viewType, setViewType] = useState('default')

  // Get installed languages: use: translate installed languages modal confirmation
  const installedLanguages = orderBy(filter(allLanguages, { 'installed': true }), ['translatable'], ['desc'])
  const exludedInstalledLanguages = orderBy(filter(allLanguages, { 'installed': false }), ['translatable'], ['desc'])

  // Get the languages with pending chars to update
  const pendingCharsToUpdate = get(languagesOffers,'updateTranslationOffers', []).filter(({toTranslateCharacters}) => toTranslateCharacters > 0)
  const hasPendingCharsToUpdate = pendingCharsToUpdate.length > 0

  const canShowInstalledLanguagesSection = installedLanguages.length > 0 && hasVariantIntegration

  useDeepEffect(() => {

    setSelectedLanguages(() => initializeSelectedLanguages(languagesOffers))

  }, [languagesOffers])


  useEffect(() => {

    const getSubscription = async () => {
      try {
        const { result, error } = await getCurrentSubscriptionRequest({projectId})
        if (error) {
            console.log('error', error)
        } else {
            const { subscription, subscriptionPlan } = result.data
            setSubscriptionType(subscription.subscriptionPlanType)
            setSubscriptionTypeText(PLANS_NAME[subscriptionPlan.subscriptionPlanType])
        }
      } catch (error) {
        console.log('error', error)
      }
    }
    getSubscription()
  },[])


  function getCurrentHash () {
    if (hasFromPreviousUpdateType) {
      return ASSISTANT_ROUTER_HASH.LANGUAGE_SELECTION_OPTION_3
    }
    if (isFirstSession) {
      return ASSISTANT_ROUTER_HASH.LANGUAGE_SELECTION_OPTION_2
    }
    return ASSISTANT_ROUTER_HASH.LANGUAGES_SELECTION
  }

  function setRouterHash () {
    addAssistantHash(getCurrentHash());
  }

  const handleToggleLanguages = (iso) => {
    let tempLanguages = [...selectedLanguages]
    if (!tempLanguages.includes(iso)) {
      tempLanguages = [...tempLanguages, iso]
    } else {
      tempLanguages = filter(tempLanguages, (lang) => lang !== iso)
    }
    getTranslationOffers(tempLanguages, false)
  }

  function showInstalledLanguagesModal (onSuccess) {

    const message = {
      type: CONFIRM_MODAL,
      closable: false,
      maskClosable: false,
      title: translateText('Fully translate languages'),
      description: translateText('The languages installed in your online store prior to Glotio will be completely translated again, so you will lose the previous translations. Do you want to integrate the languages to Glotio and translate them again?'),
      buttons: [{
        text: translateText('Cancel'),
        options: {
          type: "button",
          variant: 'outlined',
          size: 'large',
          className: styles.modal_button
        },
        callback: onCloseModal,
      },
      {
        text: translateText('Integrate and translate'),
        loadingBeforeCallback: true,
        options: {
          type: "button",
          variant: 'default',
          size: 'large',
          className: styles.modal_button
        },
        callback: onSuccess,
      }]

    }

    onOpenModal({...message});

  }

  const hasInstalledLanguagesIncludesSelectedLanguages = () => installedLanguages.some(({languageIso}) => selectedLanguages.includes(languageIso))
  const goToPaymentsView = () => {
    setViewType('payment');
    addAssistantHash(ASSISTANT_ROUTER_HASH.BILLING_FORM);
  }

  const onPayAndTranslate = () => {
    const hasLanguages = hasInstalledLanguagesIncludesSelectedLanguages();
    const totalToPay = languagesTotals.total.amount;
    const translationOptions = { selectedLanguages, totalToPay };

    if (hasLanguages) {
      handleInstalledLanguages(translationOptions, totalToPay);
      return;
    }

    if (totalToPay === 0) {
      onTranslate(translationOptions);
      return;
    }

    goToPaymentsView();
  }

  const handleInstalledLanguages = (translationOptions, totalToPay) => {
    showInstalledLanguagesModal(() => {
      onCloseModal()
      if (totalToPay === 0) {
        onTranslate(translationOptions);
        return;
      }

      goToPaymentsView();
    });
  };

  const getInitialButtonLabel = () => {
    if (selectedLanguages.length > 0 && get(languagesTotals, 'total.amount', -1) === 0) {
      return translateText('Translate for free')
    }

    return translateText('Pay and translate')
  }

  const initialSummaryValues = {
    summaryFields: [],
    handleSubmitButton:{
      title: getInitialButtonLabel(),
      isLoading: isLoadingPay,
      onClick: onPayAndTranslate,
      isDisabled: selectedLanguages.length === 0,
    },
    ...(typeof summaryBackButton === 'function' && viewType === 'default' ? { pushBack: summaryBackButton } : {})
  }

  const summaryValues = parseSummaryValues({
    initialSummaryValues,
    selectedLanguagesOffers: languagesOffers.selectedLanguagesOffers,
    updateTranslationOffers: pendingCharsToUpdate,
    translationUpdatesExtra: languagesOffers.translationUpdatesExtra,
    selectedLanguagesExtra: languagesOffers.selectedLanguagesExtra,
    languagesTotals,
    currentPlan: subscriptionType,
    currentPlanText: subscriptionTypeText,
    sections: [...summarySections],
    translateText,
    subscriptionAddLanguagesExtra: languagesOffers.subscriptionAddLanguagesExtra,
    ...(get(languagesTotals, 'isMinPaymentApplied', false) ? { supplements: [STRIPE_SUPPLEMENT]} : {}),
    translateIsoCode
    }
  )

  const summaryBillingValues = omit(summaryValues, ['handleSubmitButton'])

  const generatePendingCharsToUpdateTable = () => {
    const columns = [
      {
        name: translateText('Language'),
        selector: (row) => translateIsoCode(row.languageIso),
        highlight: true
      },
      {
        name: translateText('Total characters'),
        selector: (row) => formatNumber(row.totalCharacters)
      },
      {
        name: translateText('Translated'),
        selector: (row) => formatNumber(row.translatedCharacters)
      },
      {
        name: translateText('To translate'),
        selector: (row) => formatNumber(row.toTranslateCharacters),
        highlight: true
      }
    ]

    return <InfoTable rows={languagesOffers.updateTranslationOffers} columns={columns} />
  }
  // This is the last step prior to the translation of your online store.
  const getSubtitle = () => {
    return (
      <p>
        {translateText('This is the last step prior to the translation of your online store.')}
      </p>
    )
  }

  function getSectionTitle () {

    if (hasVariantIntegration) {
      return {
        title: translateText('Integrate languages'),
        subtitle: translateText('All languages will be fully updated, so previous translations will be replaced by Glotio translation')
      }
    }

    if (isFirstSession) {
      return {
        title: translateText('What languages do you want to translate into?'),
        subtitle: getSubtitle()
      }
    }

    return {
      title: translateText('What languages do you want to translate into?'),
      subtitle: translateText('Select the new languages you want to add to your online shop.')
    }
  }

  const summaryExtraComponent = () => {
    const renderDiscountTitle = () => {
      const discount = `<span class=${styles.infoAdvertisement_percent}>${DEFAULT_DISCOUNT}%</span>`
      return <p dangerouslySetInnerHTML={{__html: translateText('Translate into more languages with up to {discount} discount', {discount})}} />
    }

    return (
      <div className={styles.infoAdvertisement_spacer}>
        <InfoAdvertisementVertical
          title={renderDiscountTitle()}
          description={translateText('Take advantage and enjoy discounts for translating several languages at once. The more you translate, the less you pay.')}
          className={styles.h2_lg}
        />
      </div>
    )
  }

  const showTotal = get(languagesOffers, 'selectedLanguagesOffers', []).length > 0 || (hasPendingCharsToUpdate && get(languagesOffers, 'translationUpdatesExtra.currentAvailableChars', 0) === 0)

  const payParams = () => {

    if (currentProcess)  {
      return {
        params: {
          totalToPay: get(languagesTotals, 'total.amount', 0),
          selectedLanguages
        },
        projectId,
        translationBatchId: currentProcess.translationBatchId
      }
    }

    return {}
  }

  const handleView = () => {
    switch(viewType) {
      case 'default':
        return (
          <Summary {...summaryValues} extraComponent={summaryExtraComponent()} isFirtSession={isFirstSession} alwaysShowTotal={showTotal}>
            <div className={styles.content_wrapper}>
              { isFirstSession &&
                <AnalyseResume
                  defaultLanguages={defaultLanguages}
                  installedLanguages={installedLanguages}
                  className={styles.container_resume}
                  info={translateText('All languages will be translated from the source language.')}
                />
              }
              <Step
                {...getSectionTitle()}
              >
                {canShowInstalledLanguagesSection &&
                  <AddLanguagesContainer
                    handleToggleLanguages={handleToggleLanguages}
                    selectedLanguages={selectedLanguages}
                    languages={installedLanguages}
                    title='Select the languages you would like to integrate into your online shop'
                  />
                }

                <AddLanguagesContainer
                  handleToggleLanguages={handleToggleLanguages}
                  selectedLanguages={selectedLanguages}
                  languages={hasVariantIntegration ? exludedInstalledLanguages : allLanguages}
                  {...(hasVariantIntegration ? { title: 'Want to add new languages?'} : {})}
                />
              </Step>
            </div>
            <WizardFooter
              descriptionTitle="Configure which fields Glotio will translate"
              hasDescription
            />
            {
              hasPendingCharsToUpdate && (
                <Step
                  title={translateText('Update languages')}
                  subtitle={translateText('When adding a new language, it is necessary to update the existing ones.')}
                >
                  <div className={styles.languages_container}>
                    {generatePendingCharsToUpdateTable()}
                  </div>
                </Step>
              )
            }

          </Summary>

        )
      case 'payment':
      return (
        <SummaryBilling
          handlePay={() => onTranslate({selectedLanguages, totalToPay: get(languagesTotals, 'total.amount', 0)})}
          isLoading={isLoadingPay}
          payParams={payParams()}
          enviromentProduct={PRODUCT_TYPE.TRANSLATION}
          trackOnEdit
          pushBack={() => {
            setViewType('default')
            setRouterHash();
          }}
          refetch={() => getTranslationOffers(selectedLanguages, false)}
          {...summaryBillingValues}
        />
      )
      default:
        return null
    }
  }

  return (
    handleView()
  )
}

const mapStateToProps = (state) => ({
  currentAccount: selectCurrentAccount(state)
})

export default connect(mapStateToProps, null)(HandlerNewLanguages)
