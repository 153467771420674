import React, { useState, useEffect } from 'react'
import { connect, useSelector } from 'react-redux'

// hooks
import useGlotio from 'hooks/useGlotio'

// icons
import { ReactComponent as EditIcon } from 'assets/images/icons/edit.svg'

// components
import { Skeleton } from 'antd'
import StripePaymentMethods from 'components/GlotioComponents/Stripe/payment-method-config'
import SavedCreditCard from 'components/GlotioComponents/Stripe/SavedMethods/SavedCreditCard'
import GlotioIconButton from 'components/GlotioComponents/GlotioIconButton'
import GlotioAlert from 'components/GlotioComponents/GlotioAlert'
import GlotioButton from 'components/GlotioComponents/GlotioButton'

// utils
import Account from 'utils/Account'
import { hasRequestError } from 'models/api/utils'

// actions
import { fetchAccountStart } from 'redux/account/actions'

// selectors
import {
  selectCurrentAccount,
  selectIsAccountFetching,
  selectAccountIdFromProject,
} from 'redux/account/selectors'
import { selectHasBackendError } from 'redux/payment/selectors'

// styles
import styles from './index.module.scss'

const PaymentMethods = (props) => {
  const {
    currentAccount,
    isFetchingAccount,
    accountId,
    dispatch,
    onRetryPayment
  } = props

  const [isChangingPaymentMethod, setIsChangingPaymentMethod] = useState(false)

  const { translateText } = useGlotio()
  const hasBackendError = useSelector(selectHasBackendError)

  useEffect(() => {
    if (accountId) {
      dispatch(fetchAccountStart())
    }
  }, [])

  useEffect(() => {
    if (hasBackendError) {
      handleChangePaymentMethod()
    }
  }, [hasBackendError])

  const renderSavedPaymentMethod = () => {
    const methodType = Account.getActivePaymentMethodType(currentAccount)

    if (methodType === 'card') {
      return (
        <>
          <div className={styles.saved_method_container}>
            <SavedCreditCard />
            <GlotioIconButton
              size='small'
              onClick={handleChangePaymentMethod}
              icon={<EditIcon />}
            />
          </div>
          {onRetryPayment &&
            <GlotioButton className={styles.retry_payment_button} type='button' variant='link' size='small' onClick={onRetryPayment}>
              {translateText('Retry payment')}
            </GlotioButton>
          }
        </>
      )
    }

    if (hasRequestError('createAccount')) {
      return <GlotioAlert type="error" message={translateText('Error getting data')} />
    }

    return null
  }

  const handleChangePaymentMethod = () => {
    setIsChangingPaymentMethod(true)
  }

  /**
   * Generates the content of the page
   * @returns {JSX.Element}
   */
  const getPageContent = () => {
    if (isFetchingAccount || !currentAccount)
      return (
        <div className={styles.skeleton}>
          <Skeleton active />
        </div>
      )

    const hasPaymentMethod = Account.hasPaymentMethod(currentAccount)

    if (hasPaymentMethod && !isChangingPaymentMethod) {
      return renderSavedPaymentMethod()
    }

    return (
      <div>
        <StripePaymentMethods
          onCancel={hasPaymentMethod ? () => setIsChangingPaymentMethod(false) : null}
        />
      </div>
    )
  }

  return (
    <>
      {getPageContent()}
    </>
  )
}

const mapStateToProps = (state) => ({
  currentAccount: selectCurrentAccount(state),
  isFetchingAccount: selectIsAccountFetching(state),
  accountId: selectAccountIdFromProject(state),
  hasBackendError: selectHasBackendError(state)
})

export default connect(mapStateToProps)(PaymentMethods)
