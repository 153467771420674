import React from 'react'
import { Layout } from 'antd'
import { connect } from 'react-redux'
import classNames from 'classnames'

// components
import TopBar from 'components/LayoutComponents/TopBar'
import Menu from 'components/LayoutComponents/Menu'
import Submenu from 'components/LayoutComponents/Menu/Submenu'
import Footer from 'components/LayoutComponents/Footer'

// hooks
import { useCookiesModal } from 'hooks/useCookiesModal'

// constants
import { PAGE_WITH_SUBMENU_TABS } from 'constants/routes.constants'

// selectors
import { selectIsMobileView } from 'redux/settings/selectors'
import { withRouter } from 'react-router-dom'

// styles
import styles from './index.module.scss'

const MainLayout = ({ children, isMobileView, location: { pathname }}) => {
  useCookiesModal()
    return (
      <Layout
        className={classNames({
          settings__borderLess: true,
          settings__squaredBorders: false,
          settings__fixedWidth: false,
          settings__menuShadow: true,
          settings__menuTop: false,
        })}
      >
        <Menu />
        <Layout>
          <Layout.Header>
            <TopBar />
          </Layout.Header>
          <Layout.Content style={{
            position: 'relative',
            display: 'flex',
            flexDirection: PAGE_WITH_SUBMENU_TABS.includes(pathname) ? 'column' : 'row'
          }}
          >
            {!isMobileView && <Submenu />}
            <div style={{ width: '100%' }}>
              {children}
            </div>
          </Layout.Content>
          <Layout.Footer>
            <Footer />
          </Layout.Footer>
          <div id="root-portal" className={styles.rootPortal} />
        </Layout>
      </Layout>
    )
}

const mapStateToProps = state => ({
  isMobileView: selectIsMobileView(state)
})

export default connect(mapStateToProps)(withRouter(MainLayout))
