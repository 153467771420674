import Request from 'models/Request'

export const getLanguagesRequest = ({ projectId, callback }) =>
  Request.fetch({
    key: 'getLanguages',
    schema: 'ProjectLanguages',
    method: 'GET',
    url: `/api/v1/project/${projectId}/languages`,
    callback,
  })

export const getProjectByIdRequest = ({ projectId, callback }) =>
  Request.fetch({
    key: 'getProjectById',
    method: 'GET',
    url: `/api/v1/project/${projectId}`,
    callback,
  })

export const updateProjectByIdRequest = ({ projectId, params, callback }) =>
  Request.fetch({
    key: 'updateProjectById',
    url: `/api/v1/project/${projectId}`,
    method: 'PUT',
    params,
    callback,
  })

export const getInvoicesRequest = ({ page = 1, status, callback }) =>
  Request.fetch({
    key: 'getInvoices',
    url: `/api/v1/invoice`,
    params: {
      page,
      status
    },
    method: 'GET',
    callback,
  })

export const downloadInvoicePDFRequest = ({
  invoice: { id, created_at },
  downloadNameFile = null,
  params,
  callback,
}) => {
  if (!downloadNameFile) {
    const date = new Date(created_at)
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ]
    downloadNameFile = `Glotio ${months[date.getMonth()]} ${date.getFullYear()}.pdf`
  }

  return Request.fetchBlob({
    url: `/api/v1/invoice/${id}/pdf`,
    method: 'GET',
    download: true,
    downloadNameFile,
    params,
    callback,
  })
}

export const getProjectStatus = ({ projectId, callback }) =>
  Request.fetch({
    key: 'getProjectById',
    method: 'GET',
    url: `/api/v1/project/${projectId}/status`,
    callback,
  })
