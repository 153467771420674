import React, { useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { bindActionCreators } from 'redux'
import { get, omit, isEmpty } from 'lodash'
import { notification } from 'antd'
import { withRouter } from 'react-router'

// components
import Container from 'components/CleanUIComponents/Container'
import Summary from 'components/GlotioComponents/Summary'
import UpdateSummary from 'components/GlotioComponents/Asistent/Steps/Update/UpdateSummary'
import LanguageInfoWrapper from 'components/CleanUIComponents/LanguageInfoTable/LanguageInfoWrapper'
import { InfoTable } from 'components/CleanUIComponents/LanguageInfoTable'
import Loading from 'components/GlotioComponents/Wizard/Loading'
import SummaryBilling from 'components/GlotioComponents/Summary/SummaryBilling'
import LastAnalysisInfo from 'components/GlotioComponents/Asistent/Steps/Update/LastAnalysisInfo'
import InfoDanger from 'components/CleanUIComponents/Messages/InfoDanger'
import LanguageUpdatesTabs from 'components/GlotioComponents/LanguageUpdatesTabs'

// selectors
import {selectCurrentProject, selectProjectId} from 'redux/project/selectors'
import { selectCurrentProcess } from 'redux/process/selectors'
import { selectCurrentAccount } from 'redux/account/selectors'
import { setMustShowResume } from 'redux/process/actions'

// actions
import { paymentError } from 'redux/payment/actions'

// model
import { getCurrentSubscriptionRequest } from 'models/api/subscription'
import { payTranslationsOffersRequest } from 'models/api/payment'
import { getTranslationOffersRequest } from 'models/api/process'


// constants
import { PAYMENT_ERRORS } from 'constants/backend_errors'

// hooks
import useGlotio from 'hooks/useGlotio'
import useAssistantHash from 'hooks/useAssistantHash'
import { useUpdatesInfo } from 'hooks/useUpdatesInfo'

// utils
import { parseSummaryValues } from 'utils/LanguageOffers'
import {sendTranslationEvents, sendTranslationPriceRuleEvent} from 'utils/TrackerEvents'
import { shouldShowExtraCharsSection } from 'utils/shouldShowExtraCharsSection'

// constants
import { ADD_EXTRA_CHARACTERS_SECTION, ADD_PLAN_SECTION, ADD_UPDATE_LANGUAGES_SECTION } from 'constants/summary_sections.constants'
import { STRIPE_SUPPLEMENT } from 'constants/summary_extra_info.constants'
import { ORIGIN_UPDATES } from 'constants/process_origin.constants'
import { ROUTES } from 'constants/routes.constants'
import { ASSISTANT_ROUTER_HASH } from 'constants/assistant.router.hash'
import { PLANS_NAME } from 'constants/plans.constants'
import { PRODUCT_TYPE } from 'constants/product.constants'

// styles
import styles from './index.module.scss';


/**
 * This component handle the required actions to show if there are translatable content a required view to allow the user to make the payment.
 * If there is no translate language, then the component shows a view where a user can make a new analysis.
 * @param {Object} currentAccount
 * @param {CurrentSubscriptions} currentSubscription
 * @return {JSX}
 */

const UpdateLanguagesInfo = ({currentSubscription, history, setPaymentBackendError, setMustShowResumeAction}) => {

  const { translateText, formatNumber, translateIsoCode } = useGlotio()
  const projectId = useSelector(selectProjectId)
  const currentProject = useSelector(selectCurrentProject)
  const currentProcess = useSelector(selectCurrentProcess)
  const hasLoadedOffers = useRef(false)

  const [errorOffers, setErrorOffers] = useState(null)
  const [isLoadingOffers, setIsLoadingOffers] = useState(false)
  const [languageOffers, setLanguageOffers] = useState({})
  const [canUpdate, setCanUpdate] = useState(false)
  const [subscriptionType, setSubscriptionType] = useState(null)
  const [subscriptionTypeText, setSubscriptionTypeText] = useState(null)
  const [viewType, setViewType] = useState('default')
  const [isLoadingPay, setIsLoadingPay] = useState(false)

  const {
    manualCounter,
    manualUpdateDate,
    manualFrequencyText,
  } = useUpdatesInfo({currentSubscription, translateText});


  useAssistantHash(ASSISTANT_ROUTER_HASH.CONFIRM_UPDATE)

  const showErrorMessage = (result, hasErrorCode = false) => {
    notification.error({
      message: hasErrorCode ? translateText(result.errorCode) : translateText('There was a connection error.'),
      duration: 3,
      key: 'saveSuccess',
    })
  }

  const getTranslationsOffers = async (withLoading = true) => {
    try {

      if (withLoading) {
        setIsLoadingOffers(true)
      }
      const { error, result } = await getTranslationOffersRequest({ projectId, translationBatchId: currentProcess.translationBatchId, params: { "selection": [] } })
      if (error) {
        setErrorOffers('There was a connection error.', error)
      } else {
        setLanguageOffers(result.data)
      }
    } catch (error) {
      setErrorOffers('There was a connection error.', error)
    } finally {
      setIsLoadingOffers(false)
    }

  }

  const languagesToUpdate = get(languageOffers,'offers.updateTranslationOffers', []).filter(({toTranslateCharacters}) => toTranslateCharacters > 0)
  const hasLanguagesToUpdate =  languagesToUpdate.length > 0


  /* avoid to call several times to getTranslationsOffers with hasLoadedOffers.current
  *  flag due to a parent component (Assistant) cause remounting this component.
  */

  useEffect(() => {
    if (!hasLoadedOffers.current && currentProcess) {
        hasLoadedOffers.current = true
        getTranslationsOffers()
    }
  }, [currentProcess])


  useEffect(() => {
    const getSubscription = async () => {
      try {
        const { result, error } = await getCurrentSubscriptionRequest({projectId})
        if (error) {
            showErrorMessage(result)
        } else {
            const { subscription, subscriptionPlan } = result.data
            setCanUpdate(subscription.canUpdateManually)
            setSubscriptionType(subscription.subscriptionPlanType)
            setSubscriptionTypeText(PLANS_NAME[subscriptionPlan.subscriptionPlanType])
        }
      } catch (error) {
        showErrorMessage()
      }
    }

    getSubscription()

  },[])


  const onTranslate = async () => {
    const totalToPay =  get(languageOffers, 'totals.total.amount', 0)
    const params = {
      totalToPay,
      selectedLanguages: []
    }

    try {
      setIsLoadingPay(true)
      const { error, result } = await payTranslationsOffersRequest({ projectId, translationBatchId: currentProcess.translationBatchId, params })
      const hasErrorCode = result && result.errorCode && PAYMENT_ERRORS.includes(result.errorCode)
      if (error) {
        setIsLoadingPay(false)
        if (hasErrorCode) {
          setPaymentBackendError('There has been an error in the payment of the following translation. The payment method you entered is invalid.')
        }
        showErrorMessage(result, hasErrorCode)
      } else {
        sendTranslationEvents(languageOffers.offers, totalToPay)
        sendTranslationPriceRuleEvent(currentProject)
        setMustShowResumeAction(true)
      }
    } catch(err) {
      setIsLoadingPay(false)
      showErrorMessage()
    }
  }

  const onPayTranslate = () => {
    if (!canUpdate) {
      history.push(ROUTES.SUBSCRIPTION_UPGRADE);
      return;
    }
    const totalToPay =  get(languageOffers, 'totals.total.amount', 0)

    if (totalToPay === 0) {
      onTranslate()
      return
    }
    setViewType('payment')
  }

  const generateTable = () => {

    const columns = [
      {
        name: translateText('Language'),
        selector: (row) => translateIsoCode(row.languageIso),
        highlight: true
      },
      {
        name: translateText('Total characters'),
        selector: (row) => formatNumber(row.totalCharacters)
      },
      {
        name: translateText('Translated'),
        selector: (row) => formatNumber(row.translatedCharacters)
      },
      {
        name: translateText('To translate'),
        selector: (row) => formatNumber(row.toTranslateCharacters),
        highlight: true
      }
    ]

    return <InfoTable rows={languagesToUpdate} columns={columns} />

  }

  // Show the correct label depending to the has user has extra character to pay or not
  const setPaymentButtonLabel = () => {

    if (!canUpdate) {
      return translateText('Improve Plan');
    }
    if (shouldShowExtraCharsSection(get(languageOffers, 'offers.translationUpdatesExtra', {})) || get(languageOffers, 'totals.total.amount', 0)) {
      return translateText('Pay and update');
    }

    return translateText('Manual update')

  }


  const initialSummaryValues = {
    summaryFields: [],
    handleSubmitButton:{
      title: setPaymentButtonLabel(),
      isLoading: isLoadingPay,
      onClick: onPayTranslate,
      isDisabled: false,
    }
  }

  const summarySections = () => {
    let sections = [ADD_PLAN_SECTION, ADD_UPDATE_LANGUAGES_SECTION]
    if (!isEmpty(get(languageOffers, 'offers.translationUpdatesExtra', {})) && shouldShowExtraCharsSection(get(languageOffers, 'offers.translationUpdatesExtra', {}))) {
      sections = [...sections, ADD_EXTRA_CHARACTERS_SECTION]
    }
    return sections
  }

  const buildExtraInfo = () => ({

    extraInfo: {
      [ADD_PLAN_SECTION] : {
        subtitle: translateText('Available updates'),
        text: manualFrequencyText,
        subtext: manualUpdateDate,
        value: manualCounter,
        message: !canUpdate ? <InfoDanger text={translateText('You have used up your manual updates. You can upgrade your plan to get more')} /> : ''
      }
    }
  })

  const summaryValues = parseSummaryValues({
    initialSummaryValues,
    updateTranslationOffers: get(languageOffers, 'offers.updateTranslationOffers', []),
    translationUpdatesExtra: get(languageOffers, 'offers.translationUpdatesExtra', {}),
    languagesTotals: get(languageOffers, 'totals', {}),
    currentPlan: subscriptionType,
    sections: summarySections(),
    subscriptionAddLanguagesExtra: get(languageOffers, 'offers.subscriptionAddLanguagesExtra', {}),
    translateText,
    currentPlanText: subscriptionTypeText,
    ...(get(languageOffers, 'totals.isMinPaymentApplied', false) ? { supplements: [STRIPE_SUPPLEMENT]} : {}),
    ...buildExtraInfo(),
    translateIsoCode
    }
  )

  const summaryBillingValues = omit(summaryValues, ['handleSubmitButton'])

  const showTotal = hasLanguagesToUpdate && get(languageOffers, 'offers.translationUpdatesExtra.currentAvailableChars', 0) === 0

  const payParams = () => {

    if (currentProcess)  {
      const totalToPay =  get(languageOffers, 'totals.total.amount', 0)
      return {
        params: {
          totalToPay,
          selectedLanguages: []
        },
        projectId,
        translationBatchId: currentProcess.translationBatchId
      }
    }
    
    return {}
  }

  const handleView = () => {
    if (errorOffers || isLoadingOffers) {
      return <Loading isLoading={isLoadingOffers} isError={errorOffers} />
    }

    switch(viewType) {
      case 'payment':
      return (
        <SummaryBilling
          handlePay={onTranslate}
          isLoading={isLoadingPay}
          title={setPaymentButtonLabel()}
          payParams={payParams()}
          enviromentProduct={PRODUCT_TYPE.TRANSLATION}
          trackOnEdit
          pushBack={() => {
            setViewType('default')
          }}
          refetch={() => getTranslationsOffers(false)}
          {...summaryBillingValues}
        />
      )
    default:
     return (
      hasLanguagesToUpdate ?
        <>
          <div className={styles.update_info_wrapper_tabs}>
            <LanguageUpdatesTabs />
          </div>
          <Summary {...summaryValues} alwaysShowTotal={showTotal}>
            <LanguageInfoWrapper
              title={translateText('Manually update languages')}
              description={translateText('These are the languages of your online shop that are not up to date.')}
            >
              {generateTable()}
            </LanguageInfoWrapper>
          </Summary>
        </> :
        <Container>
          <div className={styles.update_info_with_container_tabs}>
            <LanguageUpdatesTabs />
          </div>
          <UpdateSummary currentSubscription={currentSubscription}>
            <LastAnalysisInfo isProcessActive origin={ORIGIN_UPDATES} sectionTitle='Start analysis to update languages' />
          </UpdateSummary>
        </Container>
     )
    }
  }

  return handleView()

}

const mapStateToProps = (state) => ({
  currentAccount: selectCurrentAccount(state)
})

const mapDispatchToProps = (dispatch) => ({
  setPaymentBackendError: bindActionCreators(paymentError, dispatch),
  setMustShowResumeAction: bindActionCreators(setMustShowResume, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UpdateLanguagesInfo))
