import React from 'react'

// components
import { Menu, Dropdown } from 'antd'
import { connect } from 'react-redux'
import CountryFlag from 'components/GlotioComponents/CountryFlag'
import withGlotio from 'hoc/withGlotio'

// utils
import activeLangs from 'services/languages-list'
import { changeLocalLanguage } from 'utils/Utils'

// actions
import { saveUserChanges, fetchUserStart } from 'redux/user/actions'
import { saveProjectChanges } from 'redux/project/actions'

// selectors
import { selectUserId, selectUserLocale } from 'redux/user/selectors'

// styles
import styles from './style.module.scss'

class LanguageSelector extends React.Component {
  changeLang = async ({ key }) => changeLocalLanguage(key, this.props)

  render() {
    const { locale, translateText } = this.props
    const language = locale.substr(0, 2)

    const langMenu = (
      <Menu className={styles.menu} selectedKeys={[locale]} onClick={this.changeLang}>
        {activeLangs.map((lang) => {
          return (
            <Menu.Item key={lang.value}>
              <span role="img" aria-label={lang.label} className="mr-3">
                <CountryFlag iso={lang.flag} />
              </span>
              {translateText(lang.label)}
            </Menu.Item>
          )
        })}
      </Menu>
    )
    return (
      <Dropdown overlay={langMenu} trigger={['click']}>
        <div className={styles.dropdown}>
          <strong className="text-uppercase">{language}</strong>
        </div>
      </Dropdown>
    )
  }
}

const mapStateToProps = (state) => ({
  locale: selectUserLocale(state),
  userId: selectUserId(state),
})

const mapDispatchToProps = (dispatch) => ({
  saveLocaleChange: (lang) => dispatch(saveUserChanges({ lang })),
  updateLanguageProject: (data) => dispatch(saveProjectChanges(data)),
  fetchUser: (userId) => dispatch(fetchUserStart(userId, 'LanguageSelector')),   // detect where the user null comes from
})

export default connect(mapStateToProps, mapDispatchToProps)(withGlotio(LanguageSelector))
