import { useCallback, useEffect, useState } from "react"
import {debounce} from 'lodash'
import { isIframeAndNotSbIframe } from 'utils/Utils'

const useIframeObserver = id => {

  const [isIframeConnected, setIsIframeConnected] = useState(false)

  useEffect(() => {
    window.parent.postMessage({ channel: 'glotio', type: 'content-loaded'}, '*')
  },[])

  useEffect(() => {
    window.addEventListener('message', event => {
      if (event.data && event.data.channel === 'glotio' && event.data.type === 'iframe-connected' && ("ResizeObserver" in window)) {
        console.log('es un iframe en el hook')
        setIsIframeConnected(true)
      }
    });
  },[])

  const sendHeight = useCallback((height, lastSentHeight) => {
    if (height !== lastSentHeight) {
      window.parent.postMessage({ channel: 'glotio', type: 'set-height', height }, '*')
      lastSentHeight = height
    }
  }, [])

  useEffect(() => {
    const isIframe = isIframeAndNotSbIframe()
    const targetLayout = document.getElementById(id)

    let resizeObserver
    let lastSentHeight

    const debouncedSendHeight = debounce(sendHeight, 200)

    if (isIframeConnected && isIframe && targetLayout && ("ResizeObserver" in window)) {
      resizeObserver = new ResizeObserver(entries => {
        for (const entry of entries) {
          const { height } = entry.contentRect
          debouncedSendHeight(height, lastSentHeight)
        }
      })

      resizeObserver.observe(targetLayout)
    }

    return () => {
      if (resizeObserver) {
        resizeObserver.disconnect()
      }
      debouncedSendHeight.cancel()
    };
  }, [isIframeConnected])

  return {
    isIframeConnected
  }

}

export default useIframeObserver
