import Request from 'models/Request'

export const getUserByIdRequest = ({ userId, callback }) =>
Request.fetch({
  key: 'getUserById',
  url: `/api/v1/user/${userId}`,
  method: 'GET',
  callback,
})

export const updateUserByIdRequest = ({ userId, params, callback }) =>
Request.fetch({
  key: 'updateUserById',
  url: `/api/v1/user/${userId}`,
  method: 'PUT',
  params,
  callback,
})