import React from 'react'
import withGlotio from 'hoc/withGlotio'
import css from './styles.module.scss'

/**
 * Renders the header section for the Auth layout pages
 * It has a title and an optional subtitle
 *
 * @param {{
 *   title: string,
 *   children?: JSX.Element | JSX.Element[],
 *   translateText: function
 * }} props
 */
const AuthHeader = ({ title, children, translateText }) => (
  <header className={css.header__container}>
    <h1>
      {translateText(title)}
    </h1>
    {children}
  </header>
)

export default withGlotio(AuthHeader)
