import React from 'react'
import { Col, Row } from 'antd'
import { get } from 'lodash'
import { Link } from 'react-router-dom'

// hooks
import useGlotio from 'hooks/useGlotio'
import { useCharacterBalance } from 'hooks/useCharacterBalance'
import { useUpdatesInfo } from 'hooks/useUpdatesInfo'

// constants
import { statusColor, statusType } from 'constants/status.constants'
import { ROUTES } from 'constants/routes.constants'
import { subscriptionFrequencyType, subscriptionType } from 'constants/subscription.constants'
import { PLANS_NAME } from 'constants/plans.constants'

// components
import InfoPopover from 'components/CleanUIComponents/Popover/InfoPopover'
import InfoDanger from 'components/CleanUIComponents/Messages/InfoDanger'
import GlotioButton from 'components/GlotioComponents/GlotioButton'
import ProgressInfo from 'components/CleanUIComponents/ProgressInfo'

// utils
import { checkClassicUserCurrentSubscription } from 'utils/isClassicUser'

import { linearFromColor, linearToColor } from 'assets/styles/mixins.scss'
import styles from  './index.module.scss'

/**
 *
 * @param {CurrentSubscriptions} currentSubscription
 * @param {JSX.Element|ReactNode} children
 * @param {Object} history
 * @return {JSX.Element}
 * @constructor
 */


const UpdateSummary = ({ currentSubscription, children }) => {
  const { translateText } = useGlotio()
  const currentPlan = PLANS_NAME[get(currentSubscription, 'subscriptionPlan.subscriptionPlanType', null)] || null
  const canUpdateManually = get(currentSubscription, 'subscription.canUpdateManually', null)
  const { manualUpdateFrequency, subscriptionPlanType } = get(currentSubscription, 'subscriptionPlan', {})
  const hasNoManualUpdate = manualUpdateFrequency === subscriptionFrequencyType.NONE
  const isFree = subscriptionPlanType === subscriptionType.FREE

  const canShowCharBalance = !hasNoManualUpdate && !isFree;

  const {usedCharString, resetDate, beforeResetAt, charPercent, MAX_PERCENT} = useCharacterBalance({currentSubscription, translateText})
  const {
    manualCounter,
    manualUpdateDate,
    manualFrequencyText,
  } = useUpdatesInfo({currentSubscription, translateText});

  // TODO Done for Classic Plan
  const isClassicUser = checkClassicUserCurrentSubscription(currentSubscription)

  return (
    <Row gutter={[16, 16]}>
      <Col className={styles.children_container} xs={24} xl={18}>
        {children}
      </Col>
      <Col xs={24} xl={6}>
        <div className={styles.update_summary}>
          <div className={styles.summary_wrapper_title}>
            <p className={styles.summary_title}>{translateText('Summary')}</p>
          </div>
          <div className={styles.summary_content}>
            <div className={`${styles.summary_section} ${styles.no_padding}`}>
              <span className={styles.summary_subtitle}>{translateText('Current plan')}</span>
              <span>
                <InfoPopover
                  message={translateText('This is the plan to which you are currently subscribed.')}
                  color={statusColor[statusType.info]}
                  direction='left'
                />
              </span>
              <span className={styles.summary_text}>{ isClassicUser ? translateText('No plan') : currentPlan}</span>
              <Link to={ROUTES.SUBSCRIPTION_UPGRADE} className={`${styles.summary_text} ${styles.summary_highlight}`}>
                {isClassicUser ? translateText('Choose plan') : translateText('Improve Plan')}
              </Link>
            </div>
            <div>
              <h3 className={styles.summary_subtitle}>{translateText('Available updates')}</h3>
              <div className={`${styles.summary_section} ${styles.small_gap}`}>
                <span className={styles.summary_text}>{manualFrequencyText}</span>
                <span className={styles.summary_text}>{manualCounter}</span>
                <span className={styles.summary_sub_text}>
                  {manualUpdateDate}
                </span>
              </div>
            </div>
            {canShowCharBalance &&
              <div>
                <h3 className={styles.summary_subtitle}>{translateText('Character balance')}</h3>
                <div className={styles.summary_progress}>
                  <ProgressInfo
                    className={styles.info_field_progress}
                    title={usedCharString}
                    subtitle={charPercent !== MAX_PERCENT
                      ? `${translateText('Available until')} ${beforeResetAt}`
                      : `${translateText('Available on')} ${resetDate}`}
                    percent={charPercent}
                    color={{ '0%': linearFromColor, '100%': linearToColor }}
                    size='small'
                  />
                </div>
              </div>
            }
            { !canUpdateManually &&
              <InfoDanger
                text={translateText('You have used up your manual updates. You can upgrade your plan to get more')}
              />
            }
          </div>
          <div className={styles.summary_separator} />
          <GlotioButton as='Link' url={ROUTES.SUBSCRIPTION_UPGRADE} variant='default' size='large' fluid>
            {translateText('Improve plan')}
          </GlotioButton>
        </div>
      </Col>
    </Row>
  )
}

export default UpdateSummary
