import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'

// hooks
import useGlotio from 'hooks/useGlotio'
import useAssistantHash from 'hooks/useAssistantHash'

// components
import FirstTranslation from 'layouts/FirstTranslation'
import { Progress } from 'antd'
import Step from 'components/GlotioComponents/Step'
import AnalyseResume from 'components/GlotioComponents/Wizard/AnalyseResume'
import GlotioButton from 'components/GlotioComponents/GlotioButton'
import RootPortal from 'components/CleanUIComponents/RootPortal'

// actions
import { fetchAccountStart } from 'redux/account/actions'

// selectors
import { selectProjectId } from 'redux/project/selectors'

// constants
import { ASSISTANT_ROUTER_HASH } from 'constants/assistant.router.hash'
import TRANSLATION_WIZARD from 'constants/translation_wizard.constants'

// assets
import check from 'assets/images/illustrations/check.svg'

// styles
import css from 'components/GlotioComponents/GlobalStatusBar/styles.module.scss'
import styles from './index.module.scss'

/**
 * @param {Function} setAssistantPage
 * @param {DefaultLanguageEstimation | Object} defaultLanguages
 * @param {NewTranslationOffer[]} installedLanguages
 * @return {JSX.Element}
 */
const AnalyseFinish = ({ setAssistantPage, installedLanguages, defaultLanguages }) => {
  const projectId = useSelector(selectProjectId)
  const dispatch = useDispatch()
  const { translateText, formatNumber } = useGlotio()
  useAssistantHash(ASSISTANT_ROUTER_HASH.DOWNLOAD_COMPLETED);
  const numChars  = get(defaultLanguages, 'stats.numChars', 0)

  /**
   * Updates the languagues list of the user on checkout load
   */
  useEffect(() => {
    dispatch(fetchAccountStart())
  }, [projectId])


  /**
   * Returns the status of the bar
   */
  const getStatusBar = () => {
    return (
      <div className={css.statusProgressBar}>
        <div className={css.statusInfo}>
          <div>
            <p className={css.process_info_percentage}>
                100%
            </p>
            <p className={css.process_info_processed} style={{ display: 'inline-block' }}>
              {translateText('analyzed')}
            </p>
          </div>
          <span>{translateText('Characters read {amount}', { amount: formatNumber(numChars) })}</span>
        </div>
        <Progress
          className={css.progress_bar}
          percent={100}
          status="active"
          strokeColor={{ '0%': '#4738c3', '100%': '#d71658' }}
        />
      </div>

    )
  }


  const handleButtonClick = () => setAssistantPage({ page: TRANSLATION_WIZARD.pages.QUESTION_WHAT_TO_DO_WITH_GLOTIO, lang: null })

  const renderButtonBar = () => {
    return (
      <RootPortal>
        <RootPortal.Basic>
          <GlotioButton
            data="button"
            type='button'
            size='large'
            variant='default'
            onClick={handleButtonClick}
          >
            {translateText('Continue')}
          </GlotioButton>
        </RootPortal.Basic>
      </RootPortal>
    )
  }

  return (
    <FirstTranslation>
      <Step
        title={translateText('Analysis finished')}
        icon={check}
        className={styles.processing_container}
      >
        {getStatusBar()}
      </Step>

      <Step
        title={translateText('Here is what we have found')}
        subtitle={translateText('Glotio has analyzed your online store and detected translatable content and calculated the total number of existing characters.')}
      >
        <AnalyseResume defaultLanguages={defaultLanguages} installedLanguages={installedLanguages} />
      </Step>
      { renderButtonBar() }
    </FirstTranslation>
  )
}


export default AnalyseFinish
