import Request from 'models/Request'

export const getProcessStatusRequest = ({ currentProcessID, callback }) =>
  Request.fetch({
    key: 'getProcessStatus',
    schema: 'ProcessStatus',
    method: 'GET',
    url: `/api/v1/process/${currentProcessID}/status`,
    callback: (res) => {
      return callback ? callback(res) : res
    },
  })

export const launchManualNewCOFRequest = ({ projectId, callback }) =>
  Request.fetch({
    key: 'launchNewCOFT',
    url: `/api/v1/project/${projectId}/new_language_assistant`,
    method: 'POST',
    callback,
  })

export const cancelAndRestartProcessRequest = ({ processId, callback }) =>
  Request.fetch({
    key: 'cancelAndRestart',
    url: `/api/v1/process/${processId}/cancelAndRestart`,
    method: 'POST',
    callback,
  })

export const getTranslationSourcesRequest = ({ projectId, callback }) =>
  Request.fetch({
    key: 'getTranslationSources',
    url: `/api/v1/project/${projectId}/translation_sources`,
    method: 'GET',
    callback,
  })

export const setDisabledFieldsRequest = ({ projectId, disabledFields, callback }) =>
  Request.fetch({
    key: 'setDisabledFields',
    url: `/api/v1/project/${projectId}/translation_sources/disabled_fields`,
    method: 'POST',
    params: disabledFields,
    callback,
  })

export const getConfigurationRequest = ({ projectId, callback = null }) =>
  Request.fetch({
    key: 'getConfiguration',
    url: `/api/v1/project/${projectId}/configuration`,
    method: 'GET',
    callback,
  })

export const setConfigurationRequest = ({ projectId, justActiveFields, callback = null }) =>
  Request.fetch({
    key: 'setConfiguration',
    url: `/api/v1/project/${projectId}/configuration`,
    method: 'POST',
    params: {
      filter_active_entities: justActiveFields,
    },
    callback,
  })

export const getTranslationHistoryRequest = ({ projectId, page, callback = null }) =>
  Request.fetch({
    key: 'getTranslationHistory',
    url: `/api/v1/project/${projectId}/translation_batches`,
    method: 'GET',
    params: {page},
    callback,
  })

export const getTranslationHistoryDetailRequest = ({ projectId, translationBatchId, callback = null }) =>
  Request.fetch({
    key: 'getTranslationHistoryDetail',
    url: `/api/v1/project/${projectId}/translation_batches/${translationBatchId}`,
    method: 'GET',
    callback,
  })

export const getTranslationOffersRequest = ({ projectId, translationBatchId, params, callback }) =>
  Request.fetch({
    key: 'getTranslationOffers',
    url: `/api/v2/project/${projectId}/translation-offers/calculate/${translationBatchId}`,
    params,
    method: 'GET',
    callback,
  })

export const updateAutomaticUpdateRequest = ({ projectId, params, callback = null }) =>
  Request.fetch({
    key: 'updateAutomaticUpdate',
    url: `/api/v2/project/${projectId}/autoupdate-configuration`,
    method: 'PUT',
    params,
    callback
  })

export const getLastAutomaticUpdateRequest = ({ projectId, callback }) =>
  Request.fetch({
    key: 'getLastAutomaticUpdate',
    url: `/api/v2/project/${projectId}/translation-batches/last-automatic-update`,
    method: 'GET',
    callback,
  })
