/**
 * Obtains the initials from the user name
 *
 * @param {String} userName The name of the user stored on the profile
 * @returns {String} The initials of the name
 */

export const getUserInitials = (userName) => {
  if (!userName) return null

  const [firstName, lastName] = userName.split(' ')

  return `${firstName ? firstName[0].toUpperCase() : ''} ${
    lastName ? lastName[0].toUpperCase() : ''
  }`
}