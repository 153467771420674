import React, {useCallback, useState} from 'react'

// components
import AdvancedConfigurationModal from 'components/GlotioComponents/Wizard/AdvancedConfigurationModal'
import BackButton from 'components/CleanUIComponents/Buttons/BackButton'
import GlotioButton from 'components/GlotioComponents/GlotioButton'

// hooks
import useGlotio from 'hooks/useGlotio'

// icons
import {ReactComponent as AlertIcon} from 'assets/images/icons/alert-circle.svg'

// styles
import styles from './index.module.scss'
/**
 * @typedef ButtonGlotio
 * @property {string} text
 * @property {Function} onClick
 * @property {boolean} isDisabled
 * @property {boolean} isLoading
 */

/**
 *
 * @param {Function} pushBack
 * @param {ButtonGlotio} buttonContinue
 * @param {boolean} hasDescription
 * @param {String} descriptionTitle
 * @param {string} className
 * @return {JSX.Element}
 * @constructor
 */
const WizardFooter = ({ pushBack, buttonContinue = {}, hasDescription, descriptionTitle = "Select which fields Glotio translates", className}) => {
  const { translateText } = useGlotio()
  const [isOpen, setIsOpen] = useState(false)
  const { text, onClick, isDisabled = false, isLoading = false } = buttonContinue

  const handleClose = useCallback(() => {
    setIsOpen(false);
  },[])

  return (
    <>
      <div className={`${styles.footer_container} ${className}`}>
        <div className={styles.footer}>
          {
            pushBack &&
            <div>
              <BackButton pushBack={pushBack} />
            </div>
          }
          {
            hasDescription &&
            <div className={styles.wizard_description_container}>
              {isOpen && <AdvancedConfigurationModal isOpen={isOpen} handleClose={handleClose} />}
              <GlotioButton
                type='button'
                variant='link'
                size='small'
                className={styles.wizard_description_text}
                onClick={() => setIsOpen(true)}
              >
                {translateText(descriptionTitle)}
              </GlotioButton>
              <div className={styles.wizard_description_subtitle}>
                <AlertIcon />
                <i>{translateText('Translation is set to partially translate.')}</i>
              </div>

            </div>
          }
          {Object.keys(buttonContinue).length > 0 &&
            <div className={styles.container_btn}>
              <GlotioButton
                type='button'
                variant='default'
                size='large'
                onClick={onClick}
                disabled={isDisabled}
                isLoading={isLoading}
              >
                {text}
              </GlotioButton>
            </div>
          }
        </div>
      </div>
    </>
  )
}

export default WizardFooter
