import React from 'react'
import AddLanguages from 'components/GlotioComponents/Wizard/HandlerNewLanguages/AddLanguagues'

const AddLanguagesContainer = ({languages = [], ...rest}) => {
  if (languages.length === 0) return null
  return (
    <AddLanguages languages={languages} {...rest} />
  )
}

export default AddLanguagesContainer