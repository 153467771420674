import { call, put, all, takeLatest, select } from 'redux-saga/effects'

// utils
import { getAccountByIdRequest } from 'models/api/account'

// actions
import actionTypes, { fetchAccountSuccess, fetchAccountFail } from 'redux/account/actions'

// selectors
import { selectAccountIdFromProject } from 'redux/account/selectors'

function* fetchAccount() {
  const accountId = yield select(selectAccountIdFromProject)

  if (!accountId) return

  const { result, error } = yield getAccountByIdRequest({ accountId })

  if (!error) {
    const { success, object } = result
    if (success) {
      yield put(fetchAccountSuccess(object))
    } else {
      yield put(fetchAccountFail(result.message || 'There was a connection error.'))
    }
  } else {
    yield put(fetchAccountFail(error))
  }
}

// Action listeners
function* onFetchAccountStart() {
  yield takeLatest(actionTypes.FETCH_ACCOUNT_START, fetchAccount)
}

export default function* projectSaga() {
  yield all([call(onFetchAccountStart)])
}
