import antdData from 'antd/lib/locale-provider/nl_NL'
import localeData from 'react-intl/locale-data/nl'
import countries from './countries/nl.json'
import languages from './languages/nl.json'
import platform from './platform/nl.json'
import urls from './urls/nl.json'
import texts from './texts/nl.json'
import faq from './faq/nl.json'

export default {
  locale: 'nl',
  antdData,
  localeData,
  messages: { ...texts, ...countries, ...languages, ...platform, ...urls, ...faq },
}
