import React, { useState } from 'react'
import { Icon, notification, Spin } from 'antd'
import { useSelector } from 'react-redux'

// hooks
import useGlotio from 'hooks/useGlotio'

// components
import GlotioButton from 'components/GlotioComponents/GlotioButton'

// selector
import { selectProjectId } from 'redux/project/selectors'

// constants
import { ROUTES } from 'constants/routes.constants'

// models
import { renewAccessTollsDays } from 'models/api/subscription'

// styles
import styles from './index.module.scss'

const RenewToolsDaysModal = ({ onClose, onSuccess }) => {
  const { translateText } = useGlotio()
  const [isLoadingRenew, setIsLoadingRenew] = useState(false)
  const projectId = useSelector(selectProjectId)
  const link = `<a class="${styles.underline_text}" href="${ROUTES.SUBSCRIPTION_UPGRADE}">${translateText('subscribe to a payment plan')}</a>`

  const onRenewToolsDays = async () => {
    try {
      setIsLoadingRenew(true)
      await renewAccessTollsDays({ projectId })
      await onSuccess()
      onClose()
    } catch (err) {
      notification.error({
        message: translateText('Unexpected error'),
        duration: 3,
        key: 'saveSuccess',
      })
    } finally {
      setIsLoadingRenew(false)
    }
  }

  if (isLoadingRenew) {
    return (
      <div className={styles.loading_container}>
        <Spin indicator={<Icon type="loading" style={{ fontSize: 64 }} spin />} />
        <h2 className={styles.loading_title}>{translateText('Tool Trial Renewal')}</h2>
        <p className={styles.loading_description}>{translateText('Please Stand By as Your Tools Trial Renews')}</p>
      </div>
    )
  }

  return (
    <>
      <div className={styles.wrapper_title}>
        <p className={styles.title}>{translateText('Renew trial tools')}</p>
        <div className={styles.description}>
          <span dangerouslySetInnerHTML={{__html: translateText('During the next 30 days you will be able to use the advanced editing tools. After the end of this period, the created rules will no longer be effective. They will only become active again if you {link}.', {link})}} />
        </div>
      </div>
      <div className={styles.wrapper_actions}>
        <GlotioButton
          type='button'
          variant='outlined'
          size='large'
          className={styles.modal_button}
          onClick={onClose}
        >
          {translateText('Cancel')}
        </GlotioButton>
        <GlotioButton
          type='button'
          variant='default'
          size='large'
          className={styles.modal_button}
          isLoading={isLoadingRenew}
          onClick={onRenewToolsDays}
        >
          {translateText('Renew trial')}
        </GlotioButton>
      </div>
    </>
  )
}

export default RenewToolsDaysModal
