import Request from 'models/Request'

export const loginUserRequest = ({ loginData, callback }) =>
Request.fetch({
  key: 'loginUser',
  schema: 'ApiResponse',
  url: `/api/login`,
  method: 'POST',
  params: loginData,
  callback,
})

export const loginAdminRequest = ({ loginData, callback }) =>
Request.fetch({
  key: 'loginAdmin',
  schema: 'ApiResponse',
  url: `/api/admin/project_login`,
  method: 'POST',
  params: loginData,
  callback,
})

export const loginAfterVerificationRequest = ({ loginData, callback }) =>
Request.fetch({
  key: 'directLogin',
  url: `/api/login_token`,
  method: 'POST',
  params: loginData,
  callback,
})

export const logoutUserRequest = ({ callback }) =>
Request.fetch({
  key: 'logoutUser',
  url: `/api/logout`,
  method: 'POST',
  callback,
})

export const forgotPasswordRequest = ({ email, callback }) =>
Request.fetch({
  key: 'forgotPassword',
  url: `/api/password/forgot/${email}`,
  method: 'GET',
  callback,
})

export const resetPasswordRequest = ({ resetInfo, callback }) =>
Request.fetch({
  url: `/api/password/forgot`,
  method: 'POST',
  params: resetInfo,
  callback,
})

export const setPasswordRequest = ({ currentPassword, newPassword, repeatNewPassword, callback = null }) =>
Request.fetch({
  key: 'setPassword',
  url: `/api/password/change_password`,
  method: 'PUT',
  params: {
    currentPassword,
    newPassword,
    newPassword_confirmation: repeatNewPassword,
  },
  callback,
})

export const checkResetPasswordTokenRequest = ({ tokenID, callback }) =>
Request.fetch({
  key: 'checkResetPasswordToken',
  url: `/api/v1/password/forgot/check_token/${tokenID}`,
  method: 'GET',
  callback,
})