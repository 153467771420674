// types of modals
export const CANCEL_SUBSCRIPTION_MODAL = "CANCEL_SUBSCRIPTION_MODAL"
export const TRANSLATIONS_HISTORY_MODAL = "TRANSLATIONS_HISTORY_MODAL"
export const CONFIRM_MODAL = "CONFIRM_MODAL"
export const RETRY_SUBSCRIPTION_PAYMENT_MODAL = "RETRY_SUBSCRIPTION_PAYMENT_MODAL"
export const FAIL_RETRY_SUBSCRIPTION_PAYMENT_MODAL = "FAIL_RETRY_SUBSCRIPTION_PAYMENT_MODAL"
export const COOKIES_CONFIRM_MODAL = "COOKIES_CONFIRM_MODAL"
export const COOKIES_CONFIG_MODAL = "COOKIES_CONFIG_MODAL"
export const FAQ_MODAL = "FAQ_MODAL"
export const FIND_REPLACE_MODAL = "FIND_REPLACE_MODAL"
export const RENEW_TOOLS_DAYS_MODAL = "RENEW_TOOLS_DAYS_MODAL"

// modals sizes
export const DEFAULT_WIDTH_MODAL = 520;
export const SMALL_WIDTH_MODAL = 630;
export const MEDIUM_WIDTH_MODAL = 768;
export const LARGE_WIDTH_MODAL = 850;
export const EXTRA_LARGE_WIDTH_MODAL = 900;

// modal priority
export const PRIORITY_HIGH = 1
export const PRIORITY_MEDIUM = 2
export const PRIORITY_LOW = 3

export const DEFAULT_PRIORITY_MODALS = {
  [COOKIES_CONFIRM_MODAL]: PRIORITY_HIGH,
  [COOKIES_CONFIG_MODAL]: PRIORITY_HIGH
}
