import React, {memo} from 'react'

// components
import AdvancedConfiguration from 'components/GlotioComponents/AdvancedConfiguration';


/**
 * Modal for subscription info
 * @param {boolean} isOpen
 * @param {Function} handleClose
 * @param {Number} projectId
 * @return {JSX.Element}
 */
const AdvancedConfigurationModal = ({ isOpen, handleClose }) => {

  return (
    <AdvancedConfiguration
      isOpen={isOpen}
      handleClose={handleClose}
      withModal
    />
  )
}

export default memo(AdvancedConfigurationModal)
