import { createSelector } from 'reselect'

const selectAccount = state => state.account

export const selectCurrentAccount = createSelector(
  [selectAccount],
  account => account.currentAccount,
)

export const selectIsAccountFetching = createSelector([selectAccount], account => account.fetching)

export const selectCurrentAccountId = createSelector([selectCurrentAccount], currentAccount =>
  currentAccount ? currentAccount.id : null,
)

export const selectAccountIdFromProject = createSelector(
  [state => state.project.currentProject],
  currentProject => (currentProject ? currentProject.account_id : null),
)

export const selectAccountPaymentLimit = createSelector([selectCurrentAccount], currentAccount =>
  currentAccount ? currentAccount.payment_limit : null,
)

export const selectAccountMonthlySpent = createSelector([selectCurrentAccount], currentAccount =>
  currentAccount ? currentAccount.monthly_spent : null,
)

export const selectAccountBillingData = createSelector([selectCurrentAccount], currentAccount => {
  if (!currentAccount) return null

  return {
    company_name: currentAccount.company_name,
    document: currentAccount.document,
    address1: currentAccount.address1,
    city: currentAccount.city,
    iso_country: currentAccount.iso_country,
    postal_code: currentAccount.postal_code,
  }
})

export const selectPaymentData = createSelector([selectCurrentAccount], currentAccount => {
  if (!currentAccount || !currentAccount.paymentMethod) return null

  return currentAccount.paymentMethod.object
})
