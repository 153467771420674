
// constants
import { HOTJAR_EVENTS } from "constants/hotjat_events.constants";

// utils
import { InjectScriptInDom } from "utils/inject-script-in-dom";

export const hotjarOnSendEvent = (event, tags = []) => {
  if (typeof hj === 'function' && tags.length > 0) {
    hj(event, tags)
  }
}

const HotjarInitializer = (logger = null) => {
  if (!process.env.REACT_APP_HOTJAR_ID) {
    return false
  }

  if (typeof hj === 'function') {
    return true
  }

  try {
    InjectScriptInDom(`(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:${  process.env.REACT_APP_HOTJAR_ID  },hjsv:${  process.env.REACT_APP_HOTJAR_VERSION  }};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`)
    hotjarOnSendEvent(HOTJAR_EVENTS.TAG_RECORDING, [process.env.REACT_APP_NODE_ENVIROMENT])
    return true
  } catch (e) {
    if (logger) {
      logger(e.message)
    }
    return false
  }
}

export default HotjarInitializer
