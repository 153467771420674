class Storage {
  static isLocalStorageAvailable() {
    try {
      localStorage.setItem('is_available', 'true')
      localStorage.removeItem('is_available')
      return true
    } catch (e) {
      return false
    }
  }

  static isSessionStorageAvailable() {
    try {
      sessionStorage.setItem('is_available', 'true')
      sessionStorage.removeItem('is_available')
      return true
    } catch (e) {
      return false
    }
  }

  static sessionSet(name, value) {
    if (this.isSessionStorageAvailable()) {
      sessionStorage.setItem(name, value)
    }
  }

  static sessionGet(name) {
    if (!this.isSessionStorageAvailable()) {
      return null
    }
    return sessionStorage.getItem(name)
  }

  static localSet(name, value) {
    if (this.isLocalStorageAvailable()) {
      localStorage.setItem(name, value)
    }
  }

  static localGet(name) {
    if (!this.isLocalStorageAvailable()) {
      return null
    }
    return localStorage.getItem(name)
  }

  static setIsAdminUser() {
    if (this.isSessionStorageAvailable()) {
      this.sessionSet('isAdminUser', 'true')
    }
  }

  static getIsAdminUser() {
    if (!this.sessionGet('isAdminUser')) {
      return false
    }

    return this.sessionGet('isAdminUser') === 'true'
  }
}

export default Storage
