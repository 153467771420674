import React from 'react'

// services
import externalUrls from 'services/externalUrls'

// components
import { Carousel }  from 'antd'

// hooks
import useGlotio from 'hooks/useGlotio'

// assets
import AdvantagesImg from 'assets/images/illustrations/traducciones.png'
import toolsImg from 'assets/images/illustrations/herramientas.png'

// styles
import styles from './index.module.scss'

const FaqCarousel = () => {
  const { translateText } = useGlotio()

  const dataSlides =
    [
      {
        key: 'search_replace1',
        category: translateText('Advantages of Glotio'),
        title: translateText('Translations will always be yours'),
        subtitle: translateText('By not depending on third parties, all the content you have translated with Glotio will always remain on the platform you use. No one better than you to safeguard them.'),
        image: AdvantagesImg,
        imageAlt: 'glotio_advantages',
        link: externalUrls.ADVANTAGES_GLOTIO_GUIDE
      },
      {
        key: 'search_replace2',
        category: translateText('Advanced tools'),
        title: translateText('Perfect your translations'),
        subtitle: `${translateText('Three advanced content editing tools are available to help you perfect your translations: excluded words, smart dictionary and search and replace')}.`,
        image: toolsImg,
        imageAlt: 'glotio_tools',
        link: externalUrls.FAQ_TOOLS
      }
    ]

  return (
    <div>
      <Carousel dotPosition='bottom' autoplay>
        {
          dataSlides.map((slide) => {
            return (
              <div className={styles.carousel_slide} key={slide.key}>
                <div className={styles.carousel_slide_content}>
                  <img src={slide.image} alt={slide.imageAlt} />
                </div>
                <div className={styles.carousel_slide_content}>
                  <p className={styles.title_category}>{ slide.category }</p>
                  <h1 className={styles.title}>{ slide.title }</h1>
                  <p className={styles.subtitle}>
                    { slide.subtitle }
                    &nbsp;
                    { slide.link && <a href={translateText(slide.link)} className={styles.link_carrousel} target="_blank" rel="noopener noreferrer">{translateText('Learn more')}.</a> }
                  </p>
                </div>
              </div>
            )
          })
        }
      </Carousel>
    </div>
  )
}

export default FaqCarousel
