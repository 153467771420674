import React, { useState } from 'react'
import { withRouter } from 'react-router'
import {isEmpty, get} from 'lodash'

// components
import FirstTranslation from 'layouts/FirstTranslation'
import Step from 'components/GlotioComponents/Step'
import AlertMessage from 'components/GlotioComponents/AlertMessage'
import CardRadioButton from 'components/CleanUIComponents/RadioButton/CardRadioButton'
import RootPortal from 'components/CleanUIComponents/RootPortal'
import GlotioButton from 'components/GlotioComponents/GlotioButton'
import BackButton from 'components/CleanUIComponents/Buttons/BackButton'
import HelpAccordion from 'components/GlotioComponents/HelpAccordion'
import FaqArticlesList from 'components/GlotioComponents/FaqArticlesList'
import InfoPopover from 'components/CleanUIComponents/Popover/InfoPopover'
import FaqLink from 'components/GlotioComponents/FaqLink'

// hooks
import useAssistantHash from 'hooks/useAssistantHash'
import useGlotio from 'hooks/useGlotio'
import { useCreateModal } from 'hooks/useCreateModal'

// constants
import { messageTypes } from 'components/GlotioComponents/AlertMessage/constants'
import TRANSLATION_WIZARD from 'constants/translation_wizard.constants'
import { CONFIRM_MODAL } from 'constants/modals.constants'
import { ASSISTANT_ROUTER_HASH } from 'constants/assistant.router.hash'
import { statusColor, statusType } from 'constants/status.constants'

// utils
import Faq from 'utils/Faq'
import { generateComponentUuid } from 'utils/Utils'

// types
import 'components/GlotioComponents/Wizard/types'

// styles
import styles from './index.module.scss'

/**
 * Returns view of select default language of shop
 * @param {Function} setAssistantPage
 * @param {DefaultLanguageEstimation | Object} defaultLanguages
 * @param {NewTranslationOffer[]} installedLanguages
 * @return {JSX.Element}
 */
const DefaultLanguage = ({ setAssistantPage, defaultLanguages, installedLanguages, wizzardQuestion }) => {
  const { translateText, translateIsoCode } = useGlotio()
  const { onOpenModal, onCloseModal } = useCreateModal()
  const [selectedLanguage, setSelectedLanguage] = useState();
  const {addAssistantHash} = useAssistantHash(ASSISTANT_ROUTER_HASH.DEFAULT_LANGUAGE)

  /**
   * Open FAQ - CHANGE_DEFAULT_LANGUAGE Modal
   * @returns void
   */
  const changeDefaultLanguageFaqModal = () => {
    if (selectedLanguage === 'none') {
      setAssistantPage({ page: TRANSLATION_WIZARD.pages.WRONG_DEFAULT_LANGUAGE_HELP, lang: null })
      return
    }

    const message = {
      type: CONFIRM_MODAL,
      title: translateText('The default language does not match the one in which you create the content'),
      description: translateText('Remember that, for Glotio to work correctly, the default language defined in your platform must be the same language you use to create the content in your online store.'),
      onCancel: () => {
        addAssistantHash(ASSISTANT_ROUTER_HASH.DEFAULT_LANGUAGE)
      },
      buttons: [{
        text: translateText('Cancel'),
        options: {
          type: "button",
          variant: "outlined",
          size: "large",
          className: styles.modal_button
        },
        callback: () => {
          addAssistantHash(ASSISTANT_ROUTER_HASH.DEFAULT_LANGUAGE)
          onCloseModal()
        },
      },
        {
          text: translateText('Modify the language'),
          options: {
            type: "button",
            variant: "default",
            size: "large",
            className: styles.modal_button
          },
          callback: () => {
            onCloseModal()
            setAssistantPage({ page: TRANSLATION_WIZARD.pages.WRONG_DEFAULT_LANGUAGE_HELP, lang: null })
          },
        }]
    }
    onOpenModal({...message})
    addAssistantHash(ASSISTANT_ROUTER_HASH.DEFAULT_LANGUAGE_POPUP);
  }

  /**
   * @param {string} isoLanguage
   * @return {JSX.Element}
   */
  const popoverInfoMessage = (isoLanguage) => {
    return (
      <>
        {translateText('Glotio cannot translate the {language} language.', { language: isoLanguage})}
        <span
          className={styles.popover_link}
          onClick={() => setAssistantPage({ page: TRANSLATION_WIZARD.pages.WRONG_DEFAULT_LANGUAGE_HELP, lang: null })}
          onKeyDown={() => null}
          tabIndex="0"
          role="button"
        >
          {translateText('Try changing the default language.')}
        </span>
      </>
    )
  }
  /**
   *
   * @param {boolean} translatable
   * @param {string} isoLanguage
   * @return {{}|{info: JSX.Element, status: string}}
   */
  const getLanguageStatusInfo = (translatable, isoLanguage) => {
    if (!translatable) {
      return {
        info: popoverInfoMessage(isoLanguage),
        status: statusType.info
      }
    }
    return {}
  }

  /**
   * Returns default language
   * @returns {JSX.Element}
   */
  const getDefaultLanguage = () => {
    const infoMessage =
      <>
        {translateText('Of all the languages installed in your e-commerce platform, the default language is the one defined as the primary language.')}
        &nbsp;
        <span
          onClick={() => setAssistantPage({ page: TRANSLATION_WIZARD.pages.WRONG_DEFAULT_LANGUAGE_HELP, lang: null })}
          onKeyDown={() => null}
          tabIndex="0"
          role="button"
          className={styles.popover_link}
        >
          {translateText('How to modify the default language.')}
        </span>
      </>

    return (
      <>
        <div>
          <h1>{ !isEmpty(defaultLanguages) ? translateIsoCode(defaultLanguages.stats.languageIso) : translateText('There is no default language') }</h1>
          <p className={styles.field_text}>
            { translateText('Default language') }
            <InfoPopover color={statusColor[statusType.info]} direction='bottom' message={infoMessage} />
          </p>
        </div>
      </>
    )
  }

  /**
   * Returns count of installed languages
   *
   * @returns {JSX.Element}
   */
  const getInstalledLanguagesCount = () => {
    const infoMessage =
      <>
        {translateText('The languages installed are those enabled in your e-commerce platform.')}
        &nbsp;
        <FaqLink className={styles.popover_link} id={Faq.articles.INSTALLED_LANGUAGES.id} external>
          {translateText('Learn more about the languages installed.')}
        </FaqLink>
      </>

    return (
      <>
        <div>
          <h1>{ installedLanguages.length + 1 }</h1>
          <p className={styles.field_text}>
            { translateText('Installed languages') }
            <InfoPopover color={statusColor[statusType.info]} direction='bottom' message={infoMessage} />
          </p>
        </div>
      </>
    )
  }

  const onSelectLanguage = (iso) => setSelectedLanguage(iso);

  /**
   * Returns select input with languages
   *
   * @returns {JSX.Element}
   */
  const getLanguageSelect = () => {
    return (
      <div className={styles.card_language_wrapper}>
        {
          !isEmpty(defaultLanguages) &&
          <CardRadioButton
            key={defaultLanguages.stats.languageIso}
            title={translateIsoCode(defaultLanguages.stats.languageIso)}
            value={defaultLanguages.stats.languageIso}
            isChecked={selectedLanguage === defaultLanguages.stats.languageIso}
            onChange={onSelectLanguage}
            size="small"
            className={styles.language_card}
          />
        }
        {
          installedLanguages.map((storeLang,index) => {
            const { languageIso, translatable, languageName } = storeLang
            const translationOfIso = translateIsoCode(languageIso,languageName)
            return (
              <CardRadioButton
                key={generateComponentUuid(index)}
                title={translationOfIso}
                value={languageIso}
                isDisabled={!translatable}
                isChecked={selectedLanguage === languageIso}
                onChange={onSelectLanguage}
                size="small"
                className={styles.language_card}
                {...getLanguageStatusInfo(translatable, translationOfIso)}
              >
                {!translatable &&
                  <p className={styles.not_available}>{translateText('Not available')}</p>
                }
              </CardRadioButton>
            )
            })
          }
        <CardRadioButton
          title={translateText('Don\'t match')}
          value="none"
          isChecked={selectedLanguage === 'none'}
          onChange={onSelectLanguage}
          className={styles.language_card}
        />
      </div>
    )
  }

  /**
   * Returns to what with do with glotio page
   *
   * @returns void
   */
  const goBackPage = () => {
    setAssistantPage({ page: TRANSLATION_WIZARD.pages.QUESTION_WHAT_TO_DO_WITH_GLOTIO, lang: null })
  }

  const onContinue = () => {

    // check if default language matchs with the selected language
    if (!get(defaultLanguages, 'stats.languageIso', false) || selectedLanguage !== defaultLanguages.stats.languageIso) {
      changeDefaultLanguageFaqModal()
    } else {
      const nextPage = {
        OPTION_TRY_GLOTIO: TRANSLATION_WIZARD.pages.LANGUAGE_SELECTOR_FREE, // case 1
        OPTION_TRANSLATE_MULTIPLE_LANGUAGES: TRANSLATION_WIZARD.pages.LANGUAGE_SELECTOR_MULTIPLE, // case 2
        OPTION_UPDATE_SHOP: TRANSLATION_WIZARD.pages.LANGUAGE_SELECTOR_UPDATE_TYPE // case 3
      }

      setAssistantPage({ page: nextPage[wizzardQuestion.selectedQuestion], lang: null });

    }

  }

  const renderButtonBar = () => {
    return (
      <RootPortal>
        <RootPortal.Grid>
          <BackButton pushBack={goBackPage} data="icon" />
          <GlotioButton
            data="button"
            type='button'
            size='large'
            variant='default'
            onClick={onContinue}
            disabled={!selectedLanguage}
          >
            {translateText('Continue')}
          </GlotioButton>
        </RootPortal.Grid>
      </RootPortal>
    )
  }

  return (
    <FirstTranslation>
      <Step
        title={translateText('Verify the source language')}
        subtitle={translateText('It is necessary and important that the language set by default in your platform is the same as the language you use to create the content. The default language is the one Glotio will consider for performing translations, and only then it can function properly.')}
      >
        <div className={styles.resume_container}>
          { getDefaultLanguage() }
          { getInstalledLanguagesCount() }
        </div>
        <div className={styles.select_container}>
          <h1>{ translateText('Of these languages installed in your store, which one do you use to create the content?') }</h1>
          { getLanguageSelect() }
        </div>
        <div className={styles.input_alert}>
          <AlertMessage type={messageTypes.ERROR} text={translateText('We recommend that you use by default the language you use to upload content to your online store. Subsequently, you cannot modify this setting.')} />
        </div>
      </Step>
      <HelpAccordion
        title={translateText('Can\'t see the languages in your online store?')}
        subtitle={translateText('We recommend that you read these articles to try to solve the problem.')}
        allowScroll
        className={styles.help_component}
      >
        <FaqArticlesList
          faqs={[Faq.articles.CHANGE_DEFAULT_LANGUAGE_PS, Faq.articles.SOURCE_LANGUAGE, Faq.articles.ADD_LANGUAGE_CMS]}
        />
      </HelpAccordion>
      { renderButtonBar() }
    </FirstTranslation>
  )
}

export default withRouter(DefaultLanguage)
