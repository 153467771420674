import React, { useState } from 'react'

// hooks
import useGlotio from 'hooks/useGlotio'

// services
import externalUrls from 'services/externalUrls'

// components
import TicketSupportModalFullContent from 'components/GlotioComponents/TicketSupport/TicketSupportModal'

// assets
import { ReactComponent as FaqIcon } from 'assets/images/icons/icon_menu_faq.svg'
import { ReactComponent as Headphones } from 'assets/images/icons/headphones.svg'

// styles
import styles from './index.module.scss'

const HelperFooter = ({ className = '' }) => {

  const { translateText } = useGlotio()

  const [isOpenModal, setIsOpenModal] = useState(false)

  const onCloseModal = () => setIsOpenModal(false)

  const openTechSupportModal = () => setIsOpenModal(true)

  const getCardButton = (icon, name, onClick, id) => {
    return (
      <button type='button' className={styles.card_button} onClick={onClick} id={id}>
        {icon}
        <span>
          {name}
        </span>
      </button>
    )
  }

  return (
    <div className={`${styles.help_auth_container} ${className}`}>
      {getCardButton(<Headphones />,  translateText('Technical support'), openTechSupportModal, 'technical_support')}
      {getCardButton(<FaqIcon />,  'Faq', () => window.open(translateText(externalUrls.FAQ), '_blank'), 'external_faq')}
      <TicketSupportModalFullContent isOpen={isOpenModal} handleClose={onCloseModal} />
    </div>
  )
}

export default HelperFooter
