import { useSelector } from 'react-redux'

// selectors
import { selectProjectPlatform } from 'redux/project/selectors'

// constants
import { PLATFORMS } from 'constants/platforms.contants'


const usePlatform = () => {

  const currentPlatform = useSelector(selectProjectPlatform) 

  const isShopify = () => currentPlatform === PLATFORMS.SHOPIFY
  const isPrestashop = () => currentPlatform === PLATFORMS.PRESTASHOP

  return {
    isShopify,
    isPrestashop
  }
}

export default usePlatform