import React, { useEffect } from 'react'

// components
import Container from 'components/CleanUIComponents/Container'
import GlotioIconButton from 'components/GlotioComponents/GlotioIconButton'

// icons
import {ReactComponent as CircleIcon} from 'assets/images/icons/x-circle.svg'

// styles
import styled from './index.module.scss'

/**
 * @param {boolean} isOpen
 * @param {() => void} handleClose
 * @param {ReactNode} children
 * @param {boolean} closeable
 * @param {boolean} isFullHeight
 * @param {ReactNode} actions
 * @return {JSX.Element}
 */
const ModalFullContent = ({ isOpen, children, handleClose, isFullHeight = false, closeable = true, actions }) => {

  useEffect(() => {
    if (isOpen) {
      document.body.style.setProperty('overflow-y', 'hidden', 'important')
    } else {
      document.body.style.removeProperty('overflow-y')
    }
    return () => {
      document.body.style.removeProperty('overflow-y')
    }
  }, [isOpen])
  return (
    <>
      <div className={`${styled.modal_container} ${(isFullHeight ? styled.isFullHeight : '')}`} style={{ display: isOpen ? 'block' : 'none' }}>
        <Container>
          <div className={styled.modal_content}>
            {children}
            {closeable &&
              <GlotioIconButton
                size='small'
                onClick={handleClose}
                icon={<CircleIcon />}
                className={styled.icon_button_close}
              />
            }
          </div>
        </Container>
        {
          actions && actions
        }
      </div>
    </>
  )
}

export default  ModalFullContent
