import actionTypes from './actions'

const initialState = {
  currentAccount: null,
  fetching: false,
  error: null,
}

const reducer = (state = initialState, { type, payload }) => {
  switch(type) {
    case actionTypes.FETCH_ACCOUNT_START:
      return {
        ...state,
        fetching: true,
        error: null,
      }
    case actionTypes.FETCH_ACCOUNT_SUCCESS:
      return {
        ...state,
        fetching: false,
        currentAccount: payload,
      }
    case actionTypes.FETCH_ACCOUNT_FAIL:
      return {
        ...state,
        fetching: false,
        error: payload,
      }
    case actionTypes.SAVE_ACCOUNT_UPDATES:
      return {
        ...state,
        currentAccount: {
          ...state.currentAccount,
          ...payload,
        }
      }
    default:
      return {
        ...state
      }
  }
}

export default reducer
