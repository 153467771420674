import React from 'react'

// components
import useGlotio from 'hooks/useGlotio'
import { Icon } from 'antd'
import Spinner from 'components/GlotioComponents/Spinner'

// models
import { hasRequestError } from 'models/api/utils'

// styles
import styles from './style.module.scss'

export default function Loading() {
  const { translateText } = useGlotio()

  const synchronizationError = hasRequestError('getProjectById') || hasRequestError('getProcessStatus')

  return (
    <div className={styles.assistant_container}>
      <div className={styles.content_block_step}>
        <div className={styles.spin_block}>
          {!synchronizationError && <Spinner center />}
          {synchronizationError && (
            <>
              <Icon type="warning" className={styles.synchronization_icon_error} />
              <span className={styles.synchronization_text_error}>
                {translateText('Connection error')}
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
