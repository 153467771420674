import React from 'react';

import style from './index.module.scss';

const AuthPromotionBlock = ({title, rightComponent}) => {
  return (
    <div className={style.auth_promotion_block}>
      <div className={style.left_content}>
        <h1 className={style.title}>{title}</h1>
      </div>
      <div className={style.right_content}>
        {rightComponent}
      </div>
    </div>
  )
}

export default AuthPromotionBlock;