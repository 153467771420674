import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Collapse } from 'react-collapse'

// components
import GlotioIconButton from 'components/GlotioComponents/GlotioIconButton'

// assets
import {ReactComponent as ChevronUp} from 'assets/images/icons/chevron-up.svg'
import {ReactComponent as ChevronDown} from 'assets/images/icons/chevron-down.svg'
import {ReactComponent as HelpCircle} from 'assets/images/icons/help-circle.svg'

// styles
import {collapseDelay} from 'assets/styles/mixins.scss'
import styles from './index.module.scss'

const COLLAPSE_DELAY = parseInt(collapseDelay.match(/\d+/)[0], 10)

/**
 *
 * @param {string} title
 * @param {string} subtitle
 * @param {boolean} allowScroll
 * @param {string} className
 * @param {ReactNode} children
 * @return {JSX.Element}
 * @constructor
 */
const HelpAccordion = forwardRef(({ title, subtitle, allowScroll = false, className, children }, ref) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (allowScroll && isOpen) {
      setTimeout(() => {
        window.scrollTo({
          top: document.body.scrollHeight,
          behavior: 'smooth'
        })
      }, [COLLAPSE_DELAY])
    }
  }, [isOpen])

  const closeAccordion = () => setIsOpen(prevState => !prevState)
  const openAccordion = () => setIsOpen(true)

  useImperativeHandle(ref, () => ({
    openAccordion
  }))

  return (
    <div className={`${styles.help_accordion_wrapper} ${className}`}>
      <div
        className={styles.help_accordion_header}
        onClick={isOpen ? closeAccordion : openAccordion}
        onKeyDown={isOpen ? closeAccordion : openAccordion}
        tabIndex="0"
        role="button"
      >
        <HelpCircle className={styles.help_accordion_header_icon} />
        <div className={styles.help_accordion_header_text}>
          <h4>{title}</h4>
          <p>{subtitle}</p>
        </div>
        {isOpen
          ?
            <GlotioIconButton size='small' icon={<ChevronUp />} />
          :
            <GlotioIconButton size='small' icon={<ChevronDown />} />
        }
      </div>
      <Collapse isOpened={isOpen}>
        <div className={styles.help_accordion_body}>
          {children}
        </div>
      </Collapse>
    </div>
  )
})

export default HelpAccordion
