import { createSelector } from 'reselect'

/**
 * Returns the process slice of the redux store
 * @param {Object<string, any>} state The whole redux state
 */
const selectProcess = state => state.process

/**
 * Returns the current process object stored on state
 */
export const selectCurrentProcess = createSelector(
  [selectProcess],
  process => process.currentProcess,
)

/**
 * Returns the current process id
 */
export const selectCurrentProcessId = createSelector(
  [selectProcess],
  process => process.currentProcessID,
)

/**
 * Returns the current process id
 */
export const selectProcessLaunchedFromOrigin = createSelector(
    [selectProcess],
    process => process.processLaunchedFromOrigin,
)

/**
 * Returns
 */
export const selectFetchingNewProcess = createSelector(
  [selectProcess],
  process => process.fetchingNewProcess,
)

/**
 * Returns if the summary should be displayed
 */
export const selectMustShowResume = createSelector(
  [selectProcess],
  process => process.mustShowResume,
)
