const actions = {
  SET_STATE: 'settings/SET_STATE',
  CHANGE_SETTING: 'settings/CHANGE_SETTING',
}

export default actions

/**
 * Updates the current language on the global state
 * @param {string} locale The iso code for the new language
 * @returns {object} 
 */
export const changeCurrentLocale = locale => ({
  type: actions.CHANGE_SETTING,
  payload: {
    setting: 'locale',
    value: locale
  }
})
