import React from 'react';

// utils
import { generateComponentUuid } from 'utils/Utils';

// styles
import styled from './index.module.scss';

/**
 * @typedef {Object} InfoTableColumns
 * @property {string} name
 * @property {Function} selector
 * @property {boolean} highlight
*/

/**
 * Dynamic table builder
 *
 * @param {{
 *   rows: []
 *   InfoTableColumns[]: columns,
 *   hasHeader: boolean,
 *   disabledRow: Function
 * }} props
 */

export const InfoTable = ({rows = [], rowClassName = "" , columns = [], hasHeader = true }) => {

  const alignments = {
    left: styled.alignment_left,
    center: styled.alignment_center,
    right: styled.alignment_right
  }

  return (

    <div className={styled.language_info_table}>
      {hasHeader && 
        <div className={styled.header}>
          {
            columns.map(({ name, headerAlignment = "left"}, index) => (
              <span className={alignments[headerAlignment]} key={generateComponentUuid(index)}>{name}</span>
            ))
          }
        </div>
      }
      {
        (rows).map((row, index) => (
          <div className={`${styled.row} ${rowClassName}`} key={generateComponentUuid(index)}>
            {
              columns.map(({highlight, colAlignment = 'left', ...restProps}, nestedIndex) => (
                <div key={generateComponentUuid(nestedIndex + index)} className={`${alignments[colAlignment]} ${styled.col} ${highlight ? styled.highlight: ''}`}>{restProps.selector(row)}</div>
              ))
            }
          </div>
        ))
      }
    </div>
  )

}