function noRoundDecimals(number, decimals) {
  const factor = 10 ** decimals;
  return Math.trunc(number * factor) / factor;
 }

 function getDecimals(abbreviation) {
   if (abbreviation >= 100) return 0;
   if (abbreviation >= 10) return 1;
   return 2;
 }

 function abbreviateNumber(translateText) {
   const suffixes = {
     1: {
       singular: translateText("thousand"),
       plural: translateText("thousand")
     },
     2: {
       singular: translateText("million"),
       plural: translateText("millions")
     }
   };
   return (number) => {
     let abbreviation;
     let decimals;
     let unit;

     if (number < 1000) {
       return number.toString();
     }

     if (number >= 1000 && number < 1000000) {
       abbreviation = number / 1000;
       decimals = getDecimals(abbreviation);
       abbreviation = Number.isInteger(abbreviation)
         ? abbreviation
         : noRoundDecimals(abbreviation, decimals);
       unit = abbreviation === 1 ? suffixes[1].singular : suffixes[1].plural;
     } else {
       abbreviation = number / 1000000;
       decimals = getDecimals(abbreviation);
       abbreviation = Number.isInteger(abbreviation)
         ? abbreviation
         : noRoundDecimals(abbreviation, decimals);
       unit = abbreviation === 1 ? suffixes[2].singular : suffixes[2].plural;
     }

     return `${abbreviation} ${unit}`;

   }

 }

 export default abbreviateNumber
