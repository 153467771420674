import React, { useEffect } from 'react'
import { connect } from 'react-redux'

// actions
import { fetchAccountStart } from 'redux/account/actions'

// selectors
import { selectCurrentAccount, selectCurrentAccountId } from 'redux/account/selectors'

// components
import CreditCardForm from 'components/GlotioComponents/Stripe/Forms/CreditCardForm'

// styles
import styles from './index.module.scss'
import './stripe.scss'

const PayMethodSelector = props => {
  const { accountId, currentAccount, onCancel, dispatch } = props

  const formStyles = styles.form_container_checkout

  useEffect(() => {
    if (accountId && !currentAccount) {
      dispatch(fetchAccountStart())
    }
  }, [accountId])

  /**
   * Returns the credit card stripe form
   *
   * @returns {JSX.Element}
   */
  const getCreditCardForm = () => {
    return (
      <div className={formStyles}>
        <CreditCardForm onCancel={onCancel} />
      </div>
    )
  }

  return (
    <div className={styles.methods_container}>
      {getCreditCardForm()}
    </div>
  )
}

const mapStateToProps = state => ({
  accountId: selectCurrentAccountId(state),
  currentAccount: selectCurrentAccount(state)
})

export default connect(mapStateToProps)(PayMethodSelector)
