import React, { useState } from 'react'
import { connect } from 'react-redux'
import { Modal } from 'antd'
import { cloneDeep, get } from 'lodash'

// components
import CancelSubscriptionModal from 'components/CleanUIComponents/GenericModal/components/cancelSubscriptionModal'
import TranslationsHistoryModal from 'components/CleanUIComponents/GenericModal/components/translationsHistoryModal'
import ConfirmModal from 'components/CleanUIComponents/GenericModal/components/confirmModal'
import RetrySubscriptionPaymentModal from 'components/CleanUIComponents/GenericModal/components/retrySubscriptionPaymentModal'
import FailRetrySubscriptionPaymentModal from 'components/CleanUIComponents/GenericModal/components/failRetrySubscriptionPaymentModal'
import CookiesModal from 'components/CleanUIComponents/GenericModal/components/cookiesModal'
import CookiesConfigModal from 'components/CleanUIComponents/GenericModal/components/cookiesConfigModal'
import FaqModal from 'components/GlotioComponents/FaqContent'
import FindAndReplaceModal from 'components/CleanUIComponents/GenericModal/components/findAndReplaceModal'
import RenewToolsDaysModal from 'components/CleanUIComponents/GenericModal/components/renewToolsDaysModal'

// actions
import { closeModal, nextModal, openModal } from 'redux/modal/actions'

// constants
import {
  CANCEL_SUBSCRIPTION_MODAL,
  SMALL_WIDTH_MODAL,
  TRANSLATIONS_HISTORY_MODAL,
  CONFIRM_MODAL,
  RETRY_SUBSCRIPTION_PAYMENT_MODAL,
  FAIL_RETRY_SUBSCRIPTION_PAYMENT_MODAL,
  COOKIES_CONFIRM_MODAL,
  COOKIES_CONFIG_MODAL,
  FAQ_MODAL,
  FIND_REPLACE_MODAL,
  RENEW_TOOLS_DAYS_MODAL
} from 'constants/modals.constants'

const HandleModal = ({ message, ...rest }) => {

  switch(message.type) {
    case CANCEL_SUBSCRIPTION_MODAL:
      return <CancelSubscriptionModal {...message} {...rest} />
    case TRANSLATIONS_HISTORY_MODAL:
      return <TranslationsHistoryModal {...message} {...rest} />
    case CONFIRM_MODAL:
      return <ConfirmModal {...message} {...rest} />
    case RETRY_SUBSCRIPTION_PAYMENT_MODAL:
      return <RetrySubscriptionPaymentModal {...message} {...rest} />
    case FAIL_RETRY_SUBSCRIPTION_PAYMENT_MODAL:
      return <FailRetrySubscriptionPaymentModal {...message} {...rest} />
    case COOKIES_CONFIRM_MODAL:
      return <CookiesModal {...message} {...rest} />
    case COOKIES_CONFIG_MODAL:
      return <CookiesConfigModal {...message} {...rest} />
    case FAQ_MODAL:
      return <FaqModal {...message} {...rest} />
    case FIND_REPLACE_MODAL:
      return <FindAndReplaceModal {...message} {...rest} />
    case RENEW_TOOLS_DAYS_MODAL:
      return <RenewToolsDaysModal {...message} {...rest}  />
    default:
      return null
  }

}

const GenericModal = (props) => {
  const { open, closeModal:onClose, openModal:onOpen, nextModal: onNext, queue } = props;
  const [ modalWidth, setModalWidth ] = useState(SMALL_WIDTH_MODAL);
  const defaultStyle = { paddingTop: '24px' }
  const [currentMessage] = queue
  const modalId = get(currentMessage, 'id', undefined)

  const onCloseModalById = () => {
    onClose(modalId)
  }

  const onNextModal = modal => {
    onNext({ id: modalId, ...modal })
  }

  return open && (
    <Modal
      visible={open}
      footer={null}
      onCancel={() => {
        if (typeof get(currentMessage, 'onCancel', false) === 'function') {
          currentMessage.onCancel();
        }
        onCloseModalById()
      }
      }
      closable={get(currentMessage, 'closable', true)}
      width={get(currentMessage, 'modalWidth', modalWidth)}
      maskClosable={get(currentMessage, 'maskClosable', true)}
      keyboard={false}
      zIndex={get(currentMessage, 'zIndex', undefined)}
      style={{...defaultStyle, ...get(props, 'style', {})}}
      centered
    >
      <HandleModal
        {...props}
        onClose={onCloseModalById}
        onOpen={onOpen}
        onNext={onNextModal}
        setModalWidth={setModalWidth}
        message={currentMessage}
      />
    </Modal>
  )
}

const mapStateToProps = state => cloneDeep(state.modal);

export default connect(mapStateToProps, { closeModal, openModal, nextModal })(GenericModal);
