import React from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'

// components
import WelcomeAuthActionsHeader from 'components/LayoutComponents/WelcomeHeader/welcomeAuthActionsHeader'
import WelcomeProjectInfoHeader from 'components/LayoutComponents/WelcomeHeader/welcomeProjectInfoHeader'
import WelcomeAvatarHeader from 'components/LayoutComponents/WelcomeHeader/welcomeAvatarHeader'
import LoginModal from 'components/GlotioComponents/LoginModal'

// selectors
import { selectCurrentUser, selectIsFirstSession } from 'redux/user/selectors'
import { selectConfirmedSubscriptionPlan } from 'redux/subscription/selectors'
import { logout } from 'redux/user/actions'

// assets
import logoImg from 'assets/images/logos/glotio_logo_purple.svg'

// hooks
import { useWelcome } from 'hooks/useWelcome'

// styles
import styles from './index.module.scss'



const WelcomeHeader = ({
  currentUser,
  isFirstSession,
  hasConfirmedSubscriptionPlan,
  logoutUser
}) =>  {
  const { currentProject, isOpenLoginModal, onOpenLoginModal, onCloseLoginModal, getUrlSignUp, canShowLoginButton, canShowRegisterButton } = useWelcome()
  const projectName = get(currentProject,'project_name', '')
  const projectUrl = get(currentProject,'url')
  const isRestrictedMenu = !isFirstSession && hasConfirmedSubscriptionPlan
  const urlSignUp = getUrlSignUp()

  return (
    <div className={`${styles.welcome_header} row`}>
      <div className={styles.logo_wrapper}>
        <picture>
          <img src={logoImg} alt="Glotio logo" />
        </picture>
      </div>
      <WelcomeProjectInfoHeader
        projectName={projectName}
        projectUrl={projectUrl}
      />
      <WelcomeAuthActionsHeader
        canShowRegisterButton={canShowRegisterButton}
        canShowLoginButton={canShowLoginButton}
        urlSignUp={urlSignUp}
        onOpenLoginModal={onOpenLoginModal}
      />
      <WelcomeAvatarHeader userName={get(currentUser,'name', '')} isRestrictedMenu={isRestrictedMenu} logoutUser={logoutUser} projectUrl={projectUrl} />
      <LoginModal isOpen={isOpenLoginModal} handleClose={onCloseLoginModal} />
    </div>
  )
}

const mapStateToProps = (state) => ({
  currentUser: selectCurrentUser(state),
  isFirstSession: selectIsFirstSession(state),
  hasConfirmedSubscriptionPlan: selectConfirmedSubscriptionPlan(state),
})

const mapDispatchToProps = (dispatch) => ({
  logoutUser: (data) => dispatch(logout(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeHeader)
