import React from 'react'
import styles from './index.module.scss'

const Checkbox = ({ value, checked, name, onChange, isDisabled = false, size = "" }) => {
  return (
    <div className={`${styles.checkbox_container} ${size === 'small' ? styles.small : ''}`}>
      <input
        id={value}
        className={styles.checkbox}
        type='checkbox'
        value={value}
        checked={checked}
        name={name}
        onChange={(e) => onChange(e.target.checked)}
        disabled={isDisabled}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <label htmlFor={value} />
    </div>
  )
}

export default Checkbox
