import { sendSubscriptionCancelEvent } from 'utils/TrackerEvents'
import { cancelSubscriptionRequest } from 'models/api/subscription'

/**
 * Get requested data and set the different status
 * @param {function} endpoint
 * @param {function} setValue
 * @param {function} setLoading
 * @param {function} setError
 * @param {number} projectId
 * @return
 */
export const getRequestData = async (endpoint, setValue, setLoading, setError, projectId, params = {}) => {
  try {
    const { error, result } = await endpoint({projectId, ...params})
    if (error) {
      setError(true)
    } else {
      setValue(result)
    }
  } catch (err) {
    setError(true)
  } finally {
    setLoading(false)
  }
}

export const onCancelSubscription = async ({ projectId, payload, currentSubscription, onSuccess, onError }) => {
  try {
    const { error } = await cancelSubscriptionRequest({
      projectId,
      params: payload,
    });
    if (!error) {
      sendSubscriptionCancelEvent(currentSubscription, payload)
      onSuccess()
    } else {
      onError()
    }
  } catch (error) {
    onError()
  }
}
