import React from 'react'
import { FormattedMessage } from 'react-intl'
import useGlotio from 'hooks/useGlotio'
import { PLATFORMS } from 'constants/platforms.contants'
import { META_CATEGORIES_SHOPIFY } from 'constants/meta_categories_shopify.contants'

/**
 * Transforms the meta_categories_tree array of a text to a translated chain of texts with a separator
 * @param {Object[]} source An array with meta source object
 * @param {String} separator A text that will be between every tree term
 * @returns {JSX.Element[]} Ej. db_tables > db_catalog > product
 */
export const translateMetaCategoriesSource = (source, separator, platform = PLATFORMS.PRESTASHOP ) => {
  const { translateText } = useGlotio()
  const translatedText = []

  if (source.subtype === 'taxonomy' || source.subtype === 'post_type') {
    return getTaxonomyOrPostTypeTranslation(source, separator)
  }

  if(source.type === 'theme') {
    return getThemeTranslation(source, separator)
  }

  if(source.type === 'theme_module') {
    return getThemeModuleTranslation(source, separator)
  }

  if(source.type === 'database_module') {
    return getDatabaseModuleTranslation(source, separator)
  }

  if(source.type === 'module') {
    return getModuleTranslation(source)
  }


  translatedText.push(translateText(parseMetaCategoriesByPlatform(source.subtype, platform)))
  return translatedText
}

const parseMetaCategoriesByPlatform = (subType, platform) => {
  if (platform === PLATFORMS.SHOPIFY) {
    return META_CATEGORIES_SHOPIFY[subType] ? META_CATEGORIES_SHOPIFY[subType] : `platform.fields.${subType}`
  }

  return `platform.fields.${subType}`
}

/**
 * Build the translation of taxonomy and post types for advanced settings
 * @param {Object[]} source An array with meta source object
 * @param {String} separator A text that will be between every tree term
 * @returns {JSX.Element[]} Ej. post_type > pages
 */
const getTaxonomyOrPostTypeTranslation = (source, separator) => {
  const { translateText } = useGlotio()
  const translation = []
  translation.push(source.taxonomy ? translateText('platform.fields.taxonomy') : translateText('platform.fields.post_type'))
  translation.push(separator || ', ')
  translation.push(source.taxonomy || source.post_type)
  return translation
}

/**
 * Build the translation of themes for advanced settings
 * @param {Object[]} source An array with meta source object
 * @param {String} separator A text that will be between every tree term
 * @returns {JSX.Element[]} Ej. themes > classic > templates
 */
const getThemeTranslation = (source, separator) => {
  const { translateText } = useGlotio()
  const translation = []
  translation.push(translateText(`platform.fields.themes`))
  translation.push(separator || ', ')
  translation.push(source.theme)
  return translation
}

/**
 * Build the translation of theme modules for advanced settings
 * @param {Object[]} source An array with meta source object
 * @param {String} separator A text that will be between every tree term
 * @returns {JSX.Element[]} Ej. themes > classic > theme modules > bankwire
 */
const getThemeModuleTranslation = (source, separator) => {
  const { translateText } = useGlotio()
  const translation = []
  translation.push(translateText(`platform.fields.themes`))
  translation.push(separator || ', ')
  translation.push(source.theme)
  translation.push(separator || ', ')
  translation.push(translateText(`platform.fields.theme_module`))
  translation.push(separator || ', ')
  translation.push(source.module)
  return translation
}

/**
 * Build the translation of database_modules for advanced settings
 * @param {Object[]} source An array with meta source object
 * @param {String} separator A text that will be between every tree term
 * @returns {JSX.Element[]} Ej. db_table > tables > db_module
 */
const getDatabaseModuleTranslation = (source, separator) => {
  const { translateText } = useGlotio()
  const translation = []
  translation.push(source.module)
  translation.push(separator || ', ')
  translation.push(translateText('platform.fields.db_tables'))
  translation.push(separator || ', ')
  translation.push(source.subtype)
  return translation
}

/**
 * Build the translation of modules and database_modules for advanced settings
 * @param {Object[]} source An array with meta source object
 * @returns {JSX.Element[]} Ej. db_module
 */
const getModuleTranslation = (source) => {
  return [source.module]
}

/**
 * Returns the field of a translation object with each portion translated an separated with a given text
 * @param {String} field The field returned by Elastic search with the values separated by |
 * @param {String} separator Any text that will be between every term of the field
 * @param {string} id The id field returned by elastic search
 * @returns {JSX.Element[]} Ej. product > link_rewrite
 */
export const translateTextField = (field, separator, id) => {
  const fieldsArray = field.split('|')
  const translatedField = fieldsArray.map((slug, index) => {
    const objectId = id ? getObjectId(id) : null

    return index >= fieldsArray.length - 2 ? (
      <span key={`${field}-${Math.random() * Date.now()}-${id}`}>
        <FormattedMessage id={slug} />
        {index !== fieldsArray.length - 1 ? (
          <>
            <span hidden={!objectId}>{` (${objectId}) `}</span>
            <span>{separator}</span>
          </>
        ) : null}
      </span>
    ) : null
  })

  return translatedField
}

/**
 * Gets the id number from the id field passed by elasticseach
 * @param {string} idField The id field generated by elasticsearch
 * @return {number} The id number fo the object
 */
const getObjectId = (idField) => {
  const fieldArray = idField.split('|').filter((item) => !Number.isNaN(+item))
  return fieldArray[0]
}

export default {
  translateTextField,
  translateMetaCategoriesSource
}
