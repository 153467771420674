import React from 'react'
import style from './style.module.scss'

class RadioButton extends React.Component {
  render () {
    const { onChange, name, value, checked, type = 'radio', isDisabled = false, square = false, size = "" } = this.props

    return (
      <div className={`${style.radio_button_container} ${size === 'small' ? style.small : ''} ${square ? style.square : ``}`}>
        <input
          id={value}
          className={style.radio_button}
          type={type}
          value={value}
          checked={checked}
          name={name}
          onChange={(e) => onChange(e.target.value)}
          disabled={isDisabled}
        />
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label htmlFor={value} />
      </div>
    )
  }
}

export default RadioButton
