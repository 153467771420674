import React, { useEffect } from 'react'

// hoc
import { connect, useDispatch, useSelector } from 'react-redux'

// hooks
import useGlotio from 'hooks/useGlotio'

// actions
import { clearAssistantPage } from 'redux/wizard/actions'
import { selectIsFirstSession } from 'redux/user/selectors'

// utils
import Process from 'utils/Process'

// selectors
import { selectCurrentProcess } from 'redux/process/selectors'

// components
import FirstTranslation from 'layouts/FirstTranslation'
import Step from 'components/GlotioComponents/Step'
import FaqCarousel from 'components/CleanUIComponents/FaqCarousel'
import GlobalStatus, { GlobalStatusBar } from 'components/GlotioComponents/GlobalStatusBar'
import Spinner from 'components/GlotioComponents/Spinner'
import AlertMessage from 'components/GlotioComponents/AlertMessage'
import { messageTypes } from 'components/GlotioComponents/AlertMessage/constants'
import ProcessingMessage from './processing-message'

// styles
import styles from './style.module.scss'

const Processing = ({isFirstSession}) => {
  const { translateText } = useGlotio()
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      const currentMode = GlobalStatusBar.getGlobalStatusMode(processStatus)
      if (currentMode === 'download' && isFirstSession) {
        dispatch(clearAssistantPage())
      }
    }
  },[])

  const processStatus = useSelector(selectCurrentProcess)

  // destructure the Process static methods
  const { hasProcessFinished, isProcessUpdating } = Process

  // when a running process with a not defined subProcess is received the spinner is shown
  if (processStatus && !processStatus.currentSubProcess && !hasProcessFinished(processStatus))
    return (
      <div style={{ textAlign: 'center' }}>
        <Spinner center />
      </div>
    )

  /**
   * Defines the title of the current assistant step
   * @var {string} title
   */
  let title = 'Processing'
  const currentMode = GlobalStatusBar.getGlobalStatusMode(processStatus)
  if (isProcessUpdating(processStatus)) {
    title = translateText("Updating languages")
  } else if (currentMode === 'download') {
    title = translateText('Searching for translatable content')
  } else if (currentMode === 'translation') {
    title = translateText('Translating your shop')
  }

  // TODO removed {isProcessUpdating(processStatus) ? null : <GlobalStatus />} for automatic updates, for the moment works
  return (
    <FirstTranslation className={styles.process_layout}>
      <Step
        title={title}
        subtitle={
          <>
            <ProcessingMessage isTranslating={Process.isProcessTranslating(processStatus)} />
            <div>
              <GlobalStatus />
            </div>
          </>
        }
      />
      <div className={styles.warning_message}>
        <div className={styles.warning_container}>
          <AlertMessage type={messageTypes.ERROR} text={translateText('Do not add content or make changes to the website during the translation process.')} background />
        </div>
      </div>
      <Step className={styles.carousel_wrapper} noPadding>
        <div>
          <FaqCarousel />
        </div>
      </Step>
    </FirstTranslation>
  )
}

const mapStateToProps = (state) => ({
  isFirstSession: selectIsFirstSession(state),
})

export default connect(mapStateToProps, {})(Processing)
