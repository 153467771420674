import { call, put, all, takeLatest, takeEvery, delay, select } from 'redux-saga/effects'
import { isEmpty } from 'lodash'

// models
import { getLanguagesRequest } from 'models/api/project'

// actions
import actionTypes, {
  fetchLanguagesStart,
  fetchLanguagesSuccess,
  fetchLanguagesFail
} from './actions'

import userActionTypes, { updateFirstSession } from '../user/actions'

// selectors
import { selectProjectId } from '../project/selectors'
import { selectConfigLanguages } from './selectors'

/**
 * Handles async call for user langs and
 * updates the store with the received langs
 */

function* fetchLanguages() {
  const projectId = yield select(selectProjectId)

  const { result, error } = yield getLanguagesRequest({ projectId})
  if (Array.isArray(result) && isEmpty(result)) {
    const time = 4;
    yield delay(time * 1000);
    yield put(fetchLanguagesStart());
  } else if (error) {
    yield put(fetchLanguagesFail('No languages found.'));
  } else {
    yield put(fetchLanguagesSuccess(result));
  }
  yield call(checkFirstTranslation)
}


/**
 * Checks if the user has made a translation to show the minimized dashboard version
 */
function* checkFirstTranslation() {

  const configLangs = yield select(selectConfigLanguages)

  const hasUserTranslated = Array.isArray(configLangs)
    ? configLangs.some((lang) => lang.translate)
    : false

  yield put(updateFirstSession(!hasUserTranslated))
}

// Action Listeners
function* onFetchLanguages() {
  yield takeLatest(actionTypes.FETCH_LANGUAGES_START, fetchLanguages)
}

function* onCheckIfUserFirstSession() {
  yield takeLatest(userActionTypes.CHECK_IF_FIRST_USER_SESSION, checkFirstTranslation)
  yield takeEvery(actionTypes.FETCH_LANGUAGES_FAIL, checkFirstTranslation)
}

export default function* languajeSaga() {
  yield all([
    call(onFetchLanguages),
    call(onCheckIfUserFirstSession),
  ])
}
