import React from 'react'

// assets
import AmericanExpress from 'assets/images/card-logos/americanexpress.svg'
import DinersClub from 'assets/images/card-logos/dinersclub.svg'
import Discover from 'assets/images/card-logos/discover.svg'
import JCB from 'assets/images/card-logos/jcb.svg'
import Mastercard from 'assets/images/card-logos/mastercard.svg'
import Unknown from 'assets/images/card-logos/unknown.svg'
import Visa from 'assets/images/card-logos/visa.svg'

export default function CreditCardLogo({ brand, cardSize }) {
  /**
   * Maps the stripe card brand responses to the corresponding logo source
   */
  const cardLogos = {
    'american express': AmericanExpress,
    'diners club': DinersClub,
    discover: Discover,
    jcb: JCB,
    mastercard: Mastercard,
    unionpay: Unknown,
    visa: Visa,
    unknown: Unknown,
  }

  /**
   * Returns the corresponding logo source for the account card
   * if the card brand is not recognized by Stripe an unknown logo will
   * be shown by default
   *
   * @param {string} storedBrand The account stored card brand
   * @returns {string} The generated path url to the logo file
   */
  const getCreditCardLogo = (storedBrand) => {
    const lowerBrand = storedBrand.toLocaleLowerCase()
    const hasValidLogo = Object.keys(cardLogos).includes(lowerBrand)

    if (hasValidLogo) {
      return cardLogos[lowerBrand]
    }

    return Unknown
  }

  return (
    <img
      style={{ height: cardSize || 25 }}
      src={getCreditCardLogo(brand)}
      alt={`${brand} card logo`}
    />
  )
}
