import React from 'react'

// components
import Container from 'components/CleanUIComponents/Container'
import Spinner from 'components/GlotioComponents/Spinner'
import NoConnectionError from 'components/GlotioComponents/ShowError/NoConnectionError'

// styles
import styles from './index.module.scss'

const Loading = ({ isError, isLoading }) => {

  const getErrorContent = () => {
    return (
      <Container>
        <NoConnectionError />
      </Container>
    )
  }
  return (
    <div className={styles.assistantContainer}>
      <div className={`${styles.stepContentBlock} ${!isError && styles.center}`}>
        <div className={styles.spin}>
          {isError && getErrorContent()}
          {!isError && isLoading && <Spinner center />}
        </div>
      </div>
    </div>
  )
}

export default Loading
