import React from 'react'

// components
import RadioButton from 'components/CleanUIComponents/RadioButton'
import InfoPopover from 'components/CleanUIComponents/Popover/InfoPopover'

// constants
import { statusColor, statusType } from 'constants/status.constants'

// styles
import css from './style.module.scss'


class CardRadioButton extends React.Component {
  render () {
    const {
      title,
      value,
      isChecked,
      onChange,
      badge,
      isDisabled = false,
      isOtherSelected = false,
      square = false,
      children = false,
      size = "",
      info = '',
      status = statusType.info,
      className = ''
    } = this.props
    return (
      <label htmlFor={value}>
        <div className={`${css.card_radio_button_container} ${className} ${isChecked ? css.active : ''} ${(isOtherSelected || isDisabled ) ? css.disabled : ''}`}>
          {
            badge &&
              <span className={css.badge}>{badge}</span>
          }
          <div className={css.input}>
            <RadioButton value={value} checked={isChecked} onChange={onChange} type='checkbox' isDisabled={isDisabled} square={square} size={size} />
          </div>
          <div className={css.title}>
            <p>{ title }</p>
            { children }
          </div>
          {info &&
            <InfoPopover
              message={info}
              color={statusColor[status]}
              direction='right'
            />
          }
        </div>
      </label>
    )
  }
}

export default CardRadioButton
