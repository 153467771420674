import activeLangs from 'services/languages-list'
import { DEFAULT_LANGUAGE } from 'constants/default_language'
import { updateUserByIdRequest } from 'models/api/user'

export const buildRandomId = () => {
    const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let autoId = ''
    for (let i = 0; i < 20; i += 1) {
      autoId += chars.charAt(Math.floor(Math.random() * chars.length))
    }
    return autoId
  }

  /**
   * Transforms an amount into the percentage used
   * @param {number} total
   * @param {number} quantity
   * @return {number}
   */
  export const getPercentageForQuantity = (total, quantity) => {
    if (total === 0) {
      return 0
    }
    return Math.trunc((quantity * 100) / total)
  }

  /**
   *
   * @param {number} min
   * @param {number} max
   * @param {number} value
   * @return {boolean}
   */
  export const isBetweenRange = (min,max,value) => {
    return value >= min && value <= max
  }

  /**
 * Generate an id for array of html components based in the index
 * @param {number} index
 * @return {string}
 */
  export const generateComponentUuid = (index) => {
    return `${index}-component-${(index*20)/12}`
  }

/**
 * Take the default language of the browser if not English
 * @return {string}
 */
  export const getDefaultLanguageByBrowser = () => {
    let browserLang = navigator.language || navigator.userLanguage

    if (browserLang && typeof browserLang === 'string') {
      browserLang = browserLang.slice(0, 2).toLowerCase()
    }

    const isBrowserLangEnabled = activeLangs.some(lang => lang.value === browserLang)
    return isBrowserLangEnabled ? browserLang : DEFAULT_LANGUAGE
  }

  /**
 * Take the location pathname and return the base path without params
 * @param {string} pathname
 * @return {string}
 */
  export const getBasePath = pathname => {
    const basePathExtracted = pathname.split("/")[1];
    const basePath = basePathExtracted.split("/")[0];
    return `/${basePath}`
  }

  /**
   * This function splits a string by a specified separator and returns the last element from the resulting array.
   * @param {string} stringToSplit
   * @param {string} separator
   * @return {string}
   */
   export const getFinalSegment = (stringToSplit, separator = '.') => {
    if (typeof stringToSplit !== 'string') {
      return stringToSplit
    }

    const splittedString = stringToSplit.split(separator);

    return splittedString.pop();
  }

  /**
   * This function remove all the white spaces, trim js function only clean the side white spaces
   * @param {string} stringToSplit
   * @param {string} separator
   * @return {string}
   */
  export const removeWhiteSpaces = input => input.replace(/\s+/g, '');

export const isSbIframe = () => {
  const isIframe = window.location !== window.parent.location
  const parentLocation = document.referrer || ''
  return isIframe && parentLocation.includes('my.local-sellboost.dev')
}

export const isIframeAndNotSbIframe = () => {
  const isIframe = window.location !== window.parent.location
  return isIframe && !isSbIframe()
}

export const changeLocalLanguage = async (iso, props) => {
  const { saveLocaleChange, userId, fetchUser, updateLanguageProject } = props
  saveLocaleChange(iso)
  updateLanguageProject({user_lang: iso})
  localStorage.setItem("app.settings.locale", JSON.stringify(iso))

  if (userId) {
    const { error } = await updateUserByIdRequest({ userId, params: { lang: iso } })

    if (!error) {
      fetchUser(userId)
    }
  }
}
