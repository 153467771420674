import Request from 'models/Request'

export const getCurrentSubscriptionRequest = ({ projectId, callback }) =>
  Request.fetch({
    key: 'getCurrentSubscription',
    url: `/api/v2/project/${projectId}/subscription`,
    method: 'GET',
    callback,
  })

export const getSubscriptionOffersRequest = ({ projectId, coupon, callback }) =>
  Request.fetch({
    key: 'getSubscriptionOffers',
    url: `/api/v2/project/${projectId}/subscription/offers`,
    method: 'GET',
    params: {
      voucherCode: coupon
    },
    callback,
  })

export const updateCurrentSubscriptionRequest = ({ subscriptionPlanId, period, totalToPay, voucherCode, projectId, callback }) =>
  Request.fetch({
    key: 'updateCurrentSubscription',
    url: `/api/v2/project/${projectId}/subscription`,
    method: 'PUT',
    params: {
      subscriptionPlanId,
      period,
      totalToPay,
      voucherCode
    },
    callback,
  })

export const selectAnySubscriptionRequest = ({ projectId, callback }) =>
  Request.fetch({
    key: 'selectAnySubscription',
    url: `/api/v2/project/${projectId}/subscription/confirm`,
    method: 'PUT',
    callback
  })

export const subscriptionCorporateProposalRequest = ({ projectId, params, callback }) =>
  Request.fetch({
    key: 'subscriptionCorporateProposalRequest',
    url: `/api/v2/project/${projectId}/subscription-plan-proposal/request`,
    method: 'POST',
    params,
    callback,
  })

export const cancelSubscriptionRequest = ({ projectId, params, callback }) =>
  Request.fetch({
    key: 'cancelSubscription',
    url: `/api/v2/project/${projectId}/subscription/cancel`,
    method: 'PUT',
    params,
    callback,
  })

export const undoCanceledSubscriptionRequest = ({ projectId, params, callback }) =>
  Request.fetch({
    key: 'undoCancelSubscription',
    url: `/api/v2/project/${projectId}/subscription/undo-cancel`,
    method: 'PUT',
    params,
    callback,
  })

export const cancelSubscriptionCorporateProposedRequest = ({ projectId, proposalId, params, callback }) =>
  Request.fetch({
    key: 'cancelSubscriptionCorporateProposedRequest',
    url: `/api/v2/project/${projectId}/subscription-plan-proposal/${proposalId}/cancel`,
    method: 'PUT',
    params,
    callback,
  })

export const renewAccessTollsDays = ({ projectId, callback }) =>
  Request.fetch({
    key: 'renewAccessTollsDays',
    url: `/api/v2/project/${projectId}/subscription/start-new-trial`,
    method: 'PUT',
    callback
  })

