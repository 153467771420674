import React, { useState } from 'react';

// hooks
import useGlotio from 'hooks/useGlotio'
import useSortedLanguages from 'hooks/useSortedLanguages';

// components
import GlotioButton from 'components/GlotioComponents/GlotioButton'
import CardRadioButton from 'components/CleanUIComponents/RadioButton/CardRadioButton'

// constants
import { statusType } from 'constants/status.constants'
import { DISCOUNT_LANGUAGE_OFFER } from 'constants/language_offer.constants';

// styles
import styles from './index.module.scss'


/**
 * Show the languages to add, included see more languages logic and sorted languages by relevance
 * @param {Offer} languages
 * @param {Array} selectedLanguages
 * @param {Function} handleToggleLanguages
 * @param {Number} offset
 * @param {string} title
 * @return {JSX.Element}
 */

const AddLanguages = ({
  languages = [],
  selectedLanguages = [],
  handleToggleLanguages,
  title = "Select the languages you want to add to your online shop"
}) => {

  const [showingAll, setShowingAll] = useState(() => lazyShowingAllInitilize());
  const { translateText, translateIsoCode } = useGlotio()

  const priorLanguagesLength = getInstalledAndFeaturedLanguagesLength()
  const sortedLanguages = useSortedLanguages(languages, priorLanguagesLength, showingAll)
  const isMaxOffsetSelected = sortedLanguages.some(({lastPriority, languageIso}) => lastPriority && selectedLanguages.includes(languageIso));
  const onSeeMoreLanguages = () => setShowingAll((prevStatus) => !prevStatus)
  const hasOffset = priorLanguagesLength > 0 && languages.length > priorLanguagesLength;

  const getLanguageStatusLabel = (translatable, installed) => {
    if (!translatable) {
      return <p className={styles.not_available}>{ translateText('Not available') }</p>
    }
    if (installed) {
      return <p className={styles.not_available}>{ translateText('Installed') }</p>
    }

    return null
  }

  function getInstalledAndFeaturedLanguagesLength () {
    return languages.filter((lang) => (lang.translatable && lang.installed) || (!lang.installed && lang.featured && lang.translatable)).length
  }

  function lazyShowingAllInitilize() {
    return getInstalledAndFeaturedLanguagesLength() === 0
  }

  const getLanguageStatusInfo = (translatable, installed) => {

    if (!translatable) {
      return {
        info: translateText('This language cannot be translated with Glotio. See the list of translatable languages.'),
        status: statusType.info
      }
    }

    if (installed) {
      return {
        info: translateText('This language is installed in your online store but is not integrated in Glotio. If you select it, it will be completely translated again, so you will lose the previous translations.'),
        status: statusType.warning
      }
    }

    return {}

  }

  const getDiscountLabel = (discount, selected) => {
    if (discount && Object.keys(discount).length > 0 && selected) {
      if (discount.type ===  DISCOUNT_LANGUAGE_OFFER.FIRST_LANGUAGE_FREE) {
        return translateText('Free')
      }
      if (discount.type === DISCOUNT_LANGUAGE_OFFER.FIRST_LANGUAGE_DISCOUNT) {
        return translateText('Promo')
      }
      return `${discount.percent}%`
    }
    return null
  }

  return (
    <div className={styles.languages_container}>
      <h1>{translateText(title)}</h1>
      <div className={styles.languages}>
        {
          sortedLanguages.map(({ languageIso, languageName, translatable, installed, discount = null, selected }) =>
            <CardRadioButton
              key={languageIso}
              title={translateIsoCode(languageIso, languageName)}
              value={languageIso}
              isChecked={selectedLanguages.includes(languageIso)}
              onChange={handleToggleLanguages}
              badge={getDiscountLabel(discount, selected)}
              isDisabled={!translatable}
              size="small"
              className={styles.cardRadioContainer}
              {...getLanguageStatusInfo(translatable, installed)}
            >
              {getLanguageStatusLabel(translatable, installed)}
            </CardRadioButton>
          )
        }
        {hasOffset &&
          <GlotioButton
            type='button'
            size='large'
            variant='outlined'
            onClick={onSeeMoreLanguages}
            disabled={isMaxOffsetSelected}
          >
            {showingAll ? translateText('Hide languages') : translateText('See more languages')}
          </GlotioButton>
        }
      </div>
    </div>
  )
}

export default AddLanguages
