import { removeWhiteSpaces } from "utils/Utils"

export const parsePhoneValue = (values, key) => {
  const { [key] : { pcode = '', pnumber = '' } } = values
  if (pcode && pnumber)  {
    const trimNumber = removeWhiteSpaces(pnumber)
    return {
      [key]: `+${pcode.split('#')[1]} ${trimNumber}`
    }
  }
  return {
    [key]: ''
  }
}