import { SHOW_LEFT_MENU, HIDE_LEFT_MENU } from "./actions";

const initialState = {
  visible: true
}

export default function modalReducer(state = initialState, action ) {
  switch (action.type) {
    case SHOW_LEFT_MENU:
    case HIDE_LEFT_MENU:
      return { ...action.payload }
    default:
      return state
  }

}