import { errorColor, successColor, warningColor, disabled, purpleDark } from 'assets/styles/mixins.scss'

export const statusType = {
  warning: 'WARNING',
  error: 'ERROR',
  success: 'SUCCESS',
  disabled: 'DISABLED',
  info: 'INFO',
  none: null
}

export const statusColor = {
  [statusType.warning]: warningColor,
  [statusType.error]: errorColor,
  [statusType.success]: successColor,
  [statusType.disabled]: disabled,
  [statusType.info]: purpleDark,
  [statusType.none]: 'transparent'
}
