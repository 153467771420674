import { get } from 'lodash'

const currencySymbol = {
  es: {
    EUR: (price) => `${price} €`,
    USD: (price) => `${price} $`
  },
  en: {
    EUR: (price) => `€${price}`,
    USD: (price) => `$${price}`
  },
  de: {
    EUR: (price) => `${price} €`,
    USD: (price) => `${price} $`
  },
  it: {
    EUR: (price) => `${price} €`,
    USD: (price) => `${price} $`
  },
  fr: {
    EUR: (price) => `${price} €`,
    USD: (price) => `${price} $`
  },
  pl: {
    EUR: (price) => `${price} €`,
    USD: (price) => `${price} $`
  },
  pt: {
    EUR: (price) => `€ ${price}`,
    USD: (price) => `$ ${price}`
  },
  nl: {
    EUR: (price) => `€ ${price}`,
    USD: (price) => `$ ${price}`
  }
}

const getDefaultSymbol = (amount, currency) => {
  if (currency) {
    return get(currencySymbol, `es.${currency}`, () => amount)(amount)
  }
  return amount
}

export const getPriceWithSymbol = (amount, locale, currency) => {
  return get(currencySymbol, `${locale}.${currency}`, () => getDefaultSymbol(amount, currency))(amount)
}
