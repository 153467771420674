import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Icon, Spin } from 'antd'

// selectors
import { selectCurrentProcess, selectCurrentProcessId } from 'redux/process/selectors';
import { selectCurrentProject } from 'redux/project/selectors';

// hooks
import useGlotio from 'hooks/useGlotio'

// components
import GlotioButton from 'components/GlotioComponents/GlotioButton'

// utils
import ProcessUtil from 'utils/Process'


// styles
import styles from './index.module.scss';

const FindAndReplaceModal = ({onClose, onOpenTicketSupportModal}) => {
  const currentProcess = useSelector(selectCurrentProcess)
  const processId = useSelector(selectCurrentProcessId)
  const currentProject = useSelector(selectCurrentProject)
  const { translateText } = useGlotio()
  const [currentStep, setCurrentStep] = useState(null)

  const abortedStepButtons = [
    {
      text: translateText('Contact support'),
      options: {
        type: 'button',
        variant: 'outlined',
        size: 'large',
        className: styles.modal_button
      },
      callback: () => {
        onClose()
        onOpenTicketSupportModal()
      }
    },
    {
      text: translateText('Accept'),
      options: {
        type: 'button',
        variant: 'default',
        size: 'large',
        className: styles.modal_button
      },
      callback: onClose
    },
  ]

  const finishStepButtons = [
    {
      text: translateText('Accept'),
      options: {
        type: 'button',
        variant: 'default',
        size: 'large',
        className: styles.modal_button
      },
      callback: onClose
    }
  ]


  const processSteps = {
    'replacing': {
      title: 'Replacing text in progress.',
      description: 'Please wait while the text replacement completes. Do not close or refresh the window.',
      loading: true,
      buttons: null
    },
    'aborted': {
      title: 'The process has been aborted.',
      description: 'An error occurred during text replacement. Please try again or contact support.',
      loading: false,
      buttons: abortedStepButtons
    },
    'finished': {
      title: 'Replacement of completed text.',
      description: 'The process has been successfully completed and the text has been replaced.',
      loading: false,
      buttons: finishStepButtons
    }
  }

  useEffect(() => {
    if (ProcessUtil.isProcessReplacing(currentProcess)) {
      setCurrentStep('replacing')
      if (ProcessUtil.hasProcessAborted(currentProcess)) {
        setCurrentStep('aborted')
      }
    }
    if (currentProcess === null && ProcessUtil.isProjectProcessCOR(currentProject)) {
      setCurrentStep('finished')
    }
  }, [currentProcess, processId])

  const { title, description, loading, buttons } =  processSteps[currentStep] || {}

  return (
    <div className={styles.wrapper_modal}>
      <div className={styles.wrapper_title}>
        {loading &&
          <div className={styles.loading_container}>
            <Spin indicator={<Icon type="loading" style={{ fontSize: 64 }} spin />} />
          </div>
        }
        { title && <p className={styles.title}>{translateText(title)}</p>}
        { description && <p className={styles.description}>{translateText(description)}</p>}
      </div>
      {
        buttons &&
        <div className={styles.wrapper_actions}>
          {
            buttons.map(({ text, callback, options}) => (
              <GlotioButton key={text} {...options} onClick={callback}>{text}</GlotioButton>
            ))
          }
        </div>
      }
    </div>
  )

}

export default FindAndReplaceModal
