import React, { useState } from 'react'

// hoc
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// hooks
import useGlotio from 'hooks/useGlotio'

// components
import { Menu, Dropdown } from 'antd'

// selectors
import { selectIsFirstSession } from 'redux/user/selectors'
import { selectConfirmedSubscriptionPlan } from 'redux/subscription/selectors';

// constants
import { ROUTES } from 'constants/routes.constants'
import externalUrls from 'services/externalUrls'

// icons
import { ReactComponent as HeadphoneIcon } from 'assets/images/icons/headphones.svg'
import { ReactComponent as HelpIcon } from 'assets/images/icons/icon_menu_faq.svg'
import { ReactComponent as MessageIcon } from 'assets/images/icons/message-square.svg'


// styles
import styles from './style.module.scss'

const HelpMenu = ({ history, isFirstSession, hasConfirmedSubscriptionPlan }) => {

  const { translateText } = useGlotio()
  const [isVisible, setIsVisible] = useState(false);
  const isFirstAssistant = isFirstSession || !hasConfirmedSubscriptionPlan


  const onVisibleChange = visible => setIsVisible(visible)

  const onClickItem = (callback) => {
    setIsVisible(false)
    callback()
  }

  const getOverlayMenu = () => (
    <Menu selectable={false}>
      <Menu.Item className={styles.menu_item}>
        <button
          type="button"
          onClick={() => onClickItem(() => window.open(translateText(externalUrls.QUICK_GUIDE), '_blank'))}
          className={styles.custom__button}
        >
          <HelpIcon className={styles.menu_icon} />
          <span className={styles.icon_text}>{translateText('Frequently asked questions')}</span>
        </button>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item className={styles.menu_item}>
        <button
          type="button"
          onClick={() => onClickItem(() => window.open(translateText(externalUrls.FAQ), '_blank'))}
          className={styles.custom__button}
        >
          <HeadphoneIcon className={styles.menu_icon} />
          <span className={styles.icon_text}>{translateText('Help Center')}</span>
        </button>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item className={styles.menu_item}>
        <button
          type="button"
          onClick={() => onClickItem(() => {
            const params = new URLSearchParams({from: window.location.href });
            history.push({ pathname: ROUTES.CONTACT_US, search: params.toString() });
          })}
          className={styles.custom__button}
        >
          <MessageIcon className={styles.menu_icon} />
          <span className={styles.icon_text}>{translateText('Contact us')}</span>
        </button>
      </Menu.Item>
    </Menu>
  )

  if (!isFirstAssistant) return null

  return (
    <Dropdown overlay={getOverlayMenu} trigger={['click']} onVisibleChange={onVisibleChange}>
      <div className={styles.dropdown}>
        <div className={`${styles.dropdown_wrapper_icon} ${isVisible ? styles.isVisible : ''}`}>
          <HelpIcon className={styles.icon_action} />
        </div>
      </div>
    </Dropdown>
  )
}

const mapStateToProps = (state) => ({
  isFirstSession: selectIsFirstSession(state),
  hasConfirmedSubscriptionPlan: selectConfirmedSubscriptionPlan(state),
})

export default connect(mapStateToProps, {})(withRouter(HelpMenu))
