import actionTypes from './actions'

const initialState = {
  projectsList: [],
  currentProject: null,
  projectId: null,
  fetching: false,
  error: null,
}

const projectReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.FETCH_PROJECT_START:
      return {
        ...state,
        fetching: true,
        error: null,
      }
    case actionTypes.FETCH_PROJECT_FAIL:
      return {
        ...state,
        fetching: false,
        error: payload,
      }
    case actionTypes.FETCH_PROJECT_SUCCESS:
      return {
        ...state,
        currentProject: payload,
        fetching: false,
        error: null,
      }
    case actionTypes.SET_PROJECT_ID:
      return {
        ...state,
        projectId: payload,
      }
    case actionTypes.SET_PROJECT_EMAIL:
      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          email: payload,
        },
      }
    case actionTypes.SAVE_PROJECT_CHANGES:
      return {
        ...state,
        currentProject: {
          ...state.currentProject,
          ...payload,
        },
      }

    default:
      return state
  }
}

export default projectReducer
