import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { selectProjectId } from '../redux/project/selectors'
import { selectUserId, selectUserLocale } from '../redux/user/selectors'
import { fetchUserStart, saveUserChanges } from '../redux/user/actions'
import { saveProjectChanges } from '../redux/project/actions'
import { changeLocalLanguage } from '../utils/Utils'

const IframeNavigation: React.FC<Props> = ({
  history,
  location,
  parentDomain,
  projectId,
  userId,
  language,
  saveLocaleChange,
  updateLanguageProject,
  fetchUser
}) => {
  const sendMessageToParent = ({ type, data }) => {
    window.parent.postMessage({ type, data }, parentDomain)
  }

  // send glotio info
  useEffect(() => {
    if (projectId && userId && language) {
      sendMessageToParent({ type: 'GLOTIO_CONF', data: { projectId, userId, language } })
    }
  }, [projectId, userId, language])

  // send router to sellboost
  useEffect(() => {
    sendMessageToParent({ type: 'NAVIGATE', data: window.location.href })

    const unlisten = history.listen(() => {
      sendMessageToParent({ type: 'NAVIGATE', data: window.location.href })
    })

    // Cleanup cuando el componente se desmonte
    return () => {
      unlisten()
    }
  }, [history, location, parentDomain])

  // receive navigation to sellboost
  useEffect(() => {
    const handlePostMessage = (event: MessageEvent) => {
      if (event.origin !== parentDomain) {
        return
      }

      const { type, data } = event.data

      if (type === 'NAVIGATE' && data) {
        history.replace(data)
      }

      if (type === 'CHANGE_LANGUAGE' && data) {
        changeLocalLanguage(data, { saveLocaleChange, userId, fetchUser, updateLanguageProject })
      }
    }

    window.addEventListener('message', handlePostMessage)

    return () => {
      window.removeEventListener('message', handlePostMessage)
    }
  }, [history])

  return null
}

const mapStateToProps = (state) => ({
  projectId: selectProjectId(state),
  userId: selectUserId(state),
  language: selectUserLocale(state)
})

const mapDispatchToProps = (dispatch) => ({
  saveLocaleChange: (lang) => dispatch(saveUserChanges({ lang })),
  updateLanguageProject: (data) => dispatch(saveProjectChanges(data)),
  fetchUser: (userId) => dispatch(fetchUserStart(userId, 'LanguageSelector')),   // detect where the user null comes from
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IframeNavigation))
