const actions = {
  SET_ASSISTANT_PAGE: 'wizard/SET_ASSISTANT_PAGE',
  GET_ASSISTANT_PAGE: 'wizard/GET_ASSISTANT_PAGE',
  DELETE_ASSISTANT_PAGE: 'wizard/DELETE_ASSISTANT_PAGE'
}

export default actions

export const getAssistantPage = () => ({
  type: actions.GET_ASSISTANT_PAGE,
})


export const setAssistantPage = (assistantPage) => ({
  type: actions.SET_ASSISTANT_PAGE,
  payload: assistantPage,
})

export const clearAssistantPage = () => ({
  type: actions.DELETE_ASSISTANT_PAGE,
})
