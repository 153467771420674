
import { get } from 'lodash'

// utils
import abbreviateNumber from 'utils/abbreviateNumber';
import { getPercentageForQuantity } from 'utils/Utils';
import { beforeDayFromDate, transformDate } from 'utils/Date'

// constants
import { dateFormat } from 'constants/dateFormat.constant';

const MAX_PERCENT = 100

export const useCharacterBalance = ({currentSubscription, translateText}) => {

  const {
    balanceTranslationsUsedChars = 0,
    availableTranslationsChars = 0,
    charsAreConsumed = 0
  } = get(currentSubscription, 'subscription.subscriptionStats', {})

  const {
    resetAt
  } = get(currentSubscription, 'subscription', {})

  const beforeResetAt = beforeDayFromDate(resetAt)

  const resetDate = transformDate(resetAt, dateFormat.default)
  const charPercent = charsAreConsumed ? 100 : getPercentageForQuantity(availableTranslationsChars, balanceTranslationsUsedChars)

  const abbreviateNumberFunction = abbreviateNumber(translateText)
  const usedCharString = `${abbreviateNumberFunction(balanceTranslationsUsedChars)} / ${abbreviateNumberFunction(availableTranslationsChars)}`

  return {
    usedCharString,
    resetDate,
    beforeResetAt,
    charPercent,
    MAX_PERCENT
  }

}
