import React, { useEffect, useState } from 'react'
import { Col, Row } from 'antd'

// components
import BackButton from 'components/CleanUIComponents/Buttons/BackButton'
import SummaryPayment from 'components/GlotioComponents/Summary/SummaryPayment'
import GlotioAlert from 'components/GlotioComponents/GlotioAlert'
import RootPortal from 'components/CleanUIComponents/RootPortal'
import GlotioButton from 'components/GlotioComponents/GlotioButton'

// hoc
import withGlotio from 'hoc/withGlotio'

// hooks
import { connect, useSelector } from 'react-redux'
import useGlotio from 'hooks/useGlotio'

// selects
import { selectCurrentAccount } from 'redux/account/selectors'
import { selectHasBackendError, selectErrorMessage } from 'redux/payment/selectors'

// utils
import { getFieldErrorMessage } from 'utils/getFieldErrorMessage'
import { generateComponentUuid } from 'utils/Utils'

// services
import mediator from "services/Mediator";

// styles
import style from './index.module.scss'


/**
 * @param {{
 * supplements: Object[],
 * total: Price, tax: number,
 * summaryFields: Object[],
 * handleSubmitButton: Button,
 * children: ReactNode,
 * disabled: boolean,
 * pushBack: Function,
 * infoMessage: string,
 * extraComponent: ReactNode,
 * alwaysShowTotal: boolean,
 * isFirtSession: boolean,
 * discountCoupon: Price }} props
 * @returns {JSX.Element}
 */
const Summary = (props) => {
  const {
    children,
    supplements,
    total,
    tax,
    discountCoupon,
    summaryFields,
    handleSubmitButton,
    disabled,
    pushBack,
    infoMessage,
    extraComponent,
    alwaysShowTotal = false,
    isFirtSession = false,
  } = props

  const [formErrors, setFormErrors] = useState([]);
  const { translateText } = useGlotio()

  const [hasBeenBackendError, setHasBeenBackendError] = useState(false)
  const hasPaymentBackendError = useSelector(selectHasBackendError)
  const backendErrorMessage = useSelector(selectErrorMessage)
  

  useEffect(() => {
    if (hasPaymentBackendError && !hasBeenBackendError) {
      setHasBeenBackendError(true)
    }
  }, [hasPaymentBackendError])

  useEffect(() => {
    mediator.subscribe('billing_form_error', setFormErrors)
  }, [])

  const renderErrorMessages = () => {

    return formErrors.map((error, index) => ((
      <div className={style.errorMessage} key={generateComponentUuid(index)}>
        <GlotioAlert type="error" message={getFieldErrorMessage(error, translateText)} />
      </div>
    )))

  }

  const renderButtomBar = () => {

    const {onClick:onHandleSubmit, isDisabled, isLoading, title } = handleSubmitButton
    const hasPushBack = typeof pushBack === 'function'
    const Template = hasPushBack ?  RootPortal.Grid : RootPortal.Basic
    return (
      <RootPortal>
        <Template>
          {hasPushBack && <BackButton pushBack={pushBack} data="icon" />}
          <GlotioButton
            data="button"
            type='button'
            size='large'
            variant='default'
            onClick={onHandleSubmit}
            disabled={isDisabled || hasPaymentBackendError}
            isLoading={isLoading}
          >
            {hasBeenBackendError ? translateText('Retry payment') : title}
          </GlotioButton>
        </Template>
      </RootPortal>
    )

  }

  return (
    <>
      <Row className={isFirtSession ? style.summary_container_in_first_session : style.summary_container} gutter={[16, 16]}>
        <Col className={style.summary_children} xs={24} xl={18}>
          {children}
        </Col>
        <Col xs={24} xl={6} className={style.sticky_summary}>
          <SummaryPayment
            total={total}
            tax={tax}
            summaryFields={summaryFields}
            hasPaymentBackendError={hasPaymentBackendError}
            backendErrorMessage={backendErrorMessage}
            disabled={disabled}
            infoMessage={infoMessage}
            supplements={supplements}
            alwaysShowTotal={alwaysShowTotal}
            discountCoupon={discountCoupon}
          />
          {extraComponent}
          {
            renderErrorMessages()
          }
        </Col>
      </Row>
      {
        renderButtomBar()
      }
    </>
  )
}

const mapStateToProps = (state) => ({
  currentAccount: selectCurrentAccount(state),
})

export default connect(mapStateToProps)(withGlotio(Summary));
