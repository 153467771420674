export default [
  {label: 'Afghanistan', value: 'AF', phoneCode: '93'},
  {label: 'Åland Islands', value: 'AX', phoneCode: '358'},
  {label: 'Albania', value: 'AL', phoneCode: '355'},
  {label: 'Algeria', value: 'DZ', phoneCode: '213'},
  {label: 'American Samoa', value: 'AS', phoneCode: '1-684'},
  {label: 'AndorrA', value: 'AD', phoneCode: '376'},
  {label: 'Angola', value: 'AO', phoneCode: '244'},
  {label: 'Anguilla', value: 'AI', phoneCode: '1-264'},
  {label: 'Antarctica', value: 'AQ', phoneCode: '672'},
  {label: 'Antigua and Barbuda', value: 'AG', phoneCode: '1-268'},
  {label: 'Argentina', value: 'AR', phoneCode: '54'},
  {label: 'Armenia', value: 'AM', phoneCode: '374'},
  {label: 'Aruba', value: 'AW', phoneCode: '297'},
  {label: 'Australia', value: 'AU', phoneCode: '61'},
  {label: 'Austria', value: 'AT', phoneCode: '43'},
  {label: 'Azerbaijan', value: 'AZ', phoneCode: '994'},
  {label: 'Bahamas', value: 'BS', phoneCode: '1-242'},
  {label: 'Bahrain', value: 'BH', phoneCode: '973'},
  {label: 'Bangladesh', value: 'BD', phoneCode: '880'},
  {label: 'Barbados', value: 'BB', phoneCode: '1-246'},
  {label: 'Belarus', value: 'BY', phoneCode: '375'},
  {label: 'Belgium', value: 'BE', phoneCode: '32'},
  {label: 'Belize', value: 'BZ', phoneCode: '501'},
  {label: 'Benin', value: 'BJ', phoneCode: '229'},
  {label: 'Bermuda', value: 'BM', phoneCode: '1-441'},
  {label: 'Bhutan', value: 'BT', phoneCode: '975'},
  {label: 'Bolivia', value: 'BO', phoneCode: '591'},
  {label: 'Bosnia and Herzegovina', value: 'BA', phoneCode: '387'},
  {label: 'Botswana', value: 'BW', phoneCode: '267'},
  {label: 'Bouvet Island', value: 'BV', phoneCode: ''},
  {label: 'Brazil', value: 'BR', phoneCode: '55'},
  {label: 'British Indian Ocean Territory', value: 'IO', phoneCode: '246'},
  {label: 'Brunei Darussalam', value: 'BN', phoneCode: '673'},
  {label: 'Bulgaria', value: 'BG', phoneCode: '359'},
  {label: 'Burkina Faso', value: 'BF', phoneCode: '226'},
  {label: 'Burundi', value: 'BI', phoneCode: '257'},
  {label: 'Cambodia', value: 'KH', phoneCode: '855'},
  {label: 'Cameroon', value: 'CM', phoneCode: '237'},
  {label: 'Canada', value: 'CA', phoneCode: '1'},
  {label: 'Cape Verde', value: 'CV', phoneCode: '238'},
  {label: 'Cayman Islands', value: 'KY', phoneCode: '1-345'},
  {label: 'Central African Republic', value: 'CF', phoneCode: '236'},
  {label: 'Chad', value: 'TD', phoneCode: '235'},
  {label: 'Chile', value: 'CL', phoneCode: '56'},
  {label: 'China', value: 'CN', phoneCode: '86'},
  {label: 'Christmas Island', value: 'CX', phoneCode: '61'},
  {label: 'Cocos (Keeling) Islands', value: 'CC', phoneCode: '61'},
  {label: 'Colombia', value: 'CO', phoneCode: '57'},
  {label: 'Comoros', value: 'KM', phoneCode: '269'},
  {label: 'Congo', value: 'CG', phoneCode: '242'},
  {label: 'Congo, The Democratic Republic of the', value: 'CD', phoneCode: '243'},
  {label: 'Cook Islands', value: 'CK', phoneCode: '682'},
  {label: 'Costa Rica', value: 'CR', phoneCode: '506'},
  {label: 'Cote D\'Ivoire', value: 'CI', phoneCode: '225'},
  {label: 'Croatia', value: 'HR', phoneCode: '385'},
  {label: 'Cuba', value: 'CU', phoneCode: '53'},
  {label: 'Cyprus', value: 'CY', phoneCode: '357'},
  {label: 'Czech Republic', value: 'CZ', phoneCode: '420'},
  {label: 'Denmark', value: 'DK', phoneCode: '45'},
  {label: 'Djibouti', value: 'DJ', phoneCode: '253'},
  {label: 'Dominica', value: 'DM', phoneCode: '1-767'},
  {label: 'Dominican Republic', value: 'DO', phoneCode: '1-809'},
  {label: 'Ecuador', value: 'EC', phoneCode: '593'},
  {label: 'Egypt', value: 'EG', phoneCode: '20'},
  {label: 'El Salvador', value: 'SV', phoneCode: '503'},
  {label: 'Equatorial Guinea', value: 'GQ', phoneCode: '240'},
  {label: 'Eritrea', value: 'ER', phoneCode: '291'},
  {label: 'Estonia', value: 'EE', phoneCode: '372'},
  {label: 'Ethiopia', value: 'ET', phoneCode: '251'},
  {label: 'Falkland Islands (Malvinas)', value: 'FK', phoneCode: '500'},
  {label: 'Faroe Islands', value: 'FO', phoneCode: '298'},
  {label: 'Fiji', value: 'FJ', phoneCode: '679'},
  {label: 'Finland', value: 'FI', phoneCode: '358'},
  {label: 'France', value: 'FR', phoneCode: '33'},
  {label: 'French Guiana', value: 'GF', phoneCode: '594'},
  {label: 'French Polynesia', value: 'PF', phoneCode: '689'},
  {label: 'French Southern Territories', value: 'TF', phoneCode: ''},
  {label: 'Gabon', value: 'GA', phoneCode: '241'},
  {label: 'Gambia', value: 'GM', phoneCode: '220'},
  {label: 'Georgia', value: 'GE', phoneCode: '995'},
  {label: 'Germany', value: 'DE', phoneCode: '49'},
  {label: 'Ghana', value: 'GH', phoneCode: '233'},
  {label: 'Gibraltar', value: 'GI', phoneCode: '350'},
  {label: 'Greece', value: 'GR', phoneCode: '30'},
  {label: 'Greenland', value: 'GL', phoneCode: '299'},
  {label: 'Grenada', value: 'GD', phoneCode: '1-473'},
  {label: 'Guadeloupe', value: 'GP', phoneCode: '590'},
  {label: 'Guam', value: 'GU', phoneCode: '1-671'},
  {label: 'Guatemala', value: 'GT', phoneCode: '502'},
  {label: 'Guernsey', value: 'GG', phoneCode: '44-1481'},
  {label: 'Guinea', value: 'GN', phoneCode: '224'},
  {label: 'Guinea-Bissau', value: 'GW', phoneCode: '245'},
  {label: 'Guyana', value: 'GY', phoneCode: '592'},
  {label: 'Haiti', value: 'HT', phoneCode: '509'},
  {label: 'Heard Island and Mcdonald Islands', value: 'HM', phoneCode: ''},
  {label: 'Holy See (Vatican City State)', value: 'VA', phoneCode: '39'},
  {label: 'Honduras', value: 'HN', phoneCode: '504'},
  {label: 'Hong Kong', value: 'HK', phoneCode: '852'},
  {label: 'Hungary', value: 'HU', phoneCode: '36'},
  {label: 'Iceland', value: 'IS', phoneCode: '354'},
  {label: 'India', value: 'IN', phoneCode: '91'},
  {label: 'Indonesia', value: 'ID', phoneCode: '62'},
  {label: 'Iran, Islamic Republic Of', value: 'IR', phoneCode: '98'},
  {label: 'Iraq', value: 'IQ', phoneCode: '964'},
  {label: 'Ireland', value: 'IE', phoneCode: '353'},
  {label: 'Isle of Man', value: 'IM', phoneCode: '44-1624'},
  {label: 'Israel', value: 'IL', phoneCode: '972'},
  {label: 'Italy', value: 'IT', phoneCode: '39'},
  {label: 'Jamaica', value: 'JM', phoneCode: '1-876'},
  {label: 'Japan', value: 'JP', phoneCode: '81'},
  {label: 'Jersey', value: 'JE', phoneCode: '44-1534'},
  {label: 'Jordan', value: 'JO', phoneCode: '962'},
  {label: 'Kazakhstan', value: 'KZ', phoneCode: '7'},
  {label: 'Kenya', value: 'KE', phoneCode: '254'},
  {label: 'Kiribati', value: 'KI', phoneCode: '686'},
  {label: 'Korea, Democratic People\'S Republic of', value: 'KP', phoneCode: '850'},
  {label: 'Korea, Republic of', value: 'KR', phoneCode: '82'},
  {label: 'Kuwait', value: 'KW', phoneCode: '965'},
  {label: 'Kyrgyzstan', value: 'KG', phoneCode: '996'},
  {label: 'Lao People\'S Democratic Republic', value: 'LA', phoneCode: '856'},
  {label: 'Latvia', value: 'LV', phoneCode: '371'},
  {label: 'Lebanon', value: 'LB', phoneCode: '961'},
  {label: 'Lesotho', value: 'LS', phoneCode: '266'},
  {label: 'Liberia', value: 'LR', phoneCode: '231'},
  {label: 'Libyan Arab Jamahiriya', value: 'LY', phoneCode: '218'},
  {label: 'Liechtenstein', value: 'LI', phoneCode: '423'},
  {label: 'Lithuania', value: 'LT', phoneCode: '370'},
  {label: 'Luxembourg', value: 'LU', phoneCode: '352'},
  {label: 'Macao', value: 'MO', phoneCode: '853'},
  {label: 'Macedonia, The Former Yugoslav Republic of', value: 'MK', phoneCode: '389'},
  {label: 'Madagascar', value: 'MG', phoneCode: '261'},
  {label: 'Malawi', value: 'MW', phoneCode: '265'},
  {label: 'Malaysia', value: 'MY', phoneCode: '60'},
  {label: 'Maldives', value: 'MV', phoneCode: '960'},
  {label: 'Mali', value: 'ML', phoneCode: '223'},
  {label: 'Malta', value: 'MT', phoneCode: '356'},
  {label: 'Marshall Islands', value: 'MH', phoneCode: '692'},
  {label: 'Martinique', value: 'MQ', phoneCode: '596'},
  {label: 'Mauritania', value: 'MR', phoneCode: '222'},
  {label: 'Mauritius', value: 'MU', phoneCode: '230'},
  {label: 'Mayotte', value: 'YT', phoneCode: '262'},
  {label: 'Mexico', value: 'MX', phoneCode: '52'},
  {label: 'Micronesia, Federated States of', value: 'FM', phoneCode: '691'},
  {label: 'Moldova, Republic of', value: 'MD', phoneCode: '373'},
  {label: 'Monaco', value: 'MC', phoneCode: '377'},
  {label: 'Mongolia', value: 'MN', phoneCode: '976'},
  {label: 'Montserrat', value: 'MS', phoneCode: '1-664'},
  {label: 'Morocco', value: 'MA', phoneCode: '212'},
  {label: 'Mozambique', value: 'MZ', phoneCode: '258'},
  {label: 'Myanmar', value: 'MM', phoneCode: '95'},
  {label: 'Namibia', value: 'NA', phoneCode: '264'},
  {label: 'Nauru', value: 'NR', phoneCode: '674'},
  {label: 'Nepal', value: 'NP', phoneCode: '977'},
  {label: 'Netherlands', value: 'NL', phoneCode: '31'}, 
  {label: 'Netherlands Antilles', value: 'AN', phoneCode: '599'}, 
  {label: 'New Caledonia', value: 'NC', phoneCode: '687'}, 
  {label: 'New Zealand', value: 'NZ', phoneCode: '64'}, 
  {label: 'Nicaragua', value: 'NI', phoneCode: '505'}, 
  {label: 'Niger', value: 'NE', phoneCode: '227'}, 
  {label: 'Nigeria', value: 'NG', phoneCode: '234'}, 
  {label: 'Niue', value: 'NU', phoneCode: '683'}, 
  {label: 'Norfolk Island', value: 'NF', phoneCode: '672'}, 
  {label: 'Northern Mariana Islands', value: 'MP', phoneCode: '1-670'}, 
  {label: 'Norway', value: 'NO', phoneCode: '47'}, 
  {label: 'Oman', value: 'OM', phoneCode: '968'}, 
  {label: 'Pakistan', value: 'PK', phoneCode: '92'}, 
  {label: 'Palau', value: 'PW', phoneCode: '680'}, 
  {label: 'Palestinian Territory, Occupied', value: 'PS', phoneCode: '970'}, 
  {label: 'Panama', value: 'PA', phoneCode: '507'}, 
  {label: 'Papua New Guinea', value: 'PG', phoneCode: '675'}, 
  {label: 'Paraguay', value: 'PY', phoneCode: '595'}, 
  {label: 'Peru', value: 'PE', phoneCode: '51'}, 
  {label: 'Philippines', value: 'PH', phoneCode: '63'}, 
  {label: 'Pitcairn', value: 'PN', phoneCode: '64'}, 
  {label: 'Poland', value: 'PL', phoneCode: '48'}, 
  {label: 'Portugal', value: 'PT', phoneCode: '351'}, 
  {label: 'Puerto Rico', value: 'PR', phoneCode: '1-787'}, 
  {label: 'Qatar', value: 'QA', phoneCode: '974'}, 
  {label: 'Reunion', value: 'RE', phoneCode: '262'}, 
  {label: 'Romania', value: 'RO', phoneCode: '40'}, 
  {label: 'Russian Federation', value: 'RU', phoneCode: '7'}, 
  {label: 'RWANDA', value: 'RW', phoneCode: '250'}, 
  {label: 'Saint Helena', value: 'SH', phoneCode: '290'}, 
  {label: 'Saint Kitts and Nevis', value: 'KN', phoneCode: '1-869'}, 
  {label: 'Saint Lucia', value: 'LC', phoneCode: '1-758'}, 
  {label: 'Saint Pierre and Miquelon', value: 'PM', phoneCode: '508'}, 
  {label: 'Saint Vincent and the Grenadines', value: 'VC', phoneCode: '1-784'}, 
  {label: 'Samoa', value: 'WS', phoneCode: '685'}, 
  {label: 'San Marino', value: 'SM', phoneCode: '378'}, 
  {label: 'Sao Tome and Principe', value: 'ST', phoneCode: '239'}, 
  {label: 'Saudi Arabia', value: 'SA', phoneCode: '966'}, 
  {label: 'Senegal', value: 'SN', phoneCode: '221'}, 
  {label: 'Serbia and Montenegro', value: 'CS', phoneCode: '381'}, 
  {label: 'Seychelles', value: 'SC', phoneCode: '248'}, 
  {label: 'Sierra Leone', value: 'SL', phoneCode: '232'}, 
  {label: 'Singapore', value: 'SG', phoneCode: '65'}, 
  {label: 'Slovakia', value: 'SK', phoneCode: '421'}, 
  {label: 'Slovenia', value: 'SI', phoneCode: '386'}, 
  {label: 'Solomon Islands', value: 'SB', phoneCode: '677'}, 
  {label: 'Somalia', value: 'SO', phoneCode: '252'}, 
  {label: 'South Africa', value: 'ZA', phoneCode: '27'}, 
  {label: 'South Georgia and the South Sandwich Islands', value: 'GS', phoneCode: '500'}, 
  {label: 'Spain', value: 'ES', phoneCode: '34'}, 
  {label: 'Sri Lanka', value: 'LK', phoneCode: '94'}, 
  {label: 'Sudan', value: 'SD', phoneCode: '249'}, 
  {label: 'Surilabel', value: 'SR', phoneCode: '597'}, 
  {label: 'Svalbard and Jan Mayen', value: 'SJ', phoneCode: '47'}, 
  {label: 'Swaziland', value: 'SZ', phoneCode: '268'}, 
  {label: 'Sweden', value: 'SE', phoneCode: '46'}, 
  {label: 'Switzerland', value: 'CH', phoneCode: '41'},
  {label: 'Syrian Arab Republic', value: 'SY', phoneCode: '963'}, 
  {label: 'Taiwan, Province of China', value: 'TW', phoneCode: '886'}, 
  {label: 'Tajikistan', value: 'TJ', phoneCode: '992'}, 
  {label: 'Tanzania, United Republic of', value: 'TZ', phoneCode: '255'}, 
  {label: 'Thailand', value: 'TH', phoneCode: '66'}, 
  {label: 'Timor-Leste', value: 'TL', phoneCode: '670'}, 
  {label: 'Togo', value: 'TG', phoneCode: '228'}, 
  {label: 'Tokelau', value: 'TK', phoneCode: '690'}, 
  {label: 'Tonga', value: 'TO', phoneCode: '676'}, 
  {label: 'Trinidad and Tobago', value: 'TT', phoneCode: '1-868'}, 
  {label: 'Tunisia', value: 'TN', phoneCode: '216'}, 
  {label: 'Turkey', value: 'TR', phoneCode: '90'}, 
  {label: 'Turkmenistan', value: 'TM', phoneCode: '993'}, 
  {label: 'Turks and Caicos Islands', value: 'TC', phoneCode: '1-649'}, 
  {label: 'Tuvalu', value: 'TV', phoneCode: '688'}, 
  {label: 'Uganda', value: 'UG', phoneCode: '256'}, 
  {label: 'Ukraine', value: 'UA', phoneCode: '380'}, 
  {label: 'United Arab Emirates', value: 'AE', phoneCode: '971'}, 
  {label: 'United Kingdom', value: 'GB', phoneCode: '44'}, 
  {label: 'United States', value: 'US', phoneCode: '1'}, 
  {label: 'United States Minor Outlying Islands', value: 'UM', phoneCode: '1'}, 
  {label: 'Uruguay', value: 'UY', phoneCode: '598'}, 
  {label: 'Uzbekistan', value: 'UZ', phoneCode: '998'}, 
  {label: 'Vanuatu', value: 'VU', phoneCode: '678'}, 
  {label: 'Venezuela', value: 'VE', phoneCode: '58'}, 
  {label: 'Viet Nam', value: 'VN', phoneCode: '84'}, 
  {label: 'Virgin Islands, British', value: 'VG', phoneCode: '1-284'}, 
  {label: 'Virgin Islands, U.S.', value: 'VI', phoneCode: '1-340'}, 
  {label: 'Wallis and Futuna', value: 'WF', phoneCode: '681'}, 
  {label: 'Western Sahara', value: 'EH', phoneCode: '212'}, 
  {label: 'Yemen', value: 'YE', phoneCode: '967'}, 
  {label: 'Zambia', value: 'ZM', phoneCode: '260'}, 
  {label: 'Zimbabwe', value: 'ZW', phoneCode: '263'}
]
