// types
export const OPEN_MODAL = "OPEN_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const NEXT_MODAL = "NEXT_MODAL";

// Actions

export const openModal = message => ({ type: OPEN_MODAL, payload: { message }})
export const closeModal = id => ({ type: CLOSE_MODAL, payload: { id }})
export const nextModal = message => ({ type: NEXT_MODAL, payload: { message }})
