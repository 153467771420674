import { store } from 'redux/store'
import { isEmpty } from 'lodash'
import {
  installNewLanguageStarted, subscriptionCancel, subscriptionPayment, subscriptionUpgrade,
  translateLanguage, translationPayment,
  translationPriceRule,
  updateLanguage
} from 'redux/trackevents/actions'

export const sendTranslationEvents = (translationOffers, totalToPay) => {
  const updatedLanguages = isUpdateEvents(translationOffers.updateTranslationOffers)
  if (updatedLanguages) {
    store.dispatch(updateLanguage(updatedLanguages))
  }

  const newTranslationLanguages = isNewTranslationEvents(translationOffers.selectedLanguagesOffers)
  if (newTranslationLanguages) {
    store.dispatch(translateLanguage(newTranslationLanguages))
  }

  const newInstalledLanguages = isNewInstallLanguageEvents(translationOffers.selectedLanguagesOffers)
  if (newInstalledLanguages) {
    store.dispatch(installNewLanguageStarted(newInstalledLanguages))
  }

  store.dispatch(translationPayment(`${totalToPay}`))
}

export const sendTranslationEventReduced = (translatedLanguages = {}, updatedLanguages = [], totalToPay) => {

  const { translatedInstalledLanguages = [], translatedNotInstalledLanguages = []} = translatedLanguages;

  if (!isEmpty(updatedLanguages)) {
    store.dispatch(updateLanguage(updatedLanguages))
  }
  if (!isEmpty(translatedInstalledLanguages)) {
    store.dispatch(translateLanguage(translatedLanguages.translatedInstalledLanguages))
  }
  if (!isEmpty(translatedNotInstalledLanguages)) {
    store.dispatch(installNewLanguageStarted(translatedLanguages.translatedNotInstalledLanguages))
  }
  
  store.dispatch(translationPayment(`${totalToPay}`))

}

export const sendTranslationPriceRuleEvent = (project) => {
  store.dispatch(translationPriceRule(project.price_rule))
}

export const sendSubscriptionUpgradeEvent = (subscription, totalToPay) => {
  const subscriptionUpgradeText = `${subscription.name} ${subscription.period}`
  store.dispatch(subscriptionUpgrade(subscriptionUpgradeText))
  store.dispatch(subscriptionPayment(`${totalToPay}`))
}

export const sendSubscriptionCancelEvent = (currentSubscription, reason) => {
  const sendReason = reason.comment ? reason.comment : reason.reason
  const cancelledSubscription = `${currentSubscription.subscriptionPlan.subscriptionPlanType} ${currentSubscription.subscription.period} ${sendReason}`
  store.dispatch(subscriptionCancel(cancelledSubscription))
}

const isUpdateEvents = (updateTranslationOffers) => {
  const updatedLanguages = updateTranslationOffers.filter((language) => language.toTranslateCharacters > 0)

  if (!updatedLanguages.length) {
    return null
  }

  return updatedLanguages.map(language => language.languageIso).join(', ')
}

const isNewTranslationEvents = (selectedLanguagesOffers) => {
  const translatedLanguages = selectedLanguagesOffers.filter((language) => language.installed === true)

  if (!translatedLanguages.length) {
    return null
  }

  return translatedLanguages.map(language => language.languageIso).join(', ')
}

const isNewInstallLanguageEvents = (selectedLanguagesOffers) => {
  const translatedLanguages = selectedLanguagesOffers.filter((language) => language.installed === false)

  if (!translatedLanguages.length) {
    return null
  }

  return translatedLanguages.map(language => language.languageIso).join(', ')
}
