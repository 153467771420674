import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import user from './user/reducers'
import menu from './menu/reducers'
import settings from './settings/reducers'
import process from './process/reducers'
import project from './project/reducers'
import languages from './languages/reducer'
import account from './account/reducer'
import wizard from "./wizard/reducers";
import question from "./question/reducers";
import modal from './modal/reducers';
import subscription from './subscription/reducers';
import payment from './payment/reducers';
import leftMenuVisibility from './left_menu_visibility/reducers'


export default history =>
  combineReducers({
    router: connectRouter(history),
    user,
    menu,
    settings,
    process,
    project,
    languages,
    account,
    wizard,
    question,
    modal,
    subscription,
    payment,
    leftMenuVisibility
  })

/**
 * Reducer variable for testing components with connect
 */
export const testReducer = combineReducers({
  user,
  menu,
  settings,
  process,
  project,
  languages,
  account,
  wizard,
  question,
  modal,
  subscription,
  payment,
  leftMenuVisibility
})
