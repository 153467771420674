import React from 'react'
import { Skeleton } from 'antd'

// components
import NoConnectionError from 'components/GlotioComponents/ShowError/NoConnectionError'

import styles from './index.module.scss'


/**
 *
 * @param {ReactNode} children
 * @param {boolean} isLoading
 * @param {boolean} isError
 * @param {boolean} noPadding
 * @param {string} className
 * @return {JSX.Element}
 * @constructor
 */
const Container = ({ ErrorComponent = NoConnectionError, isLoading = false, isError = false, children, noPadding = false, className = '' }) => {
  /**
   *
   * @return {JSX.Element|React.ReactNode}
   */
  const getContent = () => {
    if (isLoading) {
      return <Skeleton title active />
    }

    if (isError) {
      return (
        <ErrorComponent />
      )
    }

    return (
      <>
        {children}
      </>
    )
  }

  return (
    <div className={`${(!noPadding ? styles.container : '')} ${className}`}>
      {getContent()}
    </div>
  )
}

export default Container
