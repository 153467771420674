import React from 'react'
import { Progress } from 'antd'
import InfoDanger from 'components/CleanUIComponents/Messages/InfoDanger'
import styles from './index.module.scss'

/**
 *
 * @param {number} percent
 * @param {string} title
 * @param {string} subtitle
 * @param {Object|string} color
 * @param {'small' | 'default'} size
 * @return {JSX.Element}
 */
const DoubleProgressInfo = ({ beforeUpdatePercent , afterUpdatePercent , title, subtitle, exceedCharBalanceMessage = '', remainingCharsMessage= '', size = 'default', className = '' }) => {
  const hasExceededTotal = afterUpdatePercent === 100
  const canShowExceedMessage = hasExceededTotal && afterUpdatePercent !== beforeUpdatePercent
  return (
    <>
      <div className={`${styles.progress_container} ${size === 'small' ? styles.small_bar : styles.default_bar} ${className}`}>
        <div className={styles.progress_title_container}>
          <span>{title}</span>
          <span>{afterUpdatePercent} %</span>
        </div>
        <Progress percent={afterUpdatePercent} successPercent={beforeUpdatePercent} showInfo={false} />
        <span className={styles.progress_subtitle}>{subtitle}</span>
      </div>
      {
        canShowExceedMessage &&
          <div className={styles.message_wrapper}>
            <InfoDanger text={remainingCharsMessage} />
          </div>
      }
      {
        (!canShowExceedMessage && hasExceededTotal) &&
          <div className={styles.message_wrapper}>
            <InfoDanger text={exceedCharBalanceMessage} />
          </div>
      }
    </>
  )
}

export default  DoubleProgressInfo
