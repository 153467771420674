import React from 'react'

// components
import GlotioButton from 'components/GlotioComponents/GlotioButton'

// assets
import { ReactComponent as FaqIcon } from 'assets/images/icons/icon_menu_faq.svg'

// hooks
import { useGetFaqContent } from 'hooks/useGetFaqContent'
import { useCreateModal } from 'hooks/useCreateModal'

// constants
import { EXTRA_LARGE_WIDTH_MODAL, FAQ_MODAL } from 'constants/modals.constants'

// styles
import styles from './index.module.scss'

/**
 * @typedef {Object} FaqLink
 * @property {string} id
 * @property {boolean} external
 * @property {string} className
 * @property {React.ReactChild} children
 * @property {Function} openModal
 */

/**
 * Renders a link that opens a FAQ article into a modal view
 * @param {FaqLink} props
 */
const FaqLink = ({
  id,
  external = false,
  className = '',
  children
}) => {
  const { articleURI } = useGetFaqContent(id)
  const { onOpenModal } = useCreateModal()

  const onOpenFaqModal = () => {
    const message = {
      type: FAQ_MODAL,
      faqId: id,
      modalWidth: EXTRA_LARGE_WIDTH_MODAL
    }

    onOpenModal({...message})
  }


  return (
    <>
      {external ? (
        <a
          href={articleURI}
          target="_blank"
          rel="noopener noreferrer"
          className={`${styles.linkFAQ} ${className}`}
        >
          {children}
        </a>
      ) : (
        <GlotioButton
          type="button"
          variant="link"
          onClick={onOpenFaqModal}
          className={`${styles.linkFAQ} ${className}`}
        >
          {children}
        </GlotioButton>
      )}
    </>
  )
}

/**
 * @param {FaqLink} props
 */
export const FaqLinkWithIconComponent = ({ children, ...rest }) => (
  <FaqLink {...rest}>
    <FaqIcon />
    {children}
  </FaqLink>
)

export const FaqLinkWithIcon = FaqLinkWithIconComponent
export default FaqLink
