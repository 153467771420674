import React, { useState } from 'react';
import { notification } from 'antd'

// components
import GlotioButton from 'components/GlotioComponents/GlotioButton'

// redux
import { useSelector } from 'react-redux'

// selectors
import { selectProjectId } from 'redux/project/selectors'

// hook
import useGlotio from 'hooks/useGlotio'

// constants
import { RETURN_TO_FREE_PLAN } from 'constants/cancel_subscription_reasons'

// utils
import { onCancelSubscription } from 'utils/Requests'

// styles
import styles from './index.module.scss';

const FailRetrySubscriptionPaymentModal= ({ currentSubscription, onSuccessCancelSubscription, onModifyPayment }) => {
  const { translateText } = useGlotio()
  const projectId = useSelector(selectProjectId)
  const [isLoading, setIsLoading] = useState(false)

  const cancelSubscription = async () => {
    setIsLoading(true)

    const payload = {
      reason: RETURN_TO_FREE_PLAN.id,
      comment: null,
    }

    await onCancelSubscription({
      projectId,
      payload,
      currentSubscription,
      onSuccess: onSuccessCancelSubscription,
      onError: () => {
        setIsLoading(false)
        showErrorNotification()
      }
    })
  }

  const showErrorNotification = () => {
    notification.error({
      message: translateText('An error has occurred'),
      duration: 3,
      key: 'saveError',
    })
  }

  const plan = `<span class="${styles.highlight_text}">${translateText('Plan {plan}', {plan: 'Free'})}</span>`

  return (
    <>
      <div className={styles.wrapper_title}>
        <p className={styles.title}>{translateText('The payment method still does not work')}</p>
        <div className={styles.description}>
          <p dangerouslySetInnerHTML={{__html: translateText('There seems to have been another error in the payment method entered. If you prefer, you can change your payment details or change your Glotio account at {plan}.', {plan})}} />
        </div>
      </div>
      <div className={styles.wrapper_actions}>
        <GlotioButton
          type='button'
          variant='outlined'
          size='large'
          status='error'
          isLoading={isLoading}
          onClick={cancelSubscription}
          className={styles.modal_button}
        >
          {translateText('Switch to the Free plan')}
        </GlotioButton>
        <GlotioButton
          type='button'
          variant='default'
          size='large'
          onClick={onModifyPayment}
          className={styles.modal_button}
        >
          {translateText('Modify payment details')}
        </GlotioButton>
      </div>
    </>
  )

}

export default FailRetrySubscriptionPaymentModal
