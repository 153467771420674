import React from 'react'

// components
import ModalFullContent from 'components/CleanUIComponents/Modal/ModalFullContent'
import TicketSupport from 'components/GlotioComponents/TicketSupport'

// style
import styled from './index.module.scss'


/**
 * Modal to show ticket support 
 * @param {boolean} isOpen
 * @param {Function} handleClose
 * @return {JSX.Element}
 */
const TicketSupportModalFullContent = ({ isOpen, handleClose }) => {

  return (
    <ModalFullContent isOpen={isOpen} handleClose={handleClose} isFullHeight>
      {isOpen && 
        <div className={styled.modal_container}>
          <TicketSupport handleClose={handleClose} />
        </div>
      }
    </ModalFullContent>
    )
}

export default TicketSupportModalFullContent
