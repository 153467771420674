import React, { useCallback, useEffect, useRef, useState } from 'react'
import { notification, Skeleton } from 'antd'
import { connect, useSelector } from 'react-redux'
import { isEmpty } from 'lodash'

// components
import LanguageInfoWrapper from 'components/CleanUIComponents/LanguageInfoTable/LanguageInfoWrapper'
import { InfoTable } from 'components/CleanUIComponents/LanguageInfoTable'
import InfoPopover from 'components/CleanUIComponents/Popover/InfoPopover'
import GlotioButton from 'components/GlotioComponents/GlotioButton'

// models
import { getLanguagesRequest } from 'models/api/project'

// actions
import { cancelAndRestartProcess, launchNewTranslationProcess } from 'redux/process/actions'

// selectors
import { selectIsProjectTranslatable, selectProjectId } from 'redux/project/selectors'

// hooks
import useGlotio from "hooks/useGlotio"
import useAssistantHash from 'hooks/useAssistantHash'

// constants
import { statusColor, statusType } from 'constants/status.constants'
import { ASSISTANT_ROUTER_HASH } from 'constants/assistant.router.hash'
import { ORIGIN_UPDATES } from 'constants/process_origin.constants'

// utils
import { transformDate } from 'utils/Date'
import { hasNoCharacters } from 'utils/Languages'

// icons
import { ReactComponent as CheckIcon } from 'assets/images/icons/check-circle_generic.svg'

// styles
import styles from './index.module.scss'

/**
 * This component handles the required actions to show the information about the last analysis made.
 * The user can cancel and restart a new process o start a new one if all the process are finished
 * @param {Function} launchTranslation
 * @param {Function} cancelAndLaunchTranslation
 * @param {boolean} isProcessActive
 * @param {string} origin
 * @return {JSX}
 */

const LastAnalysisInfo = ({launchTranslation, cancelAndLaunchTranslation, isProcessActive = false, isNothingToTranslate = false, origin = '', sectionTitle = ''}) => {
  const { translateText, formatNumber, translateIsoCode } = useGlotio();
  const isProjectTranslatable = useSelector(selectIsProjectTranslatable)
  const [language, setLanguage ] = useState([]);
  const [isLoadingLanguages, setIsLoadingLanguages] = useState(true);
  const projectId = useSelector(selectProjectId);
  const [startAnalyisLoading, setStartAnalysisLoading] = useState(false)
  const isMounted = useRef(true)

  useAssistantHash(ASSISTANT_ROUTER_HASH.START_ANALYSIS)

  const getLanguages = useCallback(
    async () => {
      try {
        const { result, error } = await getLanguagesRequest({projectId});
        if (!error) {
          const defaultLanguage = result.filter((l) => l.platform_default).reduce((acc, defaultLang) => ({
            ...acc,
            lastUpdate: defaultLang.total_chars_updated_at || null,
            name: defaultLang.name,
            totalChars: defaultLang.num_total_chars,
            installedLanguages: result.filter((lang) => lang.translate).length,
            iso: defaultLang.iso
          }),{});
          if (isMounted.current) {
            setLanguage(defaultLanguage);
          }
        }
      } catch (err) {
        notification.error({
          message: translateText('There was a connection error.'),
          duration: 3,
          key: 'saveSuccess',
        })
      } finally {
        if (isMounted.current) {
          setIsLoadingLanguages(false);
        }
      }
    },
   [projectId]
  )

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  useEffect(() => {
    if (projectId) {
      getLanguages();
    }
  },[projectId, getLanguages])


  const generateTable = () => {

    if (isEmpty(language)) return null;

    const { name, lastUpdate, totalChars, installedLanguages, iso } = language;

    const renderCol = (title, description, highlight = false) => {
      return (
        <>
          <p className={`${styles.column_title} ${highlight && styles.column_title_highlight}`}>{title}</p>
          <p className={styles.column_description}>{description}</p>
        </>
      )
    }

    const rows = [{name, lastUpdate, totalChars, installedLanguages}];

    const columns = [
      {
        selector: () => {
          return (
            <>
              <p className={`${styles.column_title} ${styles.column_title_highlight}`}>{translateIsoCode(iso)}</p>
              <div className={styles.info_wrapper}>
                <p className={styles.column_description}>{translateText('Default language')}</p>
                <InfoPopover
                  message={translateText('All languages will be translated from the source language.')}
                  color={statusColor[statusType.info]}
                  direction='right'
                />
              </div>
            </>
          )
        }
      },
      {
        selector: (row) => {
          const hasNoCharactersLanguage = hasNoCharacters(row.totalChars)
          return (
            <div className={hasNoCharactersLanguage ? styles.last_analysis_warning : styles.last_analysis}>
              <div>
                {row.lastUpdate ? renderCol(transformDate(row.lastUpdate), translateText('Latest analysis')) : <p>{translateText('No data')}</p>}
              </div>
              {hasNoCharactersLanguage &&
                <InfoPopover
                  message={translateText('It is necessary that you carry out an analysis to detect new content for your online shop.')}
                  color={statusColor[statusType.warning]}
                  direction='right'
                />
              }
            </div>
          )
        },
      },
      {
        selector: (row) => renderCol(formatNumber(row.totalChars), translateText('characters'))
      },
      {
        selector: (row) => renderCol(row.installedLanguages, translateText('Installed languages'))
      }
    ]

    return <InfoTable rows={rows} columns={columns} hasHeader={false} />

  }

  const onStartAnalysis = () => {
    if (isProjectTranslatable) {
      setStartAnalysisLoading(true);
      if (isProcessActive) {
        cancelAndLaunchTranslation(origin)
      } else {
        launchTranslation(origin);
      }
    }
  }

  const getDescription = () => {

    if (origin === ORIGIN_UPDATES) {
      return translateText('Before updating the languages, it is necessary to perform a new analysis so that Glotio can detect whether there is new translatable content in your online shop.')
    }

    return translateText('Before adding languages, it is necessary to perform a new analysis so that Glotio can check if there is new translatable content in your online store.')

  }

  const renderView = () => {

    return (
      <>
        {
          isLoadingLanguages ? <Skeleton active /> :
          <LanguageInfoWrapper
            title={translateText(sectionTitle || 'Start analysis to add languages')}
            description={getDescription()}
          >
            <h2 className={styles.history_table_subtitle}>{translateText('Last analysis performed')}</h2>
            <div className={styles.language_history_container}>
              {generateTable()}
            </div>
            { (isProcessActive || isNothingToTranslate) &&
              <div className={styles.language_info_message}>
                <CheckIcon className={styles.check_icon} />
                <span className={styles.message_text}>{translateText('All languages are up to date')}</span>
              </div>
            }
            <GlotioButton
              type='button'
              size='large'
              fluid
              className={styles.start_analysis_btn}
              variant='default'
              isLoading={startAnalyisLoading}
              onClick={onStartAnalysis}
              disabled={!isProjectTranslatable}
            >
              {translateText('Start analysis')}
            </GlotioButton>
          </LanguageInfoWrapper>
        }
      </>
    )

  }

  return renderView();
}

const mapDispatchToProps = (dispatch) => ({
  launchTranslation: (processOrigin) => dispatch(launchNewTranslationProcess(processOrigin)),
  cancelAndLaunchTranslation: (processOrigin) => dispatch(cancelAndRestartProcess(processOrigin)),
})

export default connect(null, mapDispatchToProps)(LastAnalysisInfo);
