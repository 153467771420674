import React from 'react'
import Button from 'components/GlotioComponents/GlotioButton'
import styles from './index.module.scss'

const styleForSize = {
  small: styles.icon_button_small,
  medium: styles.icon_button_medium,
  large: styles.icon_button_large
}

/**
 *
 * @param icon
 * @param {('small'|'medium'|'large')} size
 * @param {('success'|'error'|'secondary'|'warning'|'light')} status
 * @param {string} text
 * @param {boolean} isLoading
 * @param {Function} onClick
 * @return {JSX.Element}
 * @constructor
 */
const GlotioIconButton = ({ icon, size, status, text, isLoading, onClick = () => null, className, ...rest }) => {
  const style = []
  style.push(styleForSize[size] || styleForSize.medium)
  if (!text) style.push(styles.only_icon)

  return (
    <Button
      type='button'
      variant='link'
      size={size || 'small'}
      status={status}
      className={`${style.join(' ')} ${className}`}
      isLoading={isLoading}
      onClick={onClick}
      {...rest}
    >
      {!isLoading && icon}
      {text &&
        <span>{text}</span>
      }
    </Button>
  )
}

export default GlotioIconButton
