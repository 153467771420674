import React, {useState} from 'react'

// componentes
import FirstTranslation from 'layouts/FirstTranslation'
import Step from 'components/GlotioComponents/Step'
import RadioButton from 'components/CleanUIComponents/RadioButton'
import RootPortal from 'components/CleanUIComponents/RootPortal'
import GlotioButton from 'components/GlotioComponents/GlotioButton'
import BackButton from 'components/CleanUIComponents/Buttons/BackButton'

// hooks
import useGlotio from 'hooks/useGlotio'
import useAssistantHash from 'hooks/useAssistantHash'

// constants
import constants from 'components/GlotioComponents/Wizard/constants'
import { ASSISTANT_ROUTER_HASH } from 'constants/assistant.router.hash'
import TRANSLATION_WIZARD from 'constants/translation_wizard.constants'
import { FIRST_LANGUAGE_DISCOUNT_50, FIRST_LANGUAGE_FREE } from 'constants/promo.constants'

// styles
import css from './index.module.scss'


const Questions = ({ setAssistantPage, setCurrentQuestion, clearCurrentQuestion, installedLanguages, isBigCommerce = false, isFirstLanguagePromotionApplicable = true, firstLanguagePromotion }) => {
  const { translateText } = useGlotio()
  const [ activeQuestion, setActiveQuestion ] = useState(0)
  useAssistantHash(ASSISTANT_ROUTER_HASH.FIRST_TRANSLATE_QUESTIONS)
  const canShowFirstOption = firstLanguagePromotion === FIRST_LANGUAGE_FREE && !isBigCommerce

  const getSecondOptionHighLigthedText = () => {
    if (firstLanguagePromotion === FIRST_LANGUAGE_DISCOUNT_50) {
      return translateText('First language at 50%')
    }
    if (isBigCommerce) {
      return translateText('First 10 million characters for free.')
    }
    if (firstLanguagePromotion === FIRST_LANGUAGE_FREE) {
      return translateText('First language free')
    }

    return ''
  }

  const getQuestions = () => {

    const options = [
      {
        title: translateText('I want to translate my online shop into several languages.'),
        id: constants.OPTION_TRANSLATE_MULTIPLE_LANGUAGES,
        highlightedText: isFirstLanguagePromotionApplicable ? getSecondOptionHighLigthedText() : ''
      }
    ]

    if (canShowFirstOption) {
      options.unshift({
        title: translateText('I want to test how Glotio works using the'),
        id: constants.OPTION_TRY_GLOTIO,
        highlightedText: translateText('First language free').toLowerCase()
      })
    }

    if (installedLanguages.length > 0) {
      options.push({
        title: translateText('I would like to update the languages that already exist in my online store.'),
        id: constants.OPTION_UPDATE_SHOP,
        highlightedText: false
      })
    }

    return options;

  }

  const setWhatToDoQuestion = id => {
    setActiveQuestion(id)
    setCurrentQuestion({ selectedQuestion: id });
  }

  const onContinue = () => setAssistantPage({ page: TRANSLATION_WIZARD.pages.QUESTION_DEFAULT_LANGUAGE, lang: null })
  const onGoBack = () => {
    clearCurrentQuestion()
    setAssistantPage({ page: TRANSLATION_WIZARD.pages.ANALYSE_FINISH, lang: null })
  }

  const renderButtonBar = () => {
    return (
      <RootPortal>
        <RootPortal.Grid>
          <BackButton pushBack={onGoBack} data="icon" />
          <GlotioButton
            data="button"
            type='button'
            size='large'
            variant='default'
            onClick={onContinue}
            disabled={activeQuestion === 0}
          >
            {translateText('Continue')}
          </GlotioButton>
        </RootPortal.Grid>
      </RootPortal>
    )
  }

  return (
    <FirstTranslation>
      <Step
        title={translateText('What do you want to do with Glotio?')}
        subtitle={translateText('Tell us how you want to use Glotio as first time')}
      >
        <div className={css.question_container}>
          {
            getQuestions().map((option) => {
              return (
                <label key={option.id} htmlFor={option.id} className={css.label_question}>
                  <div className={`${css.question_block}  ${activeQuestion === option.id && css.active_question}`}>
                    <div className={css.question_input}>
                      <RadioButton value={option.id} name="radioQuestion" onChange={() => setWhatToDoQuestion(option.id)} size="small" />
                    </div>
                    <div className={`${css.question_title} ${activeQuestion === option.id && css.active_question_title}`}>
                      <p>{ option.title }</p>
                      { option.highlightedText && <p className={css.question_subtitle}>{option.highlightedText}</p> }
                    </div>
                  </div>
                </label>
              )
            })
          }
        </div>
      </Step>
      {renderButtonBar()}
    </FirstTranslation>
  )
}

export default Questions
