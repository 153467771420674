import { get } from 'lodash'

// constants
import { subscriptionFrequencyType } from 'constants/subscription.constants'

// utils
import { afterDayFromDate, beforeDayFromDate, transformDate } from 'utils/Date'

/**
 * get date for manual update
 * @param {function} translateText
 * @return {(function(CurrentSubscriptions): (*))|*}
 */
export const getDateForUpdates = (translateText) => {
  const getDateForUpdate = (frequency, canUpdate, nextUpdateDate) => {
    if (frequency === subscriptionFrequencyType.UNLIMITED) {
      return ''
    }

    if (frequency === subscriptionFrequencyType.DAILY) {
      const beforeNextManualDate = beforeDayFromDate(nextUpdateDate)
      const nextManualDate = transformDate(nextUpdateDate)

      return canUpdate
        ? `${translateText('Available until')} ${beforeNextManualDate}`
        : `${translateText('Next available')} ${nextManualDate}`
    }

    const beforeNextManualDate = transformDate(nextUpdateDate)
    const nextManualDate = afterDayFromDate(nextUpdateDate)

    return canUpdate
      ? `${translateText('Available until')} ${beforeNextManualDate}`
      : `${translateText('Next available')} ${nextManualDate}`

  }

  return (currentSubscription) => {
    const { manualUpdateFrequency, autoUpdateFrequency } = get(currentSubscription, 'subscriptionPlan', {})
    const { canUpdateManually, nextManualUpdateDate, canUpdateAutomatically, nextAutoUpdateDate } = get(currentSubscription, 'subscription', null)

    const manualUpdateDate = getDateForUpdate(manualUpdateFrequency, canUpdateManually, nextManualUpdateDate)
    const autoUpdateDate =  getDateForUpdate(autoUpdateFrequency, canUpdateAutomatically, nextAutoUpdateDate)

    return { manualUpdateDate, autoUpdateDate }

  }
}

/**
 * get date for manual update
 * @param {function} translateText
 * @return {(function(CurrentSubscriptions): (*))|*}
 */
export const getUpdatesCounter = (translateText) => {
  const getUpdateAmount = ( frequency, amount, counter, canUpdate ) => {
    if (frequency === subscriptionFrequencyType.UNLIMITED) {
      return translateText('Unlimited')
    }

    if (frequency === subscriptionFrequencyType.MONTHLY) {
      return canUpdate ? (amount-counter) : 0
    }

    return canUpdate ? amount : 0
  }

  return (currentSubscription) => {
    const { manualUpdateFrequency, manualUpdateAmount, autoUpdateFrequency, autoUpdateAmount } = get(currentSubscription, 'subscriptionPlan', {})
    const {
      canUpdateManually,
      canUpdateAutomatically,
      subscriptionStats: { manualTranslationsCounter, automaticTranslationsCounter }
    } = get(currentSubscription, 'subscription', {})


    const manualCounter = getUpdateAmount(manualUpdateFrequency, manualUpdateAmount, manualTranslationsCounter, canUpdateManually, translateText)
    const automaticCounter = getUpdateAmount(autoUpdateFrequency, autoUpdateAmount, automaticTranslationsCounter, canUpdateAutomatically, translateText)

    return { manualCounter, automaticCounter }
  }
}
