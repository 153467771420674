import { useMemo } from 'react';
import { orderBy } from 'lodash';

const getWeight = (language) => {
  if (language.installed && language.translatable) {
    return 1;
  } 
  if (!language.installed && language.featured && language.translatable) {
    return 2;
  } 
  if (!language.translatable) {
    return 4;
  } 
  return 3;
};

const markPriorityLanguages = (orderedLanguages, offset) => {
  return orderedLanguages.map((language, index) => {
    const lastPriority = index >= offset;
    return { ...language, lastPriority };
  });
};

const useSortedLanguages = (languages = [], offset, showingAll) => {
  const sortedLanguages = useMemo(() => {
    const weightedLanguages = languages.map((language) => {
      const weight = getWeight(language);
      return { ...language, weight };
    });

    const orderedLanguages = orderBy(weightedLanguages, ['weight']);
    const markedLanguages = markPriorityLanguages(orderedLanguages, offset);
    const lastPriorityLanguges = markedLanguages.slice(0, offset);

    return showingAll ? markedLanguages : lastPriorityLanguges;
  }, [languages, showingAll]);

  return sortedLanguages;
};

export default useSortedLanguages;