import { subscriptionType } from 'constants/subscription.constants'
import { get } from 'lodash'

/**
 * check if the currentPlan is classic user
 * @param {CurrentSubscriptions} currentSubscription
 */
export const checkClassicUserCurrentSubscription = (currentSubscription) => {
  const currentPlanType = get(currentSubscription, 'subscriptionPlan.subscriptionPlanType', null)
  return currentPlanType === subscriptionType.CLASSIC
}
