export const TRANSLATION_BETTER_SOLUTION = {
  id: 'TRANSLATION_BETTER_SOLUTION',
  text: 'I have found a better solution for translating my online store.'
}
export const DONT_NEED_IT_ANYMORE = {
  id: 'DONT_NEED_IT_ANYMORE',
  text: 'I no longer need it.'
}
export const TRANSLATION_TOO_EXPENSIVE = {
  id: 'TRANSLATION_TOO_EXPENSIVE',
  text: 'I consider it to be expensive.'
}
export const LOW_QUALITY_TRANSLATIONS = {
  id: 'LOW_QUALITY_TRANSLATIONS',
  text: 'The quality of the translations is not good enough for my online store.'
}
export const RETURN_TO_FREE_PLAN = {
  id: 'RETURN_TO_FREE_PLAN',
  text: 'I want to go back to the Free plan'
}
export const POOR_SUPPORT = {
  id: 'POOR_SUPPORT',
  text: 'I have not been properly attended to.'
}
export const WAY_TO_USE_TOO_COMPLICATED = {
  id: 'WAY_TO_USE_TOO_COMPLICATED',
  text: 'The way to use is too complicated.'
}
export const OTHER = {
  id: 'OTHER',
  text: 'Another reason.'
}

export const CANCEL_SUBSCRIPTION_REASONS = [
  {...TRANSLATION_BETTER_SOLUTION},
  {...DONT_NEED_IT_ANYMORE},
  {...TRANSLATION_TOO_EXPENSIVE},
  {...LOW_QUALITY_TRANSLATIONS},
  {...POOR_SUPPORT},
  {...WAY_TO_USE_TOO_COMPLICATED},
  {...RETURN_TO_FREE_PLAN},
  {...OTHER},
]