import { ORIGIN_TRANSLATION } from "constants/process_origin.constants"

const actionTypes = {
  PROCESS_STATUS_START: 'process/FETCH_PROCESS_STATUS_START',
  PROCESS_STATUS_FAIL: 'process/PROCESS_STATUS_FAIL',
  PROCESS_STATUS_SUCCESS: 'process/FETCH_PROCESS_STATUS_SUCCESS',
  SET_CURRENT_PROCESS_ID: 'process/SET_CURRENT_PROCESS_ID',
  SET_CURRENT_PROCESS: 'process/SET_CURRENT_PROCESS',
  SET_CURRENT_REPLACE_PROCESS: 'process/SET_CURRENT_REPLACE_PROCESS',
  SET_CURRENT_REPLACE_PROCESS_ID: 'process/SET_CURRENT_REPLACE_PROCESS_ID',
  LAUNCH_NEW_TRANSLATION_PROCESS: 'process/LAUNCH_NEW_TRANSLATION_PROCESS',
  LAUNCH_NEW_TRANSLATION_PROCESS_FINISH: 'process/LAUNCH_NEW_TRANSLATION_PROCESS_FINISH',
  CANCEL_AND_RESTART_PROCESS: 'process/CANCEL_AND_RESTART_PROCESS',
  SET_MUST_SHOW_RESUME: 'process/SET_MUST_SHOW_RESUME',
}

/**
 * Sets the app state to fetching a new process status
 */
export const processStatusStart = (newProcessStatus) => ({
  type: actionTypes.PROCESS_STATUS_START,
  newProcessStatus,
})

/**
 * Updates the app state with an error when fetching a new process status
 * @param {string} error The error message recevied from the server call
 */
export const processStatusFail = (error) => ({
  type: actionTypes.PROCESS_STATUS_FAIL,
  payload: error,
})

/**
 * Updates the state with a success call to new process status
 * Stores the new process status
 * @param {object} process
 */
export const processStatusSuccess = (process) => ({
  type: actionTypes.PROCESS_STATUS_SUCCESS,
  payload: process,
})

/**
 * Updates the current process id to fetch from the server
 * @param {string} currentProcessId The id of the new process to fetch
 */
export const setCurrentProcessId = (currentProcessId) => ({
  type: actionTypes.SET_CURRENT_PROCESS_ID,
  payload: currentProcessId,
})

/**
 * Updates the current process with the last received from the server
 * @param {object} currentProcess The new process received from the server
 */
export const setCurrentProcess = (currentProcess) => ({
  type: actionTypes.SET_CURRENT_PROCESS,
  payload: currentProcess,
})

/**
 * Starts a new translation process on the server
 * @param {string} processLaunchedFromOrigin
 */
export const launchNewTranslationProcess = (processLaunchedFromOrigin = ORIGIN_TRANSLATION) => ({
  type: actionTypes.LAUNCH_NEW_TRANSLATION_PROCESS,
  payload: processLaunchedFromOrigin,
})

/**
 * Sets the app state to fetching a new process status
 */
export const launchNewTranslationProcessFinish = () => ({
  type: actionTypes.LAUNCH_NEW_TRANSLATION_PROCESS_FINISH,
})

/**
 * Cancels the current process and launches a new one
 */
export const cancelAndRestartProcess = (processLaunchedFromOrigin = ORIGIN_TRANSLATION) => ({
  type: actionTypes.CANCEL_AND_RESTART_PROCESS,
    payload: processLaunchedFromOrigin,
})

/**
 * Changes the status of whether to display the resume
 * @param {boolean}  shouldShow
 */
export const setMustShowResume = (shouldShow) => ({
  type: actionTypes.SET_MUST_SHOW_RESUME,
  payload: shouldShow
})

export default actionTypes
