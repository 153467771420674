const TRANSLATION_WIZARD = {
  pages: {
    ANALYSE_FINISH: 'ANALYSE_FINISH',
    QUESTION_WHAT_TO_DO_WITH_GLOTIO: 'QUESTION_WHAT_TO_DO_WITH_GLOTIO',
    QUESTION_DEFAULT_LANGUAGE: 'QUESTION_DEFAULT_LANGUAGE',
    WRONG_DEFAULT_LANGUAGE_HELP: 'WRONG_DEFAULT_LANGUAGE_HELP',
    LANGUAGE_SELECTOR_FREE: 'LANGUAGE_SELECTOR_FREE',
    LANGUAGE_SELECTOR_MULTIPLE: 'LANGUAGE_SELECTOR_MULTIPLE',
    LANGUAGE_SELECTOR_UPDATE_TYPE: 'LANGUAGE_SELECTOR_UPDATE_TYPE',
  }
}

export default TRANSLATION_WIZARD
