import React, {memo, useEffect, useRef, useState} from 'react'
import {Alert, notification, Skeleton} from 'antd'
import { cloneDeep, isEqual, isEmpty } from 'lodash'
import { connect } from 'react-redux'

// hooks
import useGlotio from 'hooks/useGlotio'
import usePlatform from 'hooks/usePlatform'
import { useCreateModal } from 'hooks/useCreateModal'

// constants
import {advanced_settings_modules} from 'constants/advanced_settings.constants'
import { CONFIRM_MODAL } from 'constants/modals.constants'


// components
import Container from 'components/CleanUIComponents/Container'
import ModalFullContent from 'components/CleanUIComponents/Modal/ModalFullContent'
import DefinitionBlock from 'components/GlotioComponents/AdvancedConfiguration/DefinitionBlock'
import ActiveProducts from 'components/GlotioComponents/AdvancedConfiguration/ActiveProducts'
import SelectAllDefinitions from 'components/GlotioComponents/AdvancedConfiguration/SelectAllDefinitions'
import Tabs from 'components/CleanUIComponents/Tabs'
import RootPortal from 'components/CleanUIComponents/RootPortal'
import GlotioButton from 'components/GlotioComponents/GlotioButton'

// selectors
import {selectProjectId} from 'redux/project/selectors'

// models
import {
  getConfigurationRequest,
  getTranslationSourcesRequest,
  setConfigurationRequest,
  setDisabledFieldsRequest
} from 'models/api/process'

// styles
import styles from './index.module.scss'



/**
 * Modal for subscription info
 * @param {boolean} isOpen
 * @param {Function} handleClose
 * @param {Number} projectId
 * @return {JSX.Element}
 */
const AdvancedConfiguration = ({

  isOpen = false,
  handleClose = () => {},
  onChangeRouteParam = () => {},
  projectId,
  withModal = false,
  section = ""

}) => {
  const { translateText, formatNumber } = useGlotio()
  const { onOpenModal, onCloseModal } = useCreateModal()
  const advanced_settings = advanced_settings_modules(translateText)
  const [defaultValue, setDefaultVale] = useState(advanced_settings()[section.toUpperCase()] || advanced_settings().CATALOG) // or match params from url
  const [sourceData, setSourceData] = useState([])
  const [justActiveFields, setJustActiveFields] = useState(null)
  const [loadingDefintions, setLoadingDefinitions] = useState(true)
  const [allDisabledFields, setAllDisabledFields] = useState(false)
  const [isLoading, setIsloading] = useState({
    isLoadingConfiguration: false,
    isLoadingSource: false
  });

  const { isShopify } = usePlatform()
  const refSourceDate = useRef({})
  const refJustActiveFields = useRef(null)

  useEffect(() => {
    getSource()
    loadConfiguration()
  }, [projectId])

  /**
   * loadSelectAllStatus: initialize the status of the select all fields on each selection module
   * @returns {boolean}
  */
  const loadSelectAllStatus = (disabledFieldSource) => !Object.values(disabledFieldSource).some( field => !isEmpty(field))

  /**
   * loadConfiguration: call action to translate only active products in the shop
   * @returns {void}
  */
  const loadConfiguration = () => {

    getConfigurationRequest({
      projectId,
      callback: ({ error, result: { filter_active_entities } }) => {
        if (!error) {
          filter_active_entities = filter_active_entities || false
          setJustActiveFields(filter_active_entities)
          refJustActiveFields.current = filter_active_entities
        } else {
          notification.error({
            message: translateText('Error getting settings'),
            description: translateText('Error getting settings, please try again later'),
            duration: 3,
          })
        }

        setIsloading((prevLoadings) => ({...prevLoadings, isLoadingConfiguration: false}))
      },
    })
  }

  /**
   * getSource: get the initial Data
   * @returns {void}
  */
  const getSource = () =>  {

    const categoryScheme = {
      [advanced_settings().CATALOG.id]: { values: [], disabledFields: {}},
      [advanced_settings().MODULES.id]: { values: [], disabledFields: {}},
      [advanced_settings().THEMES.id]: { values: [], disabledFields: {}},
      [advanced_settings().OTHERS.id]: { values: [], disabledFields: {}}
    }

    getTranslationSourcesRequest({
      projectId,
      callback: ({ result, error }) => {
        if (error) {
          console.log('error', error)
        } else {
          const categories = Object.keys(advanced_settings())
          result.forEach((definition) => {
            const { meta_categories_tree, _id: id, disabled_fields } = definition
            categories.forEach(categoryKey => {
              if (advanced_settings()[categoryKey].db.every((item) => meta_categories_tree.includes(item)) &&
              !meta_categories_tree.includes('mails')) {
                categoryScheme[categoryKey].values.push(definition)
                categoryScheme[categoryKey].disabledFields[id] = disabled_fields
              }
            })
          })

          // avoid clone references with cloneDeep
          refSourceDate.current = cloneDeep(categoryScheme)
          setSourceData(categoryScheme)
          setLoadingDefinitions(false)
          setAllDisabledFields(() => loadSelectAllStatus(categoryScheme[defaultValue.id].disabledFields))
          setIsloading((prevLoadings) => ({...prevLoadings, isLoadingSource: false}))
        }
      },
    })
  }

  /**
   * getDisabledFields
   * @param {String} definitionId
   * @return {string[] | []}
  */
  const getDisabledFields = (definitionId) => {
    const { disabledFields } = sourceData[defaultValue.id]
    return disabledFields[definitionId] ? disabledFields[definitionId] : []
  }

  /**
   * handleCheckboxChange
   * @param {String} value
   * @param {boolean} checked
   * @param {string} key
  */
  const handleCheckboxChange = ({ value, checked, key }) => {

    const updateDisabledFields = {...sourceData[defaultValue.id].disabledFields}
    updateDisabledFields[key] = updateDisabledFields[key] || []

    if (checked) {
      updateDisabledFields[key].splice(updateDisabledFields[key].indexOf(value), 1)
    } else {
      updateDisabledFields[key].push(value)
    }

    setSourceData((prevSource) => (
      {
        ...prevSource,
        [defaultValue.id]: {
          values: [...sourceData[defaultValue.id].values],
          disabledFields: { ...updateDisabledFields }
        }
      }
    ))

  }

  /**
   * onSaveChanges: save the changes at all field in all modules
   * @returns {void}
  */
  const onSaveChanges = () => {

    const clonedSource = cloneDeep(sourceData)
    const categories = Object.keys(advanced_settings())

    // @ayose => It can be more efficient only send the fields which have been modified..
    const disabledFields = categories.reduce((acc, categoryKey) => ({
      ...acc,
      ...clonedSource[categoryKey].disabledFields
    }),{})

    setIsloading((prevLoadings) => ({...prevLoadings, isLoadingSource: true, isLoadingConfiguration: true}))
    setDisabledFieldsRequest({
      projectId,
      disabledFields,
      callback: ({ error }) => {
        if (!error) {
          notification.success({
            key: 'optionsSuccessNotification',
            message: translateText('Changes saved'),
            className: 'notification-no-closable',
          })
          getSource()
          loadConfiguration()
        } else {
          notification.error({
            key: 'optionsErrorNotification',
            message: translateText('The changes could not be saved. Please try again.'),
            className: 'notification-no-closable',
          })
        }
      },
    })

    if (refJustActiveFields.current !== justActiveFields) {
      setConfigurationRequest({
        projectId,
        justActiveFields,
        callback: ({ error }) => {
          if (!error) {
            notification.success({
              message: translateText('Settings successfully saved'),
              duration: 3,
            })

          } else {
            notification.error({
              message: translateText('Error configuring your settings'),
              description: translateText(
                'An error occurred while configuring your settings. Try again',
              ),
              duration: 3,
            })
          }
        },
      })
    }


  }

  /**
   * handleSelectAllFields: handle the action depending of the selectAll status (enable or disable all fields)
   * @returns {void}
  */
  const handleSelectAllFields = (selectAll) => {

    const { disabledFields, values:definitions } = {...sourceData[defaultValue.id]}

    if (selectAll) {

        const fieldsToEnable = {}
        const fields = Object.keys(disabledFields)
        fields.forEach((field) => {
          fieldsToEnable[field] = []
        })
        setSourceData((prevSource) => ({
          ...prevSource,
          [defaultValue.id]: {
            values: [...sourceData[defaultValue.id].values],
            disabledFields: fieldsToEnable
          }
        }))

    } else {

        const fieldsToDisable = {...disabledFields}
        definitions.forEach(({ _id, fields, translatable }) => {
          if (translatable) {
            const id = _id
            const fieldNames = fields.map((field) => field.field)
            fieldsToDisable[id] = [...fieldNames]
          }
        })
        setSourceData((prevSource) => ({
          ...prevSource,
          [defaultValue.id]: {
            values: [...sourceData[defaultValue.id].values],
            disabledFields: fieldsToDisable
          }
        }))

    }

    setAllDisabledFields(selectAll)
    onCloseModal()

  }

  /**
   * showSelectedAllConfirmModal: Show a confirmation modal to start or not the process of select/unselect all fields
   * @returns {void}
  */
  const showSelectedAllConfirmModal = checked => {

    const message = {
      type: CONFIRM_MODAL,
      description: translateText(
        checked
          ? 'Are you sure you want to enable all fields on this section?'
          : 'Are you sure you want to disable all fields on this section?',
      ),
      buttons: [{
        text: translateText('Cancel'),
        options: {
          type: "button",
          variant: "outlined",
          size: 'large',
          className: styles.modal_button
        },
        callback: () => onCloseModal(),
      },
      {
        text: translateText('Continue'),
        options: {
          type: "button",
          variant: "default",
          size: 'large',
          className: styles.modal_button
        },
        callback: () => handleSelectAllFields(checked),
      }]

    }

    onOpenModal({...message})

  }

  /**
   * onChangeModule: Change the current module and set the current value of the select all checkbox
   * @returns {void}
  */
  const onChangeModule = (selectedModule) => {

    setDefaultVale(selectedModule)
    setAllDisabledFields(() => loadSelectAllStatus(sourceData[selectedModule.id].disabledFields))
    if (!withModal) {
      onChangeRouteParam(selectedModule.value)
    }
  }

  const renderModalActions = () => {
    return (
      <div className={styles.button_container}>
        <GlotioButton type='button' variant='outlined' size='large' onClick={() => handleClose()}>{translateText('Cancel')}</GlotioButton>
        <GlotioButton type='button' variant='default' size='large' onClick={onSaveChanges} disabled={canSave}>{translateText('Save')}</GlotioButton>
      </div>
    )
  }

  const getContent = () => {
    return (
      <div className={withModal ? styles.advanced_configuration_wrapper_modal : styles.advanced_configuration_wrapper}>
        <h2>{translateText('Advanced translation settings')}</h2>
        <p>{translateText('You can select the fields you want to translate. The settings you make now will be saved by default and will be applied to all future translations and updates you make.')}</p>
        <div className={styles.advice_message_wrapper}>
          {
            showModulesSupportAdvice && <Alert type="info" showIcon message={translateText("Glotio translates each module independently. If your module isn't supported, contact us and we'll study your case.")} />
          }
        </div>
        <div className={styles.configuration_container}>
          <Tabs options={Object.values(advanced_settings())} setDefaultValue={(selectedModule) => onChangeModule(selectedModule)} defaultValue={defaultValue} />
        </div>
        {showActiveProducts &&
          <ActiveProducts
            title={translateText('Translate only active products')}
            subtitle={translateText('By activating this option, Glotio will only translate those products of your online store that are active and publicly visible.')}
            onChange={(checked) => setJustActiveFields(checked)}
            isChecked={justActiveFields}
            id={1}
          />
        }
        <div className={styles.select_all_wrapper}>
          <SelectAllDefinitions
            title={translateText('Select all')}
            subtitle=""
            onChange={(checked) => showSelectedAllConfirmModal(checked)}
            isChecked={allDisabledFields}
            id={2}
          />
        </div>
        <div className={styles.definitions_container}>
          {
            sourceData[defaultValue.id].values.map((definition) => {
              const { _id: id } = definition
              return (
                <DefinitionBlock
                  key={id}
                  value={id}
                  definition={definition}
                  translateText={translateText}
                  formatNumber={formatNumber}
                  disabledFields={getDisabledFields(id)}
                  handleCheckboxChange={handleCheckboxChange}
                />
              )
            })
          }
        </div>
      </div>
    )
  }

  const renderButtonBar = () => {
    return (
      <RootPortal>
        <RootPortal.Basic>
          <GlotioButton
            data="button"
            type='button'
            size='large'
            variant='default'
            onClick={onSaveChanges}
            disabled={canSave}
            isLoading={isLoading.isLoadingConfiguration || isLoading.isLoadingSource}
          >
            {translateText('Save')}
          </GlotioButton>
        </RootPortal.Basic>
      </RootPortal>
    )
  }

  const renderContent = () => {
    if (withModal) {
      return (
        <ModalFullContent
          isOpen={isOpen}
          handleClose={handleClose}
          closeable={false}
          actions={renderModalActions()}
        >
          <div>{getContent()}</div>
        </ModalFullContent>
      )
    }
    return (
      <Container>
        {getContent()}
        {renderButtonBar()}
      </Container>
    )

  }

  const showModulesSupportAdvice = defaultValue.id === advanced_settings().MODULES.id
  const canSave = isEqual(refSourceDate.current, sourceData) && refJustActiveFields.current === justActiveFields
  const showActiveProducts = !isShopify() && (defaultValue.id === advanced_settings().CATALOG.id && justActiveFields !== null)

  if (loadingDefintions) return (!withModal ? <Container><Skeleton active /></Container> : <Skeleton active />)

  return renderContent()

}
const mapStateToProps = (state) => ({
  projectId: selectProjectId(state)
})

export default connect(mapStateToProps, null)(memo(AdvancedConfiguration))
