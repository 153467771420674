// Detect when a user has a free plan. Actually is used to handle the summaryField view at update languages

// constants
import { CLASSIC_START_DATE, subscriptionType } from 'constants/subscription.constants'

// utils
import { getDateDiff } from 'utils/Date'
import { get } from 'lodash'

export const isFreeUserWhileProcessIsNotFinished = (translationUpdatesExtra) => {

    const { currentAvailableChars } = translationUpdatesExtra;

    return currentAvailableChars === 0

}

// TODO Done for Classic Plan
export const isOldFreeUserConfirmed = (currentSubscription, project) => {
  return currentSubscription.subscription.subscriptionPlanType === subscriptionType.FREE && currentSubscription.subscription.hasConfirmedSubscriptionPlan && getDateDiff(CLASSIC_START_DATE, project.created_at) > 0
}

export const checkIsFreeUser = (currentSubscription) => {
  const subscriptionPlanType = get(currentSubscription, 'subscriptionPlan.subscriptionPlanType', subscriptionType.FREE)
  return subscriptionPlanType === subscriptionType.FREE
}
