import actionTypes from './actions'

const initialState = {
  currentProcess: null,
  currentProcessID: null,
  error: null,
  fetchingNewProcess: false,
  processLaunchedFromOrigin: null,
  mustShowResume: true
}

const processReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.PROCESS_STATUS_START:
    case actionTypes.PROCESS_STATUS_FAIL:
      return {
        ...state,
        error: payload
      }
    case actionTypes.PROCESS_STATUS_SUCCESS:
      return {
        ...state,
        currentProcess: payload
      }
    case actionTypes.SET_CURRENT_PROCESS_ID:
      return {
        ...state,
        currentProcessID: payload,
      }
    case actionTypes.SET_CURRENT_PROCESS:
      return {
        ...state,
        currentProcess: payload,
      }
    case actionTypes.LAUNCH_NEW_TRANSLATION_PROCESS:
    case actionTypes.CANCEL_AND_RESTART_PROCESS:
      return {
        ...state,
        processLaunchedFromOrigin: payload,
        fetchingNewProcess: true,
      }
    case actionTypes.LAUNCH_NEW_TRANSLATION_PROCESS_FINISH:
      return {
        ...state,
        fetchingNewProcess: false,
      }
    case actionTypes.SET_MUST_SHOW_RESUME:
      return {
        ...state,
        mustShowResume: payload
      }
    default:
      return state
  }
}

export default processReducer
