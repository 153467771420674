import React from 'react';

// components
import CardCheckbox from 'components/CleanUIComponents/Checkbox/CardCheckbox';

const SelectAllDefinitions = ({id, title, subtitle, isChecked, onChange, isDisabled = false}) => {
  return (
    <CardCheckbox
      value={id} 
      title={title}
      subtitle={subtitle}
      isChecked={isChecked}
      isDisabled={isDisabled}
      onChange={checked => onChange(checked)}
    />
  )
}

export default SelectAllDefinitions;