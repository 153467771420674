import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

// utils
import Process from 'utils/Process'

// components
import { Progress } from 'antd'
import withGlotio from 'hoc/withGlotio'

// selectors
import { selectCurrentProcess } from 'redux/process/selectors'

// styles
import css from './styles.module.scss'

/**
 * Import type definitions
 * @typedef { import("utils/Process").ProcessObject } Process
 * @typedef { import("utils/Process").PipeProcessStep } PipeProcessStep
 */

/**
 * @typedef {Object<string, any>} StepsData
 * @property {number} processSteps The number of total steps
 * @property {number} currentStep The number of the current step
 * @property {number} [startStep] An optional startStep
 *
 * @typedef {Object<number, number>} StepsWeights A list with the percentage for each step
 *
 * @typedef {Object<string, any>} GlobalProgress
 * @property {number} globalProgress
 * @property {boolean} isProcessCompleted
 */

export class GlobalStatusBar extends PureComponent {
  /**
   * Returns a user friendly message based on the current subprocess
   *
   * @param {string} subprocess
   * @returns {string} The message to display
   */
  getFriendlyText = (subprocess) => {
    const { translateText } = this.props

    switch (subprocess) {
      case Process.subProcess.DOWNLOAD:
        return translateText('Downloading texts')

      case Process.subProcess.ESTIMATE_COLLECTOR:
      case Process.subProcess.TRANSLATION_COLLECTOR:
      case Process.subProcess.SUM_COLLECTOR:
        return translateText('Analyzing texts')

      case Process.subProcess.INSTALL_LANGUAGES:
        return translateText('Installing languages')

      case Process.subProcess.TRANSLATION:
        return translateText('Translating texts')

      case Process.subProcess.UPLOAD:
        return translateText('Uploading texts')

      default:
        return translateText('Loading')
    }
  }

  /**
   * Returns a user friendly message based on the current subprocess to render with the processed elements info
   * @param {string} subprocess
   * @returns {string} The message to display
   */
  getFriendlyProgressText = (subprocess) => {
    const { translateText } = this.props

    switch (subprocess) {
      case Process.subProcess.DOWNLOAD:
        return translateText('downloaded elements')

      case Process.subProcess.ESTIMATE_COLLECTOR:
      case Process.subProcess.TRANSLATION_COLLECTOR:
      case Process.subProcess.SUM_COLLECTOR:
        return translateText('analyzed elements')

      case Process.subProcess.TRANSLATION:
        return translateText('translated elements')

      case Process.subProcess.UPLOAD:
        return translateText('uploaded elements')

      default:
        return ''
    }
  }

  /**
   * Gets the text of the action being performed by the process
   * @param {Process} process
   * @return {string}
   */
  getProgressBarSubtitle = (process) => {
    const { translateText } = this.props
    if (Process.isProcessDownloading(process)) {
      return translateText('downloaded')
    }

    if (Process.isProcessAnalysing(process)) {
      return translateText('analyzed')
    }

    if (Process.isProcessInstalling(process)) {
      return translateText('installed')
    }

    if (Process.isProcessTranslate(process)) {
      return translateText('translated')
    }

    return translateText('updated')
  }

  /**
   * Get the mode prop for <GlobalStatus /> to calculate correctly the number of steps
   * and get the current percentage progress
   * @param {Process} process
   * @returns {"translation" | "download"} The mode to pass to the component
   */
  static getGlobalStatusMode = (process) => {
    const { progress, pipeProcess } = process
    const [currentStep] = progress.split('/')

    let askLangsStep
    pipeProcess.forEach((item, index) => {
      if (item.processName === Process.subProcess.ASK_LANGS_TO_TRANSLATE || item.processName === Process.subProcess.ASK_PAYMENTS_LIMITS) {
        askLangsStep = index + 1
      }
    })

    let statusBarMode
    if (currentStep < askLangsStep) {
      statusBarMode = 'download'
    } else {
      statusBarMode = 'translation'
    }
    return statusBarMode
  }

  /**
   * Gets the process steps info
   * @param {Process} process
   * @returns {StepsData}
   */
  static getCurrentAndProcessSteps = (process) => {
    let result = { processSteps: null, currentStep: null }

    if (process && process.progress && process.pipeProcess) {
      const mode = GlobalStatusBar.getGlobalStatusMode(process)
      if (mode === 'translation') {
        result = GlobalStatusBar.getTranslationSteps(process)
      } else if (mode === 'download') {
        result = GlobalStatusBar.getDownloadSteps(process)
      }
    }

    return result
  }

  /**
   * Calculates the number of steps the download process has and
   * gets the current step of the process
   * @param {Process} process
   * @returns {StepsData}
   */
  static getDownloadSteps = (process) => {
    const { progress, pipeProcess } = process
    const [currentStep] = progress.split('/')

    let downloadFinishStep
    pipeProcess.forEach((item, index) => {
      if (item.processName === Process.subProcess.ASK_LANGS_TO_TRANSLATE || item.processName === Process.subProcess.ASK_PAYMENTS_LIMITS) {
        downloadFinishStep = index
      }
    })

    return {
      startStep: 0,
      processSteps: parseInt(downloadFinishStep, 10),
      currentStep: parseInt(currentStep, 10),
    }
  }

  /**
   * Calculates the number of steps the translation process has
   * Gets the current step of the process
   * @param {Process} process
   * @returns {StepsData}
   */
  static getTranslationSteps = (process) => {
    const { progress } = process
    const [currentStep, totalSteps] = progress.split('/')

    return {
      startStep: 0,
      processSteps: parseInt(totalSteps, 10),
      currentStep: parseInt(currentStep, 10),
    }
  }

  /**
   * Maps each step with a percent weight
   * SUM_COLLECTORs have a 5% weight over the total
   * @param {StepsData} steps
   * @param {Process} process
   * @returns {StepsWeights}
   */
  static getStepsWeight = (steps, process) => {
    if (!process || !process.pipeProcess) return null
    const { processSteps, startStep } = steps
    const pipeProcess = [...process.pipeProcess]

    const standardWeight = (100 - 5) / (processSteps - 1)
    const customPipe = pipeProcess.slice(startStep, startStep + processSteps)

    const stepsWeight = {}
    for (let i = 0; i < processSteps; i += 1) {
      if (customPipe[i].processName === Process.subProcess.SUM_COLLECTOR) {
        stepsWeight[i] = 5
      } else {
        stepsWeight[i] = Math.floor(standardWeight)
      }
    }

    return stepsWeight
  }

  /**
   * ### Calculates the total progress percentage of the current process
   * The SUM_COLLECTOR process should take up to 5% of the global progress
   * to make it look smoother
   *
   * @param {{ steps: StepsData, process: Process }} props
   * @returns {GlobalProgress}
   */
  static getGlobalProgress = ({ steps, process }) => {
    const { processSteps, currentStep } = steps
    const stepsWeight = GlobalStatusBar.getStepsWeight(steps, process)
    let updatedProgress = 0

    // controls if the process has finished
    let isFinished = false
    if (Process.hasProcessFinished(process)) {
      updatedProgress = 100
      isFinished = true
    }

    // controls if the process is still running
    if (Process.isProcessProcessing(process) && processSteps && currentStep && stepsWeight) {
      const finishedSteps = currentStep - 1
      if (finishedSteps > 0) {
        // has to go through each step adding its corresponding weight
        for (let i = 0; i < finishedSteps; i += 1) {
          updatedProgress += stepsWeight[i]
        }
      }

      // if the step isnt still finished then it only adds the percent of the step
      if (process && process.subProgress != null) {
        const { processed_elements, total_elements } = process.subProgress
        const relativeProgress = parseInt(
          (processed_elements * stepsWeight[currentStep - 1]) / total_elements,
          10,
        )
        updatedProgress += relativeProgress
      }
    }
    return {
      globalProgress: Math.round(updatedProgress),
      isProcessCompleted: isFinished,
    }
  }

  /**
   * Generates a string to show to the user the processed elements
   * @param {Process} process The current "process" object
   * @returns {string} The message to render above the progress bar
   */
  getProcessedElementsInfo = (process) => {
    const { translateText } = this.props

    // Initial message for download when has no progress info
    if (
      (Process.isDownload(process) || Process.isUpload(process)) &&
      !Process.hasSubProgress(process)
    ) {
      return translateText('Connecting with your shop...')
    }

    // as the SUM_COLLECTOR has no subProgress info we render a custom message for this step
    if (Process.isSumCollector(process) && !Process.hasSubProgress(process)) {
      if (GlobalStatusBar.getGlobalStatusMode(process) === 'download') {
        return translateText('Loading prices...')
      }

      if (GlobalStatusBar.getGlobalStatusMode(process) === 'translation') {
        return translateText('Connecting with translation service...')
      }
    }

    // while the translation gets its first progress info show default message
    if (Process.isTranslation(process) && !Process.hasSubProgress(process)) {
      return translateText('Launching translation...')
    }

    if (!process || !process.subProgress) return ''

    const { formatNumber } = this.props
    const {
      subProgress: { total_elements, processed_elements },
    } = process

    const progress = `${formatNumber(processed_elements)} / ${formatNumber(total_elements)}`
    const text = this.getFriendlyProgressText(process.currentSubProcess)

    return `${progress} ${text}`
  }

  render() {
    const { process, translateText } = this.props
    const steps = GlobalStatusBar.getCurrentAndProcessSteps(process)
    const barProgress = GlobalStatusBar.getGlobalProgress({ steps, process })

    const { globalProgress, isProcessCompleted } = barProgress

    if (isProcessCompleted) {
      return (
        <div className={css.statusProgressBar}>
          <div className={css.process_info}>
            <p
              className={`${css.process_info_title} text-success`}
            >
              {translateText('Translation finished')}
            </p>
            <p className={css.process_info_processed} />
          </div>
          <Progress
            className={css.progress_bar}
            percent={globalProgress}
          />
        </div>
      )
    }

    return (
      <div className={css.statusProgressBar}>
        <div className={css.statusInfo}>
          <div>
            <p className={css.process_info_percentage}>
              {globalProgress}%
            </p>
            <p className={css.process_info_processed} style={{ display: 'inline-block' }}>
              {this.getProgressBarSubtitle(process)}
            </p>
          </div>
          <div style={{ alignSelf: 'end', marginBottom: '2px' }}>
            <p className={css.process_info_processed}>
              {this.getProcessedElementsInfo(process)}
            </p>
          </div>
        </div>
        <Progress
          className={css.progress_bar}
          percent={globalProgress}
          status="active"
          strokeColor={{ '0%': '#4738c3', '100%': '#d71658' }}
        />
      </div>
    )
  }
}

GlobalStatusBar.propTypes = {
  process: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  process: selectCurrentProcess(state),
})

export default connect(mapStateToProps)(withGlotio(GlobalStatusBar))
