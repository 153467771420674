import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";
import { CaptureConsole } from '@sentry/integrations';

const SENTRY_DENY_URLS = [/http(s)?:\/\/script\.hotjar\.com/]

const SentryInitializer = (logger = null) => {
  if (!process.env.REACT_APP_SENTRY_URL) {
    return false
  }

  if (Sentry.getCurrentHub().getClient()) {
    return true
  }

  try {
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_URL,
      environment: process.env.REACT_APP_NODE_ENVIROMENT,
      replaysSessionSampleRate: 0.0,
      replaysOnErrorSampleRate: 1.0,
      denyUrls: SENTRY_DENY_URLS,
      integrations: [
        new BrowserTracing(),
        new Sentry.Replay({
          maskAllText: true,
          blockAllMedia: true
        }),
        new CaptureConsole({
          levels: ['error']
        })
      ],
      tracesSampleRate: 0.2,
      release: `glotio-front@${process.env.REACT_APP_GLOTIO_VERSION}`,
    })
    return true
  } catch (e) {
    if (logger) {
      logger(e.message)
    }
    return false
  }
}

export default SentryInitializer
