import { CustomScriptInDom } from 'utils/inject-script-in-dom'

const zendeskInitializer = (logger = null) => {

  if (typeof zE === 'function') {
    return true
  }

  try {
    const id = 'ze-snippet'
    const src = `https://static.zdassets.com/ekr/snippet.js?key=${process.env.REACT_APP_ZENDESK_CHAT_KEY}`
    const onload = () => {
      document.dispatchEvent(new CustomEvent('zendesk:load', {
        detail: {
          loaded: true,
        },
      }))
    }
    CustomScriptInDom({ id, src, onload })
    return true
  } catch (e) {
    if (logger) {
      logger(e.message)
    }
    return false
  }
}

export default zendeskInitializer
