import React from 'react'
import { Link } from 'react-router-dom'
import useGlotio from 'hooks/useGlotio'
import { generateComponentUuid } from 'utils/Utils'
import styles from './index.module.scss'

/**
 * @typedef Option
 * @property {string} url
 * @property {string} key
 */

/**
 *
 * @param {Option[]} options
 * @param {string} pathname
 * @return {JSX.Element}
 * @constructor
 */
const Tabs = ({ options, pathname, className = '' }) => {
  const { translateText } = useGlotio()

  return (
    <div className={`${styles.submenu_tabs} ${className}`}>
      {options.map((option, index) => (
        <Link key={generateComponentUuid(index)} to={option.url} className={pathname === option.url ? styles.tabs_selected : styles.tabs_no_selected}>
          {translateText(option.key)}
        </Link>
      ))}
    </div>
  )
}

export default Tabs
