import React from 'react'

// components
import ModalFullContent from 'components/CleanUIComponents/Modal/ModalFullContent'
import LoginPage from 'pages/login'

// assets
import logoImg from 'assets/images/logos/glotio_logo_purple.svg'

// styles
import styles from './index.module.scss'

const LoginModal = ({ isOpen, handleClose }) => {
  return (
    <ModalFullContent isOpen={isOpen} handleClose={handleClose} isFullHeight>
      {isOpen &&
        <div className={styles.login_wrapper}>
          <div className={styles.login_logo}>
            <picture>
              <img src={logoImg} alt="glotio logo" />
            </picture>
          </div>
          <LoginPage hasLoginWith={false} />
        </div>
      }
    </ModalFullContent>
  )
}

export default LoginModal
