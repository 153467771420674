const actionTypes = {
  FETCH_LANGUAGES_START: 'languages/FETCH_LANGUAGES_START',
  FETCH_LANGUAGES_SUCCESS: 'languages/FETCH_LANGUAGES_SUCCESS',
  FETCH_LANGUAGES_FAIL: 'languages/FETCH_LANGUAGES_FAIL'
}

export const fetchLanguagesStart = (loadingMessage = "") => ({
  type: actionTypes.FETCH_LANGUAGES_START,
  payload: loadingMessage
})

export const fetchLanguagesSuccess = (languages) => ({
  type: actionTypes.FETCH_LANGUAGES_SUCCESS,
  payload: languages,
})

export const fetchLanguagesFail = (error) => ({
  type: actionTypes.FETCH_LANGUAGES_FAIL,
  payload: error,
})

export default actionTypes
