const actions = {
  LOGIN_START: 'user/LOGIN_START',
  LOGIN_SUCCESS: 'user/LOGIN_SUCCESS',
  LOGIN_FAIL: 'user/LOGIN_FAIL',
  SIGNUP_SUCCESS: 'user/SIGNUP_SUCCESS',
  CHECK_USER_SESSION: 'user/CHECK_USER_SESSION',
  LOGOUT: 'user/LOGOUT',
  LOGOUT_SUCCESS: 'user/LOGOUT_SUCCESS',
  LOGOUT_FAIL: 'user/LOGOUT_FAIL',
  CHECK_ACCOUNT: 'user/CHECK_ACCOUNT',
  CHECK_ACCOUNT_SUCCESS: 'user/CHECK_ACCOUNT_SUCCESS',
  SET_CURRENT_USER: 'user/SET_CURRENT_USER',
  SAVE_USER_CHANGES: 'user/SAVE_USER_CHANGES',
  FETCH_USER_START: 'user/FETCH_USER_START',
  FETCH_USER_SUCCESS: 'user/FETCH_USER_SUCCESS',
  UPDATE_FIRST_SESSION: 'user/UPDATE_FIRST_SESSION',
  CHECK_IF_FIRST_USER_SESSION: 'user/CHECK_IF_FIRST_USER_SESSION',
  UPDATE_TOKEN: 'user/UPDATE_TOKEN',
  RENEW_SESSION_START: 'user/RENEW_SESSION_START',
  RENEW_SESSION_SUCCESS: 'user/RENEW_SESSION_SUCCESS',
  RENEW_SESSION_FAIL: 'user/RENEW_SESSION_FAIL',
  SET_USER_ADMIN: 'user/SET_USER_ADMIN',
  CLOSE_OLDER_SESSIONS: 'user/CLOSE_OLDER_SESSIONS',
  SPREAD_AUTH_INFO: 'user/SPREAD_AUTH_INFO',
  UPDATE_AUTH_INFO: 'user/UPDATE_AUTH_INFO'

}

export default actions

export const loginUserStart = ({ loading = true, ...rest }) => ({
  type: actions.LOGIN_START,
  payload: { loading, ...rest },
})

export const loginUserSuccess = (authData) => ({
  type: actions.LOGIN_SUCCESS,
  payload: authData,
})

export const loginUserFail = (error) => ({
  type: actions.LOGIN_FAIL,
  payload: error,
})

export const checkUserSession = (entryPoint) => ({
  type: actions.CHECK_USER_SESSION,
  payload: entryPoint,
})

export const logout = (data) => ({
  type: actions.LOGOUT,
  payload: data,
})

export const logoutSuccess = () => ({
  type: actions.LOGOUT_SUCCESS,
})

export const logoutFail = (error) => ({
  type: actions.LOGOUT_FAIL,
  payload: error,
})

export const checkAccount = (accountData) => ({
  type: actions.CHECK_ACCOUNT,
  payload: accountData,
})

export const checkAccountSuccess = (responseData) => ({
  type: actions.CHECK_ACCOUNT_SUCCESS,
  payload: responseData,
})

export const setCurrentUser = (currentUser) => ({
  type: actions.SET_CURRENT_USER,
  payload: currentUser,
})

export const saveUserChanges = (userChanges) => ({
  type: actions.SAVE_USER_CHANGES,
  payload: userChanges,
})

export const fetchUserStart = (userId, origin) => ({
  type: actions.FETCH_USER_START,
  payload: userId,
  origin   // detect where the user null comes from
})

export const fetchUserSuccess = (userData) => ({
  type: actions.FETCH_USER_SUCCESS,
  payload: userData,
})

export const checkIfFirstUserSession = () => ({
  type: actions.CHECK_IF_FIRST_USER_SESSION,
})

export const updateFirstSession = (payload) => ({
  type: actions.UPDATE_FIRST_SESSION,
  payload,
})

export const updateToken = (token) => ({
  type: actions.UPDATE_TOKEN,
  payload: token,
})

export const renewSessionStart = () => ({
  type: actions.RENEW_SESSION_START,
})

export const renewSessionSuccess = (newToken) => ({
  type: actions.RENEW_SESSION_SUCCESS,
  payload: newToken,
})

export const renewSessionFail = () => ({
  type: actions.RENEW_SESSION_FAIL,
})

export const setUserAdmin = (isAdmin) => ({
  type: actions.SET_USER_ADMIN,
  payload: isAdmin,
})

export const onCloseOlderSessions = () => ({
  type: actions.CLOSE_OLDER_SESSIONS
})

export const onSpreadAuthInfo = () => ({
  type: actions.SPREAD_AUTH_INFO,

})

export const onUpdateAuthInfo = (payload) => ({
  type: actions.UPDATE_AUTH_INFO,
  payload
})
