import Request from 'models/Request'

export const getStripeSetupIntentRequest = ({ accountId, callback }) =>
  Request.fetch({
    key: 'getSetupIntent',
    url: `/api/v2/account/${accountId}/payment_method/new_setup_intent/stripe`,
    method: 'GET',
    callback,
  })

export const setPaymentMethodRequest = ({ paymentObject, accountId, callback }) =>
  Request.fetch({
    key: 'setpaymentmethod',
    url: `/api/v2/account/${accountId}/payment_method`,
    method: 'PUT',
    params: {
      setup_intent: paymentObject,
      account_id: accountId,
      payment_gateway: 'STRIPE',
    },
    callback,
  })

export const payPendingInvoiceRequest = ({ projectId, invoiceId, totalToPay, callback }) =>
  Request.fetch({
    key: 'payPendingInvoice',
    url: `/api/v2/project/${projectId}/invoices/pay/${invoiceId}`,
    method: 'POST',
    params: {
      totalToPay
    },
    callback
  })

export const payTranslationsOffersRequest = ({ projectId, translationBatchId, params, callback }) =>
  Request.fetch({
    key: 'payTranslationsOffers',
    url: `/api/v2/project/${projectId}/translation-offers/pay/${translationBatchId}`,
    method: 'POST',
    params,
    callback,
  })


  export const getSubscriptionsPaymentLink = ({ projectId, params, callback }) =>
  Request.fetch({
    key: 'getSubscriptionsPaymentLink',
    url: `/api/v2/project/${projectId}/subscription/generate-payment-link`,
    method: 'POST',
    params,
    callback,
  })

  export const getTranslationOffersPaymentLink = ({ projectId, translationBatchId, params, callback }) =>
  Request.fetch({
    key: 'getSubscriptionsPaymentLink',
    url: `/api/v2/project/${projectId}/translation-offers/generate-payment-link/${translationBatchId}`,
    method: 'POST',
    params,
    callback,
  })

  export const getSubscriptionConfirmPaymentLink = ({ projectId, uuid, chargeId, callback }) =>
  Request.fetch({
    key: 'getConfirmPaymentLink',
    url: `/api/v2/project/${projectId}/subscription/confirm-payment-link/${uuid}?charge_id=${chargeId}`,
    method: 'PUT',
    callback,
  })

  export const getManualConfirmPaymentLink = ({ projectId, uuid, chargeId, callback }) =>
  Request.fetch({
    key: 'getManualConfirmPaymentLink',
    url: `/api/v2/project/${projectId}/translation-offers/confirm-payment-link/${uuid}?charge_id=${chargeId}`,
    method: 'PUT',
    callback,
  })