// Show extra characters section in the summary field when there are charsToPay

import { isFreeUserWhileProcessIsNotFinished } from "./isFreeUser";

export const shouldShowExtraCharsSection = (translationUpdatesExtra) => {

  const { charsToPay = 0 } = translationUpdatesExtra;

  return charsToPay > 0 && !isFreeUserWhileProcessIsNotFinished(translationUpdatesExtra)

}