import React from 'react'
import { ReactComponent as InfoIcon } from 'assets/images/icons/info.svg'
import styles from './index.module.scss'

/**
 *
 * @param {string} text
 * @param {string} className
 * @return {JSX.Element}
 * @constructor
 */
const InfoDanger = ({ text, className = "" }) => {
  return (
    <div className={`${styles.info_danger_container} ${className}`}>
      <InfoIcon />
      <span>{text}</span>
    </div>
  )
}

export default InfoDanger
