import userActions from 'redux/user/actions'

/**
 * List of actions to keep track
 */
 const trackedActions = {
  SIGNUP_SUCCESS: userActions.SIGNUP_SUCCESS,
  // Added new payment method
  ADD_NEW_PAYMENT_METHOD: 'ADD_NEW_PAYMENT_METHOD',
  // update payment method
  UPDATE_PAYMENT_METHOD: 'UPDATE_PAYMENT_METHOD',
  // Added language
  INSTALL_NEW_LANGUAGE: 'INSTALL_NEW_LANGUAGE',
  // Add excluded word
  ADD_NEW_EXCLUDED_WORD: 'ADD_NEW_EXCLUDED_WORD',
  // Add entry dictionary rule
  ADD_NEW_DICTIONARY_RULE: 'ADD_NEW_DICTIONARY_RULE',
  // Update language
  UPDATE_LANGUAGE: 'UPDATE_LANGUAGE',
  // Translate language
  TRANSLATE_LANGUAGE: 'TRANSLATE_LANGUAGE',
  // Project translation price rule
  TRANSLATION_PRICE_RULE: 'TRANSLATION_PRICE_RULE',
  // Total payment for translation
  TRANSLATION_PAYMENT: 'TRANSLATION_PAYMENT',
  // Total payment for subscription
  SUBSCRIPTION_PAYMENT: 'SUBSCRIPTION_PAYMENT',
  // New subscription plan
  SUBSCRIPTION_UPGRADE: 'SUBSCRIPTION_UPGRADE',
  // Subscription plan cancellation
  SUBSCRIPTION_CANCEL: 'SUBSCRIPTION_CANCEL',
}

export const addNewDictionaryRule = (payload) => ({
  type: trackedActions.ADD_NEW_DICTIONARY_RULE,
  payload,
})

export const addNewExcludedWord = (payload) => ({
  type: trackedActions.ADD_NEW_EXCLUDED_WORD,
  payload,
})

export const addNewPaymentMethod = (payload) => ({
  type: trackedActions.ADD_NEW_PAYMENT_METHOD,
  payload,
})

export const installNewLanguageStarted = (payload) => ({
  type: trackedActions.INSTALL_NEW_LANGUAGE,
  payload
})

export const updatePaymentMethod = (payload) => ({
  type: trackedActions.UPDATE_PAYMENT_METHOD,
  payload,
})

export const updateLanguage = (payload) => ({
  type: trackedActions.UPDATE_LANGUAGE,
  payload,
})

export const translateLanguage = (payload) => ({
  type: trackedActions.TRANSLATE_LANGUAGE,
  payload,
})

export const translationPriceRule = (payload) => ({
  type: trackedActions.TRANSLATION_PRICE_RULE,
  payload,
})

export const translationPayment = (payload) => ({
  type: trackedActions.TRANSLATION_PAYMENT,
  payload
})

export const subscriptionPayment = (payload) => ({
  type: trackedActions.SUBSCRIPTION_PAYMENT,
  payload
})

export const subscriptionUpgrade = (payload) => ({
  type: trackedActions.SUBSCRIPTION_UPGRADE,
  payload
})

export const subscriptionCancel = (payload) => ({
  type: trackedActions.SUBSCRIPTION_CANCEL,
  payload
})

export default trackedActions
