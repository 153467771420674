export const getFieldErrorMessage = ({asset_id, params = []}, translateText) => {
  let paramsMessage = {};
    params.forEach((param) => {
      paramsMessage = {
        ...paramsMessage,
        [param.id]: param.isTranslation ? translateText(param.value) : param.value
      }
    })

  return translateText(asset_id, paramsMessage);
}

export const getAllErrorMessages = obj => {

  const errors = [];

  for (const prop in obj) {
    if (obj[prop] && obj[prop].errorMessages.length > 0) {
      errors.push(...obj[prop].errorMessages)
    }
  }

  return errors;
}