import actions from './actions'

const initialState = {
  hasBackendError: false,
  errorMessages: "",
}

export default function userReducer(state = initialState, { type, payload }) {
  switch (type) {
    case actions.HAS_BACKEND_ERROR:
      return {
        ...state,
        hasBackendError: true,
        errorMessages: payload
      }
    case actions.CLEAR_BACKEND_ERROR:
      return {
        ...state,
        hasBackendError: false,
        errorMessages: ""
      }
    default:
      return state
  }
}
