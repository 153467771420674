import { createSelector } from 'reselect'

/**
 * Returns the project slice from the redux store
 * @param {Object<string, any>} state The redux store object
 */
const selectLeftMenuVisibility = (state) => state.leftMenuVisibility

/**
 * Returns the current left menu visibility
 */
 export const selectIsMenuLeftVisibile = createSelector(
  [selectLeftMenuVisibility],
  (leftMenuVisibility) => leftMenuVisibility.visible,
 )