export const advanced_settings_modules = (translateText) => () => ({
  CATALOG: {
    id: 'CATALOG',
    value: 'catalog',
    title: translateText('Catalog'),
    db: ['db_tables', 'db_catalog']
  },
  MODULES: {
    id: 'MODULES',
    value: 'modules',
    title: translateText('Modules'),
    db: ['modules']
  },
  THEMES: {
    id: 'THEMES',
    value: 'themes',
    title: translateText('Themes'),
    db: ['themes']
  },
  OTHERS: {
    id: 'OTHERS',
    value: 'others',
    title: translateText('Others'),
    db: ['db_tables', 'db_others']
  }
})
