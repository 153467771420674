import React, { useEffect } from 'react'
import { Skeleton } from 'antd'

// hooks
import useGlotio from 'hooks/useGlotio'
import { useGetFaqContent } from 'hooks/useGetFaqContent'

// styles
import styles from './index.module.scss'
import './index.scss'

const FaqContent = ({ faqId, children }) => {
  const { translateText } = useGlotio()
  const {
    articleContent,
    isLoadingArticle,
    hasErrorLoadingArticle,
    articleURI,
    getArticleContent
  } = useGetFaqContent(faqId)

  useEffect(() => {
    getArticleContent()
  }, [faqId])

  /**
   * Replaces all article links with a new version with blank target and fixes all relative urls
   * @param {string} textContent
   * @returns {string}
   */
  const updateContentLinks = (textContent) => {
    return textContent.replace(/<a /g, '<a target="_blank" rel="noopener noreferrer" ')
  }

  if (isLoadingArticle) {
    return <Skeleton title active />
  }

  if (hasErrorLoadingArticle || !articleContent) {
    return (
      <>
        <div className={styles.error_message}>
          <h2>{translateText('There was a connection error')}</h2>
          <p>{translateText('You can also read the article directly in our support center.')}</p>
          <p>
            <a target="_blank" rel="noopener noreferrer" href={articleURI}>
              {articleURI}
            </a>
          </p>
        </div>
        {children}
      </>
    )
  }
  const { content, excerpt, title } = articleContent
  const contentWithUpdatedLinks = updateContentLinks(content.glotio_rendered)

  return (
    <>
      <div className='articlesFAQ'>
        <header>
          {
          title &&
          <>
            <h1>
              {title.rendered}
            </h1>
            <div dangerouslySetInnerHTML={{ __html: excerpt.rendered }} />
          </>
        }
        </header>
        <section className='articlesFAQ_body' dangerouslySetInnerHTML={{ __html: contentWithUpdatedLinks }} />
      </div>
      {children}
    </>
  )

}

export default FaqContent
