import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk';

import reducers from 'redux/reducers'
import sagas from 'redux/sagas'

const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()
const routeMiddleware = routerMiddleware(history)
const middlewares = [sagaMiddleware, routeMiddleware, thunk]

let composeEnhancer = compose
if (process.env.REACT_APP_NODE_ENVIROMENT !== 'master') {
  composeEnhancer =
    (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 })) ||
    compose
}

const store = createStore(reducers(history), composeEnhancer(applyMiddleware(...middlewares)))
sagaMiddleware.run(sagas)

export { store, history }
