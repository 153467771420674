import actions from './actions'

const initialState = {
  currentUser: null,
  loading: false,
  error: null,
  validated: false,
  emailValidationExpired: null,
  logoutLoading: false,
  isFirstSession: null,
  showSessionExpirationModal: false,
  sessionExpirationWarned: false,
  isAdminUser: false,
}

export default function userReducer(state = initialState, { type, payload }) {
  switch (type) {
    case actions.LOGIN_START:
      return {
        ...state,
        loading: payload.loading,
        error: null,
      }
    case actions.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        validated: true,
        currentUser: {
          token: payload.token,
          email: payload.email,
          userId: payload.userId,
          accountId: payload.accountId,
          token_exp: payload.token_exp || null,
          zendeskToken: payload.zendeskToken
        },
      }
    case actions.LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      }
    case actions.LOGOUT:
      return {
        ...state,
        logoutLoading: true,
      }
    case actions.LOGOUT_SUCCESS:
      return {
        ...state,
        currentUser: null,
        error: null,
        logoutLoading: false,
      }
    case actions.LOGOUT_FAIL:
      return {
        ...state,
        error: payload,
        logoutLoading: false,
      }
    case actions.CHECK_ACCOUNT:
      return {
        ...state,
        loading: true,
      }
    case actions.CHECK_ACCOUNT_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
      }
    case actions.SAVE_USER_CHANGES:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...payload,
        },
      }
    case actions.FETCH_USER_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          ...payload,
        },
        loading: false,
        error: null,
      }
    case actions.UPDATE_FIRST_SESSION:
      return {
        ...state,
        isFirstSession: payload,
      }
    case actions.RENEW_SESSION_START:
      return {
        ...state,
        showSessionExpirationModal: true,
      }
    case actions.RENEW_SESSION_SUCCESS:
      return {
        ...state,
        sessionExpirationWarned: true,
        showSessionExpirationModal: false,
      }
    case actions.RENEW_SESSION_FAIL:
      return {
        ...state,
        sessionExpirationWarned: true,
        showSessionExpirationModal: false,
      }
    case actions.SET_CURRENT_USER:
      return {
        ...state,
        currentUser: payload,
      }
    case actions.SET_USER_ADMIN:
      return {
        ...state,
        isAdminUser: payload,
      }
    default:
      return state
  }
}
