export const InjectScriptInDom = (scriptToInject , type = 'text', onLoad = null) => {
  const scriptTag = document.createElement('script');
  scriptTag.type = 'text/javascript';

  if (typeof onLoad === 'function') {
    scriptTag.onload = onLoad
  }

  if (type === 'text') {
    scriptTag.text = scriptToInject;
  } else if (type === 'source') {
    scriptTag.async = true
    scriptTag.src = scriptToInject;
  }

  document.body.appendChild(scriptTag);
}

export const CustomScriptInDom = (params) => {
  const scriptTag = document.createElement('script')

  Object.entries(params).forEach(([key, value]) => {
      scriptTag[key] = value
  })

  document.body.appendChild(scriptTag)
}
