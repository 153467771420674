import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Skeleton } from 'antd';

// selectors
import { selectProjectId } from 'redux/project/selectors';
import { selectConfigLanguages } from 'redux/languages/selectors'

// models
import { getTranslationHistoryDetailRequest } from 'models/api/process'

// utils
import Process from 'utils/Process';
import LanguagesUtils from 'utils/Languages'
import { translateTextField } from 'utils/translation_sources'

// hooks
import useGlotio from 'hooks/useGlotio'

// styles
import styles from './index.module.scss';


/**
 * TranslationsHistoryModal
 * @param {String} translationBatchId
 * @param {null | string} isoRestriction
 */

const TranslationsHistoryModal = ({translationBatchId, isoRestriction = null}) => {
  const [isLoading, setIsLoading ] = useState(true);
  const [hasError, setHasError] = useState(null);
  const [resumeContent, setResumeContent] = useState(null)
  const { translateText, formatNumber } = useGlotio()
  const projectId = useSelector(selectProjectId);
  const configLanguages = useSelector(selectConfigLanguages)


  /**
   * Checks if the process has finished so the data can be displayed to the user
   *
   * @param {{ process: { status: string } }} invoiceConcept The invoice_concept object from the api response
   * @returns {boolean}
   */
  const hasProcessFinishedCorrectly = (invoiceConcept) => {
    if (!invoiceConcept || !invoiceConcept.process || !Process.hasProcessFinished(invoiceConcept.process)) return false
    return true
  }

  /**
   * Gets the detailed resume content of a translation process
   *
   * @param {string} translationBatchId
   */
  const getResume = async () => {
    if (!translationBatchId) return
    setIsLoading(true);
    setHasError(null);

    try {
      const { error, result } = await getTranslationHistoryDetailRequest({ projectId, translationBatchId })
      if (error || result.sucess === false) {
        setHasError('The content could not be loaded. Please try again.')
      } else if (result.invoice_concept && !hasProcessFinishedCorrectly(result.invoice_concept)) {
        setHasError('Details of this process are not available due to the process has not finished correctly')
      } else {
        setResumeContent(result)
      }
    } catch (err) {
      setHasError('There was a connection error. Please try again.')
    } finally {
      setIsLoading(false)
    }

  }

  useEffect(() => {
    getResume();
  }, [])

  /**
   * Generates the table with the translated content
   * @returns {JSX.Element}
   */
   const renderBody = () => {
    if (!resumeContent || !resumeContent.translation_batch) return null

    const {
      translation_batch: {
        stats: { items },
      },
    } = resumeContent

    const languagesWithoutDefault = Object.keys(items).filter(key => Object.values(items[key]).at(0).to_translate )

    return languagesWithoutDefault
      .sort()
      .filter((lang) => isoRestriction ? isoRestriction === lang : lang)
      .map((lang) => {
        const langItems = Object.keys(items[lang])
        function sortByNumberOfChars(a, b) {
          const aLang = items[lang][a]
          const bLang = items[lang][b]

          if (
            !aLang ||
            !aLang.to_translate ||
            !bLang ||
            !bLang.to_translate
          ) return 0

          const aChars = aLang.to_translate.num_chars
          const bChars = bLang.to_translate.num_chars

          if (aChars < bChars) {
            return 1
          }

          if (aChars > bChars) {
            return -1
          }

          return 0
        }

        // order langs by the number of characters
        if (langItems.length > 1) {
          langItems.sort(sortByNumberOfChars)
        }

        return (
          <div className={styles.lang_resume} key={`${lang}`}>
            <h2>{LanguagesUtils.getLangNameFromIsoCode(configLanguages, lang)}</h2>
            <table>
              <thead>
                <tr>
                  <td>{translateText('Type')}</td>
                  <td>{translateText('Elements')}</td>
                  <td>{translateText('Fields')}</td>
                  <td>{translateText('characters')}</td>
                </tr>
              </thead>
              <tbody>
                {langItems.map((item) => {
                  const {
                    to_translate: { num_chars, num_fields, num_objects },
                  } = items[lang][item]

                  return (
                    <tr key={`${lang}-${item}`}>
                      <td>{translateTextField(item, ' > ')}</td>
                      <td>{formatNumber(num_objects)}</td>
                      <td>{formatNumber(num_fields)}</td>
                      <td>{formatNumber(num_chars)}</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        )
      })
  }

  if (isLoading) return <Skeleton active />
  if (hasError) return <p className={styles.error_msg}>{translateText(hasError)}</p>

  return (
    <div>{renderBody()}</div>
  )
}

export default TranslationsHistoryModal;
