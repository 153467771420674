import { concat, filter, find, orderBy, sum } from 'lodash'
import { subscriptionType } from 'constants/subscription.constants'
import { getDateDiffByHours } from 'utils/Date'
import useGlotio from 'hooks/useGlotio'
import { hasRequestError } from 'models/api/utils'

/**
 * @typedef {LanguageData[]} LanguagesData
 *
 * @typedef {Object<string, any>} LanguageData
 * @property {number} id
 * @property {string} iso
 * @property {string} name
 * @property {string} locale
 * @property {boolean} update
 * @property {boolean} translate
 * @property {boolean} translatable
 * @property {boolean} platform_default
 * @property {boolean} platform_enabled
 */

/**
 * Language utility class
 */
export default class Languages {
  static translation_modes = {
    to_translate_all: 'to_translate_all',
    to_translate_new: 'to_translate_new',
  }

  static hasTranslatedLanguages = (configLanguages) => {
    if (!configLanguages) return false

    return configLanguages.some(({ translate, update }) => translate || update)
  }

  /**
   * Creates an object with the default selection from the languages received
   * Format is { iso: "translation_mode" }
   *
   * @param {LanguageData[]} configLanguages The list of languages of the shop
   * @param {string} priceRule The price rule applied to the project
   * @returns {Object<string, string>} The selection object
   */
  static getDefaultSelection = (configLanguages) => {
    if (!configLanguages || !Array.isArray(configLanguages) || !configLanguages.length) return null

    const defaultSelection = {}

    if (!Languages.hasTranslatedLanguages(configLanguages)) {
      const defaultLanguage = configLanguages.find(language => !language.platform_default)

      if (defaultLanguage) {
        defaultSelection[defaultLanguage.iso] = Languages.translation_modes.to_translate_all
      }
    }

    return defaultSelection
  }

  /**
   * Checks if a given language is the default language of the user
   *
   * @param {LanguageData} language
   * @returns {boolean}
   */
  static isDefaultLanguage = (language) => {
    if (!language) return false
    if (language.platform_default) return true
    return false
  }

  /**
   * Gets the default language from the store and returns it
   *
   * @param {LanguageData[]} configLanguages The list of languages of the shop
   * @returns {LanguageData} The default lang object
   */
  static getDefaultLanguage = (configLanguages) => {
    if (hasRequestError('getLanguages') || !configLanguages || !Array.isArray(configLanguages)) {
      return null
    }

    for (let i = 0; i < configLanguages.length; i += 1) {
      const idioma = configLanguages[i]
      if (idioma.platform_default) {
        return idioma
      }
    }

    const [filteredLanguage] = configLanguages.filter((language) => language.platform_default)

    return filteredLanguage || null
  }

  /**
   * Checks whether a language can be selected for translation or not
   *
   * @param {LanguageData} language
   * @returns {boolean}
   */
  static isTranslatable = (language) => {
    if (!language) return false
    if (language.translatable && !language.translate) return true
    return false
  }

  /**
   * Generates a list with all translated languages
   *
   * @param {LanguageData[]} configLanguages The list of languages installed on the shop
   * @returns {LanguageData[]} A filtered list of translated languages
   */
  static getTranslatedLangs = (configLanguages) => {
    if (!configLanguages || !configLanguages.length || !Array.isArray(configLanguages)) {
      return []
    }

    return configLanguages.filter((lang) => lang.translate)
  }

  /**
   * Checks if all shop's languages are translated
   *
   * @param {LanguageData[]} configLanguages The list of languages installed on the shop
   * @returns {LanguageData[]} A filtered list of translated languages
   */
  static areAllLangsTranslated = (configLanguages) => {
    if (!configLanguages || !configLanguages.length || !Array.isArray(configLanguages)) return true

    return configLanguages.filter((lang) => !lang.translate).length === 0
  }

  static getLangNameFromIsoCode = (configLanguages, isoCode) => {
    const { translateIsoCode } = useGlotio()

    if (!configLanguages || !configLanguages.length || !isoCode) return null

    const foundLanguage = configLanguages.filter((language) => language.iso === isoCode)[0]

    return foundLanguage ? translateIsoCode(foundLanguage.iso) : isoCode
  }

  /**
   * Sort and filter installed languages array by
   * 1. Default language
   * 2. Translated languages (alphabetically)
   * 3. Non translated languages (alphabetically)
   *
   * @param {LanguageData[]} languages The list of languages installed on the shop
   * @returns {LanguageData[]} A sorted and filtered array of installed languages
   */
  static sortLanguages = (languages) => {
    let newLanguages = []
    const defaultLanguage = find(languages, { platform_default: true })
    if (defaultLanguage) {
      newLanguages.push(defaultLanguage)
    }

    const translatedLanguages = filter(languages, { translate: true, platform_default: false })
    if (translatedLanguages) {
      newLanguages = concat(newLanguages, orderBy(translatedLanguages, ['name'], ['asc']))
    }

    const notTranslatedLanguages = filter(languages, { translate: false, platform_default: false })
    if (notTranslatedLanguages) {
      newLanguages = concat(newLanguages, orderBy(notTranslatedLanguages, ['name'], ['asc']))
    }

    return newLanguages
  }
}

/**
 * return true if is a number and > 0
 * @param {any} languageCharacters
 * @return {boolean}
 */
export const hasNoCharacters = (languageCharacters) => {
  return Number.isNaN(languageCharacters) || languageCharacters <= 0
}

const HOUR_FOR_PLAN = {
  [subscriptionType.FREE]: 120, // 5 DAYS
  [subscriptionType.BASIC]: 84, // 3.5 DAYS
  [subscriptionType.BUSINESS]: 24, // 1 DAY
  [subscriptionType.CLASSIC]: 24, // 1 DAY
  [subscriptionType.CORPORATE]: 24 // 1 DAY
}
export const isNecessaryAnalyse = (subscriptionPlanType, lastUpdate) => {
  const hoursSinceLastAnalyse = getDateDiffByHours(lastUpdate)
  return hoursSinceLastAnalyse >= HOUR_FOR_PLAN[subscriptionPlanType]
}

export const canNotAutomaticUpdate = (languages, balanceChar) => {
  const charsToUpdate = languages.reduce((acc, { update, num_total_chars_no_translated }) => {
    if (update) {
      return sum([acc, num_total_chars_no_translated])
    }
    return acc
  }, 0)

  return charsToUpdate > balanceChar
}
