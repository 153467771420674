export default [
  {
    value: 'es',
    label: 'Spanish',
    flag: 'es',
  },
  {
    value: 'en',
    label: 'English',
    flag: 'us',
  },
  {
    value: 'de',
    label: 'German',
    flag: 'de',
  },
  {
    value: 'it',
    label: 'Italian',
    flag: 'it',
  },
  {
    value: 'fr',
    label: 'French',
    flag: 'fr',
  },
  {
    value: 'pt',
    label: 'Portuguese',
    flag: 'pt',
  },
  {
    value: 'pl',
    label: 'Polish',
    flag: 'pl',
  },
  {
    value: 'nl',
    label: 'Dutch',
    flag: 'nl',
  },
]
