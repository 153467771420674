import firebase from 'firebase/app'
import { pushActiveCampaignEventRequest } from 'models/api/externals'

/**
 * @typedef EventTracker
 * @property {(type: string, value: any) => void} sendEvent
 */

/**
 * @class
 * @implements {EventTracker}
 */
export class GoogleAnalyticsTracker {
  constructor() {
    this.checkAnalyticsSupport()
  }

  checkAnalyticsSupport = async () => {
    try {
      const analyticsSupported = await firebase.analytics.isSupported()

      if (analyticsSupported) {
        this.analytics = firebase.analytics()
      }
    } catch (err) {
      // unhandled error
    }
  }

  sendEvent = ({ event, data, projectId }) => {
    if (this.analytics) {
      this.analytics.logEvent(event, { data, project: projectId })
    }
  }

  static createPurchase = (payload) => {
    return {
      transaction_id: payload.processId,
      affiliation: 'Glotio',
      currency: 'EUR',
      tax: payload.tax,
      shipping: 0,
      value: payload.total_price / 100, // Total Revenue
      coupon: payload.coupon,
      items: payload.items
    }
  }

  /**
   * @param {Object} selectedLanguages
   * @param {Object} languagesPrices
   */
  static createPurchaseItems = (selectedLanguages, languagesPrices) => {
    const items = []
    for (const [iso, translationType] of Object.entries(selectedLanguages)) {
      items.push({
        item_id: iso,
        item_name: iso,
        item_category: 'language',
        item_variant: translationType,
        item_brand: 'translation',
        price: languagesPrices[iso][translationType].computed_cost / 100
      })
    }

    return items;
  }
}

/**
 * @class
 * @implements {EventTracker}
 */
export class ActiveCampaingTracker {
  sendEvent = ({ event, data, projectId }) => {
    let params;
    if (data instanceof Object) {
      params = { event, data: JSON.stringify(data) };
    } else {
      params = { event, data };
    }

    pushActiveCampaignEventRequest({ params, projectId })
  }
}
