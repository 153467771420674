import React, { useState, useEffect } from 'react'
import useGlotio from 'hooks/useGlotio'
import { Link, withRouter } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { selectMenuLeftData } from 'redux/menu/selectors'

import { getSubmenusData } from 'services/menu'
import { PAGE_WITH_SUBMENU_TABS } from 'constants/routes.constants'

import { Menu } from 'antd'
import Tabs from 'components/LayoutComponents/Menu/Tabs'

import css from './index.module.scss'

const Submenu = ({ location: { pathname } }) => {
  /**
   * Main menu data
   */
  const leftMenuData = useSelector(selectMenuLeftData)

  /**
   * Available options list to show on the submenu
   */
  const [options, setOptions] = useState(null)

  const { translateText } = useGlotio()

  /**
   * Updates the options state on every route change
   */
  useEffect(() => {
    getCurrentSubmenu()
  }, [pathname, leftMenuData])

  /**
   * Updates the options state based on the current route
   */
  const getCurrentSubmenu = () => {
    const disableLinks = shouldDisableLinks()
    const data = getSubmenusData(disableLinks)
    const currentSubmenu = data.find((item) => pathname.includes(item.parent_url))

    if (currentSubmenu) {
      setOptions(currentSubmenu.children)
    } else {
      setOptions(null)
    }
  }

  /**
   * Check if the main menu has its entries disabled
   * @returns {boolean}
   */
  const shouldDisableLinks = () => {
    if (!leftMenuData) return false

    return leftMenuData[0].disabled
  }

  // dont render the submenu when there arent links to show
  if (!options) return null

  if (PAGE_WITH_SUBMENU_TABS.includes(pathname)) {
    return (
      <Tabs options={options} pathname={pathname} />
    )
  }

  return (
    <Menu mode="vertical" className={css.menu_container} selectedKeys={[pathname]}>
      {options.map((option) => (
        <Menu.Item
          key={option.url}
          id={pathname === option.url ? css.submenu_selected : null}
          disabled={option.disabled}
        >
          <Link to={option.url}>{translateText(option.key)}</Link>
        </Menu.Item>
      ))}
    </Menu>
  )
}

export default withRouter(Submenu)
