import { useEffect } from 'react';

// utils
import { onInitThirdPartyTrackers, userAcceptedAllCookies, userAcceptedPartialCookies } from 'utils/cookies';

const CookieLayout = () => {

  useEffect(() => {
    if (userAcceptedAllCookies()) {
      onInitThirdPartyTrackers(true)
    } else if (userAcceptedPartialCookies()) {
      onInitThirdPartyTrackers(false)
    }
  },[])
  return null
}

export default CookieLayout;