import { getCurrentSubscriptionRequest } from 'models/api/subscription'

const actions = {
    START_SUBSCRIPTIONS_REQUEST: 'subscriptions/START_SUBSCRIPTIONS_REQUEST',
    SUBSCRIPTIONS_REQUEST_SUCCESS: 'subscriptions/SUBSCRIPTIONS_REQUEST_SUCCESS',
    SUBSCRIPTIONS_REQUEST_ERROR: 'subscriptions/SUBSCRIPTIONS_REQUEST_ERROR',
  }

  export default actions;

  export const subscriptionsStart = () => ({
    type: actions.START_SUBSCRIPTIONS_REQUEST
  })

  export const subscriptionsSuccess = (currentSubscription) => ({
    type: actions.SUBSCRIPTIONS_REQUEST_SUCCESS,
    payload: currentSubscription,
  })

  export const subscriptionsFail = (error) => ({
    type: actions.SUBSCRIPTIONS_REQUEST_ERROR,
    payload: error,
  })

  export const fetchSubscription = projectId => async dispatch => {
    try {
      dispatch(subscriptionsStart());
      const { result, error } = await getCurrentSubscriptionRequest({projectId});
      if (!error) {
        dispatch(subscriptionsSuccess(result.data));
      } else {
        dispatch(subscriptionsFail(error));
      }
    } catch (error) {
      dispatch(subscriptionsFail(error));
    }
  }
