import Request from 'models/Request'

export const getContentFAQRequest = ({ id }) => Request.external(`https://glotio.com/wp-json/wp/v2/docs/${id}`)

export const sendContactMessageRequest = ({ params, projectId, callback }) =>
  Request.fetch({
    key: 'sendContactMessage',
    url: `/api/v1/contact/${projectId}`,
    method: 'POST',
    params,
    callback,
  })

export const createTicketRequest = ({ params, projectId, callback }) =>
  Request.fetch({
    key: 'createTicket',
    url: `/api/v2/project/${projectId}/support-tickets`,
    method: 'POST',
    params,
    callback,
  })

export const createAnonymousTicketRequest = ({ params, callback }) =>
  Request.fetch({
    key: 'createAnonymousTicket',
    url: `/api/v2/support-tickets/anonymous`,
    method: 'POST',
    params,
    callback,
  })

export const pushActiveCampaignEventRequest = ({ params, projectId, callback }) =>
  Request.fetch({
    key: 'pushACEvent',
    url: `/api/v1/project/${projectId}/push_event`,
    method: 'POST',
    params,
    callback,
  })

export const contactSupportRequest = ({ projectId, params, callback }) =>
  Request.fetch({
    key: 'contactSupport',
    url: `/api/v1/contact/support/${projectId}`,
    method: 'POST',
    params,
    callback,
  })
