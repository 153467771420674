import React from 'react'
import { ConfigProvider } from 'antd'
import { IntlProvider, addLocaleData } from 'react-intl'
import { InjectIntlContext } from 'context/intl'
import { connect } from 'react-redux'

// utils
import activeLangs from 'services/languages-list'
import { getDefaultLanguageByBrowser } from 'utils/Utils'

// languages
import english from 'locales/en'
import spanish from 'locales/es'
import german from 'locales/de'
import portuguese from 'locales/pt'
import polish from 'locales/pl'
import dutch from 'locales/nl'
import italian from 'locales/it'
import french from 'locales/fr'

// locales data
addLocaleData(english.localeData)
addLocaleData(spanish.localeData)
addLocaleData(german.localeData)
addLocaleData(portuguese.localeData)
addLocaleData(polish.localeData)
addLocaleData(dutch.localeData)
addLocaleData(italian.localeData)
addLocaleData(french.localeData)

const locales = {
  en: english,
  es: spanish,
  de: german,
  pt: portuguese,
  pl: polish,
  nl: dutch,
  it: italian,
  fr: french,
}

class Localization extends React.Component {
  /**
   * If needed to log translation errors on the console pass it to as the onError prop on IntlProvider
   * Logs on the console as a warning the error message generated by react-intl
   *
   * @param {string} error
   */
  logTranslationError = error => {
    console.warn(error)
  }

  render() {
    const { children, locale } = this.props

    // check the user lang is supported by the UI and change the UI lang in case it doesn't
    const isUserLangEnabled = activeLangs.some(lang => lang.value === locale)
    const lang = isUserLangEnabled ? locale : 'en'

    const currentLocale = locales[lang]
    return (
      <ConfigProvider locale={currentLocale.antdData}>
        <IntlProvider
          locale={currentLocale.locale}
          messages={currentLocale.messages}
          onError={() => {}}
        >
          <InjectIntlContext>{children}</InjectIntlContext>
        </IntlProvider>
      </ConfigProvider>
    )
  }
}

/**
 * Sets the UI language to the current user lang if its loaded
 * If not, it tries to set the UI language the same as the browser language
 * If none of them are loaded or valid UI langs the default goes to 'en'
 */
const defaultLang = getDefaultLanguageByBrowser()

const mapStateToProps = ({ user: { currentUser } }) => ({
  locale: (currentUser && currentUser.lang) ? currentUser.lang : defaultLang,
})

export default connect(mapStateToProps)(Localization)
