import React, { useState } from 'react'

// hoc
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// hooks
import useGlotio from 'hooks/useGlotio'

// components
import { Menu, Dropdown, Avatar, Badge } from 'antd'

// actions
import { logout } from 'redux/user/actions'

// selectors
import { selectCurrentUser, selectIsFirstSession } from 'redux/user/selectors'
import { selectCurrentProject } from 'redux/project/selectors'
import { selectConfirmedSubscriptionPlan } from 'redux/subscription/selectors';

// constants
import { ROUTES } from 'constants/routes.constants'

// utils
import { getUserInitials } from 'utils/getUserInitials'

// icons
import { ReactComponent as LogoutIcon } from 'assets/images/icons/icon_log-out.svg'
import { ReactComponent as UserIcon } from 'assets/images/icons/icon_user.svg'

// styles
import styles from './style.module.scss'

const ProfileMenu = (props) => {
  const { logoutUser, history, currentUser, currentProject, isFirstSession, hasConfirmedSubscriptionPlan } = props

  const { translateText } = useGlotio()
  const [isVisible, setIsVisible] = useState(false);

  const handleUserLogout = () => {
    if (currentProject && currentProject.url) {
      logoutUser({ project: currentProject.url })
    } else {
      logoutUser()
    }
  }

  const onVisibleChange = visible => {
    setIsVisible(visible)
  }

  const onClickItem = callback => {
    setIsVisible(false)
    callback()
  }

  // show only close session at the first translation flow (no plan confirmed and no translated languages)
  const isRestrictedMenu = !isFirstSession && hasConfirmedSubscriptionPlan

  /**
   * Generates the floating overlay menu
   *
   * @returns {JSX.Element}
   */
  const getOverlayMenu = () => (
    <Menu selectable={false}>
      {isRestrictedMenu &&
        <Menu.Item className={styles.menu_item}>
          <button
            type="button"
            onClick={() => onClickItem(() => history.push(ROUTES.MY_PROFILE))}
            className={styles.custom__button}
          >
            <UserIcon />
            <span className={styles.icon_text}>{translateText('My Profile')}</span>
          </button>
        </Menu.Item>
      }
      {isRestrictedMenu && <Menu.Divider /> }
      <Menu.Item className={styles.menu_item}>
        <button type="button" onClick={() => onClickItem(handleUserLogout)} className={`${styles.custom__button} ${styles.close_session_btn}`}>
          <LogoutIcon />
          <span className={styles.icon_text}>{translateText('Logout')}</span>
        </button>
      </Menu.Item>
    </Menu>
  )

  return (
    <Dropdown overlay={getOverlayMenu} trigger={['click']} onVisibleChange={onVisibleChange}>
      <div className={styles.dropdown}>
        <Badge className={styles.badge_menu}>
          <Avatar
            className={`${styles.avatar} ${isVisible ? styles.isVisible : ''}`}
            shape="circle"
            size="large"
            icon={!currentUser.name && 'user'}
          >
            {currentUser.name ? getUserInitials(currentUser.name) : null}
          </Avatar>
        </Badge>
      </div>
    </Dropdown>
  )
}

const mapStateToProps = (state) => ({
  currentUser: selectCurrentUser(state),
  currentProject: selectCurrentProject(state),
  isFirstSession: selectIsFirstSession(state),
  hasConfirmedSubscriptionPlan: selectConfirmedSubscriptionPlan(state),
})

const mapDispatchToProps = (dispatch) => ({
  logoutUser: (data) => dispatch(logout(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProfileMenu))
