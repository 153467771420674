const actions = {
    SET_QUESTION: 'question/SET_QUESTION',
    DELETE_QUESTION: 'question/DELETE_QUESTION',
  }
  
  // action creators

  export const getQuestion = () => ({
    type: actions.GET_QUESTION,
  })
  
  export const setQuestion = (selectedQuestion) => ({
    type: actions.SET_QUESTION,
    payload: selectedQuestion,
  })
  
  export const clearQuestion = () => ({
    type: actions.DELETE_QUESTION,
  })
  
  export default actions;