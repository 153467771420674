import React from 'react'

// styles
import css from './index.module.scss'

export default function Step({
  title = '',
  subtitle,
  children,
  className = '',
  icon = '',
  noPadding = false,
  number
}) {
  const hasHeader = icon || title || subtitle || number

  return (
    <div className={`${css.step_content_border} ${noPadding ? css.step_content_full : css.step_content} ${className}`}>
      {hasHeader &&
      <header className={css.step_header}>
        {typeof number === 'number' && <div className={css.circle}>{ number }</div> }
        <div className={css.step_header_content}>
          {icon &&
          <div>
            <img src={icon} alt='logo' width='32' height='32' />
          </div>
              }
          {title && (
          <div className={css.step_title_content}>
            <h1 className={css.step_title}>
              {title}
            </h1>
          </div>
              )}
        </div>
        {subtitle && (
        <div className={css.step_subtitle}>
          {subtitle}
        </div>
            )}
      </header>
        }
      <div className={css.step_body}>
        {children}
      </div>
    </div>
  )
}
