import React from 'react'

// icons
import {ReactComponent as Headphones} from 'assets/images/icons/headphones.svg'
import {ReactComponent as ChevronUp} from 'assets/images/icons/chevron-up.svg'
import {ReactComponent as ChevronDown} from 'assets/images/icons/chevron-down.svg'

// hooks
import useGlotio from 'hooks/useGlotio'

// styles
import styles from './index.module.scss'

const GlotioChatButton = ({ unreadMessages, onClick, isLoading, isOpenChat }) => {
  const { translateText } = useGlotio()

  return (
    <button className={`${styles.glotio_chat_button} ${isLoading ? styles.disabled : ''}`} type='button' onClick={onClick}>
      {unreadMessages > 0 &&
        <span className={styles.circle}>{unreadMessages}</span>
      }
      <Headphones />
      {translateText('Need help?')}
      {isOpenChat ? <ChevronDown className={styles.chevron_icon} /> : <ChevronUp className={styles.chevron_icon} /> }
    </button>
  )
}

export default GlotioChatButton
