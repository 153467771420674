import { createSelector } from 'reselect'
import { get } from 'lodash'
import moment from 'moment'
/**
 * Returns the project slice from the redux store
 * @param {Object<string, any>} state The redux store object
 */
const selectProject = (state) => state.project

/**
 * Returns the current project in the store
 */
export const selectCurrentProject = createSelector(
  [selectProject],
  (project) => project.currentProject,
)

/**
 * Returns the project id stored after login
 */
export const selectProjectId = createSelector([selectProject], (project) =>
  project ? project.projectId : null,
)

/**
 * Returns the project's url
 */
export const selectProjectUrl = createSelector([selectCurrentProject], (currentProject) =>
  currentProject ? currentProject.url : null,
)

/**
 * Returns the project's name
 */
export const selectProjectName = createSelector([selectCurrentProject], (currentProject) =>
  currentProject ? currentProject.name : null,
)

/**
 * Returns the project asociated email (used on sign up)
 */
export const selectProjectEmail = createSelector([selectCurrentProject], (currentProject) =>
  currentProject && currentProject.email ? currentProject.email : '',
)

/**
 * Returns the "translatable" status of the current project
 */
export const selectIsProjectTranslatable = createSelector(
  [selectCurrentProject],
  (currentProject) => (currentProject ? currentProject.translatable : false),
)

/**
 * Returns the loading state of the current project
 */
export const selectIsFetchingProject = createSelector(
  [selectProject],
  (project) => project.fetching,
)

/**
 * Returns the update state of the current project
 */
export const selectProjectUpdateConfig = createSelector(
  [selectCurrentProject],
  (project) =>
    project && {
      autoupdate: project.autoupdate,
      update_time: moment(project.update_time, 'HH:mm'),
      timezone: project.timezone,
    },
)

export const selectProjectProcessStatus = createSelector(
  [selectCurrentProject],
  (currentProject) => get(currentProject, 'processes[0].status', null),
)

/**
 * Returns the project's patform
 */
 export const selectProjectPlatform = createSelector([selectCurrentProject], (currentProject) =>
 currentProject ? currentProject.platform : null,
)
