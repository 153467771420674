import {all, select, takeLatest} from 'redux-saga/effects'

// trackers
import { ActiveCampaingTracker, GoogleAnalyticsTracker } from 'models/Events/trackers/trackers'

// selectors
import { selectProjectId } from 'redux/project/selectors'


// actions
import actions from './actions'

/**
 * Event trackers
 */
const analytics = new GoogleAnalyticsTracker()
const activeCampaing = new ActiveCampaingTracker()

const EVENT_TRACKING_PLATFORM_PREFIX = 'glotio_'

const sendEventToAllPlatforms = (action) => {
  sendEventToAnalytics(action)
  sendEventToActiveCampaign(action)
}

const sendEventToAnalytics = (action) => {
  addPrefixToEventName(action)
  analytics.sendEvent(action)
}

const sendEventToActiveCampaign = (action) => {
  addPrefixToEventName(action)
  activeCampaing.sendEvent(action)
}

const addPrefixToEventName = (action) => {
  if (!action.event.includes(EVENT_TRACKING_PLATFORM_PREFIX)) {
    action.event = `${EVENT_TRACKING_PLATFORM_PREFIX}${action.event}`
  }
}

/**
 * Receives a redux action and send an event to the correspondent tracking service
 *
 * @param {{ type: string, payoad: any}} action The action redirected from the sagas
 */
function* sendEvent(action) {
  const { type, payload } = action

  const projectId = yield select(selectProjectId)
  switch (type) {
    case actions.SIGNUP_SUCCESS:
      sendEventToAllPlatforms({
        event: 'new_project_registered',
        data: payload,
        projectId,
      })
      break
    case actions.ADD_NEW_PAYMENT_METHOD:
      sendEventToAllPlatforms({
        event: 'add_new_payment_method',
        data: payload,
        projectId,
      })
      break
    case actions.UPDATE_PAYMENT_METHOD:
      sendEventToAllPlatforms({
        event: 'update_payment_method',
        data: payload,
        projectId,
      })
      break
    case actions.INSTALL_NEW_LANGUAGE:
      sendEventToAllPlatforms({
        event: 'install_new_language',
        data: payload,
        projectId,
      })
      break
    case actions.ADD_NEW_EXCLUDED_WORD:
      sendEventToAllPlatforms({
        event: 'add_excluded_word',
        data: payload,
        projectId,
      })
      break
    case actions.ADD_NEW_DICTIONARY_RULE:
    sendEventToAllPlatforms({
        event: 'add_dictionary_rule',
        data: payload,
        projectId,
      })
      break
    case actions.UPDATE_LANGUAGE:
      sendEventToActiveCampaign({
        event: `${EVENT_TRACKING_PLATFORM_PREFIX}update_translation`,
        data: payload,
        projectId
      })
      break
    case actions.TRANSLATE_LANGUAGE:
      sendEventToActiveCampaign({
        event: `${EVENT_TRACKING_PLATFORM_PREFIX}new_translation`,
        data: payload,
        projectId
      })
      break
    case actions.TRANSLATION_PRICE_RULE:
      sendEventToActiveCampaign({
        event: `${EVENT_TRACKING_PLATFORM_PREFIX}translation_price_rule`,
        data: payload,
        projectId
      })
      break
    case actions.TRANSLATION_PAYMENT:
      sendEventToActiveCampaign({
        event: `${EVENT_TRACKING_PLATFORM_PREFIX}translation_payment`,
        data: payload,
        projectId
      })
      break
    case actions.SUBSCRIPTION_PAYMENT:
      sendEventToActiveCampaign({
        event: `${EVENT_TRACKING_PLATFORM_PREFIX}subscription_payment`,
        data: payload,
        projectId
      })
      break
    case actions.SUBSCRIPTION_UPGRADE:
      sendEventToActiveCampaign({
        event: `${EVENT_TRACKING_PLATFORM_PREFIX}upgrade_subscription`,
        data: payload,
        projectId
      })
      break
    case actions.SUBSCRIPTION_CANCEL:
      sendEventToActiveCampaign({
        event: `${EVENT_TRACKING_PLATFORM_PREFIX}cancel_subscription`,
        data: payload,
        projectId
      })
      break
    default:
      break
  }
}


/**
 * List of active event listeners
 */
const listeners = []

/**
 * Starts an event listener for a given action type
 *
 * @param {string} name The type property of the action
 */
function* createEventListener(name) {
  yield takeLatest(actions[name], sendEvent)
}

/**
 * Generates the event listeners and attaches them into the listeners array
 */
const actionNames = Object.keys(actions)
actionNames.forEach((name) => {
  listeners.push(createEventListener(name))
})

/**
 * Initialize all listeners on the root saga
 */
export default function* trackEventsSaga() {
  yield all(listeners)
}
