import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { filter, isArray, orderBy, get, isEmpty } from 'lodash'
import { notification } from 'antd'
import { withRouter } from 'react-router'

// redux
import wizardReducer from 'redux/wizard/reducers'
import questionReducer from 'redux/question/reducers'
import { getAssistantPage, setAssistantPage } from 'redux/wizard/actions'
import {selectCurrentProject, selectProjectId} from 'redux/project/selectors'
import { selectCurrentProcess } from 'redux/process/selectors'
import { bindActionCreators } from 'redux'
import { selectIsFirstSession } from 'redux/user/selectors'
import { clearQuestion, getQuestion, setQuestion } from 'redux/question/actions'
import { paymentError } from 'redux/payment/actions'
import { setMustShowResume } from 'redux/process/actions'

// hooks
import useGlotio from 'hooks/useGlotio'

// models
import { payTranslationsOffersRequest } from 'models/api/payment'
import { getTranslationOffersRequest } from 'models/api/process'

// components
import AnalyseFinish from 'components/GlotioComponents/Wizard/AnalyseFinish'
import Questions from 'components/GlotioComponents/Wizard/Questions'
import DefaultLanguage from 'components/GlotioComponents/Wizard/DefaultLanguage'
import LanguageSelectorFree from 'components/GlotioComponents/Wizard/LanguageSelectorFree'
import Loading from 'components/GlotioComponents/Wizard/Loading'
import UpdateType from 'components/GlotioComponents/Wizard/UpdateType'
import HandlerNewLanguages from 'components/GlotioComponents/Wizard/HandlerNewLanguages'

// types
import 'components/GlotioComponents/Wizard/types'

// utils
import {sendTranslationEvents, sendTranslationPriceRuleEvent} from 'utils/TrackerEvents'
import { shouldShowExtraCharsSection } from 'utils/shouldShowExtraCharsSection'

// constants
import { ADD_EXTRA_CHARACTERS_SECTION, ADD_LANGUAGES_SECTION, ADD_PLAN_SECTION, ADD_UPDATE_LANGUAGES_SECTION } from 'constants/summary_sections.constants'
import { ORIGIN_INTEGRATION } from 'constants/process_origin.constants'
import { PAYMENT_ERRORS } from 'constants/backend_errors'
import questionConstants from 'components/GlotioComponents/Wizard/constants'
import TRANSLATION_WIZARD from 'constants/translation_wizard.constants'
import DefaultLanguageHelp from 'components/GlotioComponents/Wizard/DefaultLanguageHelp'

const TranslationWizard = (props) => {
  const [errorOffers, setErrorOffers] = useState(null)
  const [isLoadingOffers, setIsLoadingOffers] = useState(true)
  const [isLoadingPay, setIsLoadingPay] = useState(false)
  const [languagesOffers, setLanguagesOffers] = useState(null)
  const [languageTotals, setLanguageTotals] = useState(null)
  const [installedLanguages, setInstalledLanguages] = useState(null)
  const [allLanguages, setAllLanguages] = useState([]);
  const [defaultLanguage, setDefaultLanguage] = useState(null)
  const projectId = useSelector(selectProjectId)
  const currentProject = useSelector(selectCurrentProject)
  const { translateText } = useGlotio();
  const currentProcess = useSelector(selectCurrentProcess)
  const {
    assistantPage,
    setWizardAssistantPage,
    isFirstSession,
    setCurrentQuestion,
    wizzardQuestion,
    setPaymentBackendError,
    clearCurrentQuestion,
    setMustShowResumeAction
  } = props

  // get the assistant variant to know if the the installed languages have to be in the first position
  const hasVariantIntegration = get(props,'match.params.variant', false) === ORIGIN_INTEGRATION;

  useEffect(() => {
    getTranslationOffers()
  }, [projectId])

  /**
   * Get the translation offers list and store it on state
   * @param {string[]} isos
   * @returns {Promise<void>}
   */

  const getTranslationOffers = async (isos = [], forceUpdate = true, forceSessionState = true ) => {
    let joinedIsos = isos.length ? isos.join(",") : [];
    if (forceSessionState && assistantPage.lang && isArray(joinedIsos)) {
      joinedIsos = assistantPage.lang.join(',');
    }

    if (forceUpdate) {
      setIsLoadingOffers(true);
    }
    try {
      const { error, result } = await getTranslationOffersRequest({ projectId, translationBatchId: currentProcess.translationBatchId, params: { "selection": joinedIsos } })
      if (error) {
        setErrorOffers('There was a connection error.')
      } else {
        /**
         * @type TranslationOffers
         */
        const summary = result.data
        const filteredAndOrderedLanguages = orderBy([...summary.offers.newTranslationOffers, ...summary.offers.selectedLanguagesOffers], ['languageIso'], ['asc']) ;
        setAllLanguages(filteredAndOrderedLanguages);
        setLanguagesOffers(summary.offers)
        setLanguageTotals({...summary.totals, subscriptionTotal: summary.offers.subscriptionAddLanguagesExtra.total.amount})
        setInstalledLanguages(filter(filteredAndOrderedLanguages, { 'installed': true }))
        setDefaultLanguage(summary.offers.defaultLanguageEstimation || null)
      }
    } catch (err) {
      setErrorOffers('There was a connection error.')
    } finally {
      setIsLoadingOffers(false)
    }
  }

  const onPayTranslate = async ({ totalToPay, selectedLanguages }, callback) => {
    setIsLoadingPay(true)

    const params = {
      totalToPay,
      selectedLanguages
    }

    try {
      const { error, result } = await payTranslationsOffersRequest({ projectId, translationBatchId: currentProcess.translationBatchId, params })
      const hasErrorCode = result && result.errorCode && PAYMENT_ERRORS.includes(result.errorCode)
      if (error) {
        setIsLoadingPay(false)
        if (hasErrorCode) {
          setPaymentBackendError('There has been an error in the payment of the following translation. The payment method you entered is invalid.')
        }
        notification.error({
          message: hasErrorCode ? translateText(result.errorCode) : translateText('There was a connection error.'),
          duration: 3,
          key: 'saveSuccess',
        })
      } else {
        sendTranslationEvents(languagesOffers, totalToPay)
        sendTranslationPriceRuleEvent(currentProject)
      }
      if (typeof callback === 'function')  {
          callback();
      }
      setMustShowResumeAction(true)
    } catch(err) {
      setIsLoadingPay(false)
      notification.error({
        message: translateText('There was a connection error.'),
        duration: 3,
        key: 'saveSuccess',
      })
    }
  }

  const hasFromPreviousUpdateType = get(wizzardQuestion,'selectedQuestion', '') === questionConstants.OPTION_UPDATE_SHOP

  const summaryBackButton = () => {

    setWizardAssistantPage({ page: hasFromPreviousUpdateType ? TRANSLATION_WIZARD.pages.LANGUAGE_SELECTOR_UPDATE_TYPE : TRANSLATION_WIZARD.pages.QUESTION_DEFAULT_LANGUAGE, lang: null })
    getTranslationOffers([], false, false);
  }

  if (errorOffers || isLoadingOffers) {
    return <Loading isLoading={isLoadingOffers} isError={errorOffers} />
  }

  const summarySectionsAfterFirstSession = () => {
    let sections = [ADD_LANGUAGES_SECTION, ADD_PLAN_SECTION, ADD_UPDATE_LANGUAGES_SECTION];
    if (!isEmpty(languagesOffers.translationUpdatesExtra) && shouldShowExtraCharsSection(languagesOffers.translationUpdatesExtra)) {
      sections = [...sections, ADD_EXTRA_CHARACTERS_SECTION]
    }
    return sections;
  }

  const isFirstLanguagePromotionApplicable = get(defaultLanguage, 'isFirstLanguagePromotionApplicable', true)
  const firstLanguagePromotion = get(defaultLanguage, 'firstLanguagePromotion', false)

  // Appears after the first translation flow
  if (!isFirstSession) {
    return (
      <HandlerNewLanguages
        allLanguages={allLanguages}
        languagesOffers={languagesOffers}
        getTranslationOffers={getTranslationOffers}
        languagesTotals={languageTotals}
        onTranslate={onPayTranslate}
        isLoadingPay={isLoadingPay}
        summarySections={summarySectionsAfterFirstSession()}
        isFirstSession={isFirstSession}
        hasVariantIntegration={hasVariantIntegration}
      />
      )
  }

  switch(assistantPage.page) {
    case TRANSLATION_WIZARD.pages.ANALYSE_FINISH :
      return <AnalyseFinish
        setAssistantPage={setWizardAssistantPage}
        installedLanguages={installedLanguages}
        defaultLanguages={defaultLanguage}
      />
    case TRANSLATION_WIZARD.pages.QUESTION_WHAT_TO_DO_WITH_GLOTIO:
      return <Questions
        installedLanguages={installedLanguages}
        setAssistantPage={setWizardAssistantPage}
        setCurrentQuestion={setCurrentQuestion}
        clearCurrentQuestion={clearCurrentQuestion}
        isBigCommerce={get(defaultLanguage, 'isBigCommerce', false)}
        isFirstLanguagePromotionApplicable={isFirstLanguagePromotionApplicable}
        firstLanguagePromotion={firstLanguagePromotion}
      />
    case TRANSLATION_WIZARD.pages.QUESTION_DEFAULT_LANGUAGE:
      return <DefaultLanguage
        setAssistantPage={setWizardAssistantPage}
        installedLanguages={installedLanguages}
        defaultLanguages={defaultLanguage}
        wizzardQuestion={wizzardQuestion}
      />
    case TRANSLATION_WIZARD.pages.WRONG_DEFAULT_LANGUAGE_HELP:
      return <DefaultLanguageHelp
        setAssistantPage={setWizardAssistantPage}
      />
    case TRANSLATION_WIZARD.pages.LANGUAGE_SELECTOR_FREE:
      return <LanguageSelectorFree
        allLanguages={allLanguages}
        setAssistantPage={setWizardAssistantPage}
        languagesOffers={languagesOffers}
        getTranslationOffers={getTranslationOffers}
        onTranslateFree={onPayTranslate}
        isLoadingPay={isLoadingPay}
        defaultLanguages={defaultLanguage}
      />
    case TRANSLATION_WIZARD.pages.LANGUAGE_SELECTOR_MULTIPLE:
      // Appears only in the first translation flow
      return <HandlerNewLanguages
        allLanguages={allLanguages}
        languagesOffers={languagesOffers}
        getTranslationOffers={getTranslationOffers}
        languagesTotals={languageTotals}
        onTranslate={onPayTranslate}
        isLoadingPay={isLoadingPay}
        summaryBackButton={summaryBackButton}
        summarySections={[ADD_LANGUAGES_SECTION, ADD_UPDATE_LANGUAGES_SECTION]}
        wizzardQuestion={wizzardQuestion}
        isFirstSession={isFirstSession}
        hasFromPreviousUpdateType={hasFromPreviousUpdateType}
        defaultLanguages={defaultLanguage}
      />
    case TRANSLATION_WIZARD.pages.LANGUAGE_SELECTOR_UPDATE_TYPE:
      return <UpdateType
        setAssistantPage={setWizardAssistantPage}
      />
    default:
      return <AnalyseFinish setAssistantPage={setWizardAssistantPage} installedLanguages={installedLanguages} defaultLanguages={defaultLanguage} />
  }
}

const mapStateToProps = (state) => ({
  assistantPage: wizardReducer(state, getAssistantPage()),
  isFirstSession: selectIsFirstSession(state),
  wizzardQuestion: questionReducer(state, getQuestion()),
})

const mapDispatchToProps = (dispatch) => ({
  setWizardAssistantPage: bindActionCreators(setAssistantPage, dispatch),
  setCurrentQuestion: bindActionCreators(setQuestion, dispatch),
  clearCurrentQuestion: bindActionCreators(clearQuestion, dispatch),
  setPaymentBackendError: bindActionCreators(paymentError, dispatch),
  setMustShowResumeAction: bindActionCreators(setMustShowResume, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TranslationWizard))
